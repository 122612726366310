import React from 'react';
import { RouterHistory } from 'react-router-dom';
import queryString from 'query-string';
import { InitContext, InitCtx } from '../context/InitProvider';
import auth from '../Auth/Auth';

type Props = {
  history: RouterHistory;
};

const Callback = ({ history }: Props) => {
  const { setAuthSuccess } = React.useContext<InitCtx>(InitContext);

  React.useEffect(() => {
    const qs = queryString.parse(history.location.search);
    const redirectToSavedState = () => {
      const redirectState = auth.getAndClearRedirectState();
      if (redirectState) {
        history.replace(redirectState.pathname + redirectState.search);
      } else {
        history.replace('/');
      }
    };

    if (!auth.isAuthenticated()) {
      if (qs.error) {
        // This state is possible when a partner connects with its IdP and the user is not yet identified for us
        auth.login();
      } else {
        auth.handleAuthentication();
        setAuthSuccess();
        redirectToSavedState();
      }
    } else {
      redirectToSavedState();
    }
  }, [history, setAuthSuccess]);

  return null;
};

export default Callback;
