import styled from 'styled-components';

import { NexoyaSimulationState } from '../../../../types';

import MenuList from 'components/ArrayMenuList';
import ButtonIcon from 'components/ButtonIcon';
import { useMenu } from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import Spinner from 'components/Spinner';
import SvgEllipsisV from 'components/icons/EllipsisV';

const ActionWrapStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SvgEllipsisVStyled = styled(SvgEllipsisV)`
  font-size: 18px;
`;

interface Props {
  loading: boolean;
  simulationId: number;
  state: NexoyaSimulationState;
  handleStart?: () => void;
  handleDelete?: (simulationId: number) => void;
  handleExplore?: (simulationId: number) => void;
  handleEdit?: (simulationId: number) => void;
}

export function SimulationTDM({
  loading,
  simulationId,
  handleStart,
  handleExplore,
  handleDelete,
  handleEdit,
  state,
}: Props) {
  const { anchorEl, open, toggleMenu, closeMenu } = useMenu();
  return (
    <div ref={anchorEl}>
      <ActionWrapStyled>
        {loading ? (
          <div style={{ width: '36px' }}>
            <Spinner size="20px" />
          </div>
        ) : (
          <ButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              toggleMenu();
            }}
            active={open}
            style={{ position: 'static' }}
          >
            <SvgEllipsisVStyled />
          </ButtonIcon>
        )}
      </ActionWrapStyled>
      <Panel
        container={anchorEl.current}
        anchorEl={anchorEl.current}
        open={open}
        onClose={closeMenu}
        placement="bottom-end"
        style={{
          minWidth: 138,
        }}
      >
        <MenuList color="dark">
          {state === NexoyaSimulationState.Pending ? (
            <MenuItem
              key="start-simulation"
              onClick={(e) => {
                e.stopPropagation();
                handleStart();
                closeMenu();
              }}
              color="dark"
            >
              Start simulation
            </MenuItem>
          ) : null}
          {state !== NexoyaSimulationState.Running && state !== NexoyaSimulationState.Pending ? (
            <MenuItem
              key="explore-simulation"
              onClick={(e) => {
                e.stopPropagation();
                handleExplore(simulationId);
                closeMenu();
              }}
              color="dark"
            >
              Explore simulation
            </MenuItem>
          ) : null}

          <MenuItem
            key="edit-simulation"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(simulationId);
              toggleMenu();
            }}
            color="dark"
          >
            Edit
          </MenuItem>
          <MenuItem
            key="remove-simulation"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(simulationId);
              toggleMenu();
            }}
            color="dark"
          >
            Delete
          </MenuItem>
        </MenuList>
      </Panel>
    </div>
  );
}
