type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgUnlink = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.398.962a3.28 3.28 0 014.64 0 3.28 3.28 0 010 4.64l-.99.991-.496.497a.281.281 0 01-.48-.192 4.062 4.062 0 00-.111-.887.28.28 0 01.075-.262l1.074-1.075A1.968 1.968 0 107.326 1.89L5.564 3.652a1.971 1.971 0 00.534 3.165c.109.052.169.17.154.29-.032.26-.147.512-.347.712l-.103.102a.28.28 0 01-.322.054 3.286 3.286 0 01-1.073-4.997l.043-.054c.11-.132.236-.25 1.948-1.962zm1.195 8.06A3.286 3.286 0 006.52 4.025a.28.28 0 00-.322.054l-.103.102c-.2.2-.315.452-.347.713a.282.282 0 00.154.29 1.971 1.971 0 01.535 3.165l-1.763 1.761A1.968 1.968 0 111.89 7.326l1.074-1.074a.28.28 0 00.075-.263 4.062 4.062 0 01-.11-.887c-.004-.247-.306-.366-.48-.192l-.497.497-.99.99a3.28 3.28 0 000 4.641 3.28 3.28 0 004.64 0C7.314 9.327 7.44 9.208 7.55 9.076l.043-.054z"
      fill="#A6A7B5"
    />
  </svg>
);

export default SvgUnlink;
