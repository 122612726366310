const SvgYuhLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 74.002 52.115"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      d="M44.681 12.738a4.145 4.145 0 0 0-4.181 4.109v10.326a3.6 3.6 0 1 1-7.176 0v-10.33a4.178 4.178 0 0 0-8.356 0v10.33a11.945 11.945 0 0 0 23.888.309V16.843a4.141 4.141 0 0 0-4.175-4.105Zm-24.971.073a4.144 4.144 0 0 0-4.178 4.11v10.938c0 2.41-1.609 4.372-3.588 4.372s-3.588-1.958-3.588-4.372V16.928a4.144 4.144 0 0 0-4.171-4.117h-.007A4.144 4.144 0 0 0 0 16.921v10.938c0 6.952 5.358 12.607 11.944 12.607a11.334 11.334 0 0 0 3.588-.582v8.054a4.178 4.178 0 0 0 8.356 0v-31.01a4.151 4.151 0 0 0-4.178-4.117Zm42.347-1.163a11.366 11.366 0 0 0-3.588.581V4.176a4.178 4.178 0 0 0-8.356 0V35.19a4.178 4.178 0 0 0 8.356 0V24.259c0-2.41 1.61-4.372 3.588-4.372s3.589 1.962 3.589 4.372V35.19a4.178 4.178 0 0 0 8.356 0V24.259C74 17.3 68.644 11.648 62.057 11.648Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgYuhLogo;
