import { gql, useQuery } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

const NEW_OPTIMIZATION_SUMMARY = gql`
  query NewOptimizationSummary($teamId: Int!, $portfolioId: Int!, $end: Date!) {
    newOptimizationSummary(teamId: $teamId, portfolioId: $portfolioId, end: $end) {
      isCustomImportDataFresh
      totalBudget
      hasIncorrectEndDateMonth
      allContentsHaveImpactGroupAssigned
      isOptiPeriodSpanningMultipleTargetItems
      targetItem {
        targetItemId
        name
        start
        end
        maxBudget
        value
      }
    }
  }
`;
type Options = {
  portfolioId: number;
  end: string;
};

function useNewOptimizationSummary({ portfolioId, end }: Options) {
  const { teamId } = useTeam();
  return useQuery(NEW_OPTIMIZATION_SUMMARY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      teamId,
      portfolioId,
      end,
    },
  });
}

export { NEW_OPTIMIZATION_SUMMARY, useNewOptimizationSummary };
