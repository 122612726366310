import { LogoIcon } from '../../../../components/Logo';
import Typography from '../../../../components/Typography';
import SvgGauge from '../../../../components/icons/Gauge';

import * as Styles from '../../styles/OptimizationProposal';

import NoDataFound from '../../NoDataFound';
import WhatIfValidationChart from './DetailedReportPerformanceChart';
import WhatIfValidationTable from './DetailedReportPerformanceTable';

export const WhatIfValidation = ({
  loading,
  validationPerformance,
  portfolioName,
  showDetailsTable,
  funnelSteps = [],
  selectedFunnelStep = null,
}) => {
  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <Styles.LoadingWrapStyled style={{ marginTop: 40 }}>
          <LogoIcon infinite={true} duration={1500} />
          <Styles.LoadingContent>
            <Typography variant="h1">Getting everything ready...</Typography>
            <Typography variant="h5">It might take up to a minute to load your detailed performance report.</Typography>
          </Styles.LoadingContent>
        </Styles.LoadingWrapStyled>
      ) : validationPerformance.length !== 0 ? (
        <>
          <Styles.FunnelStepsDropdown>
            <Styles.SubtitleStyled>Performance per funnel step</Styles.SubtitleStyled>
          </Styles.FunnelStepsDropdown>
          {showDetailsTable ? (
            <WhatIfValidationTable
              funnelSteps={funnelSteps}
              data={validationPerformance}
              activeFunnelStepId={selectedFunnelStep?.funnel_step_id}
              showOptimized={false}
            />
          ) : null}
          <Styles.PerformanceWrapper>
            <WhatIfValidationChart
              data={validationPerformance}
              targetFunnelStepId={selectedFunnelStep?.funnelStepId}
              showOptimized={false}
              portfolioName={portfolioName}
            />
          </Styles.PerformanceWrapper>
        </>
      ) : (
        <NoDataFound
          icon={<SvgGauge />}
          title="There is no optimization within the selected time period"
          subtitle="Please select alternative dates from the date picker to view the optimization performance"
        />
      )}
    </div>
  );
};
