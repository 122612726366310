import * as Styles from 'components/Charts/styles/PortfolioPerformanceChart';
import SwitchFluid from 'components/SwitchFluid';

import PortfolioChartLegend from '../PortfolioChartLegend';

type Props = {
  active: string;
  title: string;
  showSwitcher: boolean;
  onToggle: (e: 'left' | 'right') => void;
};

function OldPerformanceChartHeader({ active, title, showSwitcher, onToggle }: Props) {
  return (
    <Styles.ChartHeaderContainer>
      <PortfolioChartLegend active={active} title={title} />
      <div>
        {showSwitcher ? (
          <SwitchFluid
            activeText="Performance"
            inactiveText={`Cost per ${title}`}
            initial={active === 'performance' ? 'left' : 'right'}
            onToggle={onToggle}
          />
        ) : null}
      </div>
    </Styles.ChartHeaderContainer>
  );
}

export { OldPerformanceChartHeader };
