import React, { forwardRef } from 'react';

import * as Styles from './styles';

export const classes = {
  root: 'NEXYMultipleSwitch',
  section: 'NEXYMultipleSwitchSection',
};

interface Props {
  disabled?: boolean;
  switchNotAllowed?: boolean;
  onToggle?: (e: string) => void;
  sections: { id: string; text: string }[];
  style?: Record<string, unknown>;
  current?: string;
}

const MultipleSwitch = forwardRef<Props, any>(function Switch(props, ref) {
  const { switchNotAllowed, onToggle, sections, current, initial, ...rest } = props;

  const toggle = React.useCallback(
    (id: string) => {
      onToggle && onToggle(id);
    },
    [onToggle]
  );

  return (
    <Styles.Wrapper className={classes.root} notAllowed={switchNotAllowed} {...rest} ref={ref}>
      {sections.map(({ id, text }) => (
        <Styles.Section
          className={classes.section}
          key={id}
          active={current ? current === id : initial}
          onClick={() => toggle(id)}
        >
          <span>{text}</span>
        </Styles.Section>
      ))}
    </Styles.Wrapper>
  );
});

export default MultipleSwitch;
