type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgDownload = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 100 100"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path d="M89.699 68.051v17.574c0 .352-.05.676-.125.977-.352 1.726-1.875 3.023-3.676 3.023H14.074c-1.926 0-3.523-1.477-3.726-3.352-.051-.226-.051-.425-.051-.675V68.024c0-2.075 1.699-3.75 3.75-3.75a3.762 3.762 0 013.75 3.75v14.074h64.426V68.024c0-2.075 1.699-3.75 3.75-3.75 1.023 0 1.976.425 2.648 1.101.653.7 1.078 1.649 1.078 2.676z" />
    <path d="M71.625 50.449L53.199 68.875c-.023.05-.074.074-.102.102a4.344 4.344 0 01-2.425 1.226c-.075 0-.149.024-.227.024-.148.023-.3.023-.45.023l-.425-.023c-.074 0-.148-.024-.226-.024a4.27 4.27 0 01-2.426-1.226l-.102-.102L28.39 50.449c-.851-.852-1.273-1.977-1.273-3.102s.426-2.25 1.273-3.101c1.7-1.7 4.477-1.7 6.2 0L45.662 55.32l.012-40.57c0-2.399 1.977-4.375 4.375-4.375 1.2 0 2.3.5 3.102 1.273a4.32 4.32 0 011.273 3.102v40.574L65.5 44.25c1.7-1.7 4.477-1.7 6.2 0 1.624 1.726 1.624 4.5-.075 6.199z" />
  </svg>
);

export default SvgDownload;
