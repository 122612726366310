import clsx from 'clsx';
import { get } from 'lodash';
import styled from 'styled-components';

import { useTeamQuery } from '../../graphql/team/queryTeam';

import '../../theme/theme';

type Props = {
  amount: number;
  className?: string;
  withColor?: boolean;
  showDecimals?: boolean;
};
export const classes = {
  root: 'NEXYFormattedCurrency',
};

interface StyledWrapperFormattedCurrencyProps {
  withColor?: boolean;
  amount: number;
}

const StyledWrapper = styled.span<StyledWrapperFormattedCurrencyProps>`
  color: ${({ withColor, amount, theme }) =>
    withColor
      ? amount >= 0 // ? `${theme.colors.success}` // TODO: Figure out when to enable success coloring
        ? `default`
        : `${theme.colors.danger}`
      : 'default'};
`;

function FormattedCurrency({
  amount = 0,
  className = '',
  withColor = false,
  showDecimals = true, // Added a new prop for showing decimals
}: Props) {
  const teamQuery = useTeamQuery({
    withMembers: false,
    withOrg: false,
  });

  const currency = get(teamQuery, 'data.team.currency', 'CHF') || 'CHF';
  const numberFormat = get(teamQuery, 'data.team.number_format', 'de-CH') || 'de-CH';

  // Modify the options based on showDecimals prop
  const formatOptions = {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: showDecimals ? 2 : 0, // Show 2 decimals if showDecimals is true, else 0
    maximumFractionDigits: showDecimals ? 2 : 0,
  };

  return (
    <StyledWrapper withColor={withColor} amount={amount} className={clsx(className, classes.root)}>
      {Intl.NumberFormat(numberFormat, formatOptions).format(amount)}
    </StyledWrapper>
  );
}

export default FormattedCurrency;
