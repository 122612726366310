import { gql, useMutation } from '@apollo/client';

import { NexoyaPortfolioContentFunnelStepMetricInput } from 'types';

import { useTeam } from '../../context/TeamProvider';

const UPDATE_CONTENT_TARGET_METRIC_MUTATION = gql`
  mutation updateContentTargetMetric(
    $teamId: Int!
    $portfolioId: Int!
    $contentFunnelStepMetric: PortfolioContentFunnelStepMetricInput!
  ) {
    updateContentTargetMetric(
      teamId: $teamId
      portfolioId: $portfolioId
      contentFunnelStepMetric: $contentFunnelStepMetric
    )
  }
`;
type Options = {
  portfolioId: number;
};
type ExtendedOptions = {
  contentFunnelStepMetric: NexoyaPortfolioContentFunnelStepMetricInput;
};

function useUpdateContentTargetMetric({ portfolioId }: Options): any {
  const { teamId } = useTeam();
  const [mutation, state] = useMutation(UPDATE_CONTENT_TARGET_METRIC_MUTATION, {
    variables: {
      teamId,
      portfolioId,
    },
  });

  function extendMutation({ contentFunnelStepMetric }: ExtendedOptions) {
    return mutation({
      variables: {
        teamId,
        portfolioId,
        contentFunnelStepMetric,
      },
    });
  }

  return [mutation, state, extendMutation];
}

export { UPDATE_CONTENT_TARGET_METRIC_MUTATION, useUpdateContentTargetMetric };
