function SvgGauge(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      display="block"
      aria-hidden={true}
      focusable={false}
      role="presentation"
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="#E6F9F0" />
      <path
        d="M6.275 21.456c-.334-1.01-.515-2.412-.515-3.536 0-5.655 4.584-10.24 10.24-10.24 5.655 0 10.24 4.585 10.24 10.24 0 1.12-.18 2.517-.512 3.526-.096.292 0 0 0 0-.18.474-.745.77-1.267.67 0 0-2.67-.676-8.461-.676-5.792 0-8.461.677-8.461.677-.52.104-1.096-.215-1.264-.66.035.107 0 0 0 0z"
        fill="#0EC76A"
        fillOpacity={0.3}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11.84a1.28 1.28 0 110-2.56 1.28 1.28 0 010 2.56zM18.116 20a2.56 2.56 0 11-2.207-1.118l4.122-7.34a1.01 1.01 0 011.23-.442c.41.264.575.805.368 1.256L18.117 20zm3.146-8.9a.952.952 0 00-.087-.05l.177.09a.952.952 0 00-.09-.04zM9.919 12.8a1.28 1.28 0 102.56 0 1.28 1.28 0 00-2.56 0zm-1.28 6.08a1.28 1.28 0 110-2.56 1.28 1.28 0 010 2.56zm13.44-1.28a1.28 1.28 0 102.56 0 1.28 1.28 0 00-2.56 0z"
        fill="#0EC76A"
      />
    </svg>
  );
}

export default SvgGauge;
