import { FetchResult, MutationFunctionOptions, MutationResult, gql, useMutation } from '@apollo/client';

import { NexoyaPortfolioContentBudgetInput } from '../../types';

import { useTeam } from '../../context/TeamProvider';

import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';

import { PORTFOLIO_QUERY } from './queryPortfolio';

const UPDATE_PORTFOLIO_CONTENT_BUDGET_BOUNDARIES = gql`
  mutation updatePortfolioContentBudgetBoundaries(
    $teamId: Float!
    $portfolioId: Float!
    $contents: [Float!]
    $newBudget: PortfolioContentBudgetInput!
  ) {
    updatePortfolioContentBudgetBoundaries(
      teamId: $teamId
      portfolioId: $portfolioId
      onlyContentIds: $contents
      newBudget: $newBudget
    )
  }
`;
type Options = {
  portfolioId: number;
  contents: number[];
  newBudget: NexoyaPortfolioContentBudgetInput;
  dateFrom?: Date;
  dateTo?: Date;
};

type IMutationResult = [
  (
    options?: MutationFunctionOptions<
      Options,
      {
        portfolioId: number;
        contents: number[];
        newBudget: NexoyaPortfolioContentBudgetInput;
        teamId: number;
      }
    >
  ) => Promise<FetchResult<void>>,
  MutationResult
];

function useUpdatePortfolioContentBudgetBoundaries({
  portfolioId,
  contents,
  newBudget,
  dateFrom,
  dateTo,
}: Options): IMutationResult {
  const { teamId } = useTeam();
  const [mutation, state] = useMutation(UPDATE_PORTFOLIO_CONTENT_BUDGET_BOUNDARIES, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    variables: {
      teamId,
      portfolioId,
      contents,
      newBudget,
    },
    onCompleted: () => track(EVENT.PORTFOLIO_CONTENT_BUDGET_BOUNDARIES_UPDATE),
    refetchQueries: [
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          portfolioId,
          dateFrom,
          dateTo,
          withBudget: false,
          withOptimization: false,
        },
      },
    ],
  });
  return [mutation, state];
}

export { UPDATE_PORTFOLIO_CONTENT_BUDGET_BOUNDARIES, useUpdatePortfolioContentBudgetBoundaries };
