import React from 'react';

import { PageHeader, PageHeaderDescription, PageHeaderTitle } from '../PageHeader';
import Typography from '../Typography';

export const PortfolioHeaderTitle = ({ title, description }: { title: string; description: string }) => {
  return (
    <PageHeader>
      <div>
        <PageHeaderTitle>
          <Typography variant="h1" component="h2">
            {title}
          </Typography>
        </PageHeaderTitle>
        <PageHeaderDescription addTitleIconSpace={false}>
          <Typography variant="subtitle">{(description || '').trim() || 'No portfolio description'}</Typography>
        </PageHeaderDescription>
      </div>
    </PageHeader>
  );
};
