import React, { useState } from 'react';

type FormValues = {
  targetItemName: string;
  value: string;
  maxBudget: string;
  start: Date;
  end: Date;
};

function useTargetItemController(): Record<string, any> {
  const initialState: FormValues = {
    targetItemName: '',
    start: null,
    end: null,
    value: '',
    maxBudget: '',
  };
  const [lastTargetNumber, setLastTargetNumber] = useState<string>(undefined);
  const [lastMaxBudgetNumber, setLastMaxBudgetNumber] = useState<string>(undefined);
  const [targetItemState, setTargetItemState] = useState(initialState);

  const handleChangeValueByKey = React.useCallback(
    (ev: any) => {
      const { name, value } = ev.target;
      setTargetItemState((s) => ({ ...s, [name]: value }));
    },
    [setTargetItemState]
  );

  const resetState = () => {
    setTargetItemState(initialState);
    setLastTargetNumber(undefined);
    setLastMaxBudgetNumber(undefined);
  };

  return {
    lastTargetNumber,
    setLastTargetNumber,
    lastMaxBudgetNumber,
    setLastMaxBudgetNumber,
    targetItemState,
    handleChangeValueByKey,
    resetState,
  };
}

export default useTargetItemController;
