import dayjs from 'dayjs';

import { DateRange } from 'context/DateProvider';

import { DATE_RANGES, DateRangeOptions } from 'components/DateSelector/utils';

export const getAllTimeRange = (portfolioStartDate: Date, portfolioEndDate: Date) => ({
  from: portfolioStartDate,
  to: portfolioEndDate,
});

const isDateRangeWithinPeriod = (dateRange: DateRange, startDate: Date, endDate: Date): boolean => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  return (
    dayjs(dateRange.from).isBetween(start, end, 'day', '[]') && dayjs(dateRange.to).isBetween(start, end, 'day', '[]')
  );
};

export const getPortfolioDateRanges = (portfolioStartDate: Date, portfolioEndDate: Date) => {
  const portfolioDateRanges: DateRangeOptions = {};
  const updatedDateRanges = {
    ...DATE_RANGES,
  };

  portfolioDateRanges.allTime = {
    name: 'All time',
    isPast: true,
    getDateRange: () => getAllTimeRange(portfolioStartDate, portfolioEndDate),
  };

  if (dayjs(portfolioStartDate).year() < dayjs().year()) {
    updatedDateRanges.lastYear = {
      name: 'Last year',
      isPast: true,
      getDateRange: () => ({
        from: portfolioStartDate,
        to: dayjs().subtract(1, 'year').endOf('year').toDate(),
      }),
    };
  }

  Object.entries(updatedDateRanges).forEach(([key, value]) => {
    if (isDateRangeWithinPeriod(value.getDateRange(), portfolioStartDate, portfolioEndDate)) {
      portfolioDateRanges[key] = value;
    }
  });

  return portfolioDateRanges;
};

export const getCompareDateRanges = (start: Date, end: Date) => {
  const daysDifference = dayjs(end).diff(start, 'day') + 1;

  return {
    previousPeriod: {
      name: 'Previous period',
      isPast: true,
      getDateRange: () => ({
        from: dayjs(start).subtract(daysDifference, 'day').toDate(),
        to: dayjs(start).subtract(1, 'day').toDate(),
      }),
    },
    previousMonth: {
      name: 'Previous month',
      isPast: true,
      getDateRange: () => ({
        from: dayjs(start).subtract(1, 'month').toDate(),
        to: dayjs(end).subtract(1, 'month').toDate(),
      }),
    },
    previousYear: {
      name: 'Previous year',
      isPast: true,
      getDateRange: () => ({
        from: dayjs(start).subtract(1, 'year').toDate(),
        to: dayjs(end).subtract(1, 'year').toDate(),
      }),
    },
  };
};
