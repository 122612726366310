import { Fragment, useEffect, useState } from 'react';

import { toast } from 'sonner';

type Props = {
  error:
    | Error
    | {
        message: string;
      };
  className?: string;
};

const ErrorMessage = ({ error }: Props) => {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-console
  console.log(error);
  useEffect(() => {
    setOpen(true);
  }, []);

  if (open) {
    toast.error(error.message);
  }

  return <Fragment />;
};

export default ErrorMessage;
