type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
  className?: string;
  fill?: string;
};

export const SvgPin = (props: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M10.8697 11.9833C10.913 11.9004 10.9728 11.8272 11.0453 11.7681C11.1179 11.7091 11.2017 11.6654 11.2916 11.6397C11.3816 11.6141 11.4758 11.607 11.5686 11.6189C11.6614 11.6308 11.7508 11.6614 11.8313 11.709L12.2465 11.9485C12.3279 11.9946 12.3991 12.0567 12.4558 12.1311C12.5124 12.2055 12.5534 12.2907 12.5761 12.3814C12.5988 12.4721 12.6029 12.5665 12.588 12.6589C12.5732 12.7512 12.5397 12.8396 12.4897 12.9186L9.18294 18.2154L8.03718 18.9723C7.92774 19.0446 7.84518 18.9978 7.85334 18.866L7.9359 17.4956L10.8697 11.9831V11.9833Z"
        fill={props.fill || '#B4A7F6'}
      />
      <path
        d="M17.44 9.06138L15.5215 12.9218C16.0284 14.1938 15.5582 15.1864 15.5582 15.1864C15.4507 15.5685 15.0734 15.7432 14.7134 15.5714C14.7134 15.5714 13.175 14.8759 11.1 13.6778C9.02468 12.4797 7.65332 11.4952 7.65332 11.4952C7.32692 11.2691 7.27412 10.8448 7.56548 10.5717C7.56548 10.5717 8.19044 9.6717 9.54236 9.47322L11.928 5.8785C10.7044 4.23737 13.0843 3.73841 15.9 5.36394C18.7214 6.99282 19.4764 9.30258 17.44 9.06138Z"
        fill={props.fill || '#744CED'}
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
