import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'sonner';

import { NexoyaPortfolioContentDetail, NexoyaPortfolioLabel } from 'types';

import { useTeam } from '../../../../context/TeamProvider';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';

import Typography from '../../../../components/Typography';
import MenuList from 'components/ArrayMenuList';
import Button from 'components/Button';
import ButtonAdornment from 'components/ButtonAdornment';
import { useDropdownMenu } from 'components/DropdownMenu';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import { ASSIGN_LABEL_TO_PORTFOLIO_CONTENT_MUTATION } from '../../../../graphql/labels/mutationAssignLabelToPortfolioContent';
import { PORTFOLIO_QUERY } from '../../../../graphql/portfolio/queryPortfolio';
import { updateApolloCache, updatePortfolioContentDetailsLabelCache } from '../../../../utils/cache';
import { ChevronsUpDown } from 'lucide-react';
import { usePortfolio } from '../../../../context/PortfolioProvider';

interface Props {
  content: NexoyaPortfolioContentDetail;
  portfolioId: number;
  dateFrom: Date;
  dateTo: Date | string;
}
export function ContentLabelPanel({ content, portfolioId, dateFrom, dateTo }: Props) {
  const { teamId } = useTeam();
  const anchorElPanel = React.useRef(null);
  const { anchorEl: anchorElLabel, open: openLabel, closeMenu: closeLabelMenu, toggleMenu } = useDropdownMenu();

  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const labels = portfolioMeta?.labels;

  const [assignLabelToPortfolioContent, { loading }] = useMutation(ASSIGN_LABEL_TO_PORTFOLIO_CONTENT_MUTATION);

  const onClickListener = React.useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      const containsInstance = anchorElPanel.current && anchorElPanel.current.contains(event.target);
      if (containsInstance) {
        return toggleMenu();
      }
      return closeLabelMenu();
    },
    [anchorElPanel, toggleMenu, closeLabelMenu],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', onClickListener);
    return function () {
      document.removeEventListener('mousedown', onClickListener);
    };
  }, []);

  function handleAddContentToLabel(label: NexoyaPortfolioLabel) {
    assignLabelToPortfolioContent({
      notifyOnNetworkStatusChange: false,
      variables: {
        contentIds: [content.contentId],
        labelId: label.labelId,
        portfolioId,
        teamId,
      },
    })
      .then(({ data }) => {
        if (data?.bulkAssignLabels) {
          updateApolloCache({
            query: PORTFOLIO_QUERY,
            variables: {
              teamId,
              portfolioId,
              dateFrom,
              dateTo,
              withBudget: false,
            },
            updateFn: updatePortfolioContentDetailsLabelCache({ contentId: content?.contentId, label }),
          });

          closeLabelMenu();
          track(EVENT.CONTENT_CHANGE_LABEL, {
            contentId: content.contentId,
            labelId: label.labelId,
          });
        }
      })
      .catch((err) => {
        toast(err.message);
        console.error(err);
      });
  }

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        flat
        type="button"
        onClick={toggleMenu}
        active={openLabel}
        className="NEXYButtonMetric !rounded-md !px-1.5 !py-0.5 hover:bg-seasalt"
        loading={loading}
        endAdornment={
          <ButtonAdornment position="end">
            <ChevronsUpDown className={`h-4 w-4 ${content?.label?.name ? 'text-blue-grey' : 'text-neutral-200'}`} />
          </ButtonAdornment>
        }
        ref={anchorElLabel}
      >
        {content?.label?.name ?? <span className="text-neutral-200">None</span>}
      </Button>
      <Panel
        open={openLabel}
        color="dark"
        ref={anchorElPanel}
        anchorEl={anchorElLabel.current}
        placement="bottom-start"
        style={{
          minWidth: 200,
          maxHeight: 500,
        }}
      >
        <MenuList color="dark">
          {labels?.map((label: NexoyaPortfolioLabel) => (
            <MenuItem
              key={`label-${label.labelId}`}
              loading={loading}
              disabled={loading}
              async
              style={{
                minWidth: 125,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleAddContentToLabel(label);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography>{label.name}</Typography>
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </Panel>
    </>
  );
}
