const SvgBdPortfolioLifetime = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 102 58"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <g filter="url(#bd-portfolio-lifetime_svg__a)">
      <path fill="#674CED" fillOpacity={0.16} d="M50.398 5h-14.4v24h14.4z" />
    </g>
    <g filter="url(#bd-portfolio-lifetime_svg__b)">
      <path fill="#674CED" fillOpacity={0.16} d="M66.797 5h-14.4v24h14.4z" />
    </g>
    <g filter="url(#bd-portfolio-lifetime_svg__c)">
      <path fill="#674CED" fillOpacity={0.16} d="M83.203 5h-14.4v24h14.4z" />
    </g>
    <g filter="url(#bd-portfolio-lifetime_svg__d)">
      <path fill="#674CED" fillOpacity={0.16} d="M99.602 5h-14.4v24h14.4z" />
    </g>
    <path d="M0 29.352H102.01" stroke="#744CED" strokeWidth={1.2} strokeDasharray="5.09 5.09" />
    <path d="M33 .3a.3.3 0 1 1 .6 0v57.3H33V.3Z" fill="#C12017" />
    <path d="M16 31h14.4v21.72A2.28 2.28 0 0 1 28.12 55h-9.84A2.28 2.28 0 0 1 16 52.72V31Z" fill="#05A8FA" />
    <defs>
      <filter
        id="bd-portfolio-lifetime_svg__a"
        x={36}
        y={5}
        width={14.398}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.425} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.403 0 0 0 0 0.2976 0 0 0 0 0.93 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_9720_114828" />
      </filter>
      <filter
        id="bd-portfolio-lifetime_svg__b"
        x={52.398}
        y={5}
        width={14.398}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.425} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.403 0 0 0 0 0.2976 0 0 0 0 0.93 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_9720_114828" />
      </filter>
      <filter
        id="bd-portfolio-lifetime_svg__c"
        x={68.805}
        y={5}
        width={14.398}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.425} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.403 0 0 0 0 0.2976 0 0 0 0 0.93 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_9720_114828" />
      </filter>
      <filter
        id="bd-portfolio-lifetime_svg__d"
        x={85.203}
        y={5}
        width={14.398}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.425} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.403 0 0 0 0 0.2976 0 0 0 0 0.93 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_9720_114828" />
      </filter>
    </defs>
  </svg>
);
export default SvgBdPortfolioLifetime;
