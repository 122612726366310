import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components-ui/DropdownMenu';

import { usePortfolio } from '../../../../../context/PortfolioProvider';

import { track } from '../../../../../constants/datadog';
import { EVENT } from '../../../../../constants/events';

import Switch from '../../../../../components/Switch';
import SvgCog from '../../../../../components/icons/Cog';

import { SwitchContainerStyled } from '../../Funnel/styles';
import { ButtonStyled } from '../PerformanceChartHeader.styles';
import { BooleanParam, useQueryParam } from 'use-query-params';

function Customization() {
  const [dateComparisonActive] = useQueryParam('dateComparisonActive', BooleanParam);

  const {
    performanceChart: {
      isStackedAreaChartActive,
      setIsStackedAreaChartActive,
      conversionRateToggle,
      setConversionRateToggle,
      compareTo,
      setCompareTo,
    },
  } = usePortfolio();

  const handleConversionRateToggle = (e) => {
    e?.preventDefault();
    setConversionRateToggle((prevState) => !prevState);
    track(EVENT.PERFORMANCE_FUNNEL_CUSTOMIZE, {
      'Show conversion rate': !conversionRateToggle,
    });
  };

  const handleStackedAreaChartToggle = (e) => {
    e?.preventDefault();
    setIsStackedAreaChartActive((prevState) => !prevState);
    track(EVENT.PERFORMANCE_FUNNEL_CUSTOMIZE, {
      'Show stacked area chart': !isStackedAreaChartActive,
    });
  };

  const handleCompareToToggle = (e) => {
    e?.preventDefault();
    setCompareTo((prevState) => !prevState);
    track(EVENT.PERFORMANCE_FUNNEL_CUSTOMIZE, {
      'Compare to rest of the data': !compareTo,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={dateComparisonActive} asChild>
        <ButtonStyled variant="contained" color="secondary" data-cy="filterSourceBtn">
          <SvgCog />
          <span style={{ marginLeft: 5 }}>Customize</span>
        </ButtonStyled>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-72 font-normal" align="end">
        <DropdownMenuItem onSelect={handleConversionRateToggle}>
          <SwitchContainerStyled>
            <p>Show conversion rate</p>
            <Switch onToggle={handleConversionRateToggle} isOn={conversionRateToggle} />
          </SwitchContainerStyled>
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={handleStackedAreaChartToggle}>
          <SwitchContainerStyled>
            <p>Show stacked area chart</p>
            <Switch onToggle={handleStackedAreaChartToggle} isOn={isStackedAreaChartActive} />
          </SwitchContainerStyled>
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={handleCompareToToggle}>
          <SwitchContainerStyled>
            <p>Compare to rest of the data</p>
            <Switch onToggle={handleCompareToToggle} isOn={compareTo} />
          </SwitchContainerStyled>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { Customization };
