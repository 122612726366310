const SvgLowerPrioInfoCircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      d="M.5 8C.5 3.862 3.872.5 8.036.5s7.536 3.362 7.536 7.5c0 4.141-3.372 7.5-7.536 7.5S.5 12.141.5 8Z"
      fill="#20B7E9"
      stroke="#fff"
    />
    <path
      d="M8.034 8.945a1.28 1.28 0 0 0-1.284 1.278 1.28 1.28 0 0 0 1.284 1.278 1.28 1.28 0 0 0 1.283-1.278 1.28 1.28 0 0 0-1.283-1.278ZM6.82 4.351l.208 3.778c.01.177.156.315.334.315h1.355a.334.334 0 0 0 .334-.315l.207-3.777A.334.334 0 0 0 8.924 4H7.155a.334.334 0 0 0-.334.351Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLowerPrioInfoCircle;
