import { get } from 'lodash';

import { useUserQuery } from '../graphql/user/queryUser';

import { isPresenterMode } from '../utils/isPresenterMode';

const USER_ROLE_PRESENTER = `{role:readonly}`;
export default function usePresenterMode(): {
  isPresenterMode: boolean;
  loading: boolean;
} {
  const { data, loading } = useUserQuery();
  const userRole = get(data, 'user.activeRole.name', '') || '';
  return {
    isPresenterMode: isPresenterMode() || userRole === USER_ROLE_PRESENTER || loading,
    loading,
  };
}
