import { gql, useQuery } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

const FUNNEL_STEPS_V2_QUERY = gql`
  query GlobalFunnelSteps($teamId: Int!, $portfolioId: Int!) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      funnelSteps {
        funnelStepId
        title
        type
      }
    }
  }
`;

type GlobalOptions = {
  portfolioId: number;
};

function useFunnelStepsV2Query({ portfolioId }: GlobalOptions) {
  const { teamId } = useTeam();
  const query = useQuery(FUNNEL_STEPS_V2_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      portfolioId,
    },
  });
  return query;
}

export { useFunnelStepsV2Query, FUNNEL_STEPS_V2_QUERY };
