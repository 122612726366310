import { gql, useMutation } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';

import { PORTFOLIO_QUERY } from './queryPortfolio';

const UPDATE_PORTFOLIO_CONTENT_STATUS_MUTATION = gql`
  mutation updatePortfolioContentIncludedInOptimization(
    $teamId: Float!
    $portfolioId: Float!
    $portfolioContentId: Float!
    $isIncludedInOptimization: Boolean!
  ) {
    updatePortfolioContentIncludedInOptimization(
      teamId: $teamId
      portfolioId: $portfolioId
      portfolioContentId: $portfolioContentId
      isIncludedInOptimization: $isIncludedInOptimization
    )
  }
`;

type Options = {
  portfolioId: number;
  portfolioContentId: number;
  isIncludedInOptimization?: boolean;
  dateFrom: Date;
  dateTo: Date;
};

function useMutationUpdatePortfolioContentStatus({
  portfolioId,
  portfolioContentId,
  isIncludedInOptimization,
  dateFrom,
  dateTo,
}: Options): any {
  const { teamId } = useTeam();
  const [mutation, state] = useMutation(UPDATE_PORTFOLIO_CONTENT_STATUS_MUTATION, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    variables: {
      teamId,
      portfolioId,
      portfolioContentId,
      isIncludedInOptimization,
    },
    onCompleted: () => {
      track(isIncludedInOptimization ? EVENT.ENABLE_CONTENT_OPTIMIZATION : EVENT.DISABLE_CONTENT_OPTIMIZATION);
    },
    refetchQueries: [
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          portfolioId,
          dateFrom,
          dateTo,
          withBudget: false,
          withOptimization: false,
        },
      },
    ],
  });
  return [mutation, state];
}

export { UPDATE_PORTFOLIO_CONTENT_STATUS_MUTATION, useMutationUpdatePortfolioContentStatus };
