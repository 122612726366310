import { gql, QueryResult, useQuery } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { NexoyaPortfolioV2 } from '../../types';
import { FUNNEL_STEP_FRAGMENT, OPTIMIZATIONS_V2_FRAGMENT } from './fragments';

const PORTFOLIO_PERFORMANCE_QUERY = gql`
  query Performance($teamId: Int!, $portfolioId: Int!, $period: DateRangeInput!, $comparisonPeriod: DateRangeInput) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      performance(period: $period, comparisonPeriod: $comparisonPeriod) {
        funnelSteps {
          ...FunnelStepFragment
        }
        optimizations {
          ...OptimizationsV2Fragment
        }
      }
    }
  }
  ${FUNNEL_STEP_FRAGMENT}
  ${OPTIMIZATIONS_V2_FRAGMENT}
`;

type Options = {
  portfolioId: number;
  funnelStepId?: number;
  start?: string | Date;
  end?: string | Date;
  teamId?: number;
  period: { start: string; end: string };
  comparisonPeriod?: { start: string; end: string };
};

type usePerformanceQueryType = (options: Options) => QueryResult<{ portfolioV2: NexoyaPortfolioV2 }, Options>;

const usePerformanceQuery: usePerformanceQueryType = ({
  portfolioId,
  period,
  comparisonPeriod,
}: {
  portfolioId: number;
  period: { start: string; end: string };
  comparisonPeriod?: { start: string; end: string };
}) => {
  const { teamId } = useTeam();

  return useQuery(PORTFOLIO_PERFORMANCE_QUERY, {
    variables: { teamId, portfolioId, period, comparisonPeriod },
    fetchPolicy: 'cache-first',
  });
};

export { usePerformanceQuery, PORTFOLIO_PERFORMANCE_QUERY };
