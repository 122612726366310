import { Match, withRouter } from 'react-router-dom';

import styled from 'styled-components';

import { NexoyaPortfolioContentDetail } from '../../../../types';

import { useMutationUpdatePortfolioContentStatus } from '../../../../graphql/portfolio/mutationUpdatePortfolioContentStatus';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';

import Button from '../../../../components/Button';
import ButtonAsync from '../../../../components/ButtonAsync';
import Dialog from '../../../../components/Dialog';
import DialogActions from '../../../../components/DialogActions';
import DialogContent from '../../../../components/DialogContent';
import DialogTitle from '../../../../components/DialogTitle';
import ErrorMessage from '../../../../components/ErrorMessage';
import Typography from '../../../../components/Typography';

import { nexyColors } from '../../../../theme';

type Props = {
  isOpen: boolean;
  toggleDialog: () => void;
  item: NexoyaPortfolioContentDetail;
  match: Match;
  dateFrom?: Date;
  dateTo?: Date;
};

const StyledDescription = styled.p`
  font-weight: 400;
  color: ${nexyColors.blueGrey};
  display: inline-block;
  letter-spacing: 0.2px;
  line-height: 21px;
`;

const STATUS_TEXT: {
  [key: string]: {
    CTA: string;
    title: string;
    description: JSX.Element | string;
  };
} = {
  true: {
    CTA: "Yes, I'm sure",
    title: 'Are you sure you want to exclude this content from the optimization?',
    description: (
      <span>
        Excluded campaigns will not receive any optimization proposals and will remain untouched by Nexoya. They will
        still be visible in the performance overview.
      </span>
    ),
  },
  false: {
    CTA: 'Add to optimization',
    title: 'Add this content back to the optimization?',
    description: (
      <span>
        This content will be included in the next optimization.
        <br />
        <br />
        💭 We recommend double-checking if the portfolio budget is up to date.
      </span>
    ),
  },
};

function ContentOptimizationDialogComponent({ item, isOpen, toggleDialog, match, dateFrom, dateTo }: Props) {
  const { isIncludedInOptimization } = item;
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const [updateContentStatus, { loading, error }] = useMutationUpdatePortfolioContentStatus({
    portfolioId,
    isIncludedInOptimization: !isIncludedInOptimization,
    portfolioContentId: item.portfolioContentId,
    dateFrom,
    dateTo,
  });

  const handleSubmit = () => {
    updateContentStatus()
      .then(() => {
        toggleDialog();
        if (isIncludedInOptimization) {
          track(EVENT.CONTENT_INCLUDE_IN_OPTIMIZATION, {
            portfolioId,
            portfolioContentId: item.portfolioContentId,
          });
        } else {
          track(EVENT.CONTENT_EXCLUDE_FROM_OPTIMIZATION, {
            portfolioId,
            portfolioContentId: item.portfolioContentId,
          });
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={toggleDialog}
        paperProps={{
          style: {
            width: 400,
            textAlign: 'left',
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ paddingBottom: 12 }}>
          <Typography variant="h2" withEllipsis={false}>
            {STATUS_TEXT[isIncludedInOptimization ? 'true' : 'false'].title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <StyledDescription id="optimization-dialog-description">
            {STATUS_TEXT[isIncludedInOptimization ? 'true' : 'false'].description}
          </StyledDescription>
        </DialogContent>
        <DialogActions style={{ background: nexyColors.paleWhite }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              toggleDialog();
            }}
            disabled={loading}
            variant="contained"
          >
            Cancel
          </Button>
          <ButtonAsync
            disabled={loading}
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            autoFocus
          >
            {STATUS_TEXT[isIncludedInOptimization ? 'true' : 'false'].CTA}
          </ButtonAsync>
        </DialogActions>
      </Dialog>
      {error ? <ErrorMessage error={error} /> : null}
    </>
  );
}

export const ContentOptimizationDialog = withRouter(ContentOptimizationDialogComponent);
