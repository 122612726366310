import { gql, useMutation } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';

import { SIMULATIONS_QUERY } from './simulationsQuery';

const ARCHIVE_SIMULATION_MUTATION = gql`
  mutation ArchiveSimulation($simulationId: Float!, $portfolioId: Float!, $teamId: Float!) {
    deleteSimulation(simulationId: $simulationId, portfolioId: $portfolioId, teamId: $teamId)
  }
`;

function useArchiveSimulationMutation({ portfolioId }) {
  const { teamId } = useTeam();

  const [archiveSimulation, { data, loading, error }] = useMutation(ARCHIVE_SIMULATION_MUTATION, {
    refetchQueries: [
      {
        query: SIMULATIONS_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted: () => {
      track(EVENT.SIMULATION_DELETE);
    },
  });

  return { archiveSimulation, data, loading, error };
}

export { ARCHIVE_SIMULATION_MUTATION, useArchiveSimulationMutation };
