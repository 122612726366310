const SvgTrashOutline = (props) => (
  <svg
    viewBox="0 0 14 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M13.5 2.525h-2.575L9.862.754A1.5 1.5 0 0 0 8.575.025h-3.15a1.5 1.5 0 0 0-1.287.729L3.075 2.525H.5a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5H1v10.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-10.5h.5a.5.5 0 0 0 .5-.5v-.5a.5.5 0 0 0-.5-.5Zm-5.125 10.5h.75a.375.375 0 0 0 .375-.375V5.9a.375.375 0 0 0-.375-.375h-.75A.375.375 0 0 0 8 5.9v6.75c0 .207.168.375.375.375Zm-2.844-11.5a.188.188 0 0 0-.161.091l-.545.91h4.35l-.545-.91a.188.188 0 0 0-.161-.09H5.53Zm5.969 13h-9v-10.5h9v10.5Zm-5.875-1.5h-.75a.375.375 0 0 1-.375-.375V5.9c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375v6.75a.375.375 0 0 1-.375.375Z"
    />
  </svg>
);
export { SvgTrashOutline as TrashOutline };
