const SvgCashBag = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 40"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <circle cx={20.123} cy={19.998} r={19.998} fill="#05A8FA" fillOpacity={0.11} />
    <path
      d="M12.758 19.144c.33-.882.939-1.622 1.7-2.176l1.306-.952c.352-.256.638-.593.834-.982l.26-.516a1.094 1.094 0 0 0-.035-1.046l-1.463-2.487a1.094 1.094 0 0 1 .943-1.648h7.436c.835 0 1.362.898.955 1.627l-1.423 2.55a1.094 1.094 0 0 0-.042.982l.191.424c.208.462.54.857.96 1.14l1.316.892c.832.563 1.498 1.343 1.846 2.286.491 1.327 1.147 3.399 1.34 5.412.136 1.41.106 2.565.026 3.424-.1 1.088-1.002 1.846-2.09 1.943-1.802.16-4.606.375-6.686.375s-4.883-.215-6.686-.375c-1.087-.097-1.989-.855-2.09-1.943-.079-.86-.109-2.014.026-3.424.198-2.061.882-4.184 1.376-5.506Z"
      fill="#05A8FA"
      fillOpacity={0.25}
    />
    <path
      d="M22.473 20.577s-.616-.833-2.084-.833c-2.917 0-2.917 3.333 0 3.333s2.917 3.334 0 3.334c-1.25 0-2.084-.833-2.084-.833m2.084-6.668v8.334"
      stroke="#05A8FA"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.54 13.966c0-.278.155-.503.349-.503h6.301c.193 0 .35.226.35.503v.505a.631.631 0 0 1-.102.356c-.066.095-.155.148-.248.148H16.89c-.193 0-.35-.226-.35-.504v-.505Z"
      fill="#05A8FA"
    />
  </svg>
);
export default SvgCashBag;
