import { gql } from '@apollo/client';

import { TEAM_META_FRAGMENT } from '../team/fragments';
import { TRANSLATION_KEY_VALUE_FRAGMENT } from '../translation/fragment';
import { USER_META_FRAGMENT } from '../user/fragments';

const INITIAL_QUERY = gql`
  query InitialData($lang: String!) {
    translations(lang: $lang) {
      ...translationKeyValue
    }
    user {
      ...userMeta
      teams {
        members {
          role {
            name
          }
          user_id
        }
        ...teamMeta
      }
    }
  }
  ${TRANSLATION_KEY_VALUE_FRAGMENT}
  ${USER_META_FRAGMENT}
  ${TEAM_META_FRAGMENT}
`;
export { INITIAL_QUERY };
