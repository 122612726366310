import { VerticalStep } from '../components/VerticalStepper/Step';

export const portfolioCreationSteps: VerticalStep[] = [
  {
    id: 'portfolio-basic-details',
    name: 'Basic details',
    description: `Give your portfolio a name, set the duration and portfolio type.`,
  },
  {
    id: 'portfolio-select-content',
    name: 'Portfolio settings',
    description: 'Select the settings for your chosen portfolio type.',
  },
];
export const portfolioGoalsConfig = {
  AWARENESS: {
    name: 'Awareness',
    description: 'Impressions, Reach, ...',
  },
  CONSIDERATION: {
    name: 'Consideration',
    description: 'Click, Interactions, ...',
  },
  CONVERSION: {
    name: 'Conversion',
    description: 'Sales, Leads, ...',
  },
};
export const portfolioGoalsConfigFlat = {
  AWARENESS: 'AWARENESS',
  CONSIDERATION: 'CONSIDERATION',
  CONVERSION: 'CONVERSION',
  CONVERSION_VALUE: 'CONVERSION_VALUE',
  OTHER: 'OTHER',
};
export const portfolioTabs = {
  PERFORMANCE: 'performance',
  OPTIMIZATION: 'optimization',
  BUDGET: 'budget',
  TARGET: 'target',
  VALIDATION: 'validation',
  CONTENT: 'content',
  SIMULATIONS: 'simulations',
};
export const portfolioDetailsTabs = {
  TIME_BASED_VALIDATION: 'time_based_validation',
  PREDICTION_SCORE: 'prediction_score',
};
export const portfoliosTabs = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
};
export const budgetOptimizationType = {
  AUTOMATIC: 'AUTO',
  MANUAL: 'MANUAL',
  SKIP: 'SKIP',
};
export const budgetRiskType = {
  CONSERVATIVE: 20,
  MODERATE: 50,
  AGGRESSIVE: 100,
};
