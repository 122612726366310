type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgDuration = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <g fill="#674CED">
      <circle cx={16} cy={16} r={16} fillOpacity={0.11} />
      <path
        fillOpacity={0.33}
        d="M20.133 14.362c.72-.507 1.355-1.638 1.414-2.52l.213-3.202H10.24l.213 3.202c.06.885.692 2.01 1.414 2.52l1.869 1.386c.426.316.404.8-.043 1.077l-1.826 1.133c-.72.507-1.355 1.638-1.414 2.52l-.213 2.882h11.52l-.213-2.882c-.06-.885-.692-2.01-1.414-2.52l-1.826-1.133c-.45-.28-.469-.761-.043-1.077l1.87-1.386z"
      />
      <path d="M12.522 23.36H10.24a.639.639 0 00-.64.64v.64a.64.64 0 00.639.64h11.522a.639.639 0 00.639-.64V24a.64.64 0 00-.639-.64h-2.294c-.16-.528-.655-1.202-1.243-1.486l-1.647-.795c-.318-.154-.836-.154-1.154 0l-1.647.795c-.601.29-1.108.909-1.254 1.486zM9.6 7.36c0-.354.285-.64.639-.64h11.522a.64.64 0 01.639.64V8a.639.639 0 01-.639.64H10.239A.64.64 0 019.6 8v-.64zm2.24 4.16h8.32c0 .797-.597 1.84-1.332 2.328l-1.753 1.165c-.295.196-.559.638-.59.992l-.137 1.597a.354.354 0 01-.348.318.356.356 0 01-.347-.318l-.137-1.595c-.03-.352-.292-.795-.589-.992l-1.754-1.167c-.736-.49-1.333-1.603-1.333-2.328z" />
    </g>
  </svg>
);

export default SvgDuration;
