import { gql, useMutation } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

import { ACTIVE_OPTIMIZATION_FRAGMENT } from './fragments';
import { ACTIVE_OPTIMIZATION_QUERY } from './queryActiveOptimization';

const CREATE_OPTIMIZATION_REQUEST = gql`
  mutation createOptimization($teamId: Int!, $portfolioId: Int!, $end: Date!, $skipCustomImportCheck: Boolean) {
    createOptimization(
      teamId: $teamId
      portfolioId: $portfolioId
      end: $end
      skipCustomImportCheck: $skipCustomImportCheck
    ) {
      ...ActiveOptimization
    }
  }
  ${ACTIVE_OPTIMIZATION_FRAGMENT}
`;

function useCreateOptimization({ portfolioId, end, skipCustomImportCheck }) {
  const { teamId } = useTeam();
  return useMutation(CREATE_OPTIMIZATION_REQUEST, {
    variables: {
      teamId,
      portfolioId,
      end,
      skipCustomImportCheck,
    },
    refetchQueries: [
      {
        query: ACTIVE_OPTIMIZATION_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}

export { CREATE_OPTIMIZATION_REQUEST, useCreateOptimization };
