// method takes a multiple words string, and capitalizes each word
export function capitalizeWords(string: string, splitBy = ' '): string {
  if (import.meta.env.MODE !== 'production' && typeof string !== 'string') {
    throw new Error('Nexoya: capitalize(string) expects a string argument.');
  }

  return string
    ?.toLowerCase()
    ?.split(splitBy)
    ?.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
    ?.join(' ');
}

export function capitalizeFirstLetter(string) {
  if (string.length === 2) {
    return string.toUpperCase();
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// At the moment of writing, this is only being used in the PDF generator
// to make sure we don't have too long text in the "reports";
export function truncate(string: string, limit = 50): string {
  const nextString = string.length > limit ? string.slice(0, limit) : string;
  return `${nextString}${string.length > limit ? '...' : ''}`;
}

export function extractRoleString(input) {
  const match = input?.match(/{role:(\w+)}/);
  return match ? match[1] : null;
}
