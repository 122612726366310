import React from 'react';

import { NexoyaBudgetItem } from '../types';

import useBudgetItemController from '../controllers/BudgetItemController';

const BudgetItemContext = React.createContext<Record<string, any> | null>(null);

function BudgetItemProvider({
  children,
  initialValues,
}: {
  children: React.ReactNode;
  initialValues?: NexoyaBudgetItem;
}) {
  const value = useBudgetItemController(initialValues);
  return <BudgetItemContext.Provider value={value}>{children}</BudgetItemContext.Provider>;
}

function withBudgetItemProvider(Component: any) {
  return (props: any) => (
    <BudgetItemProvider>
      <Component {...props} />
    </BudgetItemProvider>
  );
}

function useBudgetItem(initialValues?: NexoyaBudgetItem): Record<string, any> {
  const context = React.useContext(BudgetItemContext);

  if (context === undefined) {
    throw new Error('useBudgetItem must be used within a BudgetItemProvider');
  }

  // Use the initial values from context or fallback to default
  return {
    ...context,
    budgetItemState: initialValues ? { ...context.budgetItemState, ...initialValues } : context.budgetItemState,
  };
}

export { BudgetItemProvider, useBudgetItem, withBudgetItemProvider };
