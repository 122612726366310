export const LINE_COLORS = [
  '#674CED',
  '#F6820D',
  '#0EC76A',
  '#274FC5',
  '#D564ED',
  '#03D8D7',
  '#8D79F2',
  '#F9A958',
  '#4FF3F2',
  '#597ADE',
  '#E191F2',
  '#4AF29E',
];
