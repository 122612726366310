const SvgInfo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8.001 8.001 0 0 0 0 16A8.001 8.001 0 0 0 8 0Zm.003 3.547a1.355 1.355 0 1 1 0 2.71 1.355 1.355 0 0 1 0-2.71Zm1.418 8.583a.387.387 0 0 0 .387-.387v-.774a.387.387 0 0 0-.387-.387h-.387V7.356a.387.387 0 0 0-.387-.387H6.582a.387.387 0 0 0-.387.387v.774c0 .214.174.387.387.387h.388v2.065h-.388a.387.387 0 0 0-.387.387v.774c0 .214.174.387.387.387h2.84Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgInfo;
