const SvgPortfolioDuotone = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      d="M23.109 21.482v-4.94a.727.727 0 0 0-.862-.715l-6.113 1.148a.73.73 0 0 1-.268 0l-6.118-1.148a.727.727 0 0 0-.862.714v4.94c0 .402.326.728.728.728H22.38a.727.727 0 0 0 .728-.727Z"
      fill="#674CED"
      fillOpacity={0.2}
      stroke="#674CED"
      strokeWidth={1.5}
    />
    <path
      d="M8 11.506c0-.402.326-.727.727-.727h14.546c.401 0 .727.325.727.727v3.39c0 .35-.25.65-.593.714l-7.273 1.366a.73.73 0 0 1-.268 0L8.593 15.61A.727.727 0 0 1 8 14.895v-3.39Z"
      fill="#674CED"
      fillOpacity={0.2}
      stroke="#674CED"
      strokeWidth={1.5}
    />
    <rect x={15.114} y={15.222} width={1.778} height={3.556} rx={0.727} fill="#674CED" />
    <path
      d="M19.557 10.778v-.323c0-.804-.651-1.455-1.455-1.455H13.9c-.803 0-1.454.651-1.454 1.455v.323"
      stroke="#674CED"
      strokeOpacity={0.6}
    />
    <circle cx={16} cy={16} r={16} fill="#674CED" fillOpacity={0.11} />
  </svg>
);
export default SvgPortfolioDuotone;
