import { capitalize, round } from 'lodash';
import React from 'react';
import { BigHeaderCell } from '../../routes/portfolio/styles/OptimizationProposal';
import { withSortSkipped } from '../../routes/portfolio/components/OptimizationProposal/withSortSkipped';
import { sortTypes } from '../Table/sortTypes';
import {
  ACTIONS_HEADER_ID,
  getHeader,
  isCostFunnelStep,
} from '../../routes/portfolio/components/OptimizationProposal/columns';
import { NexoyaFunnelStepPerformance, NexoyaFunnelStepType } from '../../types';
import dayjs from 'dayjs';
import * as Styles from './styles';
import SvgChevronDown from '../icons/ChevronDown';
import NumberValue from '../NumberValue';
import FormattedCurrency from '../FormattedCurrency';
import { cn } from '../../lib/utils';
import { DATE_SELECTOR_DEFAULT_FORMAT, DATE_SELECTOR_YEARLY_DEFAULT_FORMAT } from '../../utils/dates';

interface Props {
  performanceMetricSwitch: string;
  funnelSteps: NexoyaFunnelStepPerformance[];
  dateRange: DateRange;
  contentWidth: number;
  compareToDateRange?: DateRange;
}

export interface DateRange {
  start: Date;
  end: Date;
}

const createFunnelColumn = (
  funnelStepPerformance: NexoyaFunnelStepPerformance,
  performanceMetricSwitch: string,
  dateRange: DateRange,
  compareToDateRange: DateRange,
) => {
  const funnelStep = funnelStepPerformance.funnelStep;
  const isCostPer = performanceMetricSwitch === 'cost-per';
  const header = isCostFunnelStep(funnelStep.type) ? 'Ad spend' : getHeader(funnelStep);

  const calculateTotal = (key: string) =>
    funnelStepPerformance?.metricTotals?.providers?.reduce((acc, cur) => acc + (cur?.total?.[key] || 0), 0) || 0;

  const calculateComparisonTotal = (key: string) =>
    funnelStepPerformance?.metricTotals?.providers?.reduce((acc, cur) => acc + (cur?.comparisonTotal?.[key] || 0), 0) ||
    0;

  const calculatePercentageChange = (currentValue: number, previousValue: number) =>
    previousValue ? round(((currentValue - previousValue) / previousValue) * 100, 1) : 0;

  const total = {
    value: calculateTotal('value'),
    comparisonValue: calculateComparisonTotal('value'),
    comparisonChangePercent: calculatePercentageChange(calculateTotal('value'), calculateComparisonTotal('value')),
  };

  const adSpendTotal = {
    value: calculateTotal('adSpend'),
    comparisonValue: calculateComparisonTotal('adSpend'),
  };

  const costPer = {
    value: total.value ? adSpendTotal.value / total.value : 0,
    comparisonValue: total.comparisonValue ? adSpendTotal.comparisonValue / total.comparisonValue : 0,
  };

  const calculateCostRatioValue = () => {
    if (isCostFunnelStep(funnelStep.type)) {
      return adSpendTotal.value;
    } else if (funnelStep.type === NexoyaFunnelStepType.Awareness) {
      return costPer.value * 1000;
    } else {
      return costPer.value;
    }
  };

  const calculateCostRatioComparisonValue = () => {
    if (isCostFunnelStep(funnelStep.type)) {
      return adSpendTotal.comparisonValue;
    } else if (funnelStep.type === NexoyaFunnelStepType.Awareness) {
      return costPer.comparisonValue * 1000;
    } else {
      return costPer.comparisonValue;
    }
  };

  const costRatioTotal = {
    value: calculateCostRatioValue(),
    comparisonValue: calculateCostRatioComparisonValue(),
    comparisonChangePercent: calculatePercentageChange(calculateCostRatioValue(), calculateCostRatioComparisonValue()),
  };

  const lowerIsBetter = isCostFunnelStep(funnelStep.type) ? false : isCostPer;

  const columns = createColumnStructure(
    funnelStep?.funnelStepId,
    isCostPer ? costRatioTotal : total,
    isCostPer ? 'funnelStepCostPer' : 'funnelStepValue',
    isCostPer || isCostFunnelStep(funnelStep.type) || funnelStep.type === NexoyaFunnelStepType.ConversionValue,
    dateRange,
    compareToDateRange,
    lowerIsBetter,
  );

  const title = isCostFunnelStep(funnelStep.type) ? 'Ad spend' : capitalize(funnelStep?.title);

  return {
    id: `header-${funnelStep?.funnelStepId}`,
    title,
    disableSticky: true,
    disableSortBy: true,
    width: '100%',
    Header: (
      <BigHeaderCell className={cn('w-full', compareToDateRange ? '!text-center' : 'text-right')}>
        {isCostPer ? header : title}
      </BigHeaderCell>
    ),
    columns,
  };
};

export const getColumns = ({
  performanceMetricSwitch,
  funnelSteps,
  dateRange,
  contentWidth,
  compareToDateRange,
}: Props) => {
  const columns = funnelSteps?.map((funnelStep) =>
    createFunnelColumn(funnelStep, performanceMetricSwitch, dateRange, compareToDateRange),
  );

  return [
    {
      accessor: ACTIONS_HEADER_ID,
      disableSortBy: true,
      disableHiding: true,
      Header: '',
      tableManagerHeader: <BigHeaderCell>Content</BigHeaderCell>,
      columns: [
        {
          Header: <BigHeaderCell>Total</BigHeaderCell>,
          accessor: 'expanderHeader',
          disableSortBy: true,
          isHiddenInManager: true,
          disableHiding: true,
          className: 'border-right',
          columns: [
            {
              width: 50,
              disableSortBy: true,
              isHiddenInManager: true,
              disableHiding: true,
              id: 'expander',
              Header: ({ getToggleAllRowsExpandedProps }) => <span {...getToggleAllRowsExpandedProps()}></span>,
              Cell: ({ row }) =>
                row.canExpand ? (
                  <Styles.ChevronWrap
                    expanded={row.isExpanded}
                    className="mx-auto h-full w-fit items-center !justify-center"
                    {...row.getToggleRowExpandedProps()}
                  >
                    <SvgChevronDown />
                  </Styles.ChevronWrap>
                ) : null,
            },
            {
              width: contentWidth,
              Header: 'Content',
              accessor: 'content',
              className: 'border-right',
              sortType: withSortSkipped(sortTypes.jsxKey),
              enableColumnResize: true,
              disableHiding: true,
            },
          ],
        },
      ],
    },
    ...(columns || []),
  ];
};

export const createColumnStructure = (
  funnelStepId: number,
  total: { value: number; comparisonValue: number; comparisonChangePercent: number },
  accessorPrefix: string,
  isCurrency: boolean,
  dateRange: DateRange,
  compareToDateRange?: DateRange,
  lowerIsBetter?: boolean,
) => {
  const getDateFormat = () => {
    const compareFromYear = dayjs(compareToDateRange?.start).year();
    const compareToYear = dayjs(compareToDateRange?.end).year();
    const dateFromYear = dayjs(dateRange?.start).year();
    const dateToYear = dayjs(dateRange?.end).year();

    // Check if any of the date ranges span different years
    if (compareFromYear !== compareToYear || compareFromYear !== dateFromYear || compareToYear !== dateToYear) {
      return DATE_SELECTOR_YEARLY_DEFAULT_FORMAT;
    }

    return DATE_SELECTOR_DEFAULT_FORMAT;
  };

  const baseColumns = [
    {
      Header: (
        <>
          {compareToDateRange ? (
            <BigHeaderCell className="w-full !px-1.5 !py-5 !text-left">
              {isCurrency ? (
                <FormattedCurrency amount={total?.comparisonValue} />
              ) : (
                <NumberValue justify="flex-start" value={total?.comparisonValue} />
              )}
            </BigHeaderCell>
          ) : null}
          <BigHeaderCell className={`w-full ${compareToDateRange ? '!px-12' : '!px-3'} !py-5 text-right`}>
            {isCurrency ? (
              <FormattedCurrency amount={total?.value} />
            ) : (
              <NumberValue justify="flex-end" value={total?.value} />
            )}
          </BigHeaderCell>
          {compareToDateRange ? (
            <BigHeaderCell className="w-[70%] !px-3 !py-5 text-right">
              <NumberValue
                justify="flex-end"
                value={total?.comparisonChangePercent}
                symbol="%"
                textWithColor
                showChangePrefix
                lowerIsBetter={lowerIsBetter}
                variant={total?.comparisonChangePercent > 0 ? 'positive' : 'negative'}
              />
            </BigHeaderCell>
          ) : null}
        </>
      ),
      className: 'border-right',
      disableSortBy: true,
      disableSticky: true,
      accessor: `total_${accessorPrefix}_${funnelStepId}_${dayjs(dateRange.start).format()}_${dayjs(dateRange.end).format()}`,
      columns: [
        compareToDateRange
          ? {
              disableSortBy: false,
              Header: `${dayjs(compareToDateRange.start).format(getDateFormat())} - ${dayjs(compareToDateRange.end).format(getDateFormat())}`,
              accessor: `${accessorPrefix}_${funnelStepId}_${dayjs(compareToDateRange.start).format()}_${dayjs(compareToDateRange.end).format()}`,
              sortType: withSortSkipped(sortTypes.jsxKeyAsNumber),
              width: 130,
              rowProps: {
                style: {
                  justifyContent: 'flex-start',
                },
              },
            }
          : null,
        {
          Header: (
            <span className="w-full text-right">
              {dayjs(dateRange.start).format(getDateFormat())} - {dayjs(dateRange.end).format(getDateFormat())}
            </span>
          ),
          accessor: `${accessorPrefix}_${funnelStepId}_${dayjs(dateRange.start).format()}_${dayjs(dateRange.end).format()}`,
          sortType: withSortSkipped(sortTypes.jsxKeyAsNumber),
          className: compareToDateRange ? '' : 'border-right',
          minWidth: 150,
          width: 180,
        },
        compareToDateRange
          ? {
              disableSortBy: false,
              Header: 'Change %',
              accessor: `${accessorPrefix}_${funnelStepId}_${dayjs(compareToDateRange.start).format()}_${dayjs(compareToDateRange.end).format()}_change_percent`,
              sortType: withSortSkipped(sortTypes.jsxKeyAsNumber),
              className: 'border-right',
              rowProps: {
                style: {
                  justifyContent: 'flex-end',
                },
              },
            }
          : null,
      ].filter(Boolean),
    },
  ];

  return baseColumns;
};
