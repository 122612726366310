import { usePortfolio } from '../../context/PortfolioProvider';

import ErrorMessage from '../ErrorMessage';

type Props = {
  features: string[];
  renderOld: () => any;
  renderNew: () => any;
  bypass?: boolean;
};

function PortfolioFeatureSwitch({ features, renderOld, renderNew, bypass }: Props) {
  const {
    portfolioV2Info: {
      meta: { data, loading, error },
    },
  } = usePortfolio();

  const featureFlags = data?.featureFlags;
  const isFeatureThere =
    featureFlags && featureFlags.some((featureFlag) => features.includes(featureFlag.name) && featureFlag.status);
  if (loading) return null;
  else if (error) return <ErrorMessage error={error} />;
  else return bypass ? renderNew() : isFeatureThere ? renderNew() : renderOld();
}

export default PortfolioFeatureSwitch;
