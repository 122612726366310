import { gql, useQuery } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

const IMPACT_GROUPS_QUERY = gql`
  query ImpactGroups($teamId: Int!, $portfolioId: Int!) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      impactGroups {
        name
        impactGroupId
      }
    }
  }
`;

function useImpactGroupsQuery(portfolioId: number) {
  const { teamId } = useTeam();
  return useQuery(IMPACT_GROUPS_QUERY, {
    variables: {
      teamId: teamId || null,
      portfolioId: portfolioId || null,
    },
  });
}

export { IMPACT_GROUPS_QUERY, useImpactGroupsQuery };
