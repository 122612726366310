import dayjs from 'dayjs';

import { NexoyaPortfolio, NexoyaPortfolioType } from '../types';

import { buildPortfolioPath } from '../routes/paths';

import { format, GLOBAL_DATE_FORMAT } from './dates';

export const DEFAULT_PORTFOLIO_DATE_RANGE = {
  dateFrom: dayjs.utc().subtract(3, 'week').startOf('day').toDate(),
  dateTo: dayjs.utc().endOf('day').toDate(),
};

export const DEFAULT_PORTFOLIO_COMPARE_DATE_RANGE = {
  dateFrom: dayjs(DEFAULT_PORTFOLIO_DATE_RANGE.dateFrom).subtract(2, 'week').toDate(),
  dateTo: dayjs(DEFAULT_PORTFOLIO_DATE_RANGE.dateTo).subtract(2, 'week').toDate(),
};

// Simplifies the process of formatting date ranges and building portfolio path with query parameters
export const buildPortfolioPathWithDates = (portfolioNode: NexoyaPortfolio) => {
  const { portfolioId, startDate, endDate } = portfolioNode;
  const path = buildPortfolioPath(portfolioId);

  const startDay = dayjs.utc(startDate);
  const endDay = dayjs.utc(endDate);

  const formattedStartDate = startDay.format(GLOBAL_DATE_FORMAT);
  const formattedEndDate = endDay.format(GLOBAL_DATE_FORMAT);

  // Default range as dayjs objects for comparison
  const defaultStart = dayjs.utc(DEFAULT_PORTFOLIO_DATE_RANGE.dateFrom);
  const defaultEnd = dayjs.utc(DEFAULT_PORTFOLIO_DATE_RANGE.dateTo);

  // Determine if start or end date falls outside the default date range
  const isStartBeforeDefault = startDay.isBefore(defaultStart);
  const isEndAfterDefault = endDay.isAfter(defaultEnd);

  // Build query based on conditions
  let queryParams = `?dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}`;

  // If portfolio dates are completely outside the default range, adjust accordingly
  if (isStartBeforeDefault && isEndAfterDefault) {
    queryParams = `?dateFrom=${format(defaultStart, GLOBAL_DATE_FORMAT, true)}&dateTo=${format(
      defaultEnd,
      GLOBAL_DATE_FORMAT,
      true,
    )}`;
  } else if (!isStartBeforeDefault && isEndAfterDefault) {
    queryParams = `?dateFrom=${formattedStartDate}&dateTo=${format(defaultEnd, GLOBAL_DATE_FORMAT, true)}`;
  }

  return path + queryParams;
};

export const portfolioTypeToHumanReadable = (type: NexoyaPortfolioType) => {
  switch (type) {
    case NexoyaPortfolioType.Budget:
      return 'Budget';
    case NexoyaPortfolioType.CostPer:
      return 'Target CPA';
    case NexoyaPortfolioType.Roas:
      return 'Target ROAS';
    default:
      return 'Unknown';
  }
};
