import { useMediaQuery } from 'react-responsive';

import { sizes } from '../../theme/device';

type Props = {
  children: any;
};

function LaptopLUp({ children }: Props) {
  const isLaptopLUp = useMediaQuery({
    minWidth: sizes.laptopL,
  });
  return isLaptopLUp ? children : null;
}

export default LaptopLUp;
