import React from 'react';

import {
  NexoyaBudgetDeltaHandlingPolicy,
  NexoyaFunnelStepPerformance,
  NexoyaFunnelStepType,
  NexoyaOptimizationV2,
  NexoyaPortfolio,
  NexoyaPortfolioPerformance,
  NexoyaPortfolioType,
  NexoyaPortfolioV2,
  NexoyaProvider,
} from '../types';

import usePortfolioController from '../controllers/PortfolioController';

type FormValues = {
  title: string;
  type: NexoyaPortfolioType;
  description: string;
  startDate: Date;
  endDate: Date;
  goal: string;
  optimizationType: string;
  optimizationRiskLevel: number;
  budgetDeltaHandlingPolicy: NexoyaBudgetDeltaHandlingPolicy;
};

interface NexoyaProviderWithLogo extends NexoyaProvider {
  providerLogoColor: string;
}

interface PartialFunnelStep {
  title: string;
  funnel_step_id: any;
  type: NexoyaFunnelStepType;
}

interface QueryInfo<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  updateState: ({ data, loading, error }: { data: T; loading?: boolean; error?: any }) => void;
}

type PortfolioInfo = QueryInfo<NexoyaPortfolio>;

type PortfolioV2MetaInfo = QueryInfo<NexoyaPortfolioV2>;

type PortfolioV2FunnelStepsInfo = QueryInfo<NexoyaFunnelStepPerformance[]>; // Update with proper structure

type PortfolioV2ContentMetricsInfo = QueryInfo<NexoyaPortfolioPerformance>; // Update with proper structure

export type ValidationChartType = 'time-based' | 'prediction-details' | 'prediction-timeline' | 'achieved-predicted';

interface PortfolioV2Info {
  meta: PortfolioV2MetaInfo;
  funnelSteps: PortfolioV2FunnelStepsInfo;
  contentMetrics: PortfolioV2ContentMetricsInfo;
}

interface MetaInfo {
  value: FormValues;
  handleChange: any;
  handleBudgetChange: any;
  // @ts-ignore
  handleDateChange: ({ from: Date, to: Date }) => void;
}

interface SelectedFunnelStep {
  selectedFunnelStep: PartialFunnelStep | null;
  setSelectedFunnelStep: React.Dispatch<React.SetStateAction<PartialFunnelStep | null>>;
}

interface BudgetChart {
  visiblePlannedChart: boolean;
  setVisiblePlannedChart: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PredictionChart {
  activePredictionChart: ValidationChartType;
  setActivePredictionChart: React.Dispatch<React.SetStateAction<ValidationChartType>>;
}

interface PerformanceChart {
  isStackedAreaChartActive: boolean;
  setIsStackedAreaChartActive: React.Dispatch<React.SetStateAction<boolean>>;
  conversionRateToggle: boolean;
  setConversionRateToggle: React.Dispatch<React.SetStateAction<boolean>>;
  compareTo: boolean;
  setCompareTo: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Providers {
  providersFilter: NexoyaProviderWithLogo[];
  handleAddProvider: (provider: NexoyaProviderWithLogo) => void;
  handleRemoveProvider: (provider: NexoyaProviderWithLogo) => void;
  handleResetProvideFilterState: () => void;
}

interface IPortfolioContext {
  portfolioInfo: PortfolioInfo;
  portfolioV2Info: PortfolioV2Info;
  meta: MetaInfo;
  contentSelection: any; // Update with proper type
  selectedFunnelStep: SelectedFunnelStep;
  predictionChart: PredictionChart;
  budgetChart: BudgetChart;
  performanceChart: PerformanceChart;
  reset: () => void;
  getSummedBudget: () => number;
  optimization: {
    activeOptimization: boolean;
    setActiveOptimization: React.Dispatch<React.SetStateAction<boolean>>;
    optimizations: NexoyaOptimizationV2[];
    setOptimizations: React.Dispatch<React.SetStateAction<any>>;
  };
  providers: Providers;
}

const PortfolioContext = React.createContext<IPortfolioContext | null>(null);

function PortfolioProvider(props: any) {
  const value = usePortfolioController();
  return <PortfolioContext.Provider value={value} {...props} />;
}

function withPortfolioProvider(Component: any) {
  return (props: any) => (
    <PortfolioProvider>
      <Component {...props} />
    </PortfolioProvider>
  );
}

function usePortfolio(): IPortfolioContext {
  const context = React.useContext(PortfolioContext);

  if (context === undefined) {
    throw new Error('usePortfolio must be used within a PortfolioProvider');
  }

  return context;
}

export { PortfolioProvider, usePortfolio, withPortfolioProvider };
