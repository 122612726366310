import { gql, useMutation } from '@apollo/client';

import { NexoyaBudgetProposal } from 'types';

import { useTeam } from 'context/TeamProvider';

const CREATE_BUDGET_PROPOSAL_MUTATION = gql`
  mutation createProposal($optimizationId: Int!, $teamId: Int!, $portfolioContentIds: [Float!]) {
    createBudgetProposal(
      optimizationId: $optimizationId
      teamId: $teamId
      portfolioContentIdsToSkip: $portfolioContentIds
    ) {
      optimizationId
      timestampCreated
      timestampApplied
      status
      budgetProposalData {
        portfolioContentId
        budgetType
        initialBudget
        proposedBudget
        applicationType
      }
    }
  }
`;

type Props = {
  optimizationId: number;
  portfolioContentIdsToSkip: number[];
};
export function useCreateBudgetProposalMutation({ optimizationId, portfolioContentIdsToSkip }: Props) {
  const { teamId } = useTeam();

  return useMutation<
    NexoyaBudgetProposal,
    {
      optimizationId?: number;
      teamId?: number;
      portfolioContentIds: number[];
    }
  >(CREATE_BUDGET_PROPOSAL_MUTATION, {
    variables: {
      teamId,
      optimizationId,
      portfolioContentIds: portfolioContentIdsToSkip,
    },
  });
}
