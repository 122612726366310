import styled from 'styled-components';

import ButtonBase from '../ButtonBase';
import { useTabs } from './useTabs';

interface Props {
  children: any;
  tab: string;
  component?: any;
  disabled?: boolean;
  onClick?: (ev: React.SyntheticEvent<HTMLElement>) => void;
}
const TabNavStyled = styled(ButtonBase)``;

const TabsNav = ({ children, tab, component, onClick, disabled, ...rest }: Props) => {
  const { tab: activeTab, setTab } = useTabs();

  function handleClick(ev: React.SyntheticEvent<HTMLElement>) {
    // TODO: Check if there are regressions because of this commenting this, will be useful for simulations tab reset
    // if (tab === activeTab) {
    //   return;
    // }

    setTab(tab);

    if (onClick) {
      onClick(ev);
    }
  }

  return (
    <TabNavStyled
      data-cy={`${tab}Tab`}
      as={component}
      isActive={activeTab === tab}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </TabNavStyled>
  );
};

export default TabsNav;
