import { toast } from 'sonner';
import styled from 'styled-components';

import { useChangeBaseScenarioMutation } from '../../../../graphql/simulation/mutationChangeBaseScenario';

import MenuList from 'components/ArrayMenuList';
import ButtonIcon from 'components/ButtonIcon';
import { useMenu } from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import Spinner from 'components/Spinner';
import SvgEllipsisV from 'components/icons/EllipsisV';

const ActionWrapStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SvgEllipsisVStyled = styled(SvgEllipsisV)`
  font-size: 18px;
`;

const StyledPanel = styled(Panel)`
  .NEXYPopper {
    z-index: 3000 !important;
  }
`;

interface Props {
  scenarioId: number;
  simulationId: number;
  portfolioId: number;
  scenarioIdx: number;
  isBaseScenario?: boolean;
}

export function ScenarioTDM({ scenarioId, simulationId, portfolioId, scenarioIdx, isBaseScenario }: Props) {
  const { changeBaseScenario, loading: changeBaseScenarioLoading } = useChangeBaseScenarioMutation({
    portfolioId,
    simulationId,
    scenarioId,
  });

  const { anchorEl, open, toggleMenu, closeMenu } = useMenu();
  return (
    <div ref={anchorEl}>
      <ActionWrapStyled>
        {changeBaseScenarioLoading ? (
          <div className="w-[36px] p-[9px]">
            <Spinner size="20px" />
          </div>
        ) : (
          <ButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              toggleMenu();
            }}
            active={open}
            style={{ position: 'static' }}
          >
            <SvgEllipsisVStyled />
          </ButtonIcon>
        )}
      </ActionWrapStyled>
      <StyledPanel
        container={anchorEl.current}
        anchorEl={anchorEl.current}
        open={open}
        onClose={closeMenu}
        placement="bottom-start"
        style={{
          minWidth: 138,
        }}
        popperProps={{
          zIndex: '3000 !important',
        }}
      >
        <MenuList color="dark">
          <MenuItem
            disabled={isBaseScenario}
            style={{ zIndex: 3000 }}
            key="remove-simulation"
            onClick={(e) => {
              e.stopPropagation();
              changeBaseScenario()
                .then(() => toast.success(`Scenario ${scenarioIdx} set as base`))
                .catch(() => toast.error(`There was an error when setting Scenario ${scenarioIdx} as base`));
              toggleMenu();
            }}
            color="dark"
          >
            Make base scenario
          </MenuItem>
        </MenuList>
      </StyledPanel>
    </div>
  );
}
