type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgDollar = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48 10.208c0 1.857-1.213 3.278-3.672 3.53v.852a.78.78 0 01-.774.77c-.472 0-.826-.351-.826-.77v-.836c-1.347-.117-2.997-.636-3.52-1.455a1.008 1.008 0 01-.168-.535c0-.62.522-1.104 1.112-1.104.236 0 .454.067.656.2a4.94 4.94 0 001.92.786V9.004C2.474 8.569.588 7.934.588 5.658c0-1.69 1.347-3.128 3.62-3.362V1.41c0-.419.354-.77.826-.77a.78.78 0 01.774.77v.92c.994.116 2.426.501 2.914 1.22.101.15.169.335.169.519 0 .636-.607 1.087-1.196 1.087-.101 0-.203-.016-.32-.05-.227-.062-.47-.178-.715-.297-.283-.137-.572-.276-.852-.339V6.83c1.735.435 3.672 1.104 3.672 3.379zM4.208 6.411V4.37c-.757.1-1.179.518-1.179 1.087 0 .484.472.752 1.18.953zm1.6 2.994c.724.234 1.247.519 1.247 1.07 0 .52-.421 1.004-1.247 1.171v-2.24z"
      fill="#0EC76A"
    />
  </svg>
);

export default SvgDollar;
