import React, { useState } from 'react';

import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import ButtonAsync from 'components/ButtonAsync';

import * as Styles from '../styles/ProposalDialog';

interface Props {
  isOpen: boolean;
  loading: boolean;
  toggleDialog: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

const CONFIRMATION_KEYWORD = 'Apply';
function ProposalDialog({ isOpen, loading, toggleDialog, onClose, onSubmit }: Props) {
  const [confirmationKeyword, setConfirmationKeyword] = useState('');
  return (
    <>
      <Styles.DialogStyled
        isOpen={isOpen}
        onClose={() => {
          toggleDialog();
          onClose();
        }}
      >
        <>
          <Styles.DialogTitleStyled>
            Nexoya will automatically apply the budget changes of all your campaigns. Do you want to proceed?
          </Styles.DialogTitleStyled>
          <Styles.DialogContentStyled>
            <TextField
              fullWidth
              autoComplete="off"
              type="email"
              id="email"
              name="email"
              label={
                <span style={{ fontSize: 13, fontWeight: 400 }}>
                  Please type <b>{CONFIRMATION_KEYWORD}</b> to automatically shift all the budgets
                </span>
              }
              value={confirmationKeyword}
              onChange={(e) => setConfirmationKeyword(e.target.value)}
            />
          </Styles.DialogContentStyled>
          <Styles.DialogActionsStyled style={{ gap: 16 }}>
            <Button
              disabled={loading}
              onClick={() => {
                toggleDialog();
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <ButtonAsync
              style={{ width: '100%' }}
              onClick={() => {
                onSubmit();
              }}
              disabled={loading || confirmationKeyword !== CONFIRMATION_KEYWORD}
              loading={loading}
              variant="contained"
              color="primary"
              autoFocus
            >
              I understand it will automatically apply the budget
            </ButtonAsync>
          </Styles.DialogActionsStyled>
        </>
      </Styles.DialogStyled>
    </>
  );
}

export default ProposalDialog;
