import { gql } from '@apollo/client';

export const PERFORMANCE_METRIC_FRAGMENT = gql`
  fragment PerformanceMetric on PerformanceMetric {
    value
    adSpend
    costRatio
  }
`;

export const FUNNEL_STEP_FRAGMENT = gql`
  fragment FunnelStepFragment on FunnelStepPerformance {
    funnelStep {
      funnelStepId
      title
      type
    }
    dailyMetrics {
      comparisonDay
      day
      contents {
        contentId
        title
        isIncludedInOptimization
        providerId
        value {
          ...PerformanceMetric
        }
        comparisonValue {
          ...PerformanceMetric
        }
        comparisonChangePercent {
          ...PerformanceMetric
        }
      }
      providers {
        providerId
        comparisonChangePercent {
          ...PerformanceMetric
        }
        value {
          ...PerformanceMetric
        }
        comparisonValue {
          ...PerformanceMetric
        }
      }
      labels {
        labelId
        comparisonChangePercent {
          ...PerformanceMetric
        }
        value {
          ...PerformanceMetric
        }
        comparisonValue {
          ...PerformanceMetric
        }
      }
      impactGroups {
        impactGroup {
          name
          impactGroupId
        }
        value {
          ...PerformanceMetric
        }
        comparisonValue {
          ...PerformanceMetric
        }
        comparisonChangePercent {
          ...PerformanceMetric
        }
      }
    }
    dailyOptimizationMetrics {
      day
      providers {
        providerId
        relativeBaseline
        relativeExpected
      }
      labels {
        relativeBaseline
        relativeExpected
        labelId
      }
      impactGroups {
        relativeBaseline
        relativeExpected
        impactGroup {
          name
          impactGroupId
        }
      }
    }
    metricTotals {
      providers {
        total {
          ...PerformanceMetric
        }
        comparisonTotal {
          ...PerformanceMetric
        }
        comparisonChangePercent {
          ...PerformanceMetric
        }
        providerId
      }
      labels {
        total {
          ...PerformanceMetric
        }
        comparisonTotal {
          ...PerformanceMetric
        }
        comparisonChangePercent {
          ...PerformanceMetric
        }
        labelId
      }
      impactGroups {
        impactGroup {
          impactGroupId
          name
        }
        total {
          ...PerformanceMetric
        }
        comparisonTotal {
          ...PerformanceMetric
        }
        comparisonChangePercent {
          ...PerformanceMetric
        }
      }
    }
    optimizationMetricTotals {
      baselineTotal
      expectedTotal
      potentialPercentage
      providers {
        providerId
        baselineTotal
        expectedTotal
      }
    }
  }
  ${PERFORMANCE_METRIC_FRAGMENT}
`;
export const OPTIMIZATIONS_V2_FRAGMENT = gql`
  fragment OptimizationsV2Fragment on OptimizationV2 {
    title
    optimizationId
    appliedAt
    description
    end
    start
  }
`;

export const CONTENT_METRIC_FRAGMENT = gql`
  fragment ContentMetricFragment on FunnelStepPerformance {
    metricTotals {
      providers {
        total {
          adSpend
          costRatio
          value
        }
        comparisonTotal {
          adSpend
          costRatio
          value
        }
        comparisonChangePercent {
          adSpend
          costRatio
          value
        }
        providerId
        contents {
          total {
            costRatio
            adSpend
            value
          }
          comparisonTotal {
            costRatio
            adSpend
            value
          }
          contentId
          title
          comparisonChangePercent {
            costRatio
            adSpend
            value
          }
        }
      }
    }
  }
`;
