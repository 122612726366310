import React from 'react';

import { get } from 'lodash';

import { NexoyaPortfolio, NexoyaPortfolioContentDetail } from 'types';

import { useAddManyContentRelationsMutation } from '../../../../graphql/portfolio/mutationUpdateContentRelation';
import { KpisFilterProvider2 } from 'context/KpisFilterProvider';

import { copyToClipboard } from 'utils/helpers';

import MenuList from '../../../../components/ArrayMenuList';
import ButtonIcon from '../../../../components/ButtonIcon';
import useDialogState from '../../../../components/Dialog/useDialogState';
import { useDropdownMenu } from '../../../../components/DropdownMenu';
import ErrorMessage from '../../../../components/ErrorMessage';
import MenuItem from '../../../../components/MenuItem';
import Panel from '../../../../components/Panel';
import { useSidePanelState } from '../../../../components/SidePanel';
import SvgEllipsisV from '../../../../components/icons/EllipsisV';

import { nexyColors } from '../../../../theme';
import { ContentManageBudgetDialog } from '../ContentManageBudgetDialog';
import LinkPortfolioContentDialog from '../LinkPortfolioContentDialog';
import { ContentOptimizationDialog } from './ContentOptimizationDialog';
import ContentRemoveDialog from './ContentRemoveDialog';

type Props = {
  item: NexoyaPortfolioContentDetail;
  portfolio: NexoyaPortfolio;
  dateFrom: Date;
  dateTo: Date | string;
};

// Content Table Row ThreeDotMenu
function ContentTableRowTDM({ item, portfolio, dateFrom, dateTo }: Props) {
  const {
    anchorEl: anchorElTDM,
    open: openTDM,
    toggleMenu: toggleMenuTDM,
    closeMenu: closeMenuTDM,
  } = useDropdownMenu();
  const { isOpen, toggleSidePanel } = useSidePanelState();
  const [contentId, setContentId] = React.useState<string>();

  const { isOpen: isDeleteDialogOpen, toggleDialog: toggleDeleteDialog } = useDialogState({
    initialState: false,
  });

  const { isOpen: isOptimizationDialogOpen, toggleDialog: toggleOptimizationDialog } = useDialogState({
    initialState: false,
  });

  const { isOpen: isBudgetDialogOpen, toggleDialog: toggleBudgetDialog } = useDialogState({
    initialState: false,
  });

  const [addManyContentRelations, { loading, error }] = useAddManyContentRelationsMutation({
    dateFrom,
    dateTo,
    portfolioId: portfolio.portfolioId,
    contentRelations: [
      {
        contentId: +contentId,
        belongsToContentId: item?.contentId,
      },
    ],
  });

  async function handleSubmit() {
    try {
      const res = await addManyContentRelations();
      const success = get(res, 'data.addManyContentRelations', false);
      if (success) {
        toggleSidePanel();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <div ref={anchorElTDM}>
      <ButtonIcon
        active={openTDM}
        onClick={(e: Event) => {
          e.preventDefault();
          toggleMenuTDM();
        }}
      >
        <SvgEllipsisV
          style={{
            fontSize: 18,
          }}
        />
      </ButtonIcon>
      {openTDM ? (
        <Panel
          anchorEl={anchorElTDM.current}
          open={openTDM}
          onClose={closeMenuTDM}
          placement="bottom-end"
          popperProps={{
            style: {
              zIndex: 2200,
            },
          }}
          style={{
            minWidth: 138,
          }}
        >
          <MenuList color="dark">
            <MenuItem
              key="link-content"
              onClick={() => {
                toggleSidePanel();
                toggleMenuTDM();
              }}
            >
              Link to
            </MenuItem>
            <MenuItem
              key="copy-id"
              onClick={() => {
                copyToClipboard(item?.contentId?.toString());
                toggleMenuTDM();
              }}
            >
              Copy ID
            </MenuItem>
            <MenuItem
              key="manage-budget-limit"
              onClick={() => {
                toggleBudgetDialog();
                toggleMenuTDM();
              }}
            >
              Manage budget limit
            </MenuItem>
            <MenuItem
              key="manage-optimization"
              onClick={() => {
                toggleOptimizationDialog();
                toggleMenuTDM();
              }}
            >
              {item.isIncludedInOptimization ? 'Disable' : 'Enable'} optimization
            </MenuItem>
            <MenuItem
              key="delete-content"
              onClick={() => {
                toggleDeleteDialog();
                toggleMenuTDM();
              }}
              buttonProps={{
                style: {
                  color: nexyColors.orangeyRed,
                },
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Panel>
      ) : null}
      <KpisFilterProvider2>
        {isOpen ? (
          <LinkPortfolioContentDialog
            isOpen={isOpen}
            loading={loading}
            parentId={item.contentId}
            contentId={contentId}
            setContentId={setContentId}
            onSubmit={handleSubmit}
            onClose={toggleSidePanel}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        ) : null}

        {error ? <ErrorMessage error={error} /> : null}
      </KpisFilterProvider2>
      {isDeleteDialogOpen ? (
        <ContentRemoveDialog
          isOpen={isDeleteDialogOpen}
          toggleDialog={toggleDeleteDialog}
          item={item}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      ) : null}

      {isOptimizationDialogOpen ? (
        <ContentOptimizationDialog
          isOpen={isOptimizationDialogOpen}
          toggleDialog={toggleOptimizationDialog}
          item={item}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      ) : null}

      {isBudgetDialogOpen ? (
        <ContentManageBudgetDialog
          isOpen={isBudgetDialogOpen}
          toggleDialog={toggleBudgetDialog}
          item={item}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      ) : null}
    </div>
  );
}

export default ContentTableRowTDM;
