type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgEnvelope = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 100 100"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path d="M5 22v56c0 1.105.895 2 2 2h86c1.105 0 2-.895 2-2V22c0-1.105-.895-2-2-2H7c-1.105 0-2 .895-2 2zm75.98 4.715L50.699 56.324a1.002 1.002 0 01-1.398 0L19.02 26.714c-.641-.624-.2-1.714.699-1.714H80.28c.898 0 1.34 1.09.699 1.715zm-69.285-.063L31.48 45.898c.371.36.406.946.078 1.348L11.773 71.644c-.59.73-1.773.309-1.773-.633V27.366c0-.883 1.062-1.332 1.695-.715zm5.117 46.72l18.016-22.22a1.002 1.002 0 011.465-.097l13.023 12.297a.999.999 0 001.371 0L63.71 51.055a1.002 1.002 0 011.465.097l18.012 22.22c.531.652.066 1.628-.777 1.628H17.59c-.844 0-1.309-.976-.778-1.629zm71.41-1.731L68.437 47.243a1.007 1.007 0 01.078-1.348L88.3 26.65c.637-.613 1.7-.164 1.7.719v43.645c0 .941-1.184 1.363-1.778.629z" />
  </svg>
);

export default SvgEnvelope;
