import { NexoyaFunnelStepType, NexoyaFunnelStepV2 } from '../types';

export const extractFunnelSteps = (
  funnelSteps: NexoyaFunnelStepV2[]
): { costFunnelStep: NexoyaFunnelStepV2; otherFunnelSteps: NexoyaFunnelStepV2[] } => {
  if (!funnelSteps?.length) {
    return { costFunnelStep: null, otherFunnelSteps: [] };
  }

  const costFunnelStep = funnelSteps?.find((funnelStep) => funnelStep.type === NexoyaFunnelStepType.Cost);
  const otherFunnelSteps = funnelSteps?.filter((fs) => fs.funnelStepId !== costFunnelStep.funnelStepId);

  return { costFunnelStep, otherFunnelSteps };
};
