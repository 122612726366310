import React from 'react';

import useTargetItemController from '../controllers/TargetItemController';

const TargetItemContext = React.createContext<Record<string, any> | null>(null);

function TargetItemProvider(props: any) {
  const value = useTargetItemController();
  return <TargetItemContext.Provider value={value} {...props} />;
}

function withTargetItemProvider(Component: any) {
  return (props: any) => (
    <TargetItemProvider>
      <Component {...props} />
    </TargetItemProvider>
  );
}

function useTargetItem(): Record<string, any> {
  const context = React.useContext(TargetItemContext);

  if (context === undefined) {
    throw new Error('useTargetItem must be used within a TargetItemProvider');
  }

  return context;
}

export { TargetItemProvider, useTargetItem, withTargetItemProvider };
