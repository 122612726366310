const SvgRoasSymbol = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <circle opacity={0.1} cx={20} cy={20} r={20} fill="#05A8FA" />
    <path
      d="m30.97 22.094-1.578-.911c.099-.786.099-1.58 0-2.366l1.578-.911c.463-.267.686-.818.534-1.33a11.979 11.979 0 0 0-2.784-4.82 1.161 1.161 0 0 0-1.424-.207l-1.576.91a9.393 9.393 0 0 0-2.048-1.185v-1.82c0-.539-.37-1.007-.895-1.13a12.085 12.085 0 0 0-5.553 0 1.162 1.162 0 0 0-.895 1.13v1.82a9.396 9.396 0 0 0-2.048 1.185l-1.576-.91a1.161 1.161 0 0 0-1.424.207 11.98 11.98 0 0 0-2.784 4.82 1.159 1.159 0 0 0 .534 1.33l1.578.91c-.1.787-.1 1.581 0 2.367l-1.578.911a1.158 1.158 0 0 0-.534 1.33 11.98 11.98 0 0 0 2.784 4.82c.37.39.959.476 1.424.207l1.576-.91c.63.48 1.318.878 2.048 1.185v1.82c0 .538.37 1.007.895 1.13 1.821.432 3.731.432 5.553 0 .524-.123.895-.591.895-1.13v-1.82a9.389 9.389 0 0 0 2.048-1.184l1.576.91a1.162 1.162 0 0 0 1.424-.208 11.98 11.98 0 0 0 2.784-4.82 1.158 1.158 0 0 0-.534-1.33Z"
      fill="#05A8FA"
      fillOpacity={0.8}
    />
    <circle cx={20} cy={20.001} r={7} fill="#fff" fillOpacity={0.9} />
    <path
      d="M22.25 17.3s-.666-.9-2.25-.9c-3.15 0-3.15 3.6 0 3.6s3.15 3.6 0 3.6c-1.35 0-2.25-.9-2.25-.9M20 15.5v9"
      stroke="#05A8FA"
      strokeWidth={1.35}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgRoasSymbol;
