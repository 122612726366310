import React from 'react';

import { NumberParam, useQueryParams } from 'use-query-params';

import { NexoyaSimulation } from '../../types';

import { useSimulationsQuery } from '../../graphql/simulation/simulationsQuery';

import { Simulation } from './components/Simulations/Simulation';
import { SimulationTableSkeleton } from './components/Simulations/SimulationSkeleton';
import { SimulationTable } from './components/Simulations/SimulationTable';

type Props = {
  portfolioId: number;
};

export function Simulations({ portfolioId }: Props) {
  const { data, loading } = useSimulationsQuery({
    portfolioId,
  });

  const simulations: NexoyaSimulation[] = data?.portfolioV2?.simulations;

  const [queryParams] = useQueryParams({
    simulationId: NumberParam,
  });

  if (loading) return <SimulationTableSkeleton />;

  return queryParams.simulationId ? (
    <Simulation simulationId={queryParams.simulationId} portfolioId={portfolioId} />
  ) : (
    <SimulationTable simulations={simulations} portfolioId={portfolioId} />
  );
}
