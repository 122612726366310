const SvgClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 11 10"
    fill="currentColor"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      d="M5.072 3.94 1.867.733l-.661-.66a.25.25 0 0 0-.354 0L.145.78a.25.25 0 0 0 0 .354L4.011 5 .145 8.866a.25.25 0 0 0 0 .353l.707.708a.25.25 0 0 0 .354 0L5.072 6.06l3.866 3.866a.25.25 0 0 0 .353 0L10 9.22a.25.25 0 0 0 0-.354L6.133 5l3.205-3.205.66-.661a.25.25 0 0 0 0-.354L9.292.073a.25.25 0 0 0-.353 0L5.072 3.94Z"
      fill="#151519"
    />
  </svg>
);
export default SvgClose;
