import React, { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useTeamQuery } from '../../graphql/team/queryTeam';
import { useUserQuery } from '../../graphql/user/queryUser';

import { EVENT } from '../../constants/events';

import { EXTERNAL_GOOGLE_ADS_MANAGEMENT_URL, PATHS } from '../../routes/paths';
import SvgPortfolio from 'components/icons/Portfolio';

import { sizes } from '../../theme/device';
import * as Styles from './styles/Sidebar';

import ButtonBase from '../ButtonBase';
import { pathIncludes } from '../ButtonNav';
import { Logo } from '../Logo';
import { LaptopLUp } from '../MediaQuery';
import Account from './components/Account';
import SvgBullseyePointer from '../icons/BullseyePointer';
import SvgCog from '../icons/Cog';
import SvgFileChartLine from '../icons/FileChartLine';
import SvgHomeAlt from '../icons/HomeAlt';
import SvgProjectDiagram from '../icons/ProjectDiagram';
import SvgQuestionCircle from '../icons/QuestionCircle';
import PortfoliosSearchSidebarItem, { SearchComponentType } from './PortfolioSearchSidebarItem';
import SidebarItem from './SidebarItem';
import Teams from './Teams';
import TooltipWrapForSmallScreens from './TooltipWrapForSmallScreens';
import { ExternalLink } from 'lucide-react';
import { FEATURE_FLAGS } from '../../constants/featureFlags';

const SIDEBAR_ITEMS = [
  {
    name: (
      <div>
        Welcome <span style={{ marginLeft: 4 }}>👋</span>
      </div>
    ),
    cy: 'welcomeBtnNav',
    link: PATHS.APP.ONBOARD_GUIDE,
    exact: true,
    isOnboarding: true,
  },
  {
    name: 'Dashboard',
    cy: 'dashboardBtnNav',
    link: PATHS.APP.HOME,
    exact: true,
    icon: <SvgHomeAlt />,
  },
  {
    name: 'Portfolios',
    cy: 'portfoliosBtnNav',
    link: PATHS.APP.PORTFOLIOS,
    event: EVENT.ROUTE_PORTFOLIOS,
    icon: <SvgPortfolio />,
    component: SearchComponentType.PORTFOLIOS,
  },
  {
    name: 'Reports',
    cy: 'reportsBtnNav',
    link: PATHS.APP.REPORTS,
    icon: <SvgFileChartLine />,
    event: EVENT.ROUTE_REPORTS,
  },
  {
    name: 'Metrics',
    cy: 'metricsBtnNav',
    link: PATHS.APP.KPIS,
    icon: <SvgBullseyePointer />,
    isActive: pathIncludes('/content/'),
  },
  {
    name: 'Correlations',
    cy: 'correlationsBtnNav',
    link: PATHS.APP.CORRELATIONS,
    icon: <SvgProjectDiagram />,
    event: EVENT.ROUTE_CORRELATIONS,
  },
  {
    name: 'Campaign Manager',
    link: EXTERNAL_GOOGLE_ADS_MANAGEMENT_URL,
    feature: FEATURE_FLAGS.GOOGLE_ADS_CAMPAIGN_MANAGEMENT,
    icon: <ExternalLink className="h-4 w-4 text-neutral-700" />,
    event: EVENT.ROUTE_CORRELATIONS,
    external: true,
  },
];

const LOWER_SIDEBAR_ITEMS = [
  {
    name: 'Settings',
    cy: 'settings',
    link: PATHS.APP.SETTINGS,
    icon: <SvgCog />,
    isActive: pathIncludes('/settings/'),
  },
];
function Sidebar() {
  const [filteredItems, setFilteredItems] = useState(SIDEBAR_ITEMS);
  const { data: userData, loading: userLoading } = useUserQuery();
  const { data: teamData, loading } = useTeamQuery({
    withMembers: false,
    withOrg: false,
  });

  useLayoutEffect(() => {
    if (!loading && !userLoading) {
      const isSupportUser = userData?.user?.activeRole?.name?.includes('support');
      const isOnboarding = teamData?.team?.onboarding?.onboardingTasks?.length;
      const hasCustomDashboardLinks = teamData?.team?.dashboardUrls?.length;

      let filteredItems = SIDEBAR_ITEMS;

      if (isOnboarding && !isSupportUser) {
        filteredItems = filteredItems.filter((sidebarItem) => sidebarItem.isOnboarding);
      } else {
        filteredItems = filteredItems.filter((sidebarItem) => !sidebarItem.isOnboarding);
      }

      if (!hasCustomDashboardLinks) {
        filteredItems = filteredItems.filter((sidebarItem) => sidebarItem.name !== 'Dashboard');
      }

      setFilteredItems(filteredItems);
    }
  }, [teamData, userData]);

  const isBelowLaptopL = useMediaQuery({
    maxWidth: sizes.laptopL - 1,
  });
  return (
    <Styles.WrapStyled isBelowLaptopL={isBelowLaptopL} data-cy="sideBar">
      <div className="logo-wrap">
        <Logo hideName={isBelowLaptopL} />
      </div>
      <Teams className="team-wrap" />
      <Styles.ScrollbarStyled className="nav-wrap">
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            filteredItems.map((item) =>
              item?.component === SearchComponentType.PORTFOLIOS ? (
                <PortfoliosSearchSidebarItem key={item.link} item={item} />
              ) : (
                // @ts-ignore
                <SidebarItem key={item!.link} item={item} />
              ),
            )
          )}
        </div>
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            LOWER_SIDEBAR_ITEMS.map((item) => <SidebarItem key={item.link} item={item} />)
          )}
          <Styles.SupportWrapStyled className="support-wrap">
            <TooltipWrapForSmallScreens content="Help" variant="dark" placement="right">
              <ButtonBase data-cy="helpBtnNav" href="https://www.nexoya.com/help/?embedded=true" target="_blank">
                <SvgQuestionCircle />
                <LaptopLUp>Help</LaptopLUp>
              </ButtonBase>
            </TooltipWrapForSmallScreens>
          </Styles.SupportWrapStyled>

          <Account />
        </div>
      </Styles.ScrollbarStyled>
    </Styles.WrapStyled>
  );
}

export default Sidebar;
