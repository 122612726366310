export const HELP_CENTER_URLS = {
  DASHBOARD: {
    HOW_TO_USE_DASHBOARD: 'https://www.nexoya.com/help/how-to-use-the-dashboard/?embedded=true',
  },
  METRIC: {
    HOW_TO_USE_METRICS: 'https://www.nexoya.com/help/how-to-use-metrics/?embedded=true',
  },
  CUSTOM_KPI: {
    HOW_TO_CREATE_CUSTOM_KPI: 'https://www.nexoya.com/help/how-to-create-custom-kpis/?embedded=true',
  },
  REPORT: {
    HOW_TO_USE_REPORTS_SECTION: 'https://www.nexoya.com/help/how-to-use-the-reports-section/?embedded=true',
  },
  PORTFOLIO: {
    HOW_PORTFOLIO_OPTIMIZATION_WORKS: 'https://www.nexoya.com/help/how-campaign-optimization-works/?embedded=true',
  },
  FUNNEL: {
    HOW_TO_CREATE_MARKETING_FUNNEL: 'https://www.nexoya.com/help/how-to-create-a-marketing-funnel/?embedded=true',
  },
  CORRELATION: {},
};
