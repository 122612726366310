import React, { useState } from 'react';

import { NexoyaBudgetItem, NexoyaPacingType } from '../types';

function useBudgetItemController(
  initialValues: NexoyaBudgetItem = {
    status: undefined,
    budgetItemId: -1,
    name: '',
    startDate: null,
    endDate: null,
    budgetAmount: null,
    pacing: NexoyaPacingType.Fixed,
    budgetDailyItems: [],
  }
): Record<string, any> {
  const [lastBudgetNumber, setLastBudgetNumber] = useState<string | undefined>(undefined);
  const [budgetItemState, setBudgetItemState] = useState<NexoyaBudgetItem>(initialValues);

  const handleChangeValueByKey = React.useCallback((ev: any) => {
    const { name, value } = ev.target;
    setBudgetItemState((s) => ({ ...s, [name]: value }));
  }, []);

  const resetState = () => {
    setBudgetItemState(initialValues);
    setLastBudgetNumber(undefined);
  };

  return {
    lastBudgetNumber,
    setLastBudgetNumber,
    budgetItemState,
    handleChangeValueByKey,
    resetState,
  };
}

export default useBudgetItemController;
