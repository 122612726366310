const SvgTargetDuotone = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 34 34"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      opacity={0.4}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.323 17.002a6.72 6.72 0 1 1-13.44 0 6.72 6.72 0 0 1 13.44 0Zm-10.56 0a3.84 3.84 0 1 0 7.68 0 3.84 3.84 0 0 0-7.68 0Z"
      fill="#0EC76A"
    />
    <path
      d="m20.534 14.37-2.832 2.833a.96.96 0 1 1-1.358-1.357l2.955-2.956-.227-1.09c-.072-.346.074-.831.32-1.078l3.846-3.845c.248-.25.509-.17.58.177l.494 2.369 2.368.493c.348.072.425.334.178.581l-3.845 3.845c-.25.249-.733.392-1.08.32l-1.399-.291Z"
      fill="#0EC76A"
    />
    <path
      clipRule="evenodd"
      d="M16.602 2c8.284 0 15 6.715 15 15 0 8.284-6.716 15-15 15-8.285 0-15-6.716-15-15 0-8.285 6.715-15 15-15Z"
      stroke="#DFE1ED"
      strokeOpacity={0.5}
      strokeWidth={2.88}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTargetDuotone;
