const SvgArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17 12"
    fill="currentColor"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.072 5H3.486l3.293-3.293A.999.999 0 1 0 5.365.293l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L3.486 7h11.586a1 1 0 1 0 0-2Z"
      fill="#151519"
    />
  </svg>
);
export default SvgArrowLeft;
