const SvgNexoyaLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <mask
      id="nexoya-logo_svg__a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={15}
      y={9}
      width={2}
      height={2}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.97 10.883a.88.88 0 1 1 0-1.762.88.88 0 0 1 0 1.762Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.39 7.422h5.162v5.161h-5.161V7.422Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__b"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={0}
      width={3}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47 2.162A.88.88 0 1 1 8.47.4a.88.88 0 0 1 0 1.762Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89-1.299h5.162v5.161H5.891v-5.16Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__c"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={3}
      width={3}
      height={4}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.472 6.316c-.718 0-1.3-.575-1.3-1.284 0-.71.582-1.284 1.3-1.284.718 0 1.3.575 1.3 1.284 0 .709-.582 1.284-1.3 1.284Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__c)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.469 2.31h5.944v5.871H5.47v-5.87Z"
        fill="#0EC76A"
        fillOpacity={0.66}
      />
    </g>
    <mask
      id="nexoya-logo_svg__d"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={15}
      y={13}
      width={2}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.97 15.232a.88.88 0 1 1 0-1.761.88.88 0 0 1 0 1.761Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.39 12.496h5.162v5.161h-5.161v-5.16Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__e"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={9}
      width={2}
      height={2}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.88 10.883a.88.88 0 1 1 0-1.762.88.88 0 0 1 0 1.762Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__e)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.844 7.367h5.162v5.161h-5.162v-5.16Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__f"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={6}
      width={4}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.097 8.772a1.265 1.265 0 1 1 0-2.53 1.265 1.265 0 0 1 0 2.53Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__f)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.207 4.613h5.784v5.784H1.207V4.613Z"
        fill="#0EC76A"
        fillOpacity={0.66}
      />
    </g>
    <mask
      id="nexoya-logo_svg__g"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={11}
      width={4}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.097 13.801a1.273 1.273 0 0 1-1.265-1.28c0-.707.566-1.28 1.265-1.28.698 0 1.265.573 1.265 1.28 0 .707-.567 1.28-1.265 1.28Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__g)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.207 9.592h5.784v5.856H1.207V9.592Z"
        fill="#0EC76A"
        fillOpacity={0.66}
      />
    </g>
    <mask
      id="nexoya-logo_svg__h"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={11}
      y={6}
      width={4}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8 8.772a1.265 1.265 0 1 1 0-2.53 1.265 1.265 0 0 1 0 2.53Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__h)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.906 4.613h5.784v5.784H9.906V4.613Z"
        fill="#0EC76A"
        fillOpacity={0.66}
      />
    </g>
    <mask
      id="nexoya-logo_svg__i"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={11}
      y={11}
      width={4}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.788 13.801a1.273 1.273 0 0 1-1.265-1.28c0-.707.567-1.28 1.265-1.28.699 0 1.265.573 1.265 1.28 0 .707-.566 1.28-1.265 1.28Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__i)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.895 9.592h5.784v5.856H9.895V9.592Z"
        fill="#0EC76A"
        fillOpacity={0.66}
      />
    </g>
    <mask
      id="nexoya-logo_svg__j"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={17}
      width={3}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47 19.6c-.486 0-.88-.416-.88-.929 0-.513.394-.929.88-.929.487 0 .882.416.882.93 0 .512-.395.929-.881.929Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__j)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89 15.95h5.162v5.444H5.891v-5.445Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__k"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={7}
      y={13}
      width={3}
      height={4}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.472 16.16c-.718 0-1.3-.566-1.3-1.264 0-.699.582-1.265 1.3-1.265.718 0 1.3.566 1.3 1.265 0 .698-.582 1.264-1.3 1.264Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__k)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 12.002h5.945v5.784H5.5v-5.784Z"
        fill="#0EC76A"
        fillOpacity={0.66}
      />
    </g>
    <mask
      id="nexoya-logo_svg__l"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={6}
      y={8}
      width={5}
      height={4}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.47 11.765a1.72 1.72 0 0 1-1.716-1.721c0-.951.769-1.722 1.717-1.722.948 0 1.717.77 1.717 1.722a1.72 1.72 0 0 1-1.717 1.72Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__l)">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.098 6.676h6.747v6.764H5.098V6.676Z" fill="#0EC76A" />
    </g>
    <mask
      id="nexoya-logo_svg__m"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={4}
      width={2}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.88 6.533c-.486 0-.88-.4-.88-.895 0-.493.394-.894.88-.894.487 0 .882.4.882.894a.888.888 0 0 1-.881.895Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__m)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.844 2.959h5.162v5.24h-5.162V2.96Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__n"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={2}
      width={3}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.514 4.105a.88.88 0 1 1 0-1.761.88.88 0 0 1 0 1.761Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__n)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.934.645h5.161v5.16H1.934V.646Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__o"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={11}
      y={2}
      width={3}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.357 4.105a.88.88 0 1 1 0-1.761.88.88 0 0 1 0 1.761Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__o)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.777.645h5.162v5.16H9.777V.646Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__p"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={15}
      width={3}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.514 17.707a.88.88 0 1 1 0-1.762.88.88 0 0 1 0 1.762Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__p)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.934 14.246h5.161v5.161H1.934v-5.16Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__q"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={11}
      y={15}
      width={3}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.357 17.707a.88.88 0 1 1 0-1.762.88.88 0 0 1 0 1.762Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__q)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.777 14.246h5.162v5.161H9.777v-5.16Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__r"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={15}
      y={4}
      width={2}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.97 6.533c-.486 0-.88-.4-.88-.895 0-.493.394-.894.88-.894.487 0 .882.4.882.894a.888.888 0 0 1-.881.895Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__r)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.34 3.066H18.5v5.24H13.34v-5.24Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
    <mask
      id="nexoya-logo_svg__s"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={13}
      width={2}
      height={3}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.88 15.232a.88.88 0 1 1 0-1.761.88.88 0 0 1 0 1.761Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#nexoya-logo_svg__s)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.844 11.906h5.162v5.161h-5.162v-5.16Z"
        fill="#0EC76A"
        fillOpacity={0.33}
      />
    </g>
  </svg>
);
export default SvgNexoyaLogo;
