import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _Any: any;
  Date: any;
  DateTime: any;
  JSON: any;
  JSONObject: any;
};










export type Nexoya_Entity = NexoyaCollection | NexoyaCoreMetricValues | NexoyaMeasurement | NexoyaOtherFunnelStepMetrics | NexoyaPortfolio | NexoyaProvider | NexoyaUser;

export type Nexoya_Service = {
  __typename?: '_Service';
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>;
};

export type NexoyaAccuracyBucket = {
  __typename?: 'AccuracyBucket';
  contentCount: Scalars['Int'];
  threshold: Scalars['Int'];
};

export type NexoyaAppendLog = {
  __typename?: 'AppendLog';
  append_log_id?: Maybe<Scalars['Int']>;
  failure?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  producer_identifier?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum NexoyaArgumentAuthorizationRules {
  BudgetItemIsPartOfTeam = 'BudgetItemIsPartOfTeam',
  CollectionIsPartOfTeam = 'CollectionIsPartOfTeam',
  ContentGroupIsPartOfTeam = 'ContentGroupIsPartOfTeam',
  FunnelStepIsPartOfTeam = 'FunnelStepIsPartOfTeam',
  ImpactGroupIsPartOfTeam = 'ImpactGroupIsPartOfTeam',
  IsUserThemselves = 'IsUserThemselves',
  LabelIsPartOfTeam = 'LabelIsPartOfTeam',
  OptimizationIsPartOfTeam = 'OptimizationIsPartOfTeam',
  PortfolioContentIsPartOfTeam = 'PortfolioContentIsPartOfTeam',
  PortfolioIsPartOfTeam = 'PortfolioIsPartOfTeam',
  PortfolioTargetItemIsPartOfTeam = 'PortfolioTargetItemIsPartOfTeam',
  ScenarioIsPartOfTeam = 'ScenarioIsPartOfTeam',
  SimulationIsPartOfTeam = 'SimulationIsPartOfTeam'
}

export type NexoyaAuthZDirectiveCompositeRulesInput = {
  and?: Maybe<Array<Maybe<NexoyaAuthZRules>>>;
  not?: Maybe<NexoyaAuthZRules>;
  or?: Maybe<Array<Maybe<NexoyaAuthZRules>>>;
};

export type NexoyaAuthZDirectiveDeepCompositeRulesInput = {
  and?: Maybe<Array<Maybe<NexoyaAuthZDirectiveDeepCompositeRulesInput>>>;
  id?: Maybe<NexoyaAuthZRules>;
  not?: Maybe<NexoyaAuthZDirectiveDeepCompositeRulesInput>;
  or?: Maybe<Array<Maybe<NexoyaAuthZDirectiveDeepCompositeRulesInput>>>;
};

export enum NexoyaAuthZRules {
  IsM2MUser = 'IsM2MUser',
  IsPartOfTeamOptional = 'IsPartOfTeamOptional'
}

export type NexoyaBiddingStrategy = {
  __typename?: 'BiddingStrategy';
  applicationDelta?: Maybe<Scalars['Float']>;
  currentTcpa?: Maybe<Scalars['Float']>;
  currentTroas?: Maybe<Scalars['Float']>;
  dailyBudgetChange?: Maybe<Scalars['Float']>;
  type: NexoyaBiddingStrategyType;
  value?: Maybe<Scalars['Float']>;
};

export enum NexoyaBiddingStrategyType {
  MaximizeConversionValue = 'MAXIMIZE_CONVERSION_VALUE',
  MaximizeConversions = 'MAXIMIZE_CONVERSIONS',
  Other = 'OTHER',
  TargetCpa = 'TARGET_CPA',
  TargetRoas = 'TARGET_ROAS'
}

export type NexoyaBudget = {
  __typename?: 'Budget';
  allocatedValue: Scalars['Float'];
  budgetDetails?: Maybe<Array<NexoyaBudgetDetail>>;
  budgetTotals?: Maybe<Array<NexoyaBudgetTotal>>;
  realizedValue: Scalars['Float'];
  totalValue: Scalars['Float'];
};

export type NexoyaBudgetDailyItem = {
  __typename?: 'BudgetDailyItem';
  budgetAmount: Scalars['Float'];
  date: Scalars['Date'];
};

export enum NexoyaBudgetDeltaHandlingPolicy {
  BudgetApplication = 'BUDGET_APPLICATION',
  EndOfMonth = 'END_OF_MONTH',
  Ignore = 'IGNORE',
  PortfolioLifetime = 'PORTFOLIO_LIFETIME',
  WithinCurrentMonth = 'WITHIN_CURRENT_MONTH'
}

export type NexoyaBudgetDetail = {
  __typename?: 'BudgetDetail';
  providerId: Scalars['Int'];
  totalAllocatedValue: Scalars['Float'];
  totalRealizedValue: Scalars['Float'];
  totalValue: Scalars['Float'];
  weeklyBudgets?: Maybe<Array<NexoyaWeeklyBudget>>;
};

export type NexoyaBudgetDetailInput = {
  allocatedValue: Scalars['Float'];
  endDate: Scalars['DateTime'];
  providerId: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type NexoyaBudgetInput = {
  allocatedValue: Scalars['Float'];
  providerId: Scalars['Int'];
};

export type NexoyaBudgetItem = {
  __typename?: 'BudgetItem';
  budgetAmount: Scalars['Float'];
  budgetDailyItems: Array<NexoyaBudgetDailyItem>;
  budgetItemId: Scalars['Float'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  pacing: NexoyaPacingType;
  spendSoFar?: Maybe<Scalars['Float']>;
  startDate: Scalars['Date'];
  status: NexoyaBudgetItemStatus;
  suspendedDate?: Maybe<Scalars['Date']>;
};

export enum NexoyaBudgetItemStatus {
  Active = 'ACTIVE',
  ActiveNoOptimization = 'ACTIVE_NO_OPTIMIZATION',
  Past = 'PAST',
  Planned = 'PLANNED'
}

export type NexoyaBudgetProposal = {
  __typename?: 'BudgetProposal';
  budgetProposalData: Array<NexoyaBudgetProposalData>;
  optimizationId: Scalars['Int'];
  portfolio: NexoyaPortfolioSummary;
  status: NexoyaBudgetProposalStatus;
  timestampApplied?: Maybe<Scalars['DateTime']>;
  timestampCreated: Scalars['DateTime'];
};


export type NexoyaBudgetProposalBudgetProposalDataArgs = {
  applicationType?: Maybe<NexoyaBudgetProposalDataApplicationType>;
};

export type NexoyaBudgetProposalConnection = {
  __typename?: 'BudgetProposalConnection';
  edges: Array<NexoyaBudgetProposalEdge>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaBudgetProposalData = {
  __typename?: 'BudgetProposalData';
  applicationType: NexoyaBudgetProposalDataApplicationType;
  biddingStrategyChangePercent: Scalars['Float'];
  budgetType?: Maybe<NexoyaBudgetType>;
  initialBiddingStrategy?: Maybe<NexoyaBiddingStrategy>;
  initialBudget?: Maybe<Scalars['Float']>;
  lifetimeBudget?: Maybe<NexoyaLifetimeBudget>;
  portfolioContentId: Scalars['Float'];
  proposedBiddingStrategy: NexoyaBiddingStrategy;
  proposedBudget: Scalars['Float'];
  timestampApplied?: Maybe<Scalars['DateTime']>;
};

export enum NexoyaBudgetProposalDataApplicationType {
  Auto = 'AUTO',
  Manual = 'MANUAL',
  SkipFromProposal = 'SKIP_FROM_PROPOSAL'
}

export type NexoyaBudgetProposalEdge = {
  __typename?: 'BudgetProposalEdge';
  cursor: Scalars['String'];
  node: NexoyaBudgetProposal;
};

export enum NexoyaBudgetProposalStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type NexoyaBudgetReallocation = {
  __typename?: 'BudgetReallocation';
  dates: Array<Maybe<NexoyaBudgetReallocationDate>>;
  pastBudget: Scalars['Float'];
  pastSpend: Scalars['Float'];
};

export type NexoyaBudgetReallocationDate = {
  __typename?: 'BudgetReallocationDate';
  budgetAmount: Scalars['Float'];
  date: Scalars['Date'];
};

export type NexoyaBudgetTotal = {
  __typename?: 'BudgetTotal';
  allocatedValue: Scalars['Float'];
  endDate: Scalars['DateTime'];
  realizedValue: Scalars['Float'];
  startDate: Scalars['DateTime'];
  totalValue: Scalars['Float'];
};

export enum NexoyaBudgetType {
  Daily = 'DAILY',
  Lifetime = 'LIFETIME'
}

export enum NexoyaCalcType {
  Avg = 'avg',
  Div = 'div',
  Max = 'max',
  Min = 'min',
  Mul = 'mul',
  Sum = 'sum'
}

export type NexoyaChannelReportContent = {
  __typename?: 'ChannelReportContent';
  kpis?: Maybe<Array<NexoyaMeasurement>>;
  provider?: Maybe<NexoyaProvider>;
};

export type NexoyaCkBulkCreateInput = {
  calc_type: NexoyaCalcType;
  description?: Maybe<Scalars['String']>;
  kpis?: Maybe<Array<NexoyaCkKpiInput>>;
  name: Scalars['String'];
  search?: Maybe<NexoyaCkSearchInput>;
};

export type NexoyaCkKpiInput = {
  collection_id?: Maybe<Scalars['Float']>;
  default_value?: Maybe<Scalars['Float']>;
  measurement_id?: Maybe<Scalars['Int']>;
};

export type NexoyaCkMeasurementIds = {
  __typename?: 'CKMeasurementIds';
  avg: Scalars['Int'];
  sum: Scalars['Int'];
  total: Scalars['Int'];
};

export type NexoyaCkSearch = {
  __typename?: 'CKSearch';
  measurement_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  provider_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  query?: Maybe<Scalars['String']>;
  sumOnly?: Maybe<Scalars['Boolean']>;
};

export type NexoyaCkSearchInput = {
  measurement_id?: Maybe<Array<Scalars['Int']>>;
  provider_id?: Maybe<Array<Scalars['Int']>>;
  query: Scalars['String'];
  sumOnly: Scalars['Boolean'];
};

export type NexoyaCollection = {
  __typename?: 'Collection';
  admin_url?: Maybe<Scalars['String']>;
  child_collections?: Maybe<Array<Maybe<NexoyaCollection>>>;
  collection_id: Scalars['Float'];
  collectionType: NexoyaCollectionType;
  creation_date?: Maybe<Scalars['DateTime']>;
  detection_date?: Maybe<Scalars['DateTime']>;
  identifier?: Maybe<Scalars['String']>;
  measurements?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  metadata?: Maybe<Array<Maybe<NexoyaMetadata>>>;
  parent_collection?: Maybe<NexoyaCollection>;
  parent_collection_id?: Maybe<Scalars['Float']>;
  platform_identifier?: Maybe<Scalars['JSON']>;
  provider?: Maybe<NexoyaProvider>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type NexoyaCollectionChild_CollectionsArgs = {
  first?: Maybe<Scalars['Float']>;
};


export type NexoyaCollectionMeasurementsArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  metric_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type NexoyaCollectionMetadataArgs = {
  metadataTypes?: Maybe<Array<Maybe<NexoyaMetadataType>>>;
};

export type NexoyaCollectionConnection = {
  __typename?: 'CollectionConnection';
  edges: Array<NexoyaCollectionEdges>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaCollectionEdges = {
  __typename?: 'CollectionEdges';
  cursor: Scalars['String'];
  node: NexoyaCollection;
};

export type NexoyaCollectionInput = {
  active: Scalars['Boolean'];
  admin_url?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Float']>;
  creation_date?: Maybe<Scalars['DateTime']>;
  detection_date: Scalars['DateTime'];
  identifier?: Maybe<Scalars['String']>;
  parent_collection_id?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Int']>;
  team_id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NexoyaCollectionType = {
  __typename?: 'CollectionType';
  collection_type_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type NexoyaComputeBudgetProposalBudgetsReturnType = {
  __typename?: 'ComputeBudgetProposalBudgetsReturnType';
  failedEntryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NexoyaContent = {
  __typename?: 'Content';
  admin_url?: Maybe<Scalars['String']>;
  content_id: Scalars['Float'];
  content_type: NexoyaContentType;
  creation_date?: Maybe<Scalars['DateTime']>;
  detection_date?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Array<Maybe<NexoyaMetadata>>>;
  metrics?: Maybe<Array<Maybe<NexoyaMetric>>>;
  platform_identifier?: Maybe<Scalars['JSON']>;
  provider?: Maybe<NexoyaProvider>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type NexoyaContentMetadataArgs = {
  metadataTypes?: Maybe<Array<Maybe<NexoyaMetadataType>>>;
};


export type NexoyaContentMetricsArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  metric_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type NexoyaContentConnection = {
  __typename?: 'ContentConnection';
  edges: Array<NexoyaContentEdges>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaContentEdges = {
  __typename?: 'ContentEdges';
  cursor: Scalars['String'];
  node: NexoyaContent;
};

export type NexoyaContentFilter = {
  __typename?: 'ContentFilter';
  fieldName: NexoyaContentFilterFieldName;
  operator: NexoyaContentFilterOperator;
  value?: Maybe<NexoyaContentFilterValue>;
};

export enum NexoyaContentFilterFieldName {
  BidStrategy = 'bidStrategy',
  CollectionId = 'collectionId',
  CollectionType = 'collectionType',
  EndDate = 'endDate',
  LatestMeasurementDataDate = 'latestMeasurementDataDate',
  ParentCollectionId = 'parentCollectionId',
  ParentTitle = 'parentTitle',
  ProviderId = 'providerId',
  StartDate = 'startDate',
  TeamId = 'teamId',
  Title = 'title'
}

export type NexoyaContentFilterInput = {
  fieldName: NexoyaContentFilterFieldName;
  operator: NexoyaContentFilterOperator;
  value: NexoyaContentFilterValueInput;
};

export enum NexoyaContentFilterOperator {
  Eq = 'eq',
  Gte = 'gte',
  Lte = 'lte',
  Ne = 'ne'
}

export type NexoyaContentFilterValue = {
  __typename?: 'ContentFilterValue';
  date?: Maybe<Scalars['Date']>;
  number?: Maybe<Scalars['Float']>;
  numberArr?: Maybe<Array<Scalars['Float']>>;
  string?: Maybe<Scalars['String']>;
  stringArr?: Maybe<Array<Scalars['String']>>;
};

export type NexoyaContentFilterValueInput = {
  date?: Maybe<Scalars['Date']>;
  number?: Maybe<Scalars['Float']>;
  numberArr?: Maybe<Array<Scalars['Float']>>;
  string?: Maybe<Scalars['String']>;
  stringArr?: Maybe<Array<Scalars['String']>>;
};

export type NexoyaContentGroup = {
  __typename?: 'ContentGroup';
  contentGroupId: Scalars['Float'];
  contents: Array<NexoyaContentV2>;
  filters: Array<NexoyaContentFilter>;
  name: Scalars['String'];
  portfolioId: Scalars['Float'];
  providerId: Scalars['Float'];
  teamId: Scalars['Float'];
};

export type NexoyaContentType = {
  __typename?: 'ContentType';
  content_type_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type NexoyaContentV2 = {
  __typename?: 'ContentV2';
  active?: Maybe<Scalars['Boolean']>;
  adminUrl?: Maybe<Scalars['String']>;
  biddingStrategy?: Maybe<Scalars['String']>;
  contentId: Scalars['Float'];
  contentType: NexoyaCollectionType;
  creationDate: Scalars['DateTime'];
  dataLastUpdatedAt?: Maybe<Scalars['DateTime']>;
  detectionDate: Scalars['DateTime'];
  endDatetime?: Maybe<Scalars['DateTime']>;
  identifier?: Maybe<Scalars['String']>;
  latestMeasurementDataDate?: Maybe<Scalars['Date']>;
  parent?: Maybe<NexoyaContentV2>;
  provider?: Maybe<NexoyaProvider>;
  startDatetime?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NexoyaCoreFunnelValues = {
  __typename?: 'CoreFunnelValues';
  costPer?: Maybe<Array<Maybe<NexoyaCostPerData>>>;
  realizedMetricByContent?: Maybe<Array<NexoyaRealizedMetricDataPast>>;
  realizedMetricDataPast?: Maybe<Array<NexoyaRealizedMetricDataPast>>;
  valueTotal?: Maybe<Scalars['Float']>;
};

export type NexoyaCoreMetricValues = {
  __typename?: 'CoreMetricValues';
  costPer?: Maybe<Scalars['Float']>;
  helpcenterLink?: Maybe<Scalars['String']>;
  metricTypeId?: Maybe<Scalars['Float']>;
  metricTypeName?: Maybe<Scalars['String']>;
  valueTotal?: Maybe<Scalars['Float']>;
};

export type NexoyaCoreOptMetricValues = {
  __typename?: 'CoreOptMetricValues';
  helpcenter_link?: Maybe<Scalars['String']>;
  metric_type_id?: Maybe<Scalars['Int']>;
  metric_type_name?: Maybe<Scalars['String']>;
};

export type NexoyaCostPerData = {
  __typename?: 'CostPerData';
  timestamp: Scalars['DateTime'];
  valueCumulative?: Maybe<Scalars['Float']>;
  valueRelative?: Maybe<Scalars['Float']>;
};

export enum NexoyaCreateBudgetItemError {
  BudgetItemOverlap = 'BUDGET_ITEM_OVERLAP'
}

export type NexoyaCreateBudgetItemPrecheckResult = {
  __typename?: 'CreateBudgetItemPrecheckResult';
  errors?: Maybe<Array<Maybe<NexoyaCreateBudgetItemError>>>;
};

export type NexoyaCurrencyData = {
  __typename?: 'CurrencyData';
  currencies?: Maybe<Array<Scalars['String']>>;
  numberFormats?: Maybe<Array<Scalars['String']>>;
};

export type NexoyaCustomKpi = {
  __typename?: 'CustomKPI';
  calc_type: Scalars['String'];
  custom_kpi_id: Scalars['Int'];
  defaultValue?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  kpis?: Maybe<Array<NexoyaMeasurement>>;
  kpisAll?: Maybe<Array<NexoyaMeasurement>>;
  kpisFromPairs?: Maybe<Array<NexoyaMeasurement>>;
  measurementId: Scalars['Int'];
  measurementIds: NexoyaCkMeasurementIds;
  name: Scalars['String'];
  search?: Maybe<NexoyaCkSearch>;
  team_id: Scalars['Int'];
  updated_at: Scalars['DateTime'];
  updatedBy?: Maybe<NexoyaUser>;
};


export type NexoyaCustomKpiKpisArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type NexoyaCustomKpiKpisAllArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type NexoyaCustomKpiKpisFromPairsArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  measurementCollectionPairs: Array<Maybe<NexoyaKpiInputOptCollection>>;
};

export type NexoyaDailyContentMetric = {
  __typename?: 'DailyContentMetric';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonValue?: Maybe<NexoyaPerformanceMetric>;
  contentId: Scalars['Float'];
  isIncludedInOptimization: Scalars['Boolean'];
  providerId: Scalars['Int'];
  title: Scalars['String'];
  value?: Maybe<NexoyaPerformanceMetric>;
};

export type NexoyaDailyImpactGroupMetric = {
  __typename?: 'DailyImpactGroupMetric';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonValue?: Maybe<NexoyaPerformanceMetric>;
  impactGroup: NexoyaImpactGroup;
  value?: Maybe<NexoyaPerformanceMetric>;
};

export type NexoyaDailyLabelMetric = {
  __typename?: 'DailyLabelMetric';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonValue?: Maybe<NexoyaPerformanceMetric>;
  labelId: Scalars['Int'];
  value?: Maybe<NexoyaPerformanceMetric>;
};

export type NexoyaDailyMetric = {
  __typename?: 'DailyMetric';
  comparisonDay?: Maybe<Scalars['Date']>;
  contents: Array<NexoyaDailyContentMetric>;
  day?: Maybe<Scalars['Date']>;
  impactGroups: Array<NexoyaDailyImpactGroupMetric>;
  labels: Array<NexoyaDailyLabelMetric>;
  providers: Array<NexoyaDailyProviderMetric>;
};

export type NexoyaDailyOptimizationImpactGroupMetric = {
  __typename?: 'DailyOptimizationImpactGroupMetric';
  cumulativeBaseline: Scalars['Float'];
  cumulativeExpected: Scalars['Float'];
  impactGroup: NexoyaImpactGroup;
  relativeBaseline: Scalars['Float'];
  relativeExpected: Scalars['Float'];
};

export type NexoyaDailyOptimizationLabelMetric = {
  __typename?: 'DailyOptimizationLabelMetric';
  cumulativeBaseline: Scalars['Float'];
  cumulativeExpected: Scalars['Float'];
  labelId: Scalars['Int'];
  relativeBaseline: Scalars['Float'];
  relativeExpected: Scalars['Float'];
};

export type NexoyaDailyOptimizationMetric = {
  __typename?: 'DailyOptimizationMetric';
  day: Scalars['Date'];
  impactGroups: Array<NexoyaDailyOptimizationImpactGroupMetric>;
  labels: Array<Maybe<NexoyaDailyOptimizationLabelMetric>>;
  providers: Array<NexoyaDailyOptimizationProviderMetric>;
};

export type NexoyaDailyOptimizationProviderMetric = {
  __typename?: 'DailyOptimizationProviderMetric';
  cumulativeBaseline: Scalars['Float'];
  cumulativeExpected: Scalars['Float'];
  providerId: Scalars['Int'];
  relativeBaseline: Scalars['Float'];
  relativeExpected: Scalars['Float'];
};

export type NexoyaDailyPrediction = {
  __typename?: 'DailyPrediction';
  achieved: Scalars['Float'];
  day: Scalars['Date'];
  predicted: Scalars['Float'];
  score: Scalars['Float'];
};

export type NexoyaDailyPredictionTotal = {
  __typename?: 'DailyPredictionTotal';
  day: Scalars['Date'];
  score: Scalars['Float'];
};

export type NexoyaDailyProviderMetric = {
  __typename?: 'DailyProviderMetric';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonValue?: Maybe<NexoyaPerformanceMetric>;
  providerId: Scalars['Int'];
  value?: Maybe<NexoyaPerformanceMetric>;
};

export type NexoyaDashboardUrl = {
  __typename?: 'DashboardUrl';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type NexoyaDashboardUrlInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type NexoyaDataType = {
  __typename?: 'DataType';
  description?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['Boolean']>;
  symbol?: Maybe<Scalars['String']>;
};


export type NexoyaDateRange = {
  __typename?: 'DateRange';
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};

export type NexoyaDateRangeInput = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type NexoyaDateRangeRequiredInput = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
};


export type NexoyaEvent = {
  __typename?: 'Event';
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  event_id: Scalars['Int'];
  subject: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type NexoyaEventFilter = {
  subject?: Maybe<Scalars['String']>;
};

export type NexoyaExternalLinkInput = {
  title: Scalars['String'];
  url: Scalars['String'];
};

export type NexoyaFeatureFlag = {
  __typename?: 'FeatureFlag';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type NexoyaFeatureFlagInput = {
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type NexoyaFetchJob = {
  __typename?: 'FetchJob';
  date_created?: Maybe<Scalars['DateTime']>;
  desired_state?: Maybe<Scalars['String']>;
  fetch_job_id: Scalars['Int'];
  input?: Maybe<Scalars['JSON']>;
  provider_id?: Maybe<Scalars['Int']>;
  status?: Maybe<NexoyaJoblogState>;
  team_id?: Maybe<Scalars['Int']>;
};

export type NexoyaFieldAllowedValues = {
  __typename?: 'FieldAllowedValues';
  enumOptionsNumber?: Maybe<Array<Scalars['Float']>>;
  enumOptionsString?: Maybe<Array<Scalars['String']>>;
  fieldType: NexoyaFieldType;
};

export type NexoyaFieldOperation = {
  __typename?: 'FieldOperation';
  allowed: NexoyaFieldAllowedValues;
  fieldName: NexoyaContentFilterFieldName;
  operators: Array<NexoyaContentFilterOperator>;
};

export enum NexoyaFieldType {
  Date = 'date',
  Number = 'number',
  NumberArr = 'numberArr',
  String = 'string',
  StringArr = 'stringArr'
}

export type NexoyaFilter = {
  collectionTypes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  includeChildren?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  measurementIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
  providerIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  title?: Maybe<Scalars['String']>;
};

export type NexoyaFilterListType = {
  __typename?: 'filterListType';
  id?: Maybe<Scalars['String']>;
  itemInfo?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type NexoyaFreeFormBiddingStrategyChangeInput = {
  biddingStrategyType: NexoyaBiddingStrategyType;
  biddingStrategyValue?: Maybe<Scalars['Float']>;
  campaignId: Scalars['Float'];
};

export type NexoyaFreeFormBiddingStrategyChangesResult = {
  __typename?: 'FreeFormBiddingStrategyChangesResult';
  campaignId: Scalars['Float'];
  errorMessage?: Maybe<Scalars['String']>;
  newBiddingStrategy?: Maybe<NexoyaBiddingStrategy>;
  oldBiddingStrategy?: Maybe<NexoyaBiddingStrategy>;
  status: Scalars['String'];
};

export type NexoyaFreeFormDailyBudgetChangeInput = {
  campaignId: Scalars['Float'];
  dailyBudget: Scalars['Float'];
};

export type NexoyaFreeFormDailyBudgetChangesResult = {
  __typename?: 'FreeFormDailyBudgetChangesResult';
  campaignId: Scalars['Float'];
  errorMessage?: Maybe<Scalars['String']>;
  newBudget?: Maybe<Scalars['Float']>;
  oldBudget?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type NexoyaFreeFormLifetimeBudgetChangeInput = {
  budgetSegments: Array<NexoyaFreeFormLifetimeBudgetSegmentInput>;
  campaignId: Scalars['Float'];
};

export type NexoyaFreeFormLifetimeBudgetChangesResult = {
  __typename?: 'FreeFormLifetimeBudgetChangesResult';
  campaignId: Scalars['Float'];
  errorMessage?: Maybe<Scalars['String']>;
  newBudget?: Maybe<Array<NexoyaFreeFormLifetimeBudgetSegment>>;
  oldBudget?: Maybe<Array<NexoyaFreeFormLifetimeBudgetSegment>>;
  status: Scalars['String'];
};

export type NexoyaFreeFormLifetimeBudgetSegment = {
  __typename?: 'FreeFormLifetimeBudgetSegment';
  endDate: Scalars['Date'];
  segmentBudget: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type NexoyaFreeFormLifetimeBudgetSegmentInput = {
  endDate: Scalars['Date'];
  segmentBudget: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type NexoyaFunnelStep = {
  __typename?: 'FunnelStep';
  coreFunnelValues?: Maybe<NexoyaCoreFunnelValues>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  funnel_step_id: Scalars['Int'];
  input_funnel_step_id?: Maybe<Scalars['Int']>;
  optimizationTargetType?: Maybe<NexoyaFunnelStepType>;
  realizedMetricDataPast?: Maybe<Array<NexoyaRealizedMetricData>>;
  team_id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type NexoyaFunnelStepMapping = {
  funnelStepId: Scalars['Int'];
  metricId?: Maybe<Scalars['Int']>;
};

export type NexoyaFunnelStepPerformance = {
  __typename?: 'FunnelStepPerformance';
  dailyMetrics: Array<NexoyaDailyMetric>;
  dailyOptimizationMetrics: Array<NexoyaDailyOptimizationMetric>;
  funnelStep: NexoyaFunnelStepV2;
  metricTotals: NexoyaMetricTotal;
  optimizationMetricTotals?: Maybe<NexoyaOptimizationMetricTotal>;
};

export type NexoyaFunnelStepPredictionScore = {
  __typename?: 'FunnelStepPredictionScore';
  accuracyBuckets: Array<NexoyaAccuracyBucket>;
  dailyPredictions: Array<NexoyaDailyPrediction>;
  funnelStepId: Scalars['Int'];
  score: Scalars['Float'];
  title: Scalars['String'];
  type: NexoyaFunnelStepType;
};

export enum NexoyaFunnelStepType {
  Awareness = 'AWARENESS',
  Consideration = 'CONSIDERATION',
  Conversion = 'CONVERSION',
  ConversionValue = 'CONVERSION_VALUE',
  Cost = 'COST',
  Other = 'OTHER'
}

export type NexoyaFunnelStepV2 = {
  __typename?: 'FunnelStepV2';
  funnelStepId: Scalars['Int'];
  title: Scalars['String'];
  type: NexoyaFunnelStepType;
};

export type NexoyaImpactGroup = {
  __typename?: 'ImpactGroup';
  funnelSteps: Array<NexoyaFunnelStep>;
  impactGroupId: Scalars['Int'];
  name: Scalars['String'];
  portfolioId: Scalars['Int'];
};

export type NexoyaImpactGroupMetricTotal = {
  __typename?: 'ImpactGroupMetricTotal';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonTotal?: Maybe<NexoyaPerformanceMetric>;
  impactGroup: NexoyaImpactGroup;
  total: NexoyaPerformanceMetric;
};

export type NexoyaIntegration = {
  __typename?: 'Integration';
  connected: Scalars['Boolean'];
  connectionUrl?: Maybe<Scalars['String']>;
  connectionUrlPartner?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  filterOptions?: Maybe<Array<Maybe<NexoyaProviderFilter>>>;
  hasFilter?: Maybe<Scalars['Boolean']>;
  integration_id: Scalars['Int'];
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  provider?: Maybe<NexoyaProvider>;
  provider_id: Scalars['Int'];
  status?: Maybe<NexoyaIntegrationStatus>;
  title: Scalars['String'];
  type: Scalars['String'];
  user?: Maybe<NexoyaUser>;
};

export type NexoyaIntegrationFilter = {
  connected?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export enum NexoyaIntegrationStatus {
  Error = 'ERROR',
  Synced = 'SYNCED',
  Syncing = 'SYNCING'
}

export enum NexoyaJoblogState {
  Done = 'DONE',
  Failed = 'FAILED',
  FailedTimeout = 'FAILED_TIMEOUT',
  InProgress = 'IN_PROGRESS'
}



export type NexoyaKpi = {
  __typename?: 'KPI';
  datatype?: Maybe<NexoyaDataType>;
  description?: Maybe<Scalars['String']>;
  kpi_id: Scalars['Int'];
  measurements?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<Array<Maybe<NexoyaProvider>>>;
};


export type NexoyaKpiMeasurementsArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  team_id: Scalars['Int'];
};

export type NexoyaKpiInput = {
  collection_id: Scalars['Float'];
  measurement_id: Scalars['Int'];
};

export type NexoyaKpiInputOptCollection = {
  collection_id?: Maybe<Scalars['Float']>;
  measurement_id: Scalars['Int'];
};

export type NexoyaKpiInputOptMetric = {
  collection_id: Scalars['Float'];
  measurement_id?: Maybe<Scalars['Int']>;
};

export type NexoyaKpiSummary = {
  __typename?: 'KPISummary';
  averageType?: Maybe<Scalars['Boolean']>;
  datatype?: Maybe<NexoyaDataType>;
  group?: Maybe<NexoyaKpiSummaryGroup>;
};

export type NexoyaKpiSummaryGroup = {
  __typename?: 'KPISummaryGroup';
  goal?: Maybe<Scalars['Float']>;
  lowerIsBetter?: Maybe<Scalars['Boolean']>;
  measurement_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type NexoyaKpiSummaryType = {
  __typename?: 'KPISummaryType';
  summary?: Maybe<Array<Maybe<NexoyaKpiSummary>>>;
};

export type NexoyaLabelMetricTotal = {
  __typename?: 'LabelMetricTotal';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonTotal?: Maybe<NexoyaPerformanceMetric>;
  labelId: Scalars['Int'];
  total: NexoyaPerformanceMetric;
};

export type NexoyaLifetimeBudget = {
  __typename?: 'LifetimeBudget';
  lifetimeBudgetSegments: Array<Maybe<NexoyaLifetimeBudgetSegment>>;
  providerBudgetMissingDays: Array<Maybe<Scalars['Date']>>;
};

export type NexoyaLifetimeBudgetSegment = {
  __typename?: 'LifetimeBudgetSegment';
  endDate: Scalars['Date'];
  initialBudget: Scalars['Float'];
  proposedBudget: Scalars['Float'];
  spend?: Maybe<Scalars['Float']>;
  spendUpdatedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['Date'];
};

export type NexoyaMeasurement = {
  __typename?: 'Measurement';
  aggregate_up_to?: Maybe<Scalars['Int']>;
  api_path?: Maybe<Scalars['String']>;
  calculation_type?: Maybe<Scalars['String']>;
  collection?: Maybe<NexoyaCollection>;
  collections?: Maybe<Array<Maybe<Scalars['String']>>>;
  customKpiConfig?: Maybe<NexoyaCustomKpi>;
  datatype?: Maybe<NexoyaDataType>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<NexoyaMeasurementDetail>;
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  goal?: Maybe<Scalars['Float']>;
  helpcenter_link?: Maybe<Scalars['String']>;
  import_sum_type?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  kpi?: Maybe<NexoyaKpi>;
  lowerIsBetter?: Maybe<Scalars['Boolean']>;
  measurement_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  optimization_target_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider?: Maybe<NexoyaProvider>;
  provider_id?: Maybe<Scalars['Int']>;
  showAsTotal?: Maybe<Scalars['Boolean']>;
};


export type NexoyaMeasurementDatatypeArgs = {
  team_id?: Maybe<Scalars['Int']>;
};

export type NexoyaMeasurementAggregate = {
  __typename?: 'MeasurementAggregate';
  aggregatedValue: Scalars['Float'];
  dailyValues?: Maybe<Array<Maybe<NexoyaMeasurementData>>>;
};

export type NexoyaMeasurementCollectionInput = {
  collection_id: Scalars['Float'];
  goal?: Maybe<Scalars['Float']>;
  measurement_id: Scalars['Int'];
};

export type NexoyaMeasurementCollectionPairInput = {
  collection_id: Scalars['Float'];
  measurement_id: Scalars['Int'];
};

export type NexoyaMeasurementConnection = {
  __typename?: 'MeasurementConnection';
  edges: Array<NexoyaMeasurementEdges>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaMeasurementData = {
  __typename?: 'MeasurementData';
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  valueSumUp?: Maybe<Scalars['Float']>;
};

export type NexoyaMeasurementDetail = {
  __typename?: 'MeasurementDetail';
  data?: Maybe<Array<Maybe<NexoyaMeasurementData>>>;
  value?: Maybe<Scalars['Float']>;
  valueChangePercentage?: Maybe<Scalars['Float']>;
  valueSum?: Maybe<Scalars['Float']>;
  valueSumUptoEndDate?: Maybe<Scalars['Float']>;
};

export type NexoyaMeasurementEdges = {
  __typename?: 'MeasurementEdges';
  cursor: Scalars['String'];
  node: NexoyaMeasurement;
};

export type NexoyaMeasurementGroup = {
  __typename?: 'MeasurementGroup';
  key: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type NexoyaMetadata = {
  __typename?: 'Metadata';
  created_at?: Maybe<Scalars['DateTime']>;
  metadata_key: Scalars['String'];
  metadata_type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_lookup?: Maybe<Scalars['String']>;
};

export enum NexoyaMetadataType {
  BiddingStrategy = 'BIDDING_STRATEGY',
  DailyBudgetMicros = 'DAILY_BUDGET_MICROS',
  EndDatetime = 'END_DATETIME',
  SharedBudget = 'SHARED_BUDGET',
  StartDatetime = 'START_DATETIME',
  Status = 'STATUS',
  TargetCpaMicros = 'TARGET_CPA_MICROS'
}

export type NexoyaMetric = {
  __typename?: 'Metric';
  aggregate_up_to?: Maybe<Scalars['Int']>;
  api_path?: Maybe<Scalars['String']>;
  calculation_type?: Maybe<Scalars['String']>;
  collection?: Maybe<NexoyaCollection>;
  collections?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<NexoyaContent>;
  customKpiConfig?: Maybe<NexoyaCustomKpi>;
  datatype?: Maybe<NexoyaDataType>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<NexoyaMetricDetail>;
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  goal?: Maybe<Scalars['Float']>;
  helpcenter_link?: Maybe<Scalars['String']>;
  import_sum_type?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  kpi?: Maybe<NexoyaKpi>;
  lowerIsBetter?: Maybe<Scalars['Boolean']>;
  metric_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<NexoyaProvider>;
  provider_id?: Maybe<Scalars['Int']>;
  showAsTotal?: Maybe<Scalars['Boolean']>;
};


export type NexoyaMetricDatatypeArgs = {
  team_id?: Maybe<Scalars['Int']>;
};

export type NexoyaMetricData = {
  timestamp: Scalars['DateTime'];
  valueCumulative: Scalars['Float'];
  valueRelative: Scalars['Float'];
};

export type NexoyaMetricDataCore = {
  __typename?: 'MetricDataCore';
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  valueSumUp?: Maybe<Scalars['Float']>;
};

export type NexoyaMetricDetail = {
  __typename?: 'MetricDetail';
  data?: Maybe<Array<Maybe<NexoyaMetricDataCore>>>;
  value?: Maybe<Scalars['Float']>;
  valueChangePercentage?: Maybe<Scalars['Float']>;
  valueSum?: Maybe<Scalars['Float']>;
  valueSumUptoEndDate?: Maybe<Scalars['Float']>;
};

export type NexoyaMetricMeta = {
  __typename?: 'MetricMeta';
  helpcenter_link?: Maybe<Scalars['String']>;
  metric_type_id?: Maybe<Scalars['Int']>;
  metric_type_name?: Maybe<Scalars['String']>;
};

export type NexoyaMetricTotal = {
  __typename?: 'MetricTotal';
  impactGroups: Array<NexoyaImpactGroupMetricTotal>;
  labels: Array<NexoyaLabelMetricTotal>;
  providers?: Maybe<Array<NexoyaProviderMetricTotal>>;
};

export type NexoyaMutation = {
  __typename?: 'Mutation';
  activateUser?: Maybe<Scalars['Boolean']>;
  addManualIntegration?: Maybe<Scalars['Boolean']>;
  addManyContentRelations: Scalars['Boolean'];
  addPortfolioContent: Array<Scalars['Int']>;
  addReportKpis?: Maybe<Scalars['Boolean']>;
  addSelectedMeasurement?: Maybe<Array<Maybe<Scalars['Int']>>>;
  addTeamMember?: Maybe<Scalars['Boolean']>;
  applySimulation: NexoyaSimulation;
  assignImpactGroupToPortfolioContents?: Maybe<Scalars['Boolean']>;
  bulkAssignFunnelStepMetrics: Scalars['Boolean'];
  bulkAssignLabels: Scalars['Boolean'];
  bulkCreateCustomKpis: Array<NexoyaCustomKpi>;
  cancelOptimization: Scalars['Boolean'];
  cancelSimulation: Scalars['Boolean'];
  changeBaseScenario: Scalars['Boolean'];
  changeCurrency?: Maybe<NexoyaTeam>;
  changeFunnelStepType?: Maybe<Scalars['Boolean']>;
  changeProposalDataApplicationType?: Maybe<NexoyaBudgetProposal>;
  checkForStuckOptimizations?: Maybe<Scalars['Boolean']>;
  computeBudgetProposalBiddingStrategies?: Maybe<NexoyaBudgetProposal>;
  computeBudgetProposalBudgets?: Maybe<NexoyaComputeBudgetProposalBudgetsReturnType>;
  concludeOptimizationBudgetProposal: NexoyaOptimizationV2;
  copyPortfolio?: Maybe<NexoyaPortfolio>;
  copyReport?: Maybe<NexoyaReport>;
  createBudgetItem?: Maybe<NexoyaBudgetItem>;
  createBudgetProposal?: Maybe<NexoyaBudgetProposal>;
  createCollection?: Maybe<NexoyaCollection>;
  createContentGroup?: Maybe<NexoyaContentGroup>;
  createCustomKpi?: Maybe<NexoyaCustomKpi>;
  createEvent?: Maybe<NexoyaEvent>;
  createFetchJob?: Maybe<NexoyaFetchJob>;
  createFunnelStep?: Maybe<Scalars['Boolean']>;
  createOnboardingData?: Maybe<Scalars['Boolean']>;
  createOptimization: NexoyaOptimizationV2;
  createOrUpdateLabel: NexoyaPortfolioLabel;
  createOrUpdatePortfolioImpactGroup: NexoyaImpactGroup;
  createParentCollection?: Maybe<NexoyaCollection>;
  createPortfolio?: Maybe<NexoyaPortfolio>;
  createPortfolioTargetItem?: Maybe<NexoyaPortfolioTargetItem>;
  createReport?: Maybe<NexoyaReport>;
  createSimulation: NexoyaSimulation;
  deactivatePortfolio?: Maybe<Scalars['Boolean']>;
  deleteBudgetItem?: Maybe<Scalars['Boolean']>;
  deleteContentGroup: Scalars['Boolean'];
  deleteCustomImportData: Scalars['Int'];
  deleteCustomKpi?: Maybe<Scalars['Boolean']>;
  deleteEvent?: Maybe<Scalars['Boolean']>;
  deleteFunnelStep?: Maybe<Scalars['Boolean']>;
  deleteIntegration?: Maybe<Scalars['Boolean']>;
  deleteLabel: Scalars['Boolean'];
  deleteOptimization?: Maybe<Scalars['Boolean']>;
  deleteParentCollection?: Maybe<Scalars['Boolean']>;
  deletePortfolioImpactGroup?: Maybe<Scalars['Boolean']>;
  deleteReport?: Maybe<Scalars['Boolean']>;
  deleteSimulation: Scalars['Boolean'];
  editBudgetItem?: Maybe<NexoyaBudgetItem>;
  editPortfolioTargetItem?: Maybe<NexoyaPortfolioTargetItem>;
  editSimulation: NexoyaSimulation;
  endBudgetItem?: Maybe<NexoyaBudgetItem>;
  endPortfolioTargetItem?: Maybe<NexoyaPortfolioTargetItem>;
  executeAutomaticFreeFormBiddingStrategyChanges: Array<NexoyaFreeFormBiddingStrategyChangesResult>;
  executeAutomaticFreeFormDailyBudgetChanges: Array<NexoyaFreeFormDailyBudgetChangesResult>;
  executeAutomaticFreeFormLifetimeBudgetChanges: Array<NexoyaFreeFormLifetimeBudgetChangesResult>;
  executeBudgetProposal?: Maybe<NexoyaBudgetProposal>;
  fixPortfolioContentMetrics: Scalars['Boolean'];
  flushCache?: Maybe<Scalars['Boolean']>;
  inviteShareUser?: Maybe<Scalars['Int']>;
  inviteUser?: Maybe<Scalars['Int']>;
  makeOptimizationVisibleToAllUsers: NexoyaOptimizationV2;
  markProposalDataAsApplied?: Maybe<Scalars['Boolean']>;
  refreshPortfolioContentMeasurements?: Maybe<Scalars['Boolean']>;
  removeContentRelation: Scalars['Boolean'];
  removePortfolioContent: Scalars['Boolean'];
  removePortfolioContentWithCollectionId: Scalars['Boolean'];
  removePortfolioTargetItem?: Maybe<Scalars['Boolean']>;
  removeReportKpis?: Maybe<Scalars['Boolean']>;
  removeSelectedMeasurement?: Maybe<Array<Maybe<Scalars['Int']>>>;
  removeUser?: Maybe<Scalars['Boolean']>;
  renameFunnelStep?: Maybe<Scalars['Boolean']>;
  retryContentAutomaticApplication?: Maybe<NexoyaBudgetProposal>;
  retryOptimizationDataFetches: Scalars['Boolean'];
  retryOptimizationTask?: Maybe<Scalars['Boolean']>;
  revertBudgetProposal?: Maybe<NexoyaBudgetProposal>;
  runSimulation: NexoyaSimulation;
  setupPortfolioCalibration: Scalars['Boolean'];
  setUserInfo?: Maybe<Scalars['Boolean']>;
  setUserState?: Maybe<Scalars['Boolean']>;
  setWhitelistFilter?: Maybe<Scalars['Boolean']>;
  skipOptimizationDataFetches: Scalars['Boolean'];
  syncTeamChannel: Scalars['Boolean'];
  updateBudgetCS: Scalars['Boolean'];
  updateBudgetDetail?: Maybe<NexoyaWeeklyBudget>;
  updateContentGroup?: Maybe<NexoyaContentGroup>;
  updateContentTargetMetric?: Maybe<Scalars['Boolean']>;
  updateCustomKpi?: Maybe<NexoyaCustomKpi>;
  updateDefaultOptimizationTarget?: Maybe<Scalars['Boolean']>;
  updateEvent?: Maybe<NexoyaEvent>;
  updateFeatureFlag: Scalars['Boolean'];
  updateFunnelRelation?: Maybe<NexoyaUpdateFunnelRelationPayload>;
  updateOptimization?: Maybe<Scalars['Boolean']>;
  updatePortfolio?: Maybe<NexoyaPortfolio>;
  updatePortfolioContentBudgetBoundaries: Scalars['Boolean'];
  updatePortfolioContentIncludedInOptimization: Scalars['Boolean'];
  updateReport?: Maybe<NexoyaReport>;
  /** @deprecated Use `updateCampaignMeasurements`. */
  updateSelectedKPIs?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** @deprecated Use `addSelectedMeasurement` and `removeSelectedMeasurement`. */
  updateSelectedMeasurements?: Maybe<Array<Maybe<Scalars['Int']>>>;
  updateTeam?: Maybe<NexoyaTeam>;
  updateTeamMemberRole?: Maybe<Scalars['Boolean']>;
  upsertPortfolioContentConfig: Scalars['Boolean'];
  upsertProviderKey?: Maybe<NexoyaProviderKey>;
  upsertTeam?: Maybe<NexoyaTeam>;
  whitelistViews?: Maybe<Scalars['Boolean']>;
};


export type NexoyaMutationActivateUserArgs = {
  org_id: Scalars['Int'];
  orgInfo: NexoyaOrgInfoInput;
  team_id: Scalars['Int'];
  teamInfo: NexoyaTeamInfoInput;
  userInfo?: Maybe<NexoyaUserInfo>;
};


export type NexoyaMutationAddManualIntegrationArgs = {
  data: Scalars['String'];
  provider_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationAddManyContentRelationsArgs = {
  contentRelations: Array<NexoyaPortfolioContentRelationInput>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationAddPortfolioContentArgs = {
  collectionIds: Array<Scalars['Float']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationAddReportKpisArgs = {
  kpis: Array<NexoyaKpiInputOptMetric>;
  report_id: Scalars['Int'];
};


export type NexoyaMutationAddSelectedMeasurementArgs = {
  collection_id: Scalars['Float'];
  measurement_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationAddTeamMemberArgs = {
  role_def: Scalars['String'];
  team_id: Scalars['Int'];
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};


export type NexoyaMutationApplySimulationArgs = {
  portfolioId: Scalars['Float'];
  scenarioId: Scalars['Float'];
  simulationId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationAssignImpactGroupToPortfolioContentsArgs = {
  contentIds: Array<Scalars['Float']>;
  impactGroupId?: Maybe<Scalars['Int']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationBulkAssignFunnelStepMetricsArgs = {
  childrenProviderId?: Maybe<Scalars['Int']>;
  excludedContentIds: Array<Scalars['Float']>;
  funnelStepId: Scalars['Int'];
  metricId?: Maybe<Scalars['Int']>;
  portfolioId: Scalars['Int'];
  providerId?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};


export type NexoyaMutationBulkAssignLabelsArgs = {
  contentIds: Array<Scalars['Float']>;
  labelId?: Maybe<Scalars['Int']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationBulkCreateCustomKpisArgs = {
  customKpis: Array<NexoyaCkBulkCreateInput>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationCancelOptimizationArgs = {
  optimizationId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationCancelSimulationArgs = {
  portfolioId: Scalars['Float'];
  simulationId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationChangeBaseScenarioArgs = {
  newBaseScenarioId: Scalars['Float'];
  portfolioId: Scalars['Float'];
  simulationId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationChangeCurrencyArgs = {
  currency: Scalars['String'];
  number_format: Scalars['String'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationChangeFunnelStepTypeArgs = {
  funnelStepId: Scalars['Int'];
  newType: NexoyaFunnelStepType;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationChangeProposalDataApplicationTypeArgs = {
  newApplicationType: NexoyaBudgetProposalDataApplicationType;
  optimizationId: Scalars['Int'];
  portfolioContentId: Scalars['Float'];
  portfolioId?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationComputeBudgetProposalBiddingStrategiesArgs = {
  A_ImpactReducer_ProposedBudgetChange?: Maybe<Scalars['Float']>;
  B_ImpactReducer_BidStrategyTargetDelta?: Maybe<Scalars['Float']>;
  boundaryPercent?: Maybe<Scalars['Float']>;
  C_ImpactReducer_BudgetApplicationDelta?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Date']>;
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
  to?: Maybe<Scalars['Date']>;
};


export type NexoyaMutationComputeBudgetProposalBudgetsArgs = {
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationConcludeOptimizationBudgetProposalArgs = {
  accept: Scalars['Boolean'];
  optimizationId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationCopyPortfolioArgs = {
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationCopyReportArgs = {
  report_id: Scalars['Int'];
};


export type NexoyaMutationCreateBudgetItemArgs = {
  budgetAmount: Scalars['Float'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  pacing: NexoyaPacingType;
  portfolioId: Scalars['Int'];
  startDate: Scalars['Date'];
  suspendedDate?: Maybe<Scalars['Date']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationCreateBudgetProposalArgs = {
  optimizationId: Scalars['Int'];
  portfolioContentIdsToSkip?: Maybe<Array<Scalars['Float']>>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationCreateCollectionArgs = {
  collection?: Maybe<NexoyaCollectionInput>;
};


export type NexoyaMutationCreateContentGroupArgs = {
  filters: Array<NexoyaContentFilterInput>;
  name: Scalars['String'];
  portfolioId: Scalars['Float'];
  providerId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationCreateCustomKpiArgs = {
  calc_type: NexoyaCalcType;
  description?: Maybe<Scalars['String']>;
  kpis?: Maybe<Array<NexoyaCkKpiInput>>;
  name: Scalars['String'];
  noDataFetch?: Maybe<Scalars['Boolean']>;
  search?: Maybe<NexoyaCkSearchInput>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationCreateEventArgs = {
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  team_id: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};


export type NexoyaMutationCreateFetchJobArgs = {
  desired_state: Scalars['String'];
  inputParams?: Maybe<Scalars['JSON']>;
  provider_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationCreateFunnelStepArgs = {
  includeContent: Scalars['Boolean'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: NexoyaFunnelStepType;
};


export type NexoyaMutationCreateOnboardingDataArgs = {
  team_id: Scalars['Int'];
};


export type NexoyaMutationCreateOptimizationArgs = {
  end: Scalars['Date'];
  portfolioId: Scalars['Int'];
  skipCustomImportCheck?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationCreateOrUpdateLabelArgs = {
  labelId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationCreateOrUpdatePortfolioImpactGroupArgs = {
  funnelStepIds: Array<Scalars['Int']>;
  impactGroupId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationCreateParentCollectionArgs = {
  children?: Maybe<Array<Maybe<Scalars['Int']>>>;
  collection?: Maybe<NexoyaCollectionInput>;
};


export type NexoyaMutationCreatePortfolioArgs = {
  budgetDeltaHandlingPolicy?: Maybe<NexoyaBudgetDeltaHandlingPolicy>;
  budgets?: Maybe<Array<Maybe<NexoyaBudgetInput>>>;
  contents?: Maybe<Array<Scalars['Float']>>;
  createdByUserId: Scalars['Int'];
  defaultOptimizationTarget: NexoyaFunnelStepType;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  optimizationRiskLevel: Scalars['Int'];
  optimizationType: NexoyaOptimizationType;
  skipTrainingDays?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  teamId: Scalars['Int'];
  title: Scalars['String'];
  type: NexoyaPortfolioType;
};


export type NexoyaMutationCreatePortfolioTargetItemArgs = {
  end: Scalars['Date'];
  maxBudget: Scalars['Float'];
  name: Scalars['String'];
  portfolioId: Scalars['Int'];
  start: Scalars['Date'];
  teamId: Scalars['Int'];
  value: Scalars['Float'];
};


export type NexoyaMutationCreateReportArgs = {
  config?: Maybe<Scalars['String']>;
  dateRange: NexoyaReportDateRangeInput;
  description?: Maybe<Scalars['String']>;
  kpis?: Maybe<Array<Maybe<NexoyaKpiInputOptMetric>>>;
  name: Scalars['String'];
  report_type?: Maybe<Scalars['String']>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationCreateSimulationArgs = {
  budgetSteps: Array<NexoyaSimulationBudgetStepInput>;
  budgetStepSize: Scalars['Int'];
  end: Scalars['Date'];
  ignoreContentBudgetLimits: Scalars['Boolean'];
  name: Scalars['String'];
  portfolioId: Scalars['Int'];
  skipNonOptimizedContentBudgets: Scalars['Boolean'];
  start: Scalars['Date'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeactivatePortfolioArgs = {
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteBudgetItemArgs = {
  budgetItemId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteContentGroupArgs = {
  contentGroupId: Scalars['Float'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteCustomImportDataArgs = {
  collectionIds?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  nOfKpisToDeleteConfirm: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteCustomKpiArgs = {
  custom_kpi_id: Scalars['Int'];
};


export type NexoyaMutationDeleteEventArgs = {
  event_id: Scalars['Int'];
};


export type NexoyaMutationDeleteFunnelStepArgs = {
  funnelStepId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteIntegrationArgs = {
  provider_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationDeleteLabelArgs = {
  labelId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteOptimizationArgs = {
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteParentCollectionArgs = {
  id: Scalars['Int'];
};


export type NexoyaMutationDeletePortfolioImpactGroupArgs = {
  impactGroupId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationDeleteReportArgs = {
  report_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationDeleteSimulationArgs = {
  portfolioId: Scalars['Float'];
  simulationId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationEditBudgetItemArgs = {
  budgetAmount?: Maybe<Scalars['Float']>;
  budgetItemId: Scalars['Int'];
  endDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  portfolioId: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationEditPortfolioTargetItemArgs = {
  end?: Maybe<Scalars['Date']>;
  maxBudget?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  portfolioId: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  targetItemId: Scalars['Int'];
  teamId: Scalars['Int'];
  value?: Maybe<Scalars['Float']>;
};


export type NexoyaMutationEditSimulationArgs = {
  budgetSteps?: Maybe<Array<NexoyaSimulationBudgetStepInput>>;
  budgetStepSize?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Date']>;
  ignoreContentBudgetLimits?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  portfolioId: Scalars['Float'];
  simulationId: Scalars['Float'];
  skipNonOptimizedContentBudgets?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  teamId: Scalars['Float'];
};


export type NexoyaMutationEndBudgetItemArgs = {
  budgetItemId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationEndPortfolioTargetItemArgs = {
  portfolioId: Scalars['Int'];
  targetItemId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationExecuteAutomaticFreeFormBiddingStrategyChangesArgs = {
  changes: Array<NexoyaFreeFormBiddingStrategyChangeInput>;
  dryRun?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationExecuteAutomaticFreeFormDailyBudgetChangesArgs = {
  changes: Array<NexoyaFreeFormDailyBudgetChangeInput>;
  dryRun?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationExecuteAutomaticFreeFormLifetimeBudgetChangesArgs = {
  changes: Array<NexoyaFreeFormLifetimeBudgetChangeInput>;
  dryRun?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationExecuteBudgetProposalArgs = {
  dryRun?: Maybe<Scalars['Boolean']>;
  optimizationId: Scalars['Int'];
  portfolioContentIdsToApplyManually?: Maybe<Array<Scalars['Float']>>;
  portfolioContentIdsToSkip?: Maybe<Array<Scalars['Float']>>;
  targetBiddingApplyMode: NexoyaTargetBiddingApplyMode;
  teamId: Scalars['Int'];
  updateLifetimeBudgets?: Maybe<Scalars['Boolean']>;
};


export type NexoyaMutationFixPortfolioContentMetricsArgs = {
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationFlushCacheArgs = {
  portfolioId?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationInviteShareUserArgs = {
  shareType: NexoyaShareType;
  sharingObjectName?: Maybe<NexoyaSharingObjectName>;
  team_id: Scalars['Int'];
  to_email: Scalars['String'];
  to_name: Scalars['String'];
  url: Scalars['String'];
};


export type NexoyaMutationInviteUserArgs = {
  team_id: Scalars['Int'];
  to_email: Scalars['String'];
  to_name: Scalars['String'];
};


export type NexoyaMutationMakeOptimizationVisibleToAllUsersArgs = {
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationMarkProposalDataAsAppliedArgs = {
  optimizationId: Scalars['Int'];
  portfolioContentIds?: Maybe<Array<Scalars['Float']>>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationRefreshPortfolioContentMeasurementsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  portfolioId?: Maybe<Scalars['Int']>;
  providerIds?: Maybe<Array<Scalars['Int']>>;
  startDate?: Maybe<Scalars['Date']>;
  teamId: Scalars['Int'];
};


export type NexoyaMutationRemoveContentRelationArgs = {
  contentId: Scalars['Float'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationRemovePortfolioContentArgs = {
  portfolioContentIds: Array<Scalars['Int']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationRemovePortfolioContentWithCollectionIdArgs = {
  collectionIds: Array<Scalars['Float']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationRemovePortfolioTargetItemArgs = {
  portfolioId: Scalars['Int'];
  targetItemId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationRemoveReportKpisArgs = {
  kpis: Array<NexoyaKpiInputOptMetric>;
  report_id: Scalars['Int'];
};


export type NexoyaMutationRemoveSelectedMeasurementArgs = {
  collection_id: Scalars['Float'];
  measurement_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaMutationRemoveUserArgs = {
  team_id: Scalars['Int'];
  user_to_remove_id: Scalars['Int'];
};


export type NexoyaMutationRenameFunnelStepArgs = {
  funnelStepId: Scalars['Int'];
  newFunnelStepTitle: Scalars['String'];
};


export type NexoyaMutationRetryContentAutomaticApplicationArgs = {
  contentId: Scalars['Float'];
  dryRun?: Maybe<Scalars['Boolean']>;
  optimizationId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationRetryOptimizationDataFetchesArgs = {
  optimizationId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationRetryOptimizationTaskArgs = {
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  taskToRetry: NexoyaOptimizationTaskType;
  teamId: Scalars['Int'];
};


export type NexoyaMutationRevertBudgetProposalArgs = {
  dryRun?: Maybe<Scalars['Boolean']>;
  optimizationId: Scalars['Int'];
  targetBiddingApplyMode: NexoyaTargetBiddingApplyMode;
  teamId: Scalars['Int'];
  updateLifetimeBudgets?: Maybe<Scalars['Boolean']>;
};


export type NexoyaMutationRunSimulationArgs = {
  portfolioId: Scalars['Float'];
  simulationId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationSetupPortfolioCalibrationArgs = {
  calibrationContentId: Scalars['Float'];
  inputFunnelStepId: Scalars['Int'];
  labelId: Scalars['Int'];
  namePrefixForCustomKpi?: Maybe<Scalars['String']>;
  outputFunnelStepId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationSetUserInfoArgs = {
  userInfo: NexoyaUserInfo;
};


export type NexoyaMutationSetUserStateArgs = {
  state: NexoyaUserStateEnum;
};


export type NexoyaMutationSetWhitelistFilterArgs = {
  addList?: Maybe<Array<Scalars['String']>>;
  filterList?: Maybe<Array<Scalars['String']>>;
  filterName: Scalars['String'];
  lastFilter?: Maybe<Scalars['Boolean']>;
  provider_id: Scalars['Int'];
  removeList?: Maybe<Array<Scalars['String']>>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationSkipOptimizationDataFetchesArgs = {
  optimizationId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationSyncTeamChannelArgs = {
  from?: Maybe<Scalars['Date']>;
  providerId: Scalars['Int'];
  teamId: Scalars['Int'];
  to?: Maybe<Scalars['Date']>;
};


export type NexoyaMutationUpdateBudgetCsArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  newAllocatedValue: Scalars['Float'];
  portfolioId: Scalars['Int'];
  providerId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpdateBudgetDetailArgs = {
  budgetDetail: NexoyaBudgetDetailInput;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpdateContentGroupArgs = {
  contentGroupId: Scalars['Float'];
  filters?: Maybe<Array<NexoyaContentFilterInput>>;
  name?: Maybe<Scalars['String']>;
  portfolioId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationUpdateContentTargetMetricArgs = {
  contentFunnelStepMetric: NexoyaPortfolioContentFunnelStepMetricInput;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpdateCustomKpiArgs = {
  calc_type?: Maybe<NexoyaCalcType>;
  custom_kpi_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  kpis?: Maybe<Array<NexoyaCkKpiInput>>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<NexoyaCkSearchInput>;
};


export type NexoyaMutationUpdateDefaultOptimizationTargetArgs = {
  funnelStepId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpdateEventArgs = {
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  event_id: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};


export type NexoyaMutationUpdateFeatureFlagArgs = {
  name: NexoyaPortfolioFeatureFlag;
  portfolioId: Scalars['Int'];
  status: Scalars['Boolean'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpdateFunnelRelationArgs = {
  funnelRelations?: Maybe<Array<NexoyaPortfolioFunnelRelationInput>>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpdateOptimizationArgs = {
  attachment?: Maybe<Scalars['String']>;
  dateApplied?: Maybe<Scalars['DateTime']>;
  dateArchived?: Maybe<Scalars['DateTime']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};


export type NexoyaMutationUpdatePortfolioArgs = {
  budgetDeltaHandlingPolicy?: Maybe<NexoyaBudgetDeltaHandlingPolicy>;
  budgetProposalTargetBiddingApplyMode?: Maybe<NexoyaTargetBiddingApplyMode>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  optimizationRiskLevel?: Maybe<Scalars['Int']>;
  optimizationType?: Maybe<NexoyaOptimizationType>;
  portfolioDashboardUrls?: Maybe<Array<Maybe<NexoyaPortfolioDashboardUrlInput>>>;
  portfolioId: Scalars['Int'];
  portfolioMonitorBudgetThreshold?: Maybe<Scalars['Int']>;
  portfolioMonitorFunnelStepThreshold?: Maybe<Scalars['Int']>;
  skipTrainingDays?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};


export type NexoyaMutationUpdatePortfolioContentBudgetBoundariesArgs = {
  newBudget: NexoyaPortfolioContentBudgetInput;
  onlyContentIds?: Maybe<Array<Scalars['Float']>>;
  portfolioId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationUpdatePortfolioContentIncludedInOptimizationArgs = {
  isIncludedInOptimization: Scalars['Boolean'];
  portfolioContentId: Scalars['Float'];
  portfolioId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaMutationUpdateReportArgs = {
  config?: Maybe<Scalars['String']>;
  dateRange?: Maybe<NexoyaReportDateRangeInput>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  report_id: Scalars['Int'];
};


export type NexoyaMutationUpdateSelectedKpIsArgs = {
  kpi_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationUpdateSelectedMeasurementsArgs = {
  measurement_collection?: Maybe<Array<Maybe<NexoyaMeasurementCollectionInput>>>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationUpdateTeamArgs = {
  active?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  dashboardUrls?: Maybe<Array<NexoyaDashboardUrlInput>>;
  featureFlags?: Maybe<Array<NexoyaFeatureFlagInput>>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberFormat?: Maybe<Scalars['String']>;
  onboarding?: Maybe<NexoyaOnboardingInput>;
  team_id: Scalars['Int'];
};


export type NexoyaMutationUpdateTeamMemberRoleArgs = {
  new_role_def: Scalars['String'];
  team_id: Scalars['Int'];
  user_id: Scalars['Int'];
};


export type NexoyaMutationUpsertPortfolioContentConfigArgs = {
  contentIds: Array<Scalars['Float']>;
  funnelStepMappings: Array<NexoyaFunnelStepMapping>;
  impactGroupId?: Maybe<Scalars['Float']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaMutationUpsertProviderKeyArgs = {
  item?: Maybe<NexoyaProviderKeyInput>;
};


export type NexoyaMutationUpsertTeamArgs = {
  org?: Maybe<NexoyaTeamWithOrgInput>;
  team?: Maybe<NexoyaTeamInput>;
};


export type NexoyaMutationWhitelistViewsArgs = {
  provider_id: Scalars['Int'];
  team_id: Scalars['Int'];
  viewIds: Array<Scalars['Int']>;
};

export type NexoyaNewOptimizationSummary = {
  __typename?: 'NewOptimizationSummary';
  allContentsHaveImpactGroupAssigned: Scalars['Boolean'];
  daysWithMissingBudget: Array<Scalars['Date']>;
  hasIncorrectEndDateMonth: Scalars['Boolean'];
  isCustomImportDataFresh: Scalars['Boolean'];
  isOptiPeriodSpanningMultipleTargetItems: Scalars['Boolean'];
  targetItem?: Maybe<NexoyaPortfolioTargetItem>;
  totalBudget: Scalars['Float'];
};

export type NexoyaNewSimulationSummary = {
  __typename?: 'NewSimulationSummary';
  budgetPreviews: Array<NexoyaSimulationBudgetPreview>;
  hasContentsWithBudgetLimits: Scalars['Boolean'];
  hasDisabledContents: Scalars['Boolean'];
};

export type NexoyaOnboardingInput = {
  onboardingTasks?: Maybe<Array<NexoyaOnboardingTaskInput>>;
  videoSrc?: Maybe<Scalars['String']>;
};

export type NexoyaOnboardingTaskInput = {
  deadline: Scalars['String'];
  externalLink?: Maybe<NexoyaExternalLinkInput>;
  providerId?: Maybe<Scalars['Int']>;
  responsible: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type NexoyaOptimizationConnection = {
  __typename?: 'OptimizationConnection';
  edges: Array<NexoyaOptimizationEdge>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaOptimizationEdge = {
  __typename?: 'OptimizationEdge';
  cursor: Scalars['String'];
  node: NexoyaOptimizationV2;
};

export type NexoyaOptimizationLabelMetricTotal = {
  __typename?: 'OptimizationLabelMetricTotal';
  baselineTotal: Scalars['Float'];
  expectedTotal: Scalars['Float'];
  labelId: Scalars['Int'];
};

export type NexoyaOptimizationMetricTotal = {
  __typename?: 'OptimizationMetricTotal';
  baselineTotal: Scalars['Float'];
  expectedTotal: Scalars['Float'];
  labels?: Maybe<Array<NexoyaOptimizationLabelMetricTotal>>;
  potentialPercentage?: Maybe<Scalars['Float']>;
  providers?: Maybe<Array<NexoyaOptimizationProviderMetricTotal>>;
};

export type NexoyaOptimizationPerformance = {
  __typename?: 'OptimizationPerformance';
  channels: Array<NexoyaOptimizedChannel>;
  contents: Array<NexoyaOptimizedContent>;
  impactGroups: Array<NexoyaOptimizedImpactGroup>;
  labels: Array<NexoyaOptimizedLabel>;
  total: NexoyaOptimizedTotal;
};

export type NexoyaOptimizationProviderMetricTotal = {
  __typename?: 'OptimizationProviderMetricTotal';
  baselineTotal: Scalars['Float'];
  expectedTotal: Scalars['Float'];
  providerId: Scalars['Int'];
};

export enum NexoyaOptimizationStatus {
  Applied = 'APPLIED',
  Cancelled = 'CANCELLED',
  Discarded = 'DISCARDED',
  Running = 'RUNNING'
}

export type NexoyaOptimizationTasks = {
  __typename?: 'OptimizationTasks';
  APPLYING_BUDGET_PROPOSAL: NexoyaOptimizationTaskStatus;
  COMPUTING_BUDGET: NexoyaOptimizationTaskStatus;
  FETCHING_DATA: NexoyaOptimizationTaskStatus;
  GENERATING_BUDGET_PROPOSAL: NexoyaOptimizationTaskStatus;
  PROPOSAL_WAITING: NexoyaOptimizationTaskStatus;
  RUNNING_OPTIMIZATION: NexoyaOptimizationTaskStatus;
};

export enum NexoyaOptimizationTaskStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Skipped = 'SKIPPED',
  Successful = 'SUCCESSFUL'
}

export enum NexoyaOptimizationTaskType {
  ApplyingBudgetProposal = 'APPLYING_BUDGET_PROPOSAL',
  ComputingBudget = 'COMPUTING_BUDGET',
  FetchingData = 'FETCHING_DATA',
  GeneratingBudgetProposal = 'GENERATING_BUDGET_PROPOSAL',
  ProposalWaiting = 'PROPOSAL_WAITING',
  RunningOptimization = 'RUNNING_OPTIMIZATION'
}

export enum NexoyaOptimizationType {
  Auto = 'AUTO',
  Manual = 'MANUAL',
  Skip = 'SKIP'
}

export type NexoyaOptimizationV2 = {
  __typename?: 'OptimizationV2';
  appliedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  end: Scalars['Date'];
  onlyVisibleToSupportUsers: Scalars['Boolean'];
  optimizationId: Scalars['Int'];
  performance?: Maybe<NexoyaOptimizationPerformance>;
  start: Scalars['Date'];
  status: NexoyaOptimizationStatus;
  target?: Maybe<Scalars['Float']>;
  tasks: NexoyaOptimizationTasks;
  title: Scalars['String'];
  totalBudget?: Maybe<Scalars['Float']>;
  user: NexoyaUser;
};

export type NexoyaOptimizedChannel = {
  __typename?: 'OptimizedChannel';
  budget: NexoyaOptimizedDailyBudget;
  channelId: Scalars['Int'];
  funnelSteps: Array<Maybe<NexoyaOptimizedFunnelStep>>;
  target?: Maybe<NexoyaOptimizedTarget>;
};

export type NexoyaOptimizedContent = {
  __typename?: 'OptimizedContent';
  budget?: Maybe<NexoyaOptimizedDailyBudget>;
  budgetProposalData?: Maybe<NexoyaBudgetProposalData>;
  content: NexoyaCollection;
  funnelSteps?: Maybe<Array<Maybe<NexoyaOptimizedFunnelStep>>>;
  impactGroup?: Maybe<NexoyaImpactGroup>;
  label?: Maybe<NexoyaPortfolioLabel>;
  status?: Maybe<NexoyaOptimizedContentStatus>;
  target?: Maybe<NexoyaOptimizedTarget>;
};

export type NexoyaOptimizedContentStatus = {
  __typename?: 'OptimizedContentStatus';
  payload?: Maybe<Array<NexoyaOptimizedContentStatusPayload>>;
  reason: NexoyaOptimizedContentStatusReason;
  type: NexoyaOptimizedContentStatusType;
};

export type NexoyaOptimizedContentStatusPayload = {
  __typename?: 'OptimizedContentStatusPayload';
  appliedBudget?: Maybe<Scalars['Float']>;
  budgetMax?: Maybe<Scalars['Float']>;
  budgetMin?: Maybe<Scalars['Float']>;
  budgetRevenueResponseCurve?: Maybe<Array<Array<Scalars['Float']>>>;
  funnelStep?: Maybe<NexoyaFunnelStepV2>;
  impressionShare?: Maybe<Scalars['Float']>;
  plannedBudget?: Maybe<Scalars['Float']>;
  saturationPoint?: Maybe<Array<Scalars['Float']>>;
  saturationProfitPerUnit?: Maybe<Scalars['Float']>;
  saturationScore?: Maybe<Scalars['Float']>;
  saturationTangent?: Maybe<Array<Array<Scalars['Float']>>>;
  spentBudget?: Maybe<Scalars['Float']>;
};

export enum NexoyaOptimizedContentStatusReason {
  AppliedVsSpent = 'APPLIED_VS_SPENT',
  BudgetBoundariesMaxBudget = 'BUDGET_BOUNDARIES_MAX_BUDGET',
  BudgetBoundariesMinBudget = 'BUDGET_BOUNDARIES_MIN_BUDGET',
  DisabledOptimzation = 'DISABLED_OPTIMZATION',
  HasEnded = 'HAS_ENDED',
  ImpressionShare = 'IMPRESSION_SHARE',
  IsEnding = 'IS_ENDING',
  LowDataQuality = 'LOW_DATA_QUALITY',
  Materiality = 'MATERIALITY',
  NoBudget = 'NO_BUDGET',
  NoData = 'NO_DATA',
  PlannedVsSpent = 'PLANNED_VS_SPENT',
  Saturated = 'SATURATED',
  SpendBelowThreshold = 'SPEND_BELOW_THRESHOLD'
}

export enum NexoyaOptimizedContentStatusType {
  Insight = 'INSIGHT',
  Limited = 'LIMITED',
  Skipped = 'SKIPPED'
}

export type NexoyaOptimizedDailyBudget = {
  __typename?: 'OptimizedDailyBudget';
  changePercent: Scalars['Float'];
  proposed: Scalars['Float'];
  spent: Scalars['Float'];
};

export type NexoyaOptimizedFunnelStep = {
  __typename?: 'OptimizedFunnelStep';
  costPer: NexoyaOptimizedMetric;
  lowDataVolume: Scalars['Boolean'];
  metric: NexoyaOptimizedMetric;
};

export type NexoyaOptimizedImpactGroup = {
  __typename?: 'OptimizedImpactGroup';
  budget: NexoyaOptimizedDailyBudget;
  funnelSteps: Array<Maybe<NexoyaOptimizedFunnelStep>>;
  impactGroup?: Maybe<NexoyaImpactGroup>;
  target?: Maybe<NexoyaOptimizedTarget>;
};

export type NexoyaOptimizedLabel = {
  __typename?: 'OptimizedLabel';
  budget: NexoyaOptimizedDailyBudget;
  funnelSteps: Array<Maybe<NexoyaOptimizedFunnelStep>>;
  label?: Maybe<NexoyaPortfolioLabel>;
  target?: Maybe<NexoyaOptimizedTarget>;
};

export type NexoyaOptimizedMetric = {
  __typename?: 'OptimizedMetric';
  baseline?: Maybe<Scalars['Float']>;
  changePercent?: Maybe<Scalars['Float']>;
  predicted?: Maybe<Scalars['Float']>;
};

export type NexoyaOptimizedTarget = {
  __typename?: 'OptimizedTarget';
  changePercent?: Maybe<Scalars['Float']>;
  lowerIsBetter: Scalars['Boolean'];
  previous?: Maybe<Scalars['Float']>;
  proposed?: Maybe<Scalars['Float']>;
};

export type NexoyaOptimizedTotal = {
  __typename?: 'OptimizedTotal';
  budget: NexoyaOptimizedDailyBudget;
  funnelSteps: Array<Maybe<NexoyaOptimizedFunnelStep>>;
  target?: Maybe<NexoyaOptimizedTarget>;
};

export enum NexoyaOptionalFieldName {
  BudgetItemId = 'budgetItemId',
  CollectionId = 'collectionId',
  FunnelStepId = 'funnelStepId',
  ImpactGroupId = 'impactGroupId',
  LabelId = 'labelId',
  OptimizationId = 'optimizationId',
  PortfolioContentId = 'portfolioContentId',
  PortfolioId = 'portfolioId',
  ScenarioId = 'scenarioId',
  SimulationId = 'simulationId',
  TargetItemId = 'targetItemId'
}

export type NexoyaOrg = {
  __typename?: 'Org';
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org_id: Scalars['Int'];
  tenant?: Maybe<NexoyaTenant>;
};

export type NexoyaOrgInfoInput = {
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type NexoyaOtherFunnelStepMetrics = {
  __typename?: 'OtherFunnelStepMetrics';
  activeFunnelStepMetricId?: Maybe<Scalars['Int']>;
  funnelStepTypeName: Scalars['String'];
  otherMetrics?: Maybe<Array<Maybe<NexoyaMetricMeta>>>;
  providerId?: Maybe<Scalars['Int']>;
};

export enum NexoyaPacingType {
  DataDriven = 'DATA_DRIVEN',
  Fixed = 'FIXED',
  RampUp = 'RAMP_UP',
  Smooth = 'SMOOTH'
}

export type NexoyaPageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type NexoyaPerformanceMetric = {
  __typename?: 'PerformanceMetric';
  adSpend: Scalars['Float'];
  costRatio: Scalars['Float'];
  value: Scalars['Float'];
};

export type NexoyaPortfolio = {
  __typename?: 'Portfolio';
  budget: NexoyaBudget;
  budgetDeltaHandlingPolicy?: Maybe<NexoyaBudgetDeltaHandlingPolicy>;
  budgetProposalTargetBiddingApplyMode?: Maybe<NexoyaTargetBiddingApplyMode>;
  content: NexoyaPortfolioContent;
  createdByUserId?: Maybe<Scalars['Int']>;
  defaultOptimizationTarget: NexoyaFunnelStep;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  funnelSteps?: Maybe<Array<NexoyaFunnelStep>>;
  moneyAllocatedTotal: Scalars['Float'];
  optimizationRiskLevel?: Maybe<Scalars['Int']>;
  optimizationType?: Maybe<NexoyaOptimizationType>;
  portfolioId: Scalars['Int'];
  portfolioMonitorBudgetThreshold?: Maybe<Scalars['Int']>;
  portfolioMonitorData: Array<NexoyaPortfolioMonitorDataEntry>;
  portfolioMonitorFunnelStepThreshold?: Maybe<Scalars['Int']>;
  providers?: Maybe<Array<NexoyaProvider>>;
  skipTrainingDays?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  teamId: Scalars['Int'];
  title: Scalars['String'];
  validation?: Maybe<NexoyaValidation>;
};


export type NexoyaPortfolioContentArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};


export type NexoyaPortfolioPortfolioMonitorDataArgs = {
  groupBy: NexoyaPortfolioMonitorDataGroupingDimension;
};


export type NexoyaPortfolioProvidersArgs = {
  team_id: Scalars['Int'];
};


export type NexoyaPortfolioValidationArgs = {
  avg?: Maybe<Scalars['String']>;
  baselineWindow?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};

export type NexoyaPortfolioBudget = {
  __typename?: 'PortfolioBudget';
  budgetItems: Array<NexoyaBudgetItem>;
  budgetReallocation: NexoyaBudgetReallocation;
  spent: NexoyaPortfolioBudgetSpending;
  total: Scalars['Float'];
};


export type NexoyaPortfolioBudgetBudgetItemsArgs = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type NexoyaPortfolioBudgetSpending = {
  __typename?: 'PortfolioBudgetSpending';
  dailySpendings: Array<NexoyaDailyMetric>;
  totalSpent: Scalars['Float'];
};


export type NexoyaPortfolioBudgetSpendingDailySpendingsArgs = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type NexoyaPortfolioConnection = {
  __typename?: 'PortfolioConnection';
  edges: Array<NexoyaPortfolioEdges>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaPortfolioContent = {
  __typename?: 'PortfolioContent';
  contentDetails?: Maybe<Array<NexoyaPortfolioContentDetail>>;
};

export type NexoyaPortfolioContentBudget = {
  __typename?: 'PortfolioContentBudget';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type NexoyaPortfolioContentBudgetInput = {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type NexoyaPortfolioContentConnection = {
  __typename?: 'PortfolioContentConnection';
  edges: Array<NexoyaPortfolioContentEdges>;
  pageInfo: NexoyaPageInfo;
};

export type NexoyaPortfolioContentDetail = {
  __typename?: 'PortfolioContentDetail';
  budget: NexoyaPortfolioContentBudget;
  childContent?: Maybe<Array<NexoyaPortfolioContentDetail>>;
  content?: Maybe<NexoyaCollection>;
  contentId: Scalars['Float'];
  costs?: Maybe<Scalars['Float']>;
  impactGroup?: Maybe<NexoyaImpactGroup>;
  isIncludedInOptimization: Scalars['Boolean'];
  label?: Maybe<NexoyaPortfolioLabel>;
  metadata?: Maybe<NexoyaPortfolioContentMetadata>;
  metrics?: Maybe<Array<NexoyaPortfolioContentMetric>>;
  optimizationTarget: NexoyaFunnelStep;
  portfolioContentId: Scalars['Int'];
  proposalsAllocationDelta?: Maybe<NexoyaProposalsAllocationDelta>;
};

export type NexoyaPortfolioContentEdges = {
  __typename?: 'PortfolioContentEdges';
  cursor: Scalars['String'];
  node: NexoyaPortfolioContent;
};

export type NexoyaPortfolioContentFunnelStepMetricInput = {
  contentId: Scalars['Float'];
  funnelStepId: Scalars['Int'];
  metricTypeId?: Maybe<Scalars['Int']>;
};

export type NexoyaPortfolioContentMetadata = {
  __typename?: 'PortfolioContentMetadata';
  biddingStrategy?: Maybe<Scalars['String']>;
};

export type NexoyaPortfolioContentMetric = {
  __typename?: 'PortfolioContentMetric';
  coreMetricValues?: Maybe<NexoyaCoreMetricValues>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  funnelStep: NexoyaFunnelStep;
  isOptimizationTarget?: Maybe<Scalars['Boolean']>;
  metricTypeId?: Maybe<Scalars['Int']>;
  otherFunnelStepMetrics?: Maybe<NexoyaOtherFunnelStepMetrics>;
  team_id: Scalars['Int'];
};

export type NexoyaPortfolioContentMetricTuple = {
  __typename?: 'PortfolioContentMetricTuple';
  contentId: Scalars['Float'];
  funnelStep: NexoyaFunnelStep;
  metricTypeId: Scalars['Float'];
};

export type NexoyaPortfolioContentRelationInput = {
  belongsToContentId: Scalars['Float'];
  contentId: Scalars['Float'];
};

export type NexoyaPortfolioDashboard = {
  __typename?: 'PortfolioDashboard';
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  elements: Array<NexoyaPortfolioDashboardElement>;
  notifications: Array<NexoyaPortfolioDashboardNotification>;
  teamId: Scalars['Int'];
};

export type NexoyaPortfolioDashboardAdspend = {
  __typename?: 'PortfolioDashboardAdspend';
  percentage: Scalars['Float'];
  planned: Scalars['Float'];
  realized: Scalars['Float'];
};

export type NexoyaPortfolioDashboardBudgetProposalNotification = NexoyaPortfolioDashboardNotification & {
  __typename?: 'PortfolioDashboardBudgetProposalNotification';
  date: Scalars['Date'];
  payload: NexoyaPortfolioDashboardBudgetProposalNotificationPayload;
  title: Scalars['String'];
  type: NexoyaPortfolioDashboardNotificationType;
};

export type NexoyaPortfolioDashboardBudgetProposalNotificationPayload = {
  __typename?: 'PortfolioDashboardBudgetProposalNotificationPayload';
  budgetProposalId: Scalars['Int'];
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
};

export type NexoyaPortfolioDashboardElement = {
  __typename?: 'PortfolioDashboardElement';
  achieved?: Maybe<NexoyaPortfolioDashboardElementTrend>;
  adSpend?: Maybe<NexoyaPortfolioDashboardAdspend>;
  costPer?: Maybe<NexoyaPortfolioDashboardElementTrend>;
  endDate?: Maybe<Scalars['Date']>;
  goal: Scalars['String'];
  portfolioId: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
};

export type NexoyaPortfolioDashboardElementTrend = {
  __typename?: 'PortfolioDashboardElementTrend';
  percentage: Scalars['Float'];
  trendIndicator: NexoyaPortfolioDashboardTrendIndicator;
  value: Scalars['Float'];
};

export type NexoyaPortfolioDashboardNotification = {
  date: Scalars['Date'];
  title: Scalars['String'];
  type: NexoyaPortfolioDashboardNotificationType;
};

export enum NexoyaPortfolioDashboardNotificationType {
  BudgetApplication = 'BUDGET_APPLICATION',
  OptimizationPotential = 'OPTIMIZATION_POTENTIAL'
}

export type NexoyaPortfolioDashboardOptimizationNotification = NexoyaPortfolioDashboardNotification & {
  __typename?: 'PortfolioDashboardOptimizationNotification';
  date: Scalars['Date'];
  payload: NexoyaPortfolioDashboardOptimizationNotificationPayload;
  title: Scalars['String'];
  type: NexoyaPortfolioDashboardNotificationType;
};

export type NexoyaPortfolioDashboardOptimizationNotificationPayload = {
  __typename?: 'PortfolioDashboardOptimizationNotificationPayload';
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
};

export enum NexoyaPortfolioDashboardTrendIndicator {
  DowntrendNegative = 'DOWNTREND_NEGATIVE',
  DowntrendPositive = 'DOWNTREND_POSITIVE',
  Stationary = 'STATIONARY',
  UptrendNegative = 'UPTREND_NEGATIVE',
  UptrendPositive = 'UPTREND_POSITIVE'
}

export type NexoyaPortfolioDashboardUrl = {
  __typename?: 'PortfolioDashboardUrl';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type NexoyaPortfolioDashboardUrlInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type NexoyaPortfolioEdges = {
  __typename?: 'PortfolioEdges';
  cursor: Scalars['String'];
  node: NexoyaPortfolio;
};

export enum NexoyaPortfolioFeatureFlag {
  BudgetV1 = 'budget_v1',
  ContentEditV2 = 'content_edit_v2',
  OptimizationsOnlyVisibleToSupportUsers = 'optimizationsOnlyVisibleToSupportUsers',
  WhatIfValidation = 'what_if_validation'
}

export type NexoyaPortfolioFilter = {
  activeOnly?: Maybe<Scalars['Boolean']>;
  completedOnly?: Maybe<Scalars['Boolean']>;
  plannedOnly?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type NexoyaPortfolioFunnelRelationInput = {
  funnelStepId: Scalars['Int'];
  inputFunnelId?: Maybe<Scalars['Int']>;
};

export type NexoyaPortfolioLabel = {
  __typename?: 'PortfolioLabel';
  labelId: Scalars['Int'];
  name: Scalars['String'];
};

export type NexoyaPortfolioMonitorDataEntry = {
  __typename?: 'PortfolioMonitorDataEntry';
  funnelStepId: Scalars['Int'];
  mAE: Scalars['Float'];
  MAE: Scalars['Float'];
  mAPE: Scalars['Float'];
  MAPE: Scalars['Float'];
  optimizationId: Scalars['Int'];
  SmAPE: Scalars['Float'];
  SMAPE: Scalars['Float'];
  timestamp: Scalars['DateTime'];
  y_pred: Scalars['Float'];
  y_true: Scalars['Float'];
};

export enum NexoyaPortfolioMonitorDataGroupingDimension {
  FunnelStep = 'FUNNEL_STEP',
  Portfolio = 'PORTFOLIO'
}

export type NexoyaPortfolioPerformance = {
  __typename?: 'PortfolioPerformance';
  funnelSteps: Array<NexoyaFunnelStepPerformance>;
  optimizations: Array<NexoyaOptimizationV2>;
};

export type NexoyaPortfolioSummary = {
  __typename?: 'PortfolioSummary';
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};

export type NexoyaPortfolioTargetItem = {
  __typename?: 'PortfolioTargetItem';
  achieved?: Maybe<Scalars['Float']>;
  end: Scalars['Date'];
  maxBudget: Scalars['Float'];
  name: Scalars['String'];
  portfolioId: Scalars['Int'];
  start: Scalars['Date'];
  status: NexoyaTargetItemStatus;
  targetItemId: Scalars['Int'];
  value: Scalars['Float'];
};

export enum NexoyaPortfolioType {
  Amount = 'AMOUNT',
  Budget = 'BUDGET',
  CostPer = 'COST_PER',
  Roas = 'ROAS'
}

export type NexoyaPortfolioV2 = {
  __typename?: 'PortfolioV2';
  activeOptimization?: Maybe<NexoyaOptimizationV2>;
  activeTargetItem?: Maybe<NexoyaPortfolioTargetItem>;
  budget: NexoyaPortfolioBudget;
  budgetDeltaHandlingPolicy?: Maybe<NexoyaBudgetDeltaHandlingPolicy>;
  budgetProposalTargetBiddingApplyMode?: Maybe<NexoyaTargetBiddingApplyMode>;
  contentGroups: Array<NexoyaContentGroup>;
  createdByUserId?: Maybe<Scalars['Int']>;
  defaultOptimizationTarget?: Maybe<NexoyaFunnelStepV2>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
  featureFlags?: Maybe<Array<Maybe<NexoyaFeatureFlag>>>;
  funnelSteps: Array<NexoyaFunnelStepV2>;
  impactGroups: Array<NexoyaImpactGroup>;
  labels: Array<NexoyaPortfolioLabel>;
  latestAchievedTargetItem?: Maybe<NexoyaPortfolioTargetItem>;
  optimization: NexoyaOptimizationV2;
  optimizationRiskLevel?: Maybe<Scalars['Int']>;
  optimizations: NexoyaOptimizationConnection;
  optimizationType?: Maybe<NexoyaOptimizationType>;
  performance: NexoyaPortfolioPerformance;
  portfolioDashboardUrls?: Maybe<Array<Maybe<NexoyaPortfolioDashboardUrl>>>;
  portfolioId: Scalars['Int'];
  prediction: NexoyaPrediction;
  simulation: NexoyaSimulation;
  simulations: Array<NexoyaSimulation>;
  skipTrainingDays?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Date']>;
  targetItems: Array<NexoyaPortfolioTargetItem>;
  teamId: Scalars['Int'];
  title: Scalars['String'];
  type: NexoyaPortfolioType;
};


export type NexoyaPortfolioV2OptimizationArgs = {
  optimizationId: Scalars['Int'];
};


export type NexoyaPortfolioV2OptimizationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  status?: Maybe<NexoyaOptimizationStatus>;
};


export type NexoyaPortfolioV2PerformanceArgs = {
  comparisonPeriod?: Maybe<NexoyaDateRangeInput>;
  period: NexoyaDateRangeInput;
};


export type NexoyaPortfolioV2PredictionArgs = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};


export type NexoyaPortfolioV2SimulationArgs = {
  simulationId: Scalars['Int'];
};

export type NexoyaPredict = {
  __typename?: 'Predict';
  collectionId?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<Maybe<NexoyaPredictDetail>>>;
  measurementId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  valueSumUp?: Maybe<Array<Maybe<NexoyaPredictDetail>>>;
};

export type NexoyaPredictDetail = {
  __typename?: 'PredictDetail';
  timestamp?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  valueLower?: Maybe<Scalars['Float']>;
  valueUpper?: Maybe<Scalars['Float']>;
};

export type NexoyaPrediction = {
  __typename?: 'Prediction';
  funnelSteps: Array<NexoyaFunnelStepPredictionScore>;
  total: NexoyaPredictionTotal;
};

export type NexoyaPredictionTotal = {
  __typename?: 'PredictionTotal';
  accuracyBuckets: Array<NexoyaAccuracyBucket>;
  dailyPredictionTotal: Array<NexoyaDailyPredictionTotal>;
  score: Scalars['Float'];
};

export type NexoyaProposalsAllocationDelta = {
  __typename?: 'ProposalsAllocationDelta';
  actualAllocation?: Maybe<Scalars['Float']>;
  allocationPctDelta?: Maybe<Scalars['Float']>;
  proposedAllocation?: Maybe<Scalars['Float']>;
};

export type NexoyaProvider = {
  __typename?: 'Provider';
  category?: Maybe<Scalars['String']>;
  connected?: Maybe<Scalars['Boolean']>;
  delayed_days?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  filterable?: Maybe<Scalars['Boolean']>;
  hasCollections?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  no_initial?: Maybe<Scalars['Boolean']>;
  provider_id: Scalars['Int'];
  showInToplist?: Maybe<Scalars['Boolean']>;
};

export type NexoyaProviderContentMetricTotal = {
  __typename?: 'ProviderContentMetricTotal';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonTotal?: Maybe<NexoyaPerformanceMetric>;
  contentId: Scalars['Float'];
  title: Scalars['String'];
  total: NexoyaPerformanceMetric;
};

export type NexoyaProviderFilter = {
  __typename?: 'ProviderFilter';
  filterList?: Maybe<Array<NexoyaFilterListType>>;
  filterName: Scalars['String'];
};

export type NexoyaProviderKey = {
  __typename?: 'ProviderKey';
  active?: Maybe<Scalars['Boolean']>;
  api_key?: Maybe<Scalars['String']>;
  api_secret?: Maybe<Scalars['String']>;
  appKey?: Maybe<Scalars['String']>;
  appSecret?: Maybe<Scalars['String']>;
  enc_options?: Maybe<Scalars['String']>;
  integOpts?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  page_token?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider?: Maybe<NexoyaProvider>;
  provider_key_id?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  team?: Maybe<NexoyaTeam>;
  team_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
};

export type NexoyaProviderKeyInput = {
  api_key?: Maybe<Scalars['String']>;
  api_secret?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  provider_id: Scalars['Int'];
  provider_key_id?: Maybe<Scalars['Int']>;
  team_id: Scalars['Int'];
  token?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type NexoyaProviderMetricTotal = {
  __typename?: 'ProviderMetricTotal';
  comparisonChangePercent?: Maybe<NexoyaPerformanceMetric>;
  comparisonTotal?: Maybe<NexoyaPerformanceMetric>;
  contents: Array<NexoyaProviderContentMetricTotal>;
  providerId: Scalars['Int'];
  total: NexoyaPerformanceMetric;
};

export type NexoyaQuery = {
  __typename?: 'Query';
  _entities: Array<Maybe<Nexoya_Entity>>;
  _service: Nexoya_Service;
  availableFieldOperations: Array<NexoyaFieldOperation>;
  budgetProposals: NexoyaBudgetProposalConnection;
  childCollectionsPg: NexoyaCollectionConnection;
  collection?: Maybe<NexoyaCollection>;
  collections?: Maybe<Array<Maybe<NexoyaCollection>>>;
  collectionsPg: NexoyaCollectionConnection;
  collectionTypes?: Maybe<Array<Maybe<NexoyaCollectionType>>>;
  collectionWithProvider?: Maybe<Array<Maybe<NexoyaCollection>>>;
  content?: Maybe<NexoyaContent>;
  contents?: Maybe<Array<Maybe<NexoyaContent>>>;
  contentsPg: NexoyaContentConnection;
  contentTypes?: Maybe<Array<Maybe<NexoyaContentType>>>;
  contentWithDataPg: NexoyaCollectionConnection;
  createBudgetItemPrecheck?: Maybe<NexoyaCreateBudgetItemPrecheckResult>;
  customKpi?: Maybe<Array<Maybe<NexoyaCustomKpi>>>;
  event?: Maybe<NexoyaEvent>;
  events?: Maybe<Array<Maybe<NexoyaEvent>>>;
  filterContents: Array<NexoyaContentV2>;
  flagStatus?: Maybe<Scalars['Boolean']>;
  getDatatype?: Maybe<NexoyaDataType>;
  integration?: Maybe<NexoyaIntegration>;
  integrations?: Maybe<Array<Maybe<NexoyaIntegration>>>;
  joblogEntries?: Maybe<Array<Maybe<NexoyaAppendLog>>>;
  joblogs?: Maybe<Array<Maybe<NexoyaFetchJob>>>;
  KPIValues?: Maybe<NexoyaKpi>;
  listCurrencyData?: Maybe<NexoyaCurrencyData>;
  measurement_range?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  measurementAggregates?: Maybe<NexoyaMeasurementAggregate>;
  measurementdata_range_by_measurement_collection?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  measurementGroups?: Maybe<Array<Maybe<NexoyaMeasurementGroup>>>;
  measurementRangeSearch?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  measurementRangeSearchPg: NexoyaMeasurementConnection;
  measurements?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  measurementsPg: NexoyaMeasurementConnection;
  metadataTypes?: Maybe<Scalars['JSON']>;
  metrics?: Maybe<Array<Maybe<NexoyaMetricDataCore>>>;
  newOptimizationSummary: NexoyaNewOptimizationSummary;
  newSimulationSummary: NexoyaNewSimulationSummary;
  optimization?: Maybe<NexoyaOptimizationV2>;
  optimizationBudgetProposal?: Maybe<NexoyaBudgetProposal>;
  optimizationConfig?: Maybe<Scalars['JSON']>;
  org?: Maybe<NexoyaOrg>;
  orgs?: Maybe<Array<Maybe<NexoyaOrg>>>;
  portfolio?: Maybe<NexoyaPortfolio>;
  portfolioBudgetProposals?: Maybe<Array<Maybe<NexoyaBudgetProposal>>>;
  portfolioContent: NexoyaPortfolioContentConnection;
  portfolioContentMetricTuples?: Maybe<Array<NexoyaPortfolioContentMetricTuple>>;
  portfolioDashboard?: Maybe<NexoyaPortfolioDashboard>;
  portfolios: NexoyaPortfolioConnection;
  portfolioV2?: Maybe<NexoyaPortfolioV2>;
  predefinedContent?: Maybe<Array<Maybe<NexoyaPortfolioContent>>>;
  predict?: Maybe<NexoyaPredict>;
  provider?: Maybe<NexoyaProvider>;
  providerKeys?: Maybe<Array<Maybe<NexoyaProviderKey>>>;
  providers?: Maybe<Array<Maybe<NexoyaProvider>>>;
  report?: Maybe<NexoyaReport>;
  reports?: Maybe<Array<Maybe<NexoyaReport>>>;
  reportsSummary?: Maybe<Array<Maybe<NexoyaReportWithSummary>>>;
  reportSummary?: Maybe<NexoyaReportWithSummary>;
  roles?: Maybe<Array<NexoyaRoleDef>>;
  team?: Maybe<NexoyaTeam>;
  teams?: Maybe<Array<Maybe<NexoyaTeam>>>;
  translation?: Maybe<NexoyaTranslation>;
  translations?: Maybe<Array<Maybe<NexoyaTranslation>>>;
  user?: Maybe<NexoyaUser>;
  userByEmail?: Maybe<NexoyaUser>;
  users?: Maybe<Array<Maybe<NexoyaUser>>>;
  validationDetailedReport?: Maybe<Scalars['String']>;
};


export type NexoyaQuery_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type NexoyaQueryAvailableFieldOperationsArgs = {
  providerId: Scalars['Float'];
};


export type NexoyaQueryBudgetProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<NexoyaBudgetProposalStatus>;
};


export type NexoyaQueryChildCollectionsPgArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  parent_collection_id: Scalars['Float'];
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaSearchFilter>;
};


export type NexoyaQueryCollectionArgs = {
  collection_id: Scalars['Float'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryCollectionsArgs = {
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryCollectionsPgArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryCollectionWithProviderArgs = {
  provider_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryContentArgs = {
  content_id: Scalars['Float'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryContentsArgs = {
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryContentsPgArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryContentWithDataPgArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
  withDataFor?: Maybe<NexoyaFunnelStepType>;
};


export type NexoyaQueryCreateBudgetItemPrecheckArgs = {
  endDate: Scalars['Date'];
  portfolioId: Scalars['Int'];
  startDate: Scalars['Date'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryCustomKpiArgs = {
  custom_kpi_id?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
};


export type NexoyaQueryEventArgs = {
  event_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryEventsArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaEventFilter>;
};


export type NexoyaQueryFilterContentsArgs = {
  excludePortfolioContents: Scalars['Boolean'];
  filters: Array<NexoyaContentFilterInput>;
  portfolioId: Scalars['Float'];
  providerId: Scalars['Float'];
  teamId: Scalars['Float'];
};


export type NexoyaQueryFlagStatusArgs = {
  name: Scalars['String'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryGetDatatypeArgs = {
  id: Scalars['Int'];
};


export type NexoyaQueryIntegrationArgs = {
  integration_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryIntegrationsArgs = {
  filter?: Maybe<NexoyaIntegrationFilter>;
  team_id: Scalars['Int'];
};


export type NexoyaQueryJoblogEntriesArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  fetch_job_id: Scalars['Int'];
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type NexoyaQueryJoblogsArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  fetch_job_id?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  provider_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};


export type NexoyaQueryKpiValuesArgs = {
  id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryMeasurement_RangeArgs = {
  collection_id?: Maybe<Scalars['Float']>;
  collections?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  measurement_id?: Maybe<Scalars['Int']>;
  measurements?: Maybe<Array<Maybe<Scalars['Int']>>>;
  offset?: Maybe<Scalars['Float']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryMeasurementAggregatesArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  measurement_collection?: Maybe<Array<Maybe<NexoyaMeasurementCollectionPairInput>>>;
  team_id: Scalars['Int'];
};


export type NexoyaQueryMeasurementdata_Range_By_Measurement_CollectionArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  measurement_collection?: Maybe<Array<Maybe<NexoyaMeasurementCollectionPairInput>>>;
  team_id: Scalars['Int'];
};


export type NexoyaQueryMeasurementGroupsArgs = {
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryMeasurementRangeSearchArgs = {
  collection_id?: Maybe<Scalars['Float']>;
  collections?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  measurement_id?: Maybe<Scalars['Int']>;
  measurements?: Maybe<Array<Maybe<Scalars['Int']>>>;
  offset?: Maybe<Scalars['Float']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryMeasurementRangeSearchPgArgs = {
  after?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['Float']>;
  collections?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  measurement_id?: Maybe<Scalars['Int']>;
  measurements?: Maybe<Array<Maybe<Scalars['Int']>>>;
  offset?: Maybe<Scalars['Float']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryMeasurementsArgs = {
  provider_id?: Maybe<Scalars['Int']>;
};


export type NexoyaQueryMeasurementsPgArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaQueryMetricsArgs = {
  provider_id?: Maybe<Scalars['Int']>;
};


export type NexoyaQueryNewOptimizationSummaryArgs = {
  end: Scalars['Date'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryNewSimulationSummaryArgs = {
  budgetMax: Scalars['Int'];
  budgetMin: Scalars['Int'];
  end: Scalars['Date'];
  portfolioId: Scalars['Int'];
  simulationId?: Maybe<Scalars['Float']>;
  start: Scalars['Date'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryOptimizationArgs = {
  optimizationId: Scalars['Int'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryOptimizationBudgetProposalArgs = {
  optimizationId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryOptimizationConfigArgs = {
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryOrgArgs = {
  org_id: Scalars['ID'];
};


export type NexoyaQueryPortfolioArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryPortfolioBudgetProposalsArgs = {
  portfolioId: Scalars['Int'];
  status?: Maybe<NexoyaBudgetProposalStatus>;
  teamId: Scalars['Int'];
};


export type NexoyaQueryPortfolioContentArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<NexoyaSortBy>;
  teamId: Scalars['Int'];
  where?: Maybe<NexoyaSearchFilter>;
};


export type NexoyaQueryPortfolioContentMetricTuplesArgs = {
  funnelStepId?: Maybe<Scalars['Int']>;
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryPortfolioDashboardArgs = {
  completed?: Maybe<Scalars['Boolean']>;
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  teamId: Scalars['Int'];
};


export type NexoyaQueryPortfoliosArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<NexoyaSortBy>;
  teamId?: Maybe<Scalars['Int']>;
  where?: Maybe<NexoyaSearchFilter>;
};


export type NexoyaQueryPortfolioV2Args = {
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type NexoyaQueryPredefinedContentArgs = {
  mainGoals?: Maybe<Array<NexoyaFunnelStepType>>;
  teamId: Scalars['Int'];
};


export type NexoyaQueryPredictArgs = {
  collectionId: Scalars['Float'];
  measurementId: Scalars['Int'];
  periods?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
};


export type NexoyaQueryProviderArgs = {
  provider_id: Scalars['Int'];
};


export type NexoyaQueryProviderKeysArgs = {
  provider_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};


export type NexoyaQueryProvidersArgs = {
  team_id?: Maybe<Scalars['Int']>;
};


export type NexoyaQueryReportArgs = {
  report_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryReportsArgs = {
  report_type?: Maybe<Scalars['String']>;
  sortBy?: Maybe<NexoyaSortBy>;
  team_id: Scalars['Int'];
  where?: Maybe<NexoyaSearchFilter>;
};


export type NexoyaQueryReportsSummaryArgs = {
  report_type?: Maybe<Scalars['String']>;
  sortBy?: Maybe<NexoyaSortBy>;
  team_id: Scalars['Int'];
};


export type NexoyaQueryReportSummaryArgs = {
  report_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type NexoyaQueryTeamArgs = {
  team_id: Scalars['Int'];
};


export type NexoyaQueryTeamsArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
};


export type NexoyaQueryTranslationArgs = {
  key: Scalars['String'];
  lang: Scalars['String'];
};


export type NexoyaQueryTranslationsArgs = {
  lang: Scalars['String'];
};


export type NexoyaQueryUserArgs = {
  user_id?: Maybe<Scalars['Int']>;
};


export type NexoyaQueryUserByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type NexoyaQueryValidationDetailedReportArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  portfolioId: Scalars['Int'];
  teamId: Scalars['Int'];
};

export type NexoyaRealizedMetricData = NexoyaMetricData & {
  __typename?: 'RealizedMetricData';
  timestamp: Scalars['DateTime'];
  valueCumulative: Scalars['Float'];
  valueRelative: Scalars['Float'];
};

export type NexoyaRealizedMetricDataPast = {
  __typename?: 'RealizedMetricDataPast';
  timestamp: Scalars['DateTime'];
  valueCumulative: Scalars['Float'];
  valueRelative: Scalars['Float'];
};

export type NexoyaReport = {
  __typename?: 'Report';
  archived?: Maybe<Scalars['Boolean']>;
  config?: Maybe<Scalars['String']>;
  contents?: Maybe<Array<Maybe<NexoyaChannelReportContent>>>;
  dateRange: NexoyaReportDateRange;
  description?: Maybe<Scalars['String']>;
  kpis: Array<NexoyaMeasurement>;
  name: Scalars['String'];
  report_id: Scalars['Int'];
  report_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  updatedBy?: Maybe<NexoyaUser>;
};

export type NexoyaReportDateRange = {
  __typename?: 'ReportDateRange';
  customRange?: Maybe<NexoyaDateRange>;
  rangeType: Scalars['String'];
};

export type NexoyaReportDateRangeInput = {
  customRange?: Maybe<NexoyaDateRangeRequiredInput>;
  rangeType: Scalars['String'];
};

export type NexoyaReportWithSummary = {
  __typename?: 'ReportWithSummary';
  data?: Maybe<NexoyaSumReport>;
  report_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

export type NexoyaRoleDef = {
  __typename?: 'RoleDef';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type NexoyaScenarioBudget = {
  __typename?: 'ScenarioBudget';
  dailyMetrics: Array<NexoyaScenarioDailyBudget>;
  totals: NexoyaScenarioTotalBudget;
};

export type NexoyaScenarioDailyBudget = {
  __typename?: 'ScenarioDailyBudget';
  baseScenario?: Maybe<Scalars['Float']>;
  changePercent?: Maybe<Scalars['Float']>;
  currentScenario: Scalars['Float'];
  day: Scalars['Date'];
};

export type NexoyaScenarioDailyMetric = {
  __typename?: 'ScenarioDailyMetric';
  costPer?: Maybe<NexoyaScenarioMetric>;
  day: Scalars['Date'];
  roas?: Maybe<NexoyaScenarioMetric>;
  value: NexoyaScenarioMetric;
};

export type NexoyaScenarioFunnelStep = {
  __typename?: 'ScenarioFunnelStep';
  costPer?: Maybe<NexoyaScenarioTotalMetric>;
  dailyMetrics: Array<NexoyaScenarioDailyMetric>;
  funnelStep: NexoyaFunnelStepV2;
  isTarget: Scalars['Boolean'];
  roas?: Maybe<NexoyaScenarioTotalMetric>;
  total?: Maybe<NexoyaScenarioTotalMetric>;
};

export type NexoyaScenarioMetric = {
  __typename?: 'ScenarioMetric';
  baseScenario?: Maybe<Scalars['Float']>;
  changePercent?: Maybe<Scalars['Float']>;
  currentScenario: Scalars['Float'];
  lowerIsBetter: Scalars['Boolean'];
};

export enum NexoyaScenarioReliabilityLabel {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type NexoyaScenarioTotalBudget = {
  __typename?: 'ScenarioTotalBudget';
  baseScenarioNotOptimizedTotal?: Maybe<Scalars['Float']>;
  baseScenarioTotal?: Maybe<Scalars['Float']>;
  changePercentTotal?: Maybe<Scalars['Float']>;
  currentScenarioNotOptimizedTotal: Scalars['Float'];
  currentScenarioTotal: Scalars['Float'];
};

export type NexoyaScenarioTotalMetric = {
  __typename?: 'ScenarioTotalMetric';
  baseScenario?: Maybe<Scalars['Float']>;
  changePercent?: Maybe<Scalars['Float']>;
  currentScenario: Scalars['Float'];
  currentScenarioPredictionRange: NexoyaScenarioTotalMetricPedictionRange;
  lowerIsBetter: Scalars['Boolean'];
};

export type NexoyaScenarioTotalMetricPedictionRange = {
  __typename?: 'ScenarioTotalMetricPedictionRange';
  high: Scalars['Float'];
  highChangePercent: Scalars['Float'];
  low: Scalars['Float'];
  lowChangePercent: Scalars['Float'];
};

export type NexoyaSearchFilter = {
  search?: Maybe<Scalars['String']>;
};

export enum NexoyaShareType {
  Fullaccess = 'fullaccess',
  Readonly = 'readonly'
}

export enum NexoyaSharingObjectName {
  Portfolio = 'portfolio',
  Report = 'report'
}

export type NexoyaSimulation = {
  __typename?: 'Simulation';
  budget: NexoyaSimulationBudget;
  createdAt: Scalars['DateTime'];
  end: Scalars['Date'];
  name: Scalars['String'];
  scenario: NexoyaSimulationScenario;
  scenarios: Array<NexoyaSimulationScenario>;
  simulationId: Scalars['Float'];
  start: Scalars['Date'];
  state: NexoyaSimulationState;
};


export type NexoyaSimulationScenarioArgs = {
  scenarioId: Scalars['Int'];
};

export type NexoyaSimulationBudget = {
  __typename?: 'SimulationBudget';
  max: Scalars['Int'];
  min: Scalars['Int'];
  stepCount: Scalars['Int'];
  steps: Array<NexoyaSimulationBudgetStep>;
  stepSize: Scalars['Int'];
};

export type NexoyaSimulationBudgetPreview = {
  __typename?: 'SimulationBudgetPreview';
  budgets: Array<NexoyaSimulationBudgetStep>;
  isDefaultScenario: Scalars['Boolean'];
  stepCount: Scalars['Int'];
  stepSize: Scalars['Int'];
};

export type NexoyaSimulationBudgetStep = {
  __typename?: 'SimulationBudgetStep';
  budget: Scalars['Float'];
  isBaseScenario: Scalars['Boolean'];
  isCustomScenario: Scalars['Boolean'];
};

export type NexoyaSimulationBudgetStepInput = {
  budget: Scalars['Float'];
  isBaseScenario: Scalars['Boolean'];
  isCustomScenario: Scalars['Boolean'];
};

export type NexoyaSimulationScenario = {
  __typename?: 'SimulationScenario';
  budget: NexoyaScenarioBudget;
  funnelSteps: Array<NexoyaScenarioFunnelStep>;
  isApplied: Scalars['Boolean'];
  isBaseScenario: Scalars['Boolean'];
  isCustomScenario: Scalars['Boolean'];
  reliabilityLabel: NexoyaScenarioReliabilityLabel;
  scenarioId: Scalars['Float'];
  targetFunnelStep: NexoyaScenarioFunnelStep;
};

export enum NexoyaSimulationState {
  Applied = 'APPLIED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Running = 'RUNNING'
}

export type NexoyaSortBy = {
  field: NexoyaSortField;
  order: NexoyaSortOrder;
};

export enum NexoyaSortField {
  Name = 'name',
  Title = 'title'
}

export enum NexoyaSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum NexoyaSumGroupOption {
  Measurement = 'measurement'
}

export type NexoyaSumReport = {
  __typename?: 'SumReport';
  archived?: Maybe<Scalars['Boolean']>;
  config?: Maybe<Scalars['String']>;
  contents?: Maybe<Array<Maybe<NexoyaChannelReportContent>>>;
  dateRange: NexoyaReportDateRange;
  description?: Maybe<Scalars['String']>;
  kpis: Array<NexoyaMeasurement>;
  name: Scalars['String'];
  report_id: Scalars['Int'];
  report_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  updatedBy?: Maybe<NexoyaUser>;
};


export type NexoyaSumReportContentsArgs = {
  groupBy?: Maybe<NexoyaSumGroupOption>;
};

export enum NexoyaTargetBiddingApplyMode {
  BiddingStrategyOnly = 'BIDDING_STRATEGY_ONLY',
  BudgetAndBiddingStrategy = 'BUDGET_AND_BIDDING_STRATEGY',
  BudgetOnly = 'BUDGET_ONLY'
}

export enum NexoyaTargetItemStatus {
  Active = 'ACTIVE',
  ActiveNoOptimization = 'ACTIVE_NO_OPTIMIZATION',
  Past = 'PAST',
  Planned = 'PLANNED'
}

export type NexoyaTeam = {
  __typename?: 'Team';
  active?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  customization?: Maybe<Scalars['String']>;
  dashboardUrls?: Maybe<Array<Maybe<NexoyaDashboardUrl>>>;
  featureFlags?: Maybe<Array<Maybe<NexoyaFeatureFlag>>>;
  /**
   * currently active KPIs
   * @deprecated Use `measurement_range`.
   */
  kpi_range?: Maybe<Array<Maybe<NexoyaKpi>>>;
  /** @deprecated Use `measurement_selected`. */
  kpi_selected?: Maybe<Array<Maybe<NexoyaKpi>>>;
  logo?: Maybe<Scalars['String']>;
  /** @deprecated Use `measurement_range` root query. */
  measurement_range?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  measurement_selected?: Maybe<Array<Maybe<NexoyaMeasurement>>>;
  members: Array<Maybe<NexoyaTeamMember>>;
  name?: Maybe<Scalars['String']>;
  number_format?: Maybe<Scalars['String']>;
  onboarding?: Maybe<Scalars['JSON']>;
  organization?: Maybe<NexoyaOrg>;
  team_id: Scalars['Int'];
};


export type NexoyaTeamKpi_RangeArgs = {
  collections?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  team_id: Scalars['Int'];
};


export type NexoyaTeamMeasurement_RangeArgs = {
  collection_id?: Maybe<Scalars['Float']>;
  collections?: Maybe<Array<Maybe<Scalars['Float']>>>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Float']>;
  measurement_id?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  providers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  where?: Maybe<NexoyaFilter>;
};


export type NexoyaTeamMeasurement_SelectedArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};

export type NexoyaTeamInfoInput = {
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type NexoyaTeamInput = {
  active?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

export type NexoyaTeamMember = {
  __typename?: 'TeamMember';
  active?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastname?: Maybe<Scalars['String']>;
  role: NexoyaRoleDef;
  user_id: Scalars['Int'];
};

export type NexoyaTeamWithOrgInput = {
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type NexoyaTenant = {
  __typename?: 'Tenant';
  name?: Maybe<Scalars['String']>;
  tenant_id: Scalars['Int'];
};

export type NexoyaTranslation = {
  __typename?: 'Translation';
  key: Scalars['String'];
  lang: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type NexoyaUpdateContentRelationPayload = {
  __typename?: 'updateContentRelationPayload';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  warning?: Maybe<Scalars['String']>;
};

export type NexoyaUpdateFunnelRelationPayload = {
  __typename?: 'updateFunnelRelationPayload';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  warning?: Maybe<Scalars['String']>;
};

export type NexoyaUser = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  activeRole?: Maybe<NexoyaRoleDef>;
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastname?: Maybe<Scalars['String']>;
  slackUserId?: Maybe<Scalars['String']>;
  state?: Maybe<NexoyaUserStateEnum>;
  teams?: Maybe<Array<Maybe<NexoyaTeam>>>;
  user_id: Scalars['Int'];
};


export type NexoyaUserActiveRoleArgs = {
  team_id: Scalars['Int'];
};

export type NexoyaUserInfo = {
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
};

export enum NexoyaUserStateEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Onboarding = 'ONBOARDING'
}

export type NexoyaValidation = {
  __typename?: 'Validation';
  tooltip?: Maybe<NexoyaValidationTooltip>;
  validationPerformance?: Maybe<Array<Maybe<NexoyaValidationPerformance>>>;
  validationReport?: Maybe<Array<Maybe<NexoyaValidationReportRows>>>;
};


export type NexoyaValidationTooltipArgs = {
  funnelStepId: Scalars['Int'];
};

export type NexoyaValidationData = {
  __typename?: 'ValidationData';
  achieved: Scalars['Float'];
  nonOptimized: Scalars['Float'];
  optimized: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type NexoyaValidationDataTotal = {
  __typename?: 'ValidationDataTotal';
  costPerAchieved: Scalars['Float'];
  costPerNonOptimized: Scalars['Float'];
  costPerOptimized: Scalars['Float'];
  gainLossCostPer: Scalars['Float'];
  gainLossTotal: Scalars['Float'];
  totalAchieved: Scalars['Float'];
  totalNonOptimized: Scalars['Float'];
  totalOptimized: Scalars['Float'];
};

export type NexoyaValidationPerfomanceTooltip = {
  __typename?: 'ValidationPerfomanceTooltip';
  achieved: Scalars['Float'];
  nonOptimized: Scalars['Float'];
  optimized: Scalars['Float'];
};

export type NexoyaValidationPerformance = {
  __typename?: 'ValidationPerformance';
  funnelStep: NexoyaFunnelStep;
  validationData?: Maybe<Array<NexoyaValidationData>>;
  validationDataTotal?: Maybe<NexoyaValidationDataTotal>;
  validationTooltip?: Maybe<NexoyaValidationPerfomanceTooltip>;
};

export type NexoyaValidationReportBudgetChanges = {
  __typename?: 'validationReportBudgetChanges';
  applicationDelta?: Maybe<Scalars['Float']>;
  appliedPctChange?: Maybe<Scalars['Float']>;
  suggestedPctChange?: Maybe<Scalars['Float']>;
};

export type NexoyaValidationReportRows = {
  __typename?: 'ValidationReportRows';
  budgetChanges?: Maybe<NexoyaValidationReportBudgetChanges>;
  channelTitle?: Maybe<Scalars['String']>;
  contentTitle?: Maybe<Scalars['String']>;
  parentContentTitle?: Maybe<Scalars['String']>;
  spendings?: Maybe<NexoyaValidationReportSpendings>;
  valuesPerFunnel?: Maybe<Array<Maybe<NexoyaValidationReportValuesPerFunnel>>>;
};

export type NexoyaValidationReportSpendings = {
  __typename?: 'validationReportSpendings';
  baseline?: Maybe<Scalars['Float']>;
  proposed?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
};

export type NexoyaValidationReportValuesPerFunnel = {
  __typename?: 'validationReportValuesPerFunnel';
  achieved?: Maybe<Scalars['Float']>;
  costPer?: Maybe<Scalars['Float']>;
  funnelStepId?: Maybe<Scalars['Int']>;
  funnelStepTitle?: Maybe<Scalars['String']>;
  nonOptimized?: Maybe<Scalars['Float']>;
  optimized?: Maybe<Scalars['Float']>;
};

export type NexoyaValidationTooltip = {
  __typename?: 'ValidationTooltip';
  funnelStep: NexoyaFunnelStep;
  validationData?: Maybe<Array<NexoyaValidationData>>;
  validationDataTotal?: Maybe<NexoyaValidationDataTotal>;
};

export type NexoyaWeeklyBudget = {
  __typename?: 'WeeklyBudget';
  allocatedValue: Scalars['Float'];
  endDate: Scalars['DateTime'];
  providerId?: Maybe<Scalars['Int']>;
  realizedValue: Scalars['Float'];
  startDate: Scalars['DateTime'];
};

export type NexoyaRemoveUserMutationVariables = Exact<{
  team_id: Scalars['Int'];
  user_to_remove_id: Scalars['Int'];
}>;


export type NexoyaRemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<NexoyaMutation, 'removeUser'>
);


export const RemoveUserDocument = gql`
    mutation RemoveUser($team_id: Int!, $user_to_remove_id: Int!) {
  removeUser(team_id: $team_id, user_to_remove_id: $user_to_remove_id)
}
    `;
export type NexoyaRemoveUserMutationFn = Apollo.MutationFunction<NexoyaRemoveUserMutation, NexoyaRemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      team_id: // value for 'team_id'
 *      user_to_remove_id: // value for 'user_to_remove_id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<NexoyaRemoveUserMutation, NexoyaRemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NexoyaRemoveUserMutation, NexoyaRemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<NexoyaRemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<NexoyaRemoveUserMutation, NexoyaRemoveUserMutationVariables>;