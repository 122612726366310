import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { TRANSLATION_KEY_VALUE_FRAGMENT } from './fragment';

const TRANSLATIONS_QUERY = gql`
  query Translations($lang: String!) {
    translations(lang: $lang) {
      ...translationKeyValue
    }
  }
  ${TRANSLATION_KEY_VALUE_FRAGMENT}
`;
type Options = {
  lang?: string;
};

function useTranslationsQuery({ lang = 'en_us' }: Options = {}) {
  const query = useQuery(TRANSLATIONS_QUERY, {
    variables: {
      lang,
    },
  });
  return query;
}

export { TRANSLATIONS_QUERY, useTranslationsQuery };
