// import { type RouterHistory } from 'react-router-dom';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import queryString from "query-string";

import { SESSION_ACCESS_TOKEN, SESSION_EXPIRES_AT, SESSION_USER_EMAIL } from "../constants/localStorage";

import { ENV_VARS_WRAPPER } from "../configs/envVariables";

export const getSessionExpirationTimestamp = (): number => {
  return parseInt(localStorage.getItem(SESSION_EXPIRES_AT), 10);
};
export const beforeFirstAuthentication = (timestamp: number): boolean => {
  return isNaN(timestamp);
};
export const getLogoutUrl = () => {
  return `${window[ENV_VARS_WRAPPER].REACT_APP_AUTH0_LOG_OUT || ''}${
    window[ENV_VARS_WRAPPER].REACT_APP_AUTH0_CLIENT_ID || ''
  }`;
};
export const getExtraAuthOptions = (history: any): Record<string, any> => {
  return {
    state: compressToEncodedURIComponent(JSON.stringify(history.location)),
  };
};
export const getOAuthState = (history: any): string | null => {
  const qs = queryString.parse(history.location.search);

  if (qs.state) {
    return decompressFromEncodedURIComponent(<string>qs.state);
  }

  return null;
};
export const decompressStateValue = (stateValueStr: any): Record<string, any> => {
  const stateValue = JSON.parse(decompressFromEncodedURIComponent(stateValueStr));
  return stateValue;
};
export const compressStateValue = (stateValue: Record<string, any>): string => {
  const stateValueStr = compressToEncodedURIComponent(JSON.stringify(stateValue));
  return stateValueStr;
};
export const removeSessionStorage = (): void => {
  localStorage.removeItem(SESSION_ACCESS_TOKEN);
  localStorage.removeItem(SESSION_EXPIRES_AT);
  localStorage.removeItem(SESSION_USER_EMAIL);
};
