import React from 'react';
import { RouterHistory, withRouter } from 'react-router-dom';

import { get } from 'lodash';
import styled from 'styled-components';

import { NexoyaPortfolio, NexoyaPortfolioType } from '../../types';

import { usePortfolio } from '../../context/PortfolioProvider';
import { usePortfoliosFilter } from '../../context/PortfoliosFilterProvider';
import { useCreatePortfolioMutation } from '../../graphql/portfolio/mutationCreatePortfolio';
import { useUserQuery } from '../../graphql/user/queryUser';

import usePresenterMode from '../../hooks/usePresenterMode';
import { buildPortfolioPathWithDates } from '../../utils/portfolio';

import Button from '../../components/Button';
import ButtonAsync from '../../components/ButtonAsync';
import { useDialogState } from '../../components/Dialog';
import DialogTitle from '../../components/DialogTitle';
import ErrorMessage from '../../components/ErrorMessage';
import SidePanel, { SidePanelActions, SidePanelContent, useSidePanelState } from '../../components/SidePanel';
import { useStepper } from '../../components/Stepper';
import Text from '../../components/Text';
import VerticalStepper from '../../components/VerticalStepper';
import PortfolioBudget from './components/PortfolioBudget';
import PortfolioMeta from './components/PortfolioMeta';
import PortfolioSuccessDialog from './components/PortfolioSuccessDialog';
import { PortfolioTarget } from './components/PortfolioTarget';

import { portfolioCreationSteps } from '../../configs/portfolio';

type Props = {
  history: RouterHistory;
};
export const StepperWrapper = styled.div`
  width: 30%;
  padding-right: 48px;
`;
export const StepWrapper = styled.div`
  width: 70%;
  padding-left: 48px;
`;
const NUM_OF_STEPS = portfolioCreationSteps.length;

function CreatePortfolio({ history }: Props) {
  const { isPresenterMode } = usePresenterMode();
  const { isOpen, toggleSidePanel, closeSidePanel } = useSidePanelState();
  const userData = useUserQuery();
  const { search, order }: Record<string, any> = usePortfoliosFilter();
  const userId: number = get(userData, 'data.user.user_id', 0) || 0;
  const {
    isOpen: isSuccessDialogOpen,
    toggleDialog: toggleSuccessDialog,
    closeDialog: closeSuccessDialog,
  } = useDialogState({
    initialState: false,
  });
  const { step, nextStep, previousStep, resetStep } = useStepper({
    initialValue: 1,
    end: NUM_OF_STEPS,
  });
  const { meta, reset, contentSelection }: Record<string, any> = usePortfolio();
  const [createPortfolio, { loading, error }] = useCreatePortfolioMutation({
    title: meta.value.title,
    description: meta.value.description,
    startDate: meta.value.startDate,
    endDate: meta.value.endDate,
    type: meta.value.type,
    defaultOptimizationTarget: meta.value.goal,
    createdByUserId: userId,
    contents: contentSelection.selected.map((item) => item.collection_id),
    optimizationType: meta.value.optimizationType,
    optimizationRiskLevel: meta.value.optimizationRiskLevel,
    budgetDeltaHandlingPolicy: meta.value.budgetDeltaHandlingPolicy,
    order: get(order, 'value.orderByTitle', null),
    search: search.value,
  });
  // used to store info about newly created portfolio
  const newPortfolio = React.useRef<NexoyaPortfolio | undefined>();

  function allowNext() {
    if (step === 1 && (!meta.value.title || !meta.value.goal || !meta.value.type)) return false;
    return !(step === 2 && meta.value.type === NexoyaPortfolioType.Budget && !meta?.value?.budgetDeltaHandlingPolicy);
  }

  async function handleFinish(ev: any) {
    ev.preventDefault();

    try {
      const res = await createPortfolio();
      const portfolio = get(res, 'data.createPortfolio', null);

      if (portfolio) {
        toggleSidePanel();
        toggleSuccessDialog();
        newPortfolio.current = portfolio;
        // reset all data about previously created portfolio
        reset();
        resetStep();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  const renderStepBasedOnType = () => {
    if (step === 1) {
      return <PortfolioMeta />;
    } else if (step === 2) {
      return meta.value.type === NexoyaPortfolioType.Budget ? <PortfolioBudget /> : <PortfolioTarget />;
    }
    return null;
  };

  if (isPresenterMode) return null;
  return (
    <>
      <Button color="primary" variant="contained" onClick={toggleSidePanel} data-cy="createPortfolioBtn">
        Create portfolio
      </Button>
      <SidePanel
        isOpen={isOpen}
        onClose={toggleSidePanel}
        paperProps={{
          style: {
            width: 'calc(100% - 218px)',
            paddingBottom: '78px',
          },
        }}
        data-cy="createPortfolioDialog"
      >
        <DialogTitle
          style={{
            paddingBottom: '48px',
          }}
        >
          <Text component="h3">Create a portfolio</Text>
        </DialogTitle>
        <SidePanelContent>
          <StepperWrapper>
            <VerticalStepper current={step} steps={portfolioCreationSteps} data-cy="portfolioCreationSteps" />
          </StepperWrapper>
          <StepWrapper>{renderStepBasedOnType()}</StepWrapper>
        </SidePanelContent>
        <SidePanelActions>
          {step > 1 && (
            <Button id="previous" variant="contained" onClick={previousStep}>
              Previous step
            </Button>
          )}
          <ButtonAsync
            id="next"
            variant="contained"
            color="primary"
            disabled={!allowNext() || loading}
            // @ts-expect-error
            onClick={step !== 2 ? nextStep : handleFinish}
            style={{
              marginLeft: 'auto',
            }}
          >
            {step !== 2 ? 'Next step' : 'Finish'}
          </ButtonAsync>
        </SidePanelActions>
        {error ? <ErrorMessage error={error} /> : null}
      </SidePanel>
      <PortfolioSuccessDialog
        isOpen={isSuccessDialogOpen}
        portfolio={newPortfolio.current}
        onClose={() => {
          newPortfolio.current = null;
          closeSuccessDialog();
          closeSidePanel();
        }}
        onStartNewProcess={() => {
          toggleSuccessDialog();
          toggleSidePanel();
          newPortfolio.current = null;
        }}
        onSuccess={() => {
          if (newPortfolio.current) {
            history.push(buildPortfolioPathWithDates(newPortfolio.current));
          }

          toggleSuccessDialog();
          toggleSidePanel();
          newPortfolio.current = null;
        }}
      />
    </>
  );
}

export default withRouter(CreatePortfolio);
