const SvgGreenArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      d="M8.07 1.93V16.07m0-14.142 5.893 5.893M8.07 1.929 2.178 7.822"
      stroke="#0EC76A"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgGreenArrow;
