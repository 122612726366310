import React, { SyntheticEvent, useEffect, useState } from 'react';
import { get } from 'lodash';
import { ChevronsUpDown, ExternalLink } from 'lucide-react';
import { useTeam } from '../../../../context/TeamProvider';
import { useUpdateContentTargetMetric } from 'graphql/portfolio/mutationUpdateContentTargetMetric';
import { updateApolloCache, updatePortfolioContentDetailsFunnelStepMetricsCache } from '../../../../utils/cache';
import { PORTFOLIO_QUERY } from '../../../../graphql/portfolio/queryPortfolio';
import ErrorMessage from 'components/ErrorMessage';
import TypographyTranslation from 'components/TypographyTranslation';
import ButtonIcon from '../../../../components/ButtonIcon';
import {
  NexoyaMetricMeta,
  NexoyaPortfolioContentDetail,
  NexoyaPortfolioContentFunnelStepMetricInput,
  NexoyaPortfolioContentMetric,
} from '../../../../types';
import TextField from '../../../../components/TextField';

// Importing ShadCN dropdown menu components
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components-ui/DropdownMenu';
import { nexyColors } from '../../../../theme';
import styled from 'styled-components';
import translate from '../../../../utils/translate';
import { useTranslationsQuery } from '../../../../graphql/translation/queryTranslations';

const formatNoDataAsNone = (text: string) => (text === 'No data' ? 'None' : text);

// Function to escape special characters in a string
const escapeSpecialChars = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
const StyledTextField = styled(TextField)`
  .NEXYInputWrap {
    padding: 4px 6px;
    box-shadow: rgba(223, 225, 237, 0.2) 0 0 0 1px;
  }
`;

interface Props {
  item: NexoyaPortfolioContentDetail;
  portfolioId: number;
  funnelStepId: number;
  metricsForFunnelStep: NexoyaPortfolioContentMetric;
  dateFrom: Date;
  dateTo: Date | string;
  isChildContent?: boolean;
  targetName: string | null;
  link: string | null;
  shouldRenderDropdown: boolean;
}

function ContentMetricPanel({
  item,
  portfolioId,
  funnelStepId,
  metricsForFunnelStep,
  dateFrom,
  dateTo,
  isChildContent = false,
  targetName,
  link,
  shouldRenderDropdown,
}: Props) {
  const { teamId } = useTeam();
  const { data: translationData } = useTranslationsQuery();
  const translations = get(translationData, 'translations', []);

  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const metricsList: NexoyaMetricMeta[] = get(metricsForFunnelStep, 'otherFunnelStepMetrics.otherMetrics', []) || [];
  const [, { error }, extendContentTargetMetric] = useUpdateContentTargetMetric({
    portfolioId,
  });

  const handleUpdateContentTargetMetric = async (
    contentFunnelStepMetric: NexoyaPortfolioContentFunnelStepMetricInput,
  ) => {
    try {
      const res = await extendContentTargetMetric({
        contentFunnelStepMetric,
      });

      if (res) {
        updateApolloCache({
          query: PORTFOLIO_QUERY,
          variables: {
            teamId,
            portfolioId,
            dateFrom,
            dateTo,
            withBudget: false,
          },
          updateFn: updatePortfolioContentDetailsFunnelStepMetricsCache({
            isChildContent,
            contentId: contentFunnelStepMetric.contentId,
            funnelStepId: contentFunnelStepMetric.funnelStepId,
            metricTypeId: contentFunnelStepMetric.metricTypeId,
          }),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onContentSelection = (event: SyntheticEvent, metric: NexoyaMetricMeta) => {
    event.stopPropagation();
    handleUpdateContentTargetMetric({
      contentId: item.contentId,
      funnelStepId: funnelStepId,
      metricTypeId: metric.metric_type_id,
    });
    setIsMenuOpen(false); // Close the menu after selection
  };

  // Filter metrics based on the search term using a smart regex pattern
  const filteredMetricsList = metricsList.filter((metric) => {
    const regexPattern = searchTerm
      .split(' ')
      .filter(Boolean) // Remove any empty strings from splitting
      .map((term) => escapeSpecialChars(term)) // Escape special characters in the term
      .join('.*'); // Join terms with '.*' to allow for flexible matching

    const regex = new RegExp(regexPattern, 'i'); // Create a case-insensitive regex
    return regex.test(formatNoDataAsNone(translate(translations, metric.metric_type_name)));
  });

  // Reset the search term with a delay when the dropdown menu closes
  useEffect(() => {
    let resetTimeout: ReturnType<typeof setTimeout>;

    if (!isMenuOpen) {
      resetTimeout = setTimeout(() => {
        setSearchTerm('');
      }, 300);
    }

    // Cleanup timeout if the component unmounts or if the menu opens again before the timeout completes
    return () => {
      clearTimeout(resetTimeout);
    };
  }, [isMenuOpen]);

  return (
    <div className="flex w-full justify-between gap-2">
      {shouldRenderDropdown ? (
        <DropdownMenu modal={false} onOpenChange={(open) => setIsMenuOpen(open)}>
          <DropdownMenuTrigger asChild>
            <div className="flex cursor-pointer items-center rounded-md px-1.5 py-0.5 transition-all hover:bg-seasalt">
              <TypographyTranslation text={targetName} />
              <ChevronsUpDown className="ml-1 h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          {/* Show search input always inside the dropdown */}
          <DropdownMenuContent
            className="w-full min-w-[350px] font-normal"
            style={{ maxHeight: '400px', overflowY: 'auto' }} // Fixed height to avoid shrinking
          >
            <StyledTextField
              type="text"
              placeholder="Search metrics..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%', padding: '4px 8px', color: nexyColors.white }}
              onKeyDown={(e) => e.stopPropagation()} // Prevents keydown events from affecting focus
            />
            <div className="w-full px-3 py-2 font-normal">
              {filteredMetricsList.length
                ? filteredMetricsList.map((com) => (
                    <DropdownMenuItem
                      key={`optionalMetric-${com.metric_type_id}`}
                      className="metricMenuItem"
                      // async={changedOptionalMetricId === com.metric_type_id}
                      // loading={loadingMetric}
                      onSelect={(e) => onContentSelection(e as unknown as SyntheticEvent, com)}
                      onMouseDown={(e) => e.preventDefault()} // Prevent focus shift on selection
                    >
                      <div className="flex items-center">
                        <TypographyTranslation text={formatNoDataAsNone(com.metric_type_name)} />
                      </div>
                    </DropdownMenuItem>
                  ))
                : 'No metrics found'}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <div className="flex items-center rounded-md px-1.5 py-0.5">
          <TypographyTranslation text={targetName} />
        </div>
      )}

      {link ? (
        <ButtonIcon
          variant="text"
          color="secondary"
          flat
          type="button"
          className="NEXYButtonMetric"
          onClick={(e) => {
            e.stopPropagation();
            window.open(link, '_blank');
          }}
          style={{ padding: 4 }}
        >
          <ExternalLink className="h-4 w-4" />
        </ButtonIcon>
      ) : null}

      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default ContentMetricPanel;
