import styled, { css } from 'styled-components';

import FormattedCurrency from 'components/FormattedCurrency';
import GridRow from 'components/GridRow';
import Typography from 'components/Typography';
import TypographyTranslation from 'components/TypographyTranslation';
import SvgSort from 'components/icons/Sort2';

import theme from 'theme/theme';
import { colorByKey } from 'theme/utils';

import ButtonIcon from '../ButtonIcon';
import NumberValue from '../NumberValue';

const TypographyFontStyled = css`
  font-size: 11px;
  color: #b7bac7;
  font-weight: 600;
`;

const CurrencyStyled = css`
  text-align: right;
  padding-right: 20px;
  font-variant-numeric: tabular-nums;
`;

export const WrapStyled = styled.div<{
  extraColumn?: boolean;
  isCostFunnelStep?: boolean;
}>`
  width: 100%;

  .NEXYCSSGrid {
    min-width: 100%;
    padding: 0 24px;
    grid-template-columns: ${({ extraColumn, isCostFunnelStep }) =>
      `${extraColumn ? '40px' : ''} minmax(300px, 3fr) 200px ${isCostFunnelStep ? '' : '200px 200px'} 80px`};
  }
`;

export const SvgSortStyledContent = styled(SvgSort)<{
  readonly isActive: boolean;
  readonly onClick: any;
}>`
  display: inline;
  position: absolute;
  margin-left: 8px;
  margin-top: 2px;
  font-size: 12px;
  cursor: pointer;
  z-index: ${theme.layers.body};
  color: ${({ isActive }) => (isActive ? colorByKey('greenTeal') : 'inherit')};
  :hover {
    color: ${colorByKey('greenTeal')};
  }
`;
export const SvgSortStyled = styled(SvgSort)<{
  readonly isActive: boolean;
  readonly onClick: any;
}>`
  display: inline;
  position: absolute;
  margin-left: 8px;
  margin-top: 2px;
  right: 0;
  font-size: 12px;
  cursor: pointer;
  z-index: ${theme.layers.body};
  color: ${({ isActive }) => (isActive ? colorByKey('greenTeal') : 'inherit')};
  :hover {
    color: ${colorByKey('greenTeal')};
  }
`;
export const TypographyStyled = styled(Typography)`
  position: relative;
  margin-left: 36px;
  ${TypographyFontStyled}
`;
export const TypographyStyledAligned = styled(Typography)`
  position: relative;
  text-align: right;
  padding-right: 20px;
  ${TypographyFontStyled}
`;
export const ContentRowStyled = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1520px) {
    max-width: 96px;
  }
`;
export const ProviderNameStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .NEXYText {
    font-size: 12px;
    color: #a6a7b5;
  }
`;
export const ValuesStyled = styled.div`
  ${CurrencyStyled}
`;
export const GridRowTotalStyled = styled(GridRow)`
  background: ${colorByKey('paleGrey50')};
  font-weight: 600;
  border-bottom: none;
`;
export const TotalTitle = styled.div`
  font-weight: normal;
  color: #a0a2ad;
`;

export const ChevronWrap = styled.div<{
  readonly expanded?: boolean;
}>`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  color: #a6a7b5;
  & > svg {
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 250ms ease-in-out;
    transform-origin: center;
  }
`;

export const TypographyTranslationStyled = styled(TypographyTranslation)`
  font-size: 12px;
  color: #a6a7b5;
`;

export const ChildContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

export const FormattedCurrencyStyled = styled(FormattedCurrency)<{ color?: string }>`
  color: ${(props) => props.color};
  ${CurrencyStyled}
`;

export const NumberValueStyled = styled(NumberValue)<{ color?: string }>`
  color: ${(props) => props.color};
  ${CurrencyStyled}
`;

export const IconContent = styled.div`
  padding-left: 20px;
`;

export const FilterBullet = styled.div<{
  readonly backgroundColor?: string;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ backgroundColor }) => backgroundColor};
  margin-right: 8px;
`;

export const ExportButtonStyled = styled(ButtonIcon)`
  padding: 8px;
  font-size: 12px;
  margin-right: -7px;

  .NEXYButtonLabel {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
