import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row as RowType,
  TableCellProps,
  TableHeaderProps,
  TableOptions,
  TableToggleHideAllColumnProps,
  useBlockLayout,
  useExpanded,
  useGlobalFilter,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';

import clsx from 'clsx';
import styled from 'styled-components';

import { useUserQuery } from '../../graphql/user/queryUser';

import { ACTIONS_HEADER_ID } from '../../routes/portfolio/components/OptimizationProposal/columns';
import SvgSort from 'components/icons/Sort2';

import { colorByKey } from 'theme/utils';

import { nexyColors } from '../../theme';
import { ButtonSortStyled } from './ButtonSortStyled';
import { Row } from './Row';
import { getInitialHiddenColumnsByUser } from './cacheHiddenColumns';
import { sortTypes } from './sortTypes';
import { IColumnProps } from './tableTypes';

const TABLE_VERSION = 1;
const TABLE_MANAGER_WIDTH = 32;

export const classes = {
  container: 'NEXYTableContainer',
  header: 'NEXYTableHeader',
  row: 'NEXYTableRow',
};

interface TableProps<D extends object = {}> extends TableOptions<D> {
  disableManager?: boolean;
  disableExpanded?: boolean;
  disableSort?: boolean;
  tableId?: string;
  getCustomCellStyles?: (cell: Column<IColumnProps>, row?: RowType, column?: any) => React.CSSProperties;
  renderTableManager?: (props: TableManagerProps) => JSX.Element;
}

interface TableManagerProps {
  setStickyColumns: (value: ((prevState: string[]) => string[]) | string[]) => void;
  columns: Array<ColumnInstance<{}>>;
  getToggleHideAllColumnsProps: (props?: Partial<TableToggleHideAllColumnProps>) => TableToggleHideAllColumnProps;
  rows: Array<RowType<{}>>;
  toggleHideAllColumns: (value?: boolean) => void;
  stickyColumns: string[];
}

export const ExtendedTable: React.FC<TableProps> = ({
  columns,
  data,
  disableManager,
  disableSort,
  getCustomCellStyles,
  renderTableManager,
  disableExpanded = true,
  tableId,
}) => {
  const hooks: any[] = [useBlockLayout, useResizeColumns, useGlobalFilter];

  if (!disableSort) hooks.push(useSortBy);
  if (!disableExpanded) hooks.push(useExpanded);

  const match = useRouteMatch<{ portfolioID: string }>();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const { data: userData } = useUserQuery();
  const localStorageKey = `${tableId}-${TABLE_VERSION}-tableManager-${userData?.user?.user_id}-${portfolioId}`;

  const localStorageData = getInitialHiddenColumnsByUser(localStorageKey);
  const [stickyColumns, setStickyColumns] = useState(localStorageData.stickyColumns || []);
  const [columnWidths, setColumnWidths] = useState(localStorageData.columnWidths || {});
  const [sortState, setSortState] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data,
      sortTypes,
      autoResetExpanded: false,
      autoResetResize: false,
      initialState: {
        hiddenColumns: localStorageData.hiddenColumns,
        // @ts-ignore
        columnResizing: { columnWidths: localStorageData.columnWidths || {} },
        sortBy: sortState,
      },
      stateReducer: handleStateReducer,
    },
    ...hooks,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { hiddenColumns },
    setHiddenColumns,
    getToggleHideAllColumnsProps,
  } = tableInstance;

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify({ hiddenColumns, stickyColumns, columnWidths }));
  }, [hiddenColumns, stickyColumns, columnWidths, localStorageKey]);

  ensureNotAllHidden(allColumns);

  const toggleHideAllColumns = () => {
    // @ts-ignore
    const hideableColumns = allColumns.filter((column) => !column.disableHiding).map((column) => column.id);
    const allHideableColumnsHidden = hideableColumns.every((columnId) => hiddenColumns.includes(columnId));

    if (allHideableColumnsHidden) {
      // Unhide all hideable columns
      setHiddenColumns(hiddenColumns.filter((columnId) => !hideableColumns.includes(columnId)));
    } else {
      // Hide all hideable columns
      setHiddenColumns([...hiddenColumns, ...hideableColumns]);
    }
  };

  return (
    <StyledTable className={classes.container} enableSticky={true}>
      <table {...getTableProps()}>
        <TableHeader
          headerGroups={headerGroups}
          disableSort={disableSort}
          disableManager={disableManager}
          renderTableManager={renderTableManager}
          getCustomCellStyles={getCustomCellStyles}
          getStylingByHeader={getStylingByHeader}
          allColumns={allColumns}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          toggleHideAllColumns={toggleHideAllColumns}
          setStickyColumns={setStickyColumns}
          stickyColumns={stickyColumns}
          rows={rows}
        />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          disableManager={disableManager}
          getCustomCellStyles={getCustomCellStyles}
          getCellStyling={getCellStyling}
        />
      </table>
    </StyledTable>
  );

  function handleStateReducer(newState, action) {
    switch (action.type) {
      case 'toggleSortBy':
        setSortState(newState.sortBy);
        break;
      case 'columnResizing':
        setColumnWidths({ ...newState.columnResizing.columnWidths });
        break;
    }
    return newState;
  }

  function getStylingByHeader(header: HeaderGroup<IColumnProps>) {
    const isSticky = (header: HeaderGroup<IColumnProps>) =>
      stickyColumns.includes(header.id.split('_')[0] || header.id.split('_')[0]);

    if (isSticky(header)) {
      const headers = headerGroups.flatMap((headerGroup) => headerGroup.headers);
      const ownIndex = headers.indexOf(header);
      const stickyLeft = headers
        .slice(0, ownIndex)
        .filter((headerArg) => isSticky(headerArg) && headerArg.depth === header.depth)
        .map((header) => header.totalWidth)
        .reduce((partialSum, a) => partialSum + a, 0);

      return {
        className: 'sticky',
        style: {
          left: stickyLeft.toString() + 'px',
          boxShadow: '2px 0px 2px 0px rgba(0, 0, 0, 0.05)',
          zIndex: 10,
          overflow: 'visible',
        },
      };
    }
    return {};
  }

  function getCellStyling(cell: Cell<IColumnProps>) {
    const headers = headerGroups.flatMap((headerGroup) => headerGroup.headers);
    const header = headers.find((header) => header.id === cell.column.id);
    return getStylingByHeader(header);
  }
};

const TableHeader = ({
  headerGroups,
  disableSort,
  disableManager,
  renderTableManager,
  getCustomCellStyles,
  getStylingByHeader,
  allColumns,
  getToggleHideAllColumnsProps,
  toggleHideAllColumns,
  setStickyColumns,
  stickyColumns,
  rows,
}) => {
  return (
    <thead className={classes.header}>
      {headerGroups.map((headerGroup, index) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column) => {
            const sortStyles = !disableSort ? column.getSortByToggleProps() : null;
            const stickyStyles = getStylingByHeader(column);

            const headerProps = column.getHeaderProps();
            ensureEvenWidth(headerProps);
            const customStyles = getCustomCellStyles ? getCustomCellStyles(column) : {};

            return (
              <th
                className={clsx(column['className'] ?? '', stickyStyles.className ?? '')}
                {...headerProps}
                key={column['id']}
                style={{
                  ...headerProps.style,
                  ...customStyles,
                  ...(column.originalId === ACTIONS_HEADER_ID ? { display: 'flex', justifyContent: 'flex-start' } : {}),
                  ...stickyStyles.style,
                }}
              >
                {column.originalId === ACTIONS_HEADER_ID && !disableManager
                  ? renderTableManager({
                      columns: allColumns,
                      getToggleHideAllColumnsProps,
                      toggleHideAllColumns,
                      setStickyColumns,
                      stickyColumns,
                      rows,
                    })
                  : null}
                {column['enableColumnResize'] ? (
                  <div
                    {...column.getResizerProps()}
                    title="Resize column"
                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                  />
                ) : null}
                <Row
                  {...sortStyles}
                  title=""
                  style={{ height: '100%' }}
                  isSortable={!column['disableSortBy']}
                  {...column.rowProps}
                >
                  {column.render('Header')}{' '}
                  {column['disableSortBy'] ? null : (
                    <ButtonSortStyled
                      style={{ justifySelf: 'start', position: 'static' }}
                      asc={column['isSorted'] ? !column['isSortedDesc'] : null}
                      desc={column['isSortedDesc']}
                    >
                      <SvgSort style={{ width: 12, height: 10.5 }} />
                    </ButtonSortStyled>
                  )}
                </Row>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

const TableBody = ({ getTableBodyProps, rows, prepareRow, disableManager, getCustomCellStyles, getCellStyling }) => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, idx) => {
        let rowClassList = [classes.row];
        if (row.original['classList']) rowClassList = [...rowClassList, ...row.original['classList']];
        if (row.original?.['highlight']) rowClassList.push('highlight');
        prepareRow(row);
        return (
          <tr className={clsx(rowClassList)} {...row.getRowProps()} key={idx}>
            {disableManager ? null : (
              <td
                className={clsx([row.original?.['highlight'] ? 'highlight' : null])}
                style={{ ...tableManagerColStyle, width: 0, padding: 0, minWidth: 0 }}
              />
            )}
            {row.cells.map((cell) => {
              const classList = [];
              const styling = getCellStyling(cell);
              if (styling.className) classList.push(styling.className);
              if (cell.column['className']) classList.push(cell.column['className']);
              if (row.original?.['highlight']) classList.push('highlight');
              if (row.original?.['lightHighlight']) classList.push('lightHighlight');
              const cellProps = cell.getCellProps({
                className: clsx(classList),
                style: styling.style,
              });
              ensureEvenWidth(cellProps);
              const customStyles = getCustomCellStyles ? getCustomCellStyles(cell.column, row, cell) : {};
              return (
                <td
                  className={clsx(
                    cell.column.isResizing ? 'isResizing' : '',
                    cell.column['enableColumnResize'] ? 'columnResizable' : '',
                    cell.column['className'] ?? '',
                  )}
                  {...cellProps}
                  key={cell?.column?.id}
                  style={{ ...cellProps.style, ...customStyles }}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

const ensureNotAllHidden = (columns: ColumnInstance<IColumnProps>[]) => {
  const allHidden = !columns.some((col) => col.isVisible);
  if (allHidden) columns[0].toggleHidden();
};

const ensureEvenWidth = (props: TableCellProps | TableHeaderProps) => {
  if (props.style.width && !props.style.minWidth) props.style.minWidth = props.style.width;
  if (props.style.width && !props.style.maxWidth) props.style.maxWidth = props.style.width;
};

const tableManagerColStyle = {
  width: TABLE_MANAGER_WIDTH.toString() + 'px',
  minWidth: TABLE_MANAGER_WIDTH.toString() + 'px',
};

const StyledTable = styled.div<any>`
  overflow-x: scroll;
  position: relative;

  .sticky {
    position: ${(props) => (props.enableSticky ? 'sticky' : 'initial')} !important;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 1230;
  }
  .alwaysSticky {
    position: sticky;
    left: 0;
    top: 0;
  }

  table {
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: right;
    position: relative;
    width: 100%;

    th {
      padding: 0.25rem 1rem;
      min-height: 20px;
      color: ${nexyColors.raisinBlack};
      align-items: center;
      border-width: 0 0px 1px 0;
      border-style: solid;
      border-color: rgb(42 42 50 / 8%);

      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 12px */
      letter-spacing: 0.24px;
      width: 100%;

      :first-child {
        padding: 0;
      }

      :last-child {
        border-width: 0 0 1px 0;
      }
      position: relative;
      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action: none;
        user-select: none;

        &:hover {
          background: ${colorByKey('lavender')};
        }

        &.isResizing {
          width: 4px;
          background: ${colorByKey('purpleish')};
        }
      }
    }

    tr {
      align-items: stretch;
      min-height: 20px;

      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: rgb(42 42 50 / 8%);
      width: 100%;

      :last-child {
        border-width: 0;
      }
    }

    td {
      margin: 0;
      padding: 0.5rem 0.75rem;
      align-items: center;
      min-height: 20px;
      border-width: 0;
      overflow-wrap: break-word;
      overflow: hidden !important;
      width: 100%;

      :first-child {
        border-width: 0;
      }
      :last-child {
        border-width: 0;
      }
    }

    .highlight {
      background-color: ${nexyColors.seasalt} !important;
    }

    .lightHighlight {
      background-color: ${nexyColors.ghostWhite} !important;
    }

    .isResizing {
      border-color: ${colorByKey('purpleish')};
      border-right-width: 2px;
    }
  }
`;
