const SvgCheckInDashedCircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      d="m8 11.857 2.5 2.5L15.857 9M21.772 14.182c.16-.7.24-1.43.24-2.17s-.09-1.47-.24-2.17m-18.19 7.53c.79 1.23 1.83 2.28 3.07 3.07m3.19 1.33c.7.16 1.43.24 2.17.24s1.47-.09 2.17-.24m3.19-1.33c1.23-.79 2.28-1.83 3.07-3.07M6.652 3.582c-1.23.79-2.28 1.83-3.07 3.07m-1.33 3.19c-.16.7-.24 1.43-.24 2.17s.09 1.47.24 2.17m11.93-11.93c-.7-.16-1.43-.24-2.17-.24s-1.47.09-2.17.24m10.6 4.4c-.79-1.23-1.83-2.28-3.07-3.07"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCheckInDashedCircle;
