import { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { get } from 'lodash';
import styled from 'styled-components';

import { NexoyaTeam } from '../../types/types';

import { useTeam } from '../../context/TeamProvider';
import { useUserQuery } from '../../graphql/user/queryUser';

import { colorByKey } from '../../theme/utils';

import Avatar from '../Avatar';
import ButtonBase from '../ButtonBase';
import Menu, { DropdownMenuAnchor, useDropdownMenu } from '../DropdownMenu';
import { LaptopLUp } from '../MediaQuery';
import MenuItem from '../MenuItem';
import Text from '../Text';

type Props = {
  history: RouterHistory;
};
const MenuAnchorStyled = styled(DropdownMenuAnchor)`
  .NEXYPopper {
    min-width: 100%;
    max-width: 207px;
  }
`;

const MenuStyled = styled(Menu)`
  width: 13.25rem;
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #2a2b2e;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #585a6a80;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #585a6a9d;
  }
`;
const AnchorButtonStyled = styled(ButtonBase)`
  background: ${colorByKey('white')};
  border: 1px solid #e3e4e8;
  border-radius: 5px;
  width: 100%;
  justify-content: start;

  img {
    height: 100%;
  }
`;
const WrapNameStyled = styled.div`
  flex: 1;
  text-align: left;
  margin-left: 8px;
  min-width: 0;

  span {
    display: block;
    width: 100%;

    &:first-child::first-letter {
      text-transform: capitalize;
    }

    &:last-child {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: normal;
      color: ${colorByKey('blueyGrey')};
    }
  }
`;
const MenuItemStyled = styled(MenuItem)`
  display: flex;
  align-items: center;

  img {
    height: 100%;
  }
`;

function Teams({ history, ...rest }: Props) {
  const { anchorEl, open, toggleMenu, closeMenu } = useDropdownMenu();
  const { teamId, setTeam } = useTeam();
  const { data } = useUserQuery();
  const teams = get(data, 'user.teams', []);
  const selectedTeam: NexoyaTeam = teams.find((t) => t.team_id === teamId) || {};
  return (
    <>
      <MenuAnchorStyled ref={anchorEl} {...rest}>
        <AnchorButtonStyled onClick={toggleMenu} data-cy="teamNavBtn">
          <Avatar size={32} src={selectedTeam.logo} alt={selectedTeam.name}>
            {get(selectedTeam, 'name', '').charAt(0)}
          </Avatar>
          <LaptopLUp>
            <WrapNameStyled>
              <Text>{selectedTeam.name || ''}</Text>
              <Text>Team</Text>
            </WrapNameStyled>
          </LaptopLUp>
        </AnchorButtonStyled>
      </MenuAnchorStyled>
      <MenuStyled
        open={open}
        placement="bottom-start"
        anchorEl={anchorEl.current}
        container={anchorEl.current}
        color="dark"
        onClose={closeMenu}
        style={{
          maxHeight: 320,
        }}
        data-cy="teamsMenu"
      >
        {teams.map((team) => (
          <MenuItemStyled
            data-cy={`${team.name}`}
            isSelected={team.team_id === selectedTeam.team_id}
            key={team.team_id}
            onClick={() => {
              if (team.team_id !== selectedTeam.team_id) {
                closeMenu();
                setTeam(team.team_id, () => history.push('/'));
              }
            }}
            buttonProps={{
              startAdornment: (
                <Avatar size={32} src={team.logo} alt={team.name}>
                  {get(team, 'name', '').charAt(0)}
                </Avatar>
              ),
            }}
          >
            <WrapNameStyled>
              <Text>{team.name}</Text>
              <Text>Team</Text>
            </WrapNameStyled>
          </MenuItemStyled>
        ))}
      </MenuStyled>
    </>
  );
}

export default withRouter(Teams);
