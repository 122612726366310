import client from '../apollo'; // Importing the Apollo client instance
import {
  NexoyaImpactGroup,
  NexoyaPortfolio,
  NexoyaPortfolioContentDetail,
  NexoyaPortfolioContentMetric,
  NexoyaPortfolioLabel,
} from '../types'; // Importing relevant types

// Function to update the Apollo cache with a given query, variables, and update function
export function updateApolloCache({ query, variables, updateFn }) {
  client.cache.updateQuery(
    {
      query: query,
      broadcast: false, // Silences the broadcast of cache updates and prevents automatic query refresh
      variables: variables,
    },
    updateFn, // Function to update the cache with new data
  );
}

// Function to update the cache with new impact group data for a specific content ID
export function updatePortfolioContentDetailsImpactGroupCache({
  contentId,
  impactGroup,
  portfolioId,
}: {
  contentId: number;
  impactGroup: NexoyaImpactGroup;
  portfolioId: number;
}) {
  return (currentCache: { portfolio: NexoyaPortfolio }) => ({
    ...currentCache, // Spread the current cache to maintain other data intact
    portfolio: {
      ...currentCache.portfolio,
      content: {
        ...currentCache.portfolio.content,
        contentDetails: currentCache?.portfolio?.content?.contentDetails?.map(
          (item) => (item.contentId === contentId ? { ...item, impactGroup: { ...impactGroup, portfolioId } } : item),
          // If contentId matches, update the impactGroup and portfolioId, otherwise return the item unchanged
        ),
      },
    },
  });
}

// Function to update the cache with new funnel step metrics data for a specific content ID
export function updatePortfolioContentDetailsFunnelStepMetricsCache({
  contentId,
  funnelStepId,
  metricTypeId,
  isChildContent, // New parameter to indicate if the update is for child content
}: {
  contentId: number;
  funnelStepId: number;
  metricTypeId: number | null;
  isChildContent: boolean;
}) {
  const effectiveMetricTypeId = metricTypeId ?? 0; // If metricTypeId is null, default to 0

  // Internal function to update metrics array based on the given funnelStepId and metricTypeId
  const updateMetrics = (metrics: NexoyaPortfolioContentMetric[]) => {
    return metrics?.map((metric) => {
      if (metric.funnelStep.funnel_step_id === funnelStepId) {
        // Find the matching metric in otherMetrics
        const matchedOtherMetric = metric.otherFunnelStepMetrics?.otherMetrics?.find(
          (otherMetric) => otherMetric?.metric_type_id === effectiveMetricTypeId,
        );

        // Filter out the current metricTypeId and the new effectiveMetricTypeId from otherMetrics
        const updatedOtherMetrics =
          metric.otherFunnelStepMetrics?.otherMetrics?.filter(
            (otherMetric) =>
              otherMetric?.metric_type_id !== metric.metricTypeId &&
              otherMetric?.metric_type_id !== effectiveMetricTypeId,
          ) || [];

        // Ensure the "None" option is added only once and not duplicated
        const noneMetric = updatedOtherMetrics.find((m) => m.metric_type_id === null);

        if (!noneMetric) {
          updatedOtherMetrics.push({
            metric_type_id: null,
            metric_type_name: 'None', // Adding the "None" option if not present
          });
        }

        // Add the current metricTypeId back to the list if it's valid
        if (metric.metricTypeId && metric.metricTypeId !== 0) {
          updatedOtherMetrics.push({
            metric_type_id: metric.metricTypeId,
            metric_type_name: metric.coreMetricValues?.metricTypeName,
          });
        }

        // Sort the updatedOtherMetrics alphabetically by metric_type_name
        updatedOtherMetrics.sort((a, b) => (a?.metric_type_name ?? '').localeCompare(b?.metric_type_name ?? ''));

        // If a matching metric was found, update the metric and return the updated object
        if (matchedOtherMetric) {
          return {
            ...metric,
            metricTypeId: effectiveMetricTypeId,
            coreMetricValues: {
              ...metric.coreMetricValues,
              metricTypeName: matchedOtherMetric.metric_type_name,
            },
            otherFunnelStepMetrics: {
              ...metric.otherFunnelStepMetrics,
              activeFunnelStepMetricId: effectiveMetricTypeId,
              otherMetrics: updatedOtherMetrics,
            },
          };
        }

        // Return the updated metric object with the new metricTypeId and sorted otherMetrics
        return {
          ...metric,
          metricTypeId: effectiveMetricTypeId,
          otherFunnelStepMetrics: {
            ...metric.otherFunnelStepMetrics,
            activeFunnelStepMetricId: effectiveMetricTypeId,
            otherMetrics: updatedOtherMetrics,
          },
        };
      }
      return metric; // Return unchanged metric if funnelStepId does not match
    });
  };

  // Function to update content details, including handling of child content
  const updateContentDetails = (contentDetails: NexoyaPortfolioContentDetail[]) => {
    return contentDetails.map((item) =>
      item.contentId === contentId
        ? {
            ...item,
            metrics: updateMetrics(item.metrics), // Update main content metrics
            childContent: item.childContent?.map((child) =>
              child.contentId === contentId
                ? {
                    ...child,
                    metrics: updateMetrics(child.metrics), // Update child content metrics
                  }
                : child,
            ),
          }
        : item,
    );
  };

  return (currentCache: { portfolio: NexoyaPortfolio }) => {
    if (isChildContent) {
      // Update child content if isChildContent is true
      return {
        ...currentCache,
        portfolio: {
          ...currentCache.portfolio,
          content: {
            ...currentCache.portfolio.content,
            contentDetails: currentCache?.portfolio?.content?.contentDetails?.map((item) =>
              item.childContent?.some((child) => child.contentId === contentId)
                ? {
                    ...item,
                    childContent: updateContentDetails(item.childContent),
                  }
                : item,
            ),
          },
        },
      };
    } else {
      // Update main content if isChildContent is false
      return {
        ...currentCache,
        portfolio: {
          ...currentCache.portfolio,
          content: {
            ...currentCache.portfolio.content,
            contentDetails: updateContentDetails(currentCache?.portfolio?.content?.contentDetails),
          },
        },
      };
    }
  };
}

// Function to update the cache with a new label for a specific content ID
export function updatePortfolioContentDetailsLabelCache({
  contentId,
  label,
}: {
  contentId: number;
  label: NexoyaPortfolioLabel;
}) {
  return (currentCache: { portfolio: NexoyaPortfolio }) => ({
    ...currentCache,
    portfolio: {
      ...currentCache.portfolio,
      content: {
        ...currentCache.portfolio.content,
        contentDetails: currentCache?.portfolio?.content?.contentDetails?.map(
          (item) => (item.contentId === contentId ? { ...item, label } : item), // Update the label if contentId matches
        ),
      },
    },
  });
}
