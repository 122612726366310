import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

import { NexoyaPortfolioContentRelationInput } from '../../types/types';

import { useTeam } from '../../context/TeamProvider';

import { PORTFOLIO_QUERY } from './queryPortfolio';

const ADD_MANY_CONTENT_RELATIONS = gql`
  mutation addManyContentRelations(
    $teamId: Int!
    $portfolioId: Int!
    $contentRelations: [PortfolioContentRelationInput!]!
  ) {
    addManyContentRelations(teamId: $teamId, portfolioId: $portfolioId, contentRelations: $contentRelations)
  }
`;

type AddRelationsArgs = {
  portfolioId: number;
  contentRelations: NexoyaPortfolioContentRelationInput[];
  dateTo: Date | string;
  dateFrom: Date;
};
export function useAddManyContentRelationsMutation({
  portfolioId,
  contentRelations,
  dateFrom,
  dateTo,
}: AddRelationsArgs) {
  const { teamId } = useTeam();
  return useMutation(ADD_MANY_CONTENT_RELATIONS, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    variables: {
      teamId,
      portfolioId,
      contentRelations,
    },
    refetchQueries: [
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          dateTo,
          dateFrom,
          portfolioId,
          withBudget: true,
          withOptimization: true,
        },
      },
    ],
  });
}

const REMOVE_CONTENT_RELATION = gql`
  mutation removeContentRelation($teamId: Int!, $portfolioId: Int!, $contentId: Float!) {
    removeContentRelation(teamId: $teamId, portfolioId: $portfolioId, contentId: $contentId)
  }
`;

type RemoveRelationArgs = {
  portfolioId: number;
  contentId: number;
  dateTo: Date | string;
  dateFrom: Date;
};
export function useRemoveContentRelationMutation({ portfolioId, contentId, dateFrom, dateTo }: RemoveRelationArgs) {
  const { teamId } = useTeam();
  return useMutation(REMOVE_CONTENT_RELATION, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    variables: {
      teamId,
      portfolioId,
      contentId,
    },
    refetchQueries: [
      {
        query: PORTFOLIO_QUERY,
        variables: {
          teamId,
          dateTo,
          dateFrom,
          portfolioId,
          withBudget: true,
          withOptimization: true,
        },
      },
    ],
  });
}
