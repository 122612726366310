import { get } from 'lodash';
import { toast } from 'sonner';

import { usePortfolio } from 'context/PortfolioProvider';
import { useRemoveContentRelationMutation } from 'graphql/portfolio/mutationUpdateContentRelation';

import MenuList from 'components/ArrayMenuList';
import ButtonAdornment from 'components/ButtonAdornment';
import ButtonIcon from 'components/ButtonIcon';
import ErrorMessage from 'components/ErrorMessage';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import SvgCloneRegular from 'components/icons/CloneRegular';
import SvgEllipsisV from 'components/icons/EllipsisV';
import SvgUnlink from 'components/icons/Unlink';
import { useDropdownMenu } from '../../../components/DropdownMenu';

function ChildTDM({ portfolioId, contentId, dateTo, dateFrom }) {
  const {
    anchorEl: anchorElTDM,
    open: openTDM,
    toggleMenu: toggleMenuTDM,
    closeMenu: closeMenuTDM,
  } = useDropdownMenu();
  const {
    contentSelection: { reset },
  } = usePortfolio();
  const [removeContentRelation, { loading: loadingTDM, error: errorTDM }] = useRemoveContentRelationMutation({
    dateTo,
    dateFrom,
    portfolioId,
    contentId,
  });

  async function handleUnlinkSubmit() {
    reset();
    try {
      const res = await removeContentRelation();

      if (get(res, 'data.updateContentRelation', null)) {
        toggleMenuTDM();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }
  return (
    <div ref={anchorElTDM}>
      <ButtonIcon
        active={openTDM}
        onClick={(e) => {
          e.stopPropagation();
          toggleMenuTDM();
        }}
      >
        <SvgEllipsisV
          style={{
            fontSize: 18,
          }}
        />
      </ButtonIcon>
      {openTDM ? (
        <Panel
          anchorEl={anchorElTDM.current}
          open={openTDM}
          onClose={closeMenuTDM}
          placement="bottom-end"
          popperProps={{
            style: {
              zIndex: 2200,
            },
          }}
          style={{
            minWidth: 138,
          }}
        >
          <MenuList color="dark">
            <MenuItem
              onClick={() => {
                navigator.clipboard.writeText(contentId).then(() => toast.message('Content ID copied to clipboard'));
                toggleMenuTDM();
              }}
              buttonProps={{
                startAdornment: (
                  <ButtonAdornment position="start">
                    <SvgCloneRegular />
                  </ButtonAdornment>
                ),
              }}
            >
              Copy ID
            </MenuItem>
            <MenuItem
              key="link-content"
              async={true}
              loading={loadingTDM}
              onClick={() => handleUnlinkSubmit()}
              buttonProps={{
                startAdornment: (
                  <ButtonAdornment position="start">
                    <SvgUnlink />
                  </ButtonAdornment>
                ),
              }}
            >
              Remove
            </MenuItem>
          </MenuList>
        </Panel>
      ) : null}

      {errorTDM ? <ErrorMessage error={errorTDM} /> : null}
    </div>
  );
}

export default ChildTDM;
