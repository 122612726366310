import React from 'react';

import { get } from 'lodash';

import { NexoyaProvider } from '../types/types';

import { useProvidersQuery } from '../graphql/provider/queryProviders';

import { emptyArr } from '../utils/array';

type ProvidersState = {
  activeProviders: NexoyaProvider[];
  providersMap: Map<number, NexoyaProvider>;
};
const ProvidersContext = React.createContext<any>(null);

function ProvidersProvider(props: any) {
  const [state, setState] = React.useState<ProvidersState>({
    activeProviders: [],
    providersMap: new Map(),
  });
  const { data, loading, error, refetch } = useProvidersQuery();
  const providers = get(data, 'providers', []);
  React.useEffect(() => {
    if (!loading && !emptyArr(providers)) {
      setState({
        activeProviders: providers.filter((p) => p.hasCollections),
        providersMap: new Map(providers.map((p) => [p.provider_id, p])),
      });
    }
  }, [providers, setState, loading]);

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  const values = React.useMemo(
    () => ({
      state,
      setState,
      refetch,
    }),
    [state, setState, refetch]
  );
  return <ProvidersContext.Provider value={values} {...props} />;
}

function useProviders() {
  const context = React.useContext(ProvidersContext);

  if (context === undefined) {
    throw new Error(`useProviders must be used within ProvidersProvider`);
  }

  const { state, refetch } = context;
  const providerById = React.useCallback(
    (providerId: number): NexoyaProvider | {} => {
      const provider = state.providersMap.get(providerId);

      if (!provider) {
        // eslint-disable-next-line no-console
        console.warn(`useProviders: we could not find such provider (providerId:${providerId})`);
        return {};
      }

      return provider;
    },
    [state]
  );
  return { ...state, providerById, refetch };
}

export { ProvidersProvider, useProviders };
