import React from 'react';

import { useMutation } from '@apollo/client';
import { toast } from 'sonner';

import { NexoyaImpactGroup, NexoyaPortfolioContentDetail } from 'types';

import { useTeam } from '../../../../context/TeamProvider';
import { ASSIGN_IMPACT_GROUP_TO_PORTFOLIO_CONTENT_MUTATION } from '../../../../graphql/impactGroups/mutationAssignImpactGroupToPortfolioContent';
import { PORTFOLIO_QUERY } from '../../../../graphql/portfolio/queryPortfolio';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';

import Typography from '../../../../components/Typography';
import MenuList from 'components/ArrayMenuList';
import Button from 'components/Button';
import ButtonAdornment from 'components/ButtonAdornment';
import { useDropdownMenu } from 'components/DropdownMenu';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import { updateApolloCache, updatePortfolioContentDetailsImpactGroupCache } from '../../../../utils/cache';
import { ChevronsUpDown } from 'lucide-react';
import { usePortfolio } from '../../../../context/PortfolioProvider';

interface Props {
  content: NexoyaPortfolioContentDetail;
  portfolioId: number;
  dateFrom: Date;
  dateTo: Date | string;
}
export function ContentImpactGroupPanel({ content, portfolioId, dateFrom, dateTo }: Props) {
  const { teamId } = useTeam();
  const anchorElPanel = React.useRef(null);
  const {
    anchorEl: anchorElImpactGroup,
    open: openImpactGroup,
    closeMenu: closeImpactGroupMenu,
    toggleMenu,
  } = useDropdownMenu();

  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const impactGroups = portfolioMeta?.impactGroups;

  const [assignImpactGroupToPortfolioContent, { loading }] = useMutation(
    ASSIGN_IMPACT_GROUP_TO_PORTFOLIO_CONTENT_MUTATION,
  );

  const onClickListener = React.useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      const containsInstance = anchorElPanel.current && anchorElPanel.current.contains(event.target);
      if (containsInstance) {
        return toggleMenu();
      }
      return closeImpactGroupMenu();
    },
    [anchorElPanel, toggleMenu, closeImpactGroupMenu],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', onClickListener);
    return function () {
      document.removeEventListener('mousedown', onClickListener);
    };
  }, []);

  function handleAddContentToImpactGroup(impactGroup: NexoyaImpactGroup) {
    assignImpactGroupToPortfolioContent({
      notifyOnNetworkStatusChange: false,
      variables: {
        contentIds: [content.contentId],
        impactGroupId: impactGroup.impactGroupId,
        portfolioId,
        teamId,
      },
    })
      .then(({ data }) => {
        if (data?.assignImpactGroupToPortfolioContents) {
          // Use updateQuery to read and modify the cache in one step
          updateApolloCache({
            query: PORTFOLIO_QUERY,
            variables: {
              teamId,
              portfolioId,
              dateFrom,
              dateTo,
              withBudget: false,
            },
            updateFn: updatePortfolioContentDetailsImpactGroupCache({
              contentId: content?.contentId,
              impactGroup,
              portfolioId,
            }),
          });

          closeImpactGroupMenu();
          track(EVENT.CONTENT_CHANGE_IMPACT_GROUP, {
            contentId: content.contentId,
            impactGroupId: impactGroup.impactGroupId,
          });
        }
      })
      .catch((err) => {
        toast(err.message);
        console.error(err);
      });
  }

  return (
    <>
      <Button
        variant="text"
        color="tertiary"
        flat
        type="button"
        onClick={toggleMenu}
        active={openImpactGroup}
        className="NEXYButtonMetric !rounded-md !px-1.5 !py-0.5 hover:bg-seasalt"
        loading={loading}
        endAdornment={
          <ButtonAdornment position="end">
            <ChevronsUpDown className="h-4 w-4" />
          </ButtonAdornment>
        }
        ref={anchorElImpactGroup}
      >
        {content?.impactGroup?.name}
      </Button>
      <Panel
        open={openImpactGroup}
        color="dark"
        ref={anchorElPanel}
        anchorEl={anchorElImpactGroup.current}
        placement="bottom-start"
        style={{
          minWidth: 200,
          maxHeight: 500,
        }}
      >
        <MenuList color="dark">
          {impactGroups?.map((impactGroup: NexoyaImpactGroup) => (
            <MenuItem
              key={`impactGroup-${impactGroup.impactGroupId}`}
              loading={loading}
              disabled={loading}
              async
              style={{
                minWidth: 125,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleAddContentToImpactGroup(impactGroup);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography>{impactGroup.name}</Typography>
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </Panel>
    </>
  );
}
