import { NexoyaDailyMetric, NexoyaDailyProviderMetric, NexoyaFunnelStepPerformance } from '../../../types';

import { useLabels } from '../../../context/LabelsProvider';
import { usePortfolio } from '../../../context/PortfolioProvider';
import { PartialFunnelStep } from '../../../controllers/PortfolioController';

import {
  getDailyValuePerImpactGroup,
  getDailyValuePerLabel,
  getDailyValuePerProvider,
  getFilterSource,
  getOptiDailyValuePerImpactGroup,
  getOptiDailyValuePerLabel,
  getOptiDailyValuePerProvider,
} from '../../../utils/provider';
import { isInTheFuture, isToday } from 'utils/dates';
import { useImpactGroups } from '../../../context/ImpactGroupsProvider';

interface PerformanceChartDataPoint {
  timestamp: string;
  value: number;
  timestampComparison?: string;
  valueTimeComparison?: number;
  comparisonChangePercent?: number;
}

export function usePortfolioToPerformanceChartData(
  funnelSteps: NexoyaFunnelStepPerformance[],
  isActivePortfolio: boolean,
  selectedFunnelStep: PartialFunnelStep,
) {
  const {
    providers: { providersFilter },
    performanceChart: { isStackedAreaChartActive, compareTo: compareToRestOfData },
  } = usePortfolio();
  const {
    filter: { labelsFilter },
  } = useLabels();

  const {
    filter: { impactGroupsFilter },
  } = useImpactGroups();

  if (!funnelSteps || !selectedFunnelStep) {
    return {
      isActivePortfolio: null,
      dataForChart: null,
      realizedMetricDataPast: null,
      validationDataFormatted: null,
      funnelSteps: null,
      validationTooltip: null,
      selectedFunnelStep: null,
    };
  }

  const filteredProviderIds = providersFilter.map((provider) => provider.provider_id);
  const filteredLabelIds = labelsFilter.map((label) => label.labelId);
  const filteredImpactGroupIds = impactGroupsFilter.map((impactGroup) => impactGroup.impactGroupId);

  const targetFunnelStep = funnelSteps?.find(
    (fs) => fs?.funnelStep?.funnelStepId === selectedFunnelStep?.funnel_step_id,
  );
  const dailyMetrics: NexoyaDailyMetric[] = targetFunnelStep?.dailyMetrics || [];
  const optimizationData = optimizationForGivenDate();

  const comparisonPerformanceChartData = dailyMetrics
    .map((dailyMetric) => {
      const providerSource = getFilterSource(
        dailyMetric,
        false,
        false,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      const value =
        providerSource.reduce((acc, providerMetric) => acc + (providerMetric?.value?.value || 0), 0) || null;

      const comparisonValue = providerSource.reduce(
        (acc, providerMetric) => acc + (providerMetric.comparisonValue?.value || 0),
        0,
      );
      const comparisonChangePercent = comparisonValue ? ((value - comparisonValue) / comparisonValue) * 100 : 0;

      const dataPoint: PerformanceChartDataPoint = {
        timestamp: dailyMetric.day?.substring(0, 10),
        value,
      };

      if (dailyMetric.comparisonDay) {
        dataPoint.timestampComparison = dailyMetric.comparisonDay.substring(0, 10);
        dataPoint.valueTimeComparison = comparisonValue;
        dataPoint.comparisonChangePercent = comparisonChangePercent;
      }

      return dataPoint;
    })
    ?.filter((chartDataPoint) => {
      const isValueValid = !isNaN(chartDataPoint.value) && chartDataPoint.value !== Infinity;
      const isComparisonValueValid =
        chartDataPoint.valueTimeComparison === undefined ||
        (!isNaN(chartDataPoint.valueTimeComparison) && chartDataPoint.valueTimeComparison !== Infinity);

      return isValueValid && isComparisonValueValid;
    });

  function optimizationForGivenDate() {
    const targetPerformanceData = targetFunnelStep?.optimizationMetricTotals;
    return {
      optimizationPotentialValue: targetPerformanceData?.expectedTotal,
      optimizationPotentialPercentage: targetPerformanceData?.potentialPercentage,
    };
  }
  const getDataForChart = () => {
    const realizedData = dailyMetrics.map((dailyMetric) => {
      const providerSource = getFilterSource(
        dailyMetric,
        isStackedAreaChartActive,
        compareToRestOfData,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      const value = providerSource.reduce(
        (acc: number, providerMetric: NexoyaDailyProviderMetric) => acc + providerMetric.value?.value,
        0,
      );

      return {
        timestamp: dailyMetric.day,
        value,
        ...getDailyValuePerProvider(dailyMetric),
        ...getDailyValuePerLabel(dailyMetric),
        ...getDailyValuePerImpactGroup(dailyMetric),
        ...optimizationData,
      };
    });

    const optimizationMetrics = (targetFunnelStep?.dailyOptimizationMetrics || []).map((dailyMetric) => {
      const filteredProviders = getFilterSource(
        dailyMetric,
        isStackedAreaChartActive,
        compareToRestOfData,
        filteredProviderIds,
        filteredLabelIds,
        filteredImpactGroupIds,
      );

      let baselinePerformanceRelative = 0;
      let expectedPerformanceRelative = 0;

      filteredProviders?.forEach((providerMetric) => {
        baselinePerformanceRelative += providerMetric.relativeBaseline;
        expectedPerformanceRelative += providerMetric.relativeExpected;
      });

      return {
        timestamp: dailyMetric.day.substring(0, 10),
        baselinePerformanceRelative,
        expectedPerformanceRelative,
        ...getOptiDailyValuePerProvider(dailyMetric),
        ...getOptiDailyValuePerLabel(dailyMetric),
        ...getOptiDailyValuePerImpactGroup(dailyMetric),
      };
    });

    // Check if `expectedPerformanceRelative` is same for all days
    const allSamePerformance = optimizationMetrics.every(
      (item) => item.expectedPerformanceRelative === optimizationMetrics[0].expectedPerformanceRelative,
    );
    let expectedData: string | any[];

    if (allSamePerformance) {
      // create a copy of the original object to avoid directly mutating original data
      const firstItem = { ...optimizationMetrics[0] };
      firstItem.baselinePerformanceRelative = firstItem.expectedPerformanceRelative;

      expectedData = [firstItem, optimizationMetrics[optimizationMetrics.length - 1]];
    } else {
      expectedData = optimizationMetrics;
    }

    function mergeRealizedDataAndExpectedData(realizedData, expectedData) {
      if (isToday(expectedData?.[0]?.timestamp) || isInTheFuture(expectedData?.[0]?.timestamp)) {
        // if optimization starts today or tomorrow, we artificially extend metric data past,
        // so there is no gap between the data points of two chart lines
        realizedData.push({
          timestamp: expectedData?.[0]?.timestamp,
          value: expectedData?.[0]?.expectedPerformanceRelative,
          optimizationTooltipDisabled: true,
        });
      }

      return [...realizedData, ...expectedData];
    }

    return expectedData?.length ? mergeRealizedDataAndExpectedData(realizedData, expectedData) : realizedData;
  };

  return {
    isActivePortfolio,
    dataForChart: getDataForChart(),
    realizedMetricDataPast: dailyMetrics,
    comparisonPerformanceChartData,
    validationDataFormatted: null,
    validationTooltip: null,
    funnelSteps,
    selectedFunnelStep,
  };
}
