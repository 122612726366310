import styled from 'styled-components';

import MenuList from 'components/ArrayMenuList';
import ButtonIcon from 'components/ButtonIcon';
import { useMenu } from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Panel from 'components/Panel';
import Spinner from 'components/Spinner';
import SvgEllipsisV from 'components/icons/EllipsisV';

import { ExtendedLabel } from './LabelsEditTable';

const ActionWrapStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SvgEllipsisVStyled = styled(SvgEllipsisV)`
  font-size: 18px;
`;

interface Props {
  loading: boolean;
  label: ExtendedLabel;
  handleDelete: (labelId: number) => void;
  handleEdit: (label: ExtendedLabel) => void;
}

export function LabelsTableTDM({ label, loading, handleDelete, handleEdit }: Props) {
  const { anchorEl, open, toggleMenu, closeMenu } = useMenu();
  return (
    <div ref={anchorEl}>
      <ActionWrapStyled>
        {loading ? (
          <div style={{ width: '36px' }}>
            <Spinner size="20px" />
          </div>
        ) : (
          <ButtonIcon onClick={toggleMenu} active={open} style={{ position: 'static' }}>
            <SvgEllipsisVStyled />
          </ButtonIcon>
        )}
      </ActionWrapStyled>
      <Panel
        container={anchorEl.current}
        anchorEl={anchorEl.current}
        open={open}
        onClose={closeMenu}
        placement="bottom-end"
        style={{
          minWidth: 138,
        }}
      >
        <MenuList color="dark">
          <MenuItem
            key="edit-impact-group"
            onClick={() => {
              handleEdit(label);
              toggleMenu();
            }}
            color="dark"
          >
            Edit name
          </MenuItem>
          <MenuItem
            key="remove-impact-group"
            onClick={() => {
              handleDelete(label.labelId);
              toggleMenu();
            }}
            color="dark"
          >
            Remove
          </MenuItem>
        </MenuList>
      </Panel>
    </div>
  );
}
