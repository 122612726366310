import { Match, withRouter } from 'react-router-dom';

import { get } from 'lodash';
import styled from 'styled-components';

import { NexoyaPortfolioContentDetail } from '../../../../types';

import { useTeam } from '../../../../context/TeamProvider';
import { useRemovePortfolioContentMutation } from 'graphql/portfolio/mutationRemovePortfolioContent';
import { PORTFOLIO_QUERY } from 'graphql/portfolio/queryPortfolio';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';
import removeApolloCacheKeys from 'utils/removeApolloCacheKeys';

import Button from '../../../../components/Button';
import ButtonAsync from '../../../../components/ButtonAsync';
import Dialog from '../../../../components/Dialog';
import DialogActions from '../../../../components/DialogActions';
import DialogContent from '../../../../components/DialogContent';
import DialogTitle from '../../../../components/DialogTitle';
import ErrorMessage from '../../../../components/ErrorMessage';
import Text from '../../../../components/Text';
import Typography from '../../../../components/Typography';

import { colorByKey } from '../../../../theme/utils';

type Props = {
  isOpen: boolean;
  toggleDialog: () => void;
  item: NexoyaPortfolioContentDetail;
  match: Match;
  dateFrom?: Date;
  dateTo?: Date;
};
const StyledEmoji = styled.span`
  display: block;
  font-size: 48px;
  line-height: 48px;
  margin-top: 32px;
  margin-bottom: 20px;
`;
const StyledTitle = styled.span`
  font-weight: 500;
  color: ${colorByKey('greenTeal')};
  display: inline-block;
`;

function ContentRemoveDialog({ item, isOpen, toggleDialog, match, dateFrom, dateTo }: Props) {
  const { teamId } = useTeam();

  const portfolioId = parseInt(match.params.portfolioID, 10);
  //@ts-expect-error
  const [removeContent, { loading, error }] = useRemovePortfolioContentMutation({
    portfolioId,
    portfolioContentIds: [item.portfolioContentId],
    additionalOptions: {
      refetchQueries: [
        {
          query: PORTFOLIO_QUERY,
          variables: {
            teamId,
            dateTo,
            dateFrom,
            portfolioId,
            withBudget: true,
            withOptimization: true,
          },
        },
      ],
      update: (cache) => removeApolloCacheKeys(cache, 'portfolio'),
    },
  });
  async function handleSubmit() {
    try {
      //@ts-expect-error
      const res = await removeContent();
      if (get(res, 'data.removePortfolioContent', null)) {
        toggleDialog();
        track(EVENT.CONTENT_DELETE, {
          contentId: item.contentId,
          portfolioContentId: item.portfolioContentId,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={toggleDialog}
        paperProps={{
          style: {
            width: 455,
            textAlign: 'center',
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Text component="h3" withEllipsis={false}>
            <StyledEmoji role="img" aria-label="thinking-face emoji">
              🤔
            </StyledEmoji>
            <Typography variant="h2" withEllipsis={false}>
              Are you sure?
            </Typography>
          </Text>
        </DialogTitle>
        <DialogContent>
          <p id="alert-dialog-description">
            Do you really want to delete <StyledTitle>{item.content?.title}</StyledTitle> content?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              toggleDialog();
            }}
            disabled={loading}
            variant="contained"
          >
            Cancel
          </Button>
          <ButtonAsync
            disabled={loading}
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
            color="danger"
            autoFocus
          >
            Delete
          </ButtonAsync>
        </DialogActions>
      </Dialog>
      {error ? <ErrorMessage error={error} /> : null}
    </>
  );
}

export default withRouter(ContentRemoveDialog);
