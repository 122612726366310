import { gql, useQuery } from '@apollo/client';

import { NexoyaBudgetProposal, NexoyaQueryOptimizationBudgetProposalArgs } from 'types';

import { useTeam } from 'context/TeamProvider';

import { BUDGET_MANAGER_FRAGMENT } from './fragments';

const OPTIMIZATION_BUDGET_PROPOSAL_QUERY = gql`
  query OptimizationBudgetProposal($teamId: Int!, $optimizationId: Int!) {
    optimizationBudgetProposal(teamId: $teamId, optimizationId: $optimizationId) {
      ...BudgetProposalFragment
    }
  }
  ${BUDGET_MANAGER_FRAGMENT}
`;

type Props = {
  optimizationId: number;
};
export function useOptimizationBudgetProposalQuery({ optimizationId }: Props) {
  const { teamId } = useTeam();

  const query = useQuery<
    {
      optimizationBudgetProposal: NexoyaBudgetProposal | null;
    },
    NexoyaQueryOptimizationBudgetProposalArgs
  >(OPTIMIZATION_BUDGET_PROPOSAL_QUERY, {
    variables: {
      teamId,
      optimizationId,
    },
  });

  return query;
}
