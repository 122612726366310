const SvgCostPerSymbol = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 41 40"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <circle opacity={0.1} cx={20.447} cy={20} r={20} fill="#05A8FA" />
    <path
      d="M25.738 14.19s-1.548-2.095-5.238-2.095c-7.333 0-7.333 8.381 0 8.381s7.333 8.381 0 8.381c-3.143 0-5.238-2.095-5.238-2.095M20.5 10v20.952"
      stroke="#05A8FA"
      strokeWidth={3.143}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCostPerSymbol;
