import React from 'react';
import { Location } from 'react-router-dom';
import 'react-router-dom';

import { InitContext, InitCtx } from '../context/InitProvider';

import auth from '../Auth/Auth';
import { PATHS } from './paths';

type Props = {
  location: Location;
};

const Authenticate = ({ location }: Props) => {
  const { initializing, setAuthSuccess } = React.useContext<InitCtx>(InitContext);
  React.useEffect(() => {
    if (location.pathname === PATHS.AUTH.CALLBACK) {
      return;
    }

    if (!auth.isAuthenticated()) {
      auth.login(location.pathname === PATHS.AUTH.SIGN_UP);
      return;
    }

    if (initializing) {
      setAuthSuccess();
    }
  }, [location, initializing, setAuthSuccess]);
  return null;
};

export default Authenticate;
