import { RouterHistory, withRouter } from 'react-router-dom';

import get from 'lodash/get';
import { nexyColors } from 'theme';

import { NexoyaPortfolioV2 } from 'types';

import { useFlushCacheMutation } from 'graphql/cache/mutationFlushCache';
import { useCopyPortfolioMutation } from 'graphql/portfolio/mutationCopyPortfolio';

import Button from '../../components/Button';
import ShareDialog from '../../components/Share/ShareDialog';
import Share from '../../components/icons/Share';
import ButtonAdornment from 'components/ButtonAdornment';
import { useDialogState } from 'components/Dialog';
import Menu, { useDropdownMenu } from 'components/DropdownMenu';
import MenuItem from 'components/MenuItem';
import { useSidePanelState } from 'components/SidePanel';
import SvgClone from 'components/icons/CloneRegular';
import SvgEllipsisV from 'components/icons/EllipsisV';
import SvgPencil from 'components/icons/Pencil';
import SvgTrash from 'components/icons/Trash';
import SvgWarning from 'components/icons/Warning';
import { buildPortfolioPath } from 'routes/paths';

import PortfolioDeleteDialog from './PortfolioDeleteDialog';
import PortfolioEditMetaSidepanel from './PortfolioEditMetaSidepanel';
import { PortfolioFeatureFlagsManagementDialog } from './PortfolioFeatureFlagsManagementDialog';
import React from 'react';
import { Flag, RefreshCcw } from 'lucide-react';
import { useUserQuery } from '../../graphql/user/queryUser';
import { useRefreshPortfolioContentMeasurementsMutation } from 'graphql/portfolio/mutationRefreshPortfolioContentMeasurements';
import { toast } from 'sonner';

type Props = {
  portfolio: NexoyaPortfolioV2;
  history: RouterHistory;
};

function PortfolioHeaderMenu({ portfolio, history }: Props) {
  const { open, closeMenu, toggleMenu, anchorEl } = useDropdownMenu();
  const { data: userData } = useUserQuery();
  const isSupportUser = userData?.user?.activeRole?.name?.includes('support');

  const { isOpen: isDeleteOpen, toggleDialog: toggleDelete } = useDialogState({
    initialState: false,
  });
  const { isOpen: isFeatureFlagsDialpgOpen, toggleDialog: toggleFeatureFlagsDialog } = useDialogState({
    initialState: false,
  });
  const { isOpen: isShareOpen, toggleDialog: toggleShare } = useDialogState({
    initialState: false,
  });
  const { isOpen: isEditOpen, toggleSidePanel: toggleEdit } = useSidePanelState();
  const [copyPortfolio] = useCopyPortfolioMutation({
    portfolioId: portfolio?.portfolioId,
  });

  const [flushCache] = useFlushCacheMutation({
    portfolioId: portfolio?.portfolioId,
  });

  const { refreshPortfolioContentMeasurements } = useRefreshPortfolioContentMeasurementsMutation({
    portfolioId: portfolio?.portfolioId,
  });

  async function handleCopy() {
    try {
      const res = await copyPortfolio();
      const portfolioId = get(res, 'data.copyPortfolio.portfolioId', null);

      if (portfolioId) {
        history.push(buildPortfolioPath(portfolioId));
        // refresh the page in order to have
        // a proper selectedFunnelStep in place
        history.go(0);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  async function handleCacheFlush() {
    try {
      await flushCache();
      toggleMenu();
      setTimeout(() => window?.location?.reload(), 500);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  async function handleFetchLast30Days() {
    try {
      toast.success('Started fetching last 30 days measurements');
      await refreshPortfolioContentMeasurements();
    } catch (error) {
      toast.error(`Failed to fetch last 30 days: ${error.message}`);
    }
  }

  if (!portfolio) {
    return;
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startAdornment={
          <ButtonAdornment>
            <SvgPencil
              style={{
                fill: 'none',
                stroke: 'currentColor',
                fontSize: 16,
                marginRight: 6,
                marginLeft: -4,
              }}
            />
          </ButtonAdornment>
        }
        onClick={toggleEdit}
      >
        Edit
      </Button>
      <div ref={anchorEl}>
        <Button
          variant="contained"
          color="secondary"
          startAdornment={
            <ButtonAdornment>
              <SvgEllipsisV
                style={{
                  fontSize: 18,
                  marginRight: 4,
                  marginLeft: -4,
                }}
              />
            </ButtonAdornment>
          }
          active={open}
          onClick={toggleMenu}
          data-cy="portfolioHeaderMenuButton"
        >
          More
        </Button>
      </div>
      <Menu
        container={anchorEl.current}
        anchorEl={anchorEl.current}
        open={open}
        onClose={closeMenu}
        placement="bottom-end"
        color="dark"
      >
        <MenuItem
          key="edit-portfolio2"
          data-cy="edit-portfolio2"
          onClick={toggleShare}
          buttonProps={{
            startAdornment: (
              <ButtonAdornment position="start">
                <Share />
              </ButtonAdornment>
            ),
          }}
        >
          Share
        </MenuItem>
        <MenuItem
          key="invalidate-cache"
          onClick={handleCacheFlush}
          buttonProps={{
            startAdornment: (
              <ButtonAdornment position="start">
                <SvgWarning />
              </ButtonAdornment>
            ),
          }}
        >
          Hard Refresh
        </MenuItem>
        <MenuItem
          key="copy-portfolio2"
          data-cy="copy-portfolio2"
          onClick={() => {
            closeMenu();
            handleCopy();
          }}
          buttonProps={{
            startAdornment: (
              <ButtonAdornment position="start">
                <SvgClone />
              </ButtonAdornment>
            ),
          }}
        >
          Duplicate
        </MenuItem>
        {isSupportUser ? (
          <MenuItem
            key="feature-flags-portfolio"
            data-cy="feature-flags-portfolio"
            onClick={() => {
              closeMenu();
              toggleFeatureFlagsDialog();
            }}
            buttonProps={{
              startAdornment: (
                <ButtonAdornment position="start">
                  <Flag className="h-4 w-4" />
                </ButtonAdornment>
              ),
            }}
          >
            Feature flags
          </MenuItem>
        ) : null}
        {isSupportUser ? (
          <MenuItem
            key="fetch-last-30-days"
            data-cy="fetch-last-30-days"
            onClick={() => {
              closeMenu();
              handleFetchLast30Days();
            }}
            buttonProps={{
              startAdornment: (
                <ButtonAdornment position="start">
                  <RefreshCcw className="h-4 w-4" />
                </ButtonAdornment>
              ),
            }}
          >
            Fetch last 30 days
          </MenuItem>
        ) : null}

        <MenuItem
          key="delete-portfolio2"
          data-cy="delete-portfolio2"
          onClick={() => {
            closeMenu();
            toggleDelete();
          }}
          buttonProps={{
            startAdornment: (
              <ButtonAdornment position="start">
                <SvgTrash />
              </ButtonAdornment>
            ),
            style: {
              color: nexyColors.orangeyRed,
            },
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {isSupportUser ? (
        <PortfolioFeatureFlagsManagementDialog
          open={isFeatureFlagsDialpgOpen}
          toggleDialog={toggleFeatureFlagsDialog}
          activeFeatureFlags={portfolio.featureFlags}
          portfolioId={portfolio?.portfolioId}
        />
      ) : null}
      <PortfolioDeleteDialog isOpen={isDeleteOpen} toggleDialog={toggleDelete} portfolio={portfolio} />
      <PortfolioEditMetaSidepanel isOpen={isEditOpen} onClose={toggleEdit} portfolio={portfolio} />
      <ShareDialog isOpen={isShareOpen} toggleDialog={toggleShare} type="portfolio" itemId={portfolio?.portfolioId} />
    </>
  );
}

export default withRouter(PortfolioHeaderMenu);
