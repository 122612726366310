import { gql, useMutation } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';

import { useTeam } from 'context/TeamProvider';

const REMOVE_PORTFOLIO_CONTENT_MUTATION = gql`
  mutation RemovePortfolioContent($teamId: Int!, $portfolioId: Int!, $portfolioContentIds: [Int!]!) {
    removePortfolioContent(teamId: $teamId, portfolioId: $portfolioId, portfolioContentIds: $portfolioContentIds)
  }
`;

type Props = {
  portfolioId: number;
  portfolioContentIds: number[];
  additionalOptions?: OperationVariables;
};
const useRemovePortfolioContentMutation = ({ portfolioId, portfolioContentIds, additionalOptions }: Props) => {
  const { teamId } = useTeam();
  const [mutation, state] = useMutation(REMOVE_PORTFOLIO_CONTENT_MUTATION, {
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      portfolioId,
      portfolioContentIds,
    },
    ...additionalOptions,
  });

  return [mutation, state];
};

export { REMOVE_PORTFOLIO_CONTENT_MUTATION, useRemovePortfolioContentMutation };
