import { RouterHistory, withRouter } from 'react-router-dom';

import { get } from 'lodash';

import { NexoyaDashboardUrl } from 'types';

import { useTeamQuery } from 'graphql/team/queryTeam';
import { useUserQuery } from 'graphql/user/queryUser';

import usePresenterMode from 'hooks/usePresenterMode';
import { mergeQueryState } from 'utils/graphql';

import CustomDashboard from '../components/CustomDashboard';
import OldDashboard from '../components/Dashboard/OldDashboard';
import Spinner from '../components/Spinner';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MainContent from 'components/MainContent';
import ScrollToTop from 'components/ScrollToTop';

import { PATHS } from './paths';

type Props = {
  history: RouterHistory;
};

const Dashboard = ({ history }: Props) => {
  const userData = useUserQuery();
  const teamData = useTeamQuery({
    withMembers: true,
  });
  const { isPresenterMode, loading: isPresenterModeInfoLoading } = usePresenterMode();
  const { error } = mergeQueryState(userData, teamData);

  if (isPresenterMode && !isPresenterModeInfoLoading) {
    history.push(PATHS.APP.REPORTS);
  }
  const customDashboardUrls: NexoyaDashboardUrl[] = get(teamData, 'data.team.dashboardUrls', null);
  const hasCustomDashboards = customDashboardUrls && !!customDashboardUrls.length;

  if (teamData.loading || userData.loading) {
    return (
      <div className="flex justify-center items-center h-[100vh]">
        <Spinner />;
      </div>
    );
  }

  return (
    <ScrollToTop>
      <MainContent className="sectionToPrint">
        {!teamData.loading && hasCustomDashboards ? <CustomDashboard data={customDashboardUrls} /> : <OldDashboard />}
      </MainContent>
      {error ? <ErrorMessage error={error} /> : null}
    </ScrollToTop>
  );
};

export default withRouter(Dashboard);
