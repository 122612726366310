import React, { useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'sonner';
import { NumberParam, useQueryParam } from 'use-query-params';

import { NexoyaSimulation, NexoyaSimulationState } from '../../../../types';

import { useTeam } from '../../../../context/TeamProvider';
import { useArchiveSimulationMutation } from '../../../../graphql/simulation/mutationArchiveSimulation';
import { useRunSimulationMutation } from '../../../../graphql/simulation/mutationRunSimulation';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { getFormattedState } from '../../utils/simulation';

import { useDialogState } from '../../../../components/Dialog';
import FormattedCurrency from '../../../../components/FormattedCurrency';
import GridHeader from '../../../../components/GridHeader';
import GridWrap from '../../../../components/GridWrap';
import NumberValue from '../../../../components/NumberValue';
import * as Styles from '../../../../components/PerformanceTable/styles';
import Typography from '../../../../components/Typography';

import { TagStyled } from '../../styles/OptimizationProposal';

import { TypographyStyled, TypographyStyledAligned } from '../TargetItem/TargetItemsTable';
import SimulationArchiveDialog from './SimulationArchiveDialog';
import SimulationEdit from './SimulationEdit';
import SimulationReviewCreateDialog from './SimulationReviewCreateDialog';
import { SimulationTDM } from './SimulationTDM';
import { ClickableGridRow } from './styles';
import styled from 'styled-components';

export function SimulationTable({
  simulations,
  portfolioId,
}: {
  simulations: NexoyaSimulation[];
  portfolioId: number;
}) {
  const [selectedSimulation, setSelectedSimulation] = useState<NexoyaSimulation>();
  const [, setSimulationId] = useQueryParam('simulationId', NumberParam);

  const { teamId } = useTeam();
  const {
    isOpen: isDialogStartSimulationOpen,
    openDialog: openRunDialog,
    closeDialog: closeRunDialog,
  } = useDialogState({
    initialState: false,
  });
  const {
    isOpen: isDialogArchiveOpen,
    openDialog: openArchiveDialog,
    closeDialog: closeArchiveDialog,
  } = useDialogState({
    initialState: false,
  });
  const {
    isOpen: isEditOpen,
    openDialog: openEdit,
    closeDialog: closeEdit,
  } = useDialogState({
    initialState: false,
  });

  const { runSimulation, loading: runSimulationLoading } = useRunSimulationMutation({ portfolioId });
  const { archiveSimulation, loading: archiveSimulationLoading } = useArchiveSimulationMutation({ portfolioId });

  const handleStartSimulation = (simulationId: number) => {
    runSimulation({
      variables: {
        teamId: teamId,
        portfolioId: portfolioId,
        simulationId: simulationId,
      },
    })
      .then(() => {
        closeRunDialog();
        toast.success(`Simulation ${selectedSimulation.name} started`);
      })
      .catch((e) => toast.error(e.message));
  };

  const handleArchiveSimulation = (simulationId: number) => {
    archiveSimulation({
      variables: {
        teamId: teamId,
        portfolioId: portfolioId,
        simulationId: simulationId,
      },
    })
      .then(() => {
        closeArchiveDialog();
        toast.success(`Simulation ${selectedSimulation.name} deleted`);
      })
      .catch((e) => toast.error(e.message));
  };

  const handleSimulationClick = (simulation: NexoyaSimulation) => {
    switch (simulation.state) {
      case NexoyaSimulationState.Completed:
        setSimulationId(simulation.simulationId);
        track(EVENT.SIMULATION_EXPLORE, {
          simulationId: simulation.simulationId,
          state: simulation.state,
        });
        break;
      case NexoyaSimulationState.Expired:
        setSimulationId(simulation.simulationId);
        track(EVENT.SIMULATION_EXPLORE, {
          simulationId: simulation.simulationId,
          state: simulation.state,
        });
        break;
      case NexoyaSimulationState.Pending:
        setSelectedSimulation(simulation);
        openRunDialog();
        break;
    }
  };

  return (
    <WrapStyled style={{ marginTop: '3rem' }}>
      <GridWrap>
        <GridHeader style={{ justifyItems: 'start' }}>
          <TypographyStyled>
            <span>Name</span>
          </TypographyStyled>
          <TypographyStyledAligned>
            <span>Range</span>
          </TypographyStyledAligned>
          <TypographyStyledAligned>
            <span>Steps between scenarios</span>
          </TypographyStyledAligned>
          <TypographyStyledAligned>
            <span>Timeframe</span>
          </TypographyStyledAligned>
          <TypographyStyledAligned>
            <span>Created</span>
          </TypographyStyledAligned>
          <TypographyStyledAligned>
            <span>State</span>
          </TypographyStyledAligned>
        </GridHeader>

        {simulations?.map((simulation) => {
          const formattedState = getFormattedState(simulation.state);
          return (
            <ClickableGridRow
              disabled={simulation.state === NexoyaSimulationState.Running}
              onClick={() => handleSimulationClick(simulation)}
              style={{ justifyItems: 'start', padding: '12px 24px' }}
              key={simulation.simulationId}
            >
              <Styles.ContentRowStyled>
                <Typography withTooltip={simulation.name.length > 30} withEllipsis style={{ maxWidth: 220 }}>
                  {simulation.name}
                </Typography>
              </Styles.ContentRowStyled>
              <Styles.ContentRowStyled style={{ color: '#888a94' }}>
                <Typography>
                  <FormattedCurrency showDecimals={false} amount={simulation?.budget?.min} /> -{' '}
                  <FormattedCurrency showDecimals={false} amount={simulation?.budget?.max} />
                </Typography>
              </Styles.ContentRowStyled>
              <Styles.ContentRowStyled style={{ color: '#888a94' }}>
                <Typography style={{ display: 'flex', gap: 4 }}>
                  <NumberValue value={simulation.budget.stepSize} /> ({simulation.budget.stepCount} scenarios)
                </Typography>
              </Styles.ContentRowStyled>
              <Styles.ContentRowStyled style={{ color: '#888a94' }}>
                <Typography>
                  {dayjs(simulation.start).format(READABLE_FORMAT)} - {dayjs(simulation.end).format(READABLE_FORMAT)}
                </Typography>
              </Styles.ContentRowStyled>
              <Styles.ContentRowStyled style={{ color: '#888a94' }}>
                <Typography>{dayjs(simulation.createdAt).format(READABLE_FORMAT)}</Typography>
              </Styles.ContentRowStyled>
              <TagStyled bgColor={formattedState.color}>
                <Typography style={{ fontWeight: 500 }}>{formattedState.label}</Typography>
              </TagStyled>
              <Styles.ContentRowStyled>
                <div className="flex w-full justify-end">
                  <SimulationTDM
                    loading={runSimulationLoading || archiveSimulationLoading}
                    simulationId={simulation.simulationId}
                    state={simulation.state}
                    handleExplore={(simulationId) => {
                      setSimulationId(simulationId);
                      track(EVENT.SIMULATION_EXPLORE, {
                        simulationId: simulation.simulationId,
                        state: simulation.state,
                      });
                    }}
                    handleDelete={() => {
                      setSelectedSimulation(simulation);
                      openArchiveDialog();
                    }}
                    handleEdit={() => {
                      setSelectedSimulation(simulation);
                      openEdit();
                    }}
                    handleStart={() => {
                      setSelectedSimulation(simulation);
                      openRunDialog();
                    }}
                  />
                </div>
              </Styles.ContentRowStyled>
            </ClickableGridRow>
          );
        })}
      </GridWrap>
      {selectedSimulation && isDialogStartSimulationOpen ? (
        <SimulationReviewCreateDialog
          loading={runSimulationLoading}
          isOpen={isDialogStartSimulationOpen}
          onSaveForLater={null}
          onStartSimulation={() => handleStartSimulation(selectedSimulation.simulationId)}
          simulation={{
            end: selectedSimulation.end,
            start: selectedSimulation.start,
            min: selectedSimulation.budget.min,
            max: selectedSimulation.budget.max,
            name: selectedSimulation.name,
            budgetStepSize: selectedSimulation.budget.stepSize,
            scenarios: selectedSimulation.budget.stepCount,
            scenariosInput: {
              // @ts-ignore
              budgets: selectedSimulation.budget.steps.map((step) => ({
                budget: step.budget,
                isCustomScenario: step.isCustomScenario,
              })),
            },
          }}
          onClose={closeRunDialog}
          onGoBack={closeRunDialog}
        />
      ) : null}
      {selectedSimulation && isDialogArchiveOpen ? (
        <SimulationArchiveDialog
          loading={archiveSimulationLoading}
          isOpen={isDialogArchiveOpen}
          onArchiveSimulation={() => handleArchiveSimulation(selectedSimulation.simulationId)}
          simulation={{
            end: selectedSimulation.end,
            start: selectedSimulation.start,
            min: selectedSimulation.budget.min,
            max: selectedSimulation.budget.max,
            name: selectedSimulation.name,
            budgetStepSize: selectedSimulation.budget.stepSize,
            scenarios: selectedSimulation.budget.stepCount,
            scenariosInput: {
              // @ts-ignore
              budgets: selectedSimulation.budget.steps.map((step) => ({
                budget: step.budget,
                isCustomScenario: step.isCustomScenario,
                value: step.budget,
              })),
            },
          }}
          onClose={closeArchiveDialog}
          onGoBack={closeArchiveDialog}
        />
      ) : null}
      {selectedSimulation && isEditOpen ? (
        <SimulationEdit
          portfolioId={portfolioId}
          isSimulationEditOpen={isEditOpen}
          openSimulationEdit={openEdit}
          closeSimulationEdit={closeEdit}
          simulation={selectedSimulation}
        />
      ) : null}
    </WrapStyled>
  );
}

export const WrapStyled = styled.div<{
  extraColumn?: boolean;
}>`
  width: 100%;

  .NEXYCSSGrid {
    min-width: 100%;
    padding: 0 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.7fr 30px;
  }
`;
