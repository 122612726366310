import React from 'react';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import { NexoyaPortfolioTargetItem, NexoyaPortfolioType, NexoyaPortfolioV2 } from '../../types';

import { PORTFOLIO_FORMAT } from '../../utils/dates';

import { WrapBricksStyled } from '../../routes/Portfolio';
import * as Styles from '../../routes/portfolio/styles/Portfolio';

import { nexyColors } from '../../theme';
import FormattedCurrency from '../FormattedCurrency';
import { HeaderBrickWrap } from '../HeaderBrick';
import NumberValue from '../NumberValue';
import PercentCircle from '../PercentCircle';
import { PortfolioTargetTypeSwitch } from '../PortfolioTypeSwitch';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import SvgCostPerSymbol from '../icons/CostPerSymbol';
import SvgDuration from '../icons/Duration';
import SvgPortfolioDuotone from '../icons/PortfolioDuotone';
import SvgRoasSymbol from '../icons/RoasSymbol';
import SvgTarget from '../icons/Target';
import SvgTargetDuotone from '../icons/TargetDuotone';

const TargetContainerStyled = styled.div`
  .NEXYNumberValue {
    span {
      display: flex;
    }
  }
`;

export const PortfolioBricks = ({
  portfolio,
  currency,
  numberFormat,
  funnelStepTitle,
}: {
  portfolio: Partial<NexoyaPortfolioV2>;
  currency: string;
  numberFormat: string;
  funnelStepTitle: string;
}) => {
  const portfolioMetaBudget = portfolio?.budget;
  const portfolioType = portfolio?.type;
  const latestAchievedTargetItem: NexoyaPortfolioTargetItem = portfolio?.latestAchievedTargetItem;
  const isTargetPortfolio = portfolioType !== NexoyaPortfolioType.Budget;

  const activeTargetItem = portfolio?.activeTargetItem;

  const getPrimaryTargetStringBasedOnPortfolioType = () => {
    switch (portfolio?.type) {
      case NexoyaPortfolioType.Roas:
        return 'Ratio-per ';
      case NexoyaPortfolioType.CostPer:
        return 'Cost-per ';
      case NexoyaPortfolioType.Budget:
        return '';
      default:
        return '';
    }
  };

  const renderTargetPortfolioHeaderBricks = () => (
    <WrapBricksStyled>
      <HeaderBrickWrap>
        <Styles.HeaderBrickStyled
          icon={
            <SvgDuration
              style={{
                fontSize: 32,
              }}
            />
          }
          label="DURATION"
          content={`${dayjs(portfolio?.start).format(PORTFOLIO_FORMAT)} - ${dayjs(portfolio?.end)
            .endOf('day')
            .format(PORTFOLIO_FORMAT)}`}
          data-cy="portfolioDuration"
        />
        <Styles.HeaderBrickStyled
          icon={
            <SvgPortfolioDuotone
              style={{
                fontSize: 32,
              }}
            />
          }
          label="PORTFOLIO TYPE"
          content={<div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>Target-based</div>}
        />
        <Styles.HeaderBrickStyled
          icon={
            portfolioType === NexoyaPortfolioType.CostPer ? (
              <SvgCostPerSymbol
                style={{
                  fontSize: 32,
                }}
              />
            ) : (
              <SvgRoasSymbol
                style={{
                  fontSize: 32,
                }}
              />
            )
          }
          label="PORTFOLIO TARGET"
          content={getPrimaryTargetStringBasedOnPortfolioType() + capitalize(funnelStepTitle || '')}
        />
        <Styles.HeaderBrickStyled
          icon={
            <SvgTargetDuotone
              style={{
                fontSize: 32,
              }}
            />
          }
          label="LATEST ACHIEVED"
          content={
            activeTargetItem ? (
              <Tooltip
                variant="dark"
                size="small"
                content={
                  <>
                    <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Portfolio target:{' '}
                      <span style={{ color: nexyColors.greenTeal }}>
                        <PortfolioTargetTypeSwitch
                          renderForCPAType={() => <FormattedCurrency amount={latestAchievedTargetItem?.value} />}
                          renderForROASType={() => <NumberValue value={latestAchievedTargetItem?.value} symbol="%" />}
                        />
                      </span>
                    </Typography>
                    <Typography style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Latest achieved portfolio target:{' '}
                      {latestAchievedTargetItem ? (
                        <span style={{ color: nexyColors.greenTeal }}>
                          <PortfolioTargetTypeSwitch
                            renderForCPAType={() => <FormattedCurrency amount={latestAchievedTargetItem?.achieved} />}
                            renderForROASType={() => (
                              <NumberValue value={latestAchievedTargetItem?.achieved} symbol="%" />
                            )}
                          />
                        </span>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </>
                }
              >
                <TargetContainerStyled>
                  <Typography variant="h4" style={{ display: 'flex', alignItems: 'baseline', gap: 6 }}>
                    {latestAchievedTargetItem ? (
                      <PortfolioTargetTypeSwitch
                        renderForCPAType={() => <FormattedCurrency amount={latestAchievedTargetItem?.achieved} />}
                        renderForROASType={() => <NumberValue value={latestAchievedTargetItem?.achieved} symbol="%" />}
                      />
                    ) : (
                      <span>-</span>
                    )}

                    <span style={{ display: 'contents', color: nexyColors.secondaryText }}>
                      /{' '}
                      <PortfolioTargetTypeSwitch
                        renderForCPAType={() => <FormattedCurrency amount={latestAchievedTargetItem?.value} />}
                        renderForROASType={() => <NumberValue value={latestAchievedTargetItem?.value} symbol="%" />}
                      />
                    </span>
                  </Typography>
                </TargetContainerStyled>
              </Tooltip>
            ) : (
              <span style={{ color: nexyColors.coolGray, opacity: 0.5 }}>No active target</span>
            )
          }
        />
      </HeaderBrickWrap>
    </WrapBricksStyled>
  );

  const renderBudgetPortfolioHeaderBricks = () => (
    <WrapBricksStyled>
      <HeaderBrickWrap>
        <Styles.HeaderBrickStyled
          icon={
            <SvgDuration
              style={{
                fontSize: 32,
              }}
            />
          }
          label="DURATION"
          content={`${dayjs(portfolio?.start).format(PORTFOLIO_FORMAT)} - ${dayjs(portfolio?.end)
            .endOf('day')
            .format(PORTFOLIO_FORMAT)}`}
          data-cy="portfolioDuration"
        />
        <Styles.HeaderBrickStyled
          icon={
            <SvgPortfolioDuotone
              style={{
                fontSize: 32,
              }}
            />
          }
          label="PORTFOLIO TYPE"
          content="Budget-based"
        />
        <Styles.HeaderBrickStyled
          icon={
            <SvgTarget
              style={{
                fontSize: 32,
              }}
            />
          }
          label="PORTFOLIO TARGET"
          content={capitalize(funnelStepTitle || '')}
        />
        <Styles.HeaderBrickStyled
          icon={
            <div
              style={{
                width: 32,
                height: 32,
              }}
            >
              <PercentCircle
                percent={
                  portfolioMetaBudget?.spent?.totalSpent
                    ? (portfolioMetaBudget?.spent?.totalSpent / (portfolioMetaBudget.total ?? 100)) * 100
                    : 0
                }
                fixed={true}
                showNumber={false}
                icon={<Styles.SvgDollarStyled />}
              />
            </div>
          }
          label="SPENT"
          content={`${Intl.NumberFormat(numberFormat, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: 0,
          }).format(portfolioMetaBudget?.spent?.totalSpent || 0)} of ${Intl.NumberFormat(numberFormat, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: 0,
          }).format(portfolioMetaBudget?.total || 0)}`}
        />
      </HeaderBrickWrap>
    </WrapBricksStyled>
  );

  return isTargetPortfolio ? renderTargetPortfolioHeaderBricks() : renderBudgetPortfolioHeaderBricks();
};
