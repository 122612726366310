import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'sonner';
import { NumberParam, useQueryParams } from 'use-query-params';

import {
  NexoyaFunnelStepPerformance,
  NexoyaFunnelStepType,
  NexoyaFunnelStepV2,
  NexoyaValidationPerformance,
  NexoyaValidationReportRows,
} from '../../types';

import { usePortfolio, ValidationChartType } from '../../context/PortfolioProvider';
import { useFunnelStepsV2Query } from '../../graphql/portfolio/queryFunnelSteps';
import { useValidationQuery } from '../../graphql/portfolio/queryValidation';
import { useTeamQuery } from '../../graphql/team/queryTeam';
import { useUserQuery } from '../../graphql/user/queryUser';

import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';
import { PORTFOLIO_FEATURE_FLAGS } from '../../constants/featureFlags';
import { getFormattedDateSelector, GLOBAL_DATE_FORMAT } from '../../utils/dates';

import ButtonAdornment from '../../components/ButtonAdornment';
import ButtonAsync from '../../components/ButtonAsync';
import MultipleSwitch from '../../components/MultipleSwitchFluid';
import PortfolioFeatureSwitch from '../../components/PortfolioFeatureSwitch';
import SvgCheck from '../../components/icons/Check';
import SvgDownload from '../../components/icons/Download';
import SvgGauge from '../../components/icons/Gauge';
import DetailedReportDownload from './components/DetailedReport/DetailedReportDownload';
import { PredictedVsAchievedChart } from './components/DetailedReport/PredictedVsAchievedChart';
import { PredictionThresholdsChart } from './components/DetailedReport/Prediction/PredictionThresholdsChart';
import { ValidationSkeleton } from './components/DetailedReport/Prediction/ValidationSkeleton';
import { PredictionTimelineChart } from './components/DetailedReport/PredictionTimelineChart';
import { WhatIfValidation } from './components/DetailedReport/WhatIfValidation';
import { PredictionFunnel } from './components/Funnel/PredictionFunnel';
import { EmptyChartMessage } from './components/Funnel/styles';
import FunnelStepsDropdown from './components/FunnelStepsDropdown';

import NoDataFound from './NoDataFound';
import { usePredictionQuery } from '../../graphql/prediction/queryPrediction';

type Props = {
  portfolioId: number;
  dateFrom: Date;
  dateTo: Date;
};

export const VALIDATION_CHART_SECTIONS = [
  {
    id: 'time-based' as ValidationChartType,
    text: 'Time-based',
  },
  {
    id: 'achieved-predicted' as ValidationChartType,
    text: 'Achieved / Predicted',
  },
  {
    id: 'prediction-timeline' as ValidationChartType,
    text: 'Prediction timeline',
  },

  {
    id: 'prediction-details' as ValidationChartType,
    text: 'Prediction details',
  },
];

const getRenderConditions = ({ activePredictionChart, validationPerformance, predictionFunnelSteps }) => {
  const showFunnelDropdown = activePredictionChart === 'time-based' && validationPerformance.length;
  const showPredictionFunnel = ['prediction-details', 'achieved-predicted', 'prediction-timeline'].includes(
    activePredictionChart,
  );
  const showPredictionDetails = activePredictionChart === 'prediction-details';
  const showAchievedPredicted = activePredictionChart === 'achieved-predicted';
  const showPredictionTimeline = activePredictionChart === 'prediction-timeline';
  const showTimeBasedValidation = activePredictionChart === 'time-based';
  const hasPredictionData = predictionFunnelSteps?.length;

  return {
    showFunnelDropdown,
    showPredictionFunnel,
    showPredictionDetails,
    showAchievedPredicted,
    showPredictionTimeline,
    showTimeBasedValidation,
    hasPredictionData,
  };
};

export const Validation = ({ portfolioId, dateFrom, dateTo }: Props) => {
  const [download, setDownload] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useQueryParams({
    fs: NumberParam,
  });
  const {
    selectedFunnelStep: { selectedFunnelStep: partialSelectedFunnelStep, setSelectedFunnelStep },
    predictionChart: { setActivePredictionChart, activePredictionChart },
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const { data: userData } = useUserQuery();
  const teamQuery = useTeamQuery({
    withMembers: false,
    withOrg: false,
  });
  const { data: predictionData, loading: predictionLoading } = usePredictionQuery({
    portfolioId,
    start: dayjs(dateFrom).format(GLOBAL_DATE_FORMAT),
    end: dayjs(dateTo).format(GLOBAL_DATE_FORMAT),
  });

  const { data: funnelStepsData } = useFunnelStepsV2Query({
    portfolioId,
  });

  const { data, loading } = useValidationQuery({
    portfolioId,
    dateFrom,
    dateTo,
    funnelStepId: partialSelectedFunnelStep?.funnel_step_id || portfolioMeta?.defaultOptimizationTarget?.funnelStepId,
  });

  const isTotalSelected = partialSelectedFunnelStep?.funnel_step_id === -1;
  const numberFormat = teamQuery?.data?.team?.number_format || 'de-CH';
  const isSupportUser = userData?.user?.activeRole?.name?.includes('support');

  useEffect(() => {
    if (activePredictionChart === 'achieved-predicted' && isTotalSelected) {
      const firstFunnelStep = globalFunnelSteps[globalFunnelSteps.length - 1];
      setSelectedFunnelStep({
        title: firstFunnelStep?.title,
        funnel_step_id: firstFunnelStep.funnelStepId,
        type: firstFunnelStep.type,
      });
    }
  }, [activePredictionChart, isTotalSelected]);

  const onFunnelStepChange = useCallback(
    (funnelStep: NexoyaFunnelStepV2) => {
      setSelectedFunnelStep({
        title: funnelStep?.title,
        funnel_step_id: funnelStep.funnelStepId,
        type: funnelStep.type,
      });
      setQueryParams({ fs: funnelStep?.funnelStepId });
    },
    [setQueryParams, setSelectedFunnelStep],
  );

  const validation = data?.validation?.validation;
  const validationReport: NexoyaValidationReportRows[] = validation?.validationReport || [];
  const validationPerformance: NexoyaValidationPerformance[] = validation?.validationPerformance || [];

  const hasPortfolioWhatIfFeature = portfolioMeta?.featureFlags.some(
    (featureFlag) => PORTFOLIO_FEATURE_FLAGS.WHAT_IF_VALIDATION === featureFlag.name && featureFlag.status,
  );

  const totalPredictionData = predictionData?.portfolioV2?.prediction?.total;
  const predictionFunnelSteps = predictionData?.portfolioV2?.prediction?.funnelSteps;

  const globalFunnelSteps: NexoyaFunnelStepV2[] = funnelStepsData?.portfolioV2?.funnelSteps;
  const selectedFunnelStep = globalFunnelSteps?.find(
    (fs) => fs?.funnelStepId === partialSelectedFunnelStep?.funnel_step_id,
  );
  const selectedPredictionFunnelStep = useMemo(
    () => predictionFunnelSteps?.find((fs) => fs?.funnelStepId === partialSelectedFunnelStep?.funnel_step_id),
    [partialSelectedFunnelStep],
  );

  const {
    showAchievedPredicted,
    showFunnelDropdown,
    showPredictionDetails,
    showPredictionFunnel,
    showPredictionTimeline,
    showTimeBasedValidation,
    hasPredictionData,
  } = getRenderConditions({
    activePredictionChart,
    validationPerformance,
    predictionFunnelSteps,
  });

  useEffect(() => {
    if (activePredictionChart === 'time-based' && !hasPortfolioWhatIfFeature) {
      setActivePredictionChart('achieved-predicted');
    }
  }, [hasPortfolioWhatIfFeature]);

  useEffect(() => {
    if (isSupportUser && !hasPortfolioWhatIfFeature) {
      const infoToast = toast.message(
        'What-if validation is not enabled for this portfolio, but you can view it because you are a support user.',
        {
          important: true,
          action: {
            // @ts-ignore
            label: <SvgCheck />,
            onClick: () => toast.dismiss(infoToast),
          },
        },
      );
    }
  }, [isSupportUser, hasPortfolioWhatIfFeature]);

  useEffect(() => {
    if (globalFunnelSteps?.length) {
      let targetFunnelStepId = null;
      if (queryParams.fs) {
        targetFunnelStepId = globalFunnelSteps.some(
          (fs) => fs.type !== NexoyaFunnelStepType.Cost && fs.funnelStepId === queryParams.fs,
        )
          ? queryParams.fs
          : null;
      }

      if (!targetFunnelStepId) {
        targetFunnelStepId = portfolioMeta?.defaultOptimizationTarget?.funnelStepId;
      }

      const targetFunnelStep = globalFunnelSteps?.length
        ? globalFunnelSteps?.find((fs) => fs.funnelStepId === targetFunnelStepId) ||
          globalFunnelSteps[globalFunnelSteps.length - 1]
        : { title: '', funnelStepId: '', type: NexoyaFunnelStepType.Other };

      setSelectedFunnelStep({
        title: targetFunnelStep?.title,
        funnel_step_id: targetFunnelStep.funnelStepId,
        type: targetFunnelStep.type,
      });

      setQueryParams({ fs: targetFunnelStep?.funnelStepId as number });
    }
  }, [globalFunnelSteps, queryParams, portfolioId, predictionFunnelSteps]);

  const handleSwitchToggle = (value) => {
    setActivePredictionChart(value);
    track(EVENT.VALIDATION_SWITCH_VIEW(VALIDATION_CHART_SECTIONS.find((v) => v.id === value).text));
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PortfolioFeatureSwitch
          bypass={isSupportUser}
          features={[PORTFOLIO_FEATURE_FLAGS.WHAT_IF_VALIDATION]}
          renderNew={() => {
            return (
              <MultipleSwitch
                style={{ marginBottom: 24 }}
                current={activePredictionChart}
                sections={VALIDATION_CHART_SECTIONS}
                initial={activePredictionChart}
                onToggle={handleSwitchToggle}
              />
            );
          }}
          renderOld={() => (
            <MultipleSwitch
              style={{ marginBottom: 24 }}
              current={activePredictionChart}
              sections={VALIDATION_CHART_SECTIONS.slice(1)}
              initial={activePredictionChart}
              onToggle={handleSwitchToggle}
            />
          )}
        />

        {showFunnelDropdown ? (
          <div>
            <FunnelStepsDropdown initial={selectedFunnelStep} data={globalFunnelSteps} onChange={onFunnelStepChange} />
            <ButtonAsync
              size="small"
              variant="contained"
              loading={loading || predictionLoading || download}
              color="primary"
              disabled={loading || predictionLoading || download}
              onClick={() => {
                setDownload(true);
                track(EVENT.VALIDATION_DOWNLOAD_XLSX, {
                  portfolioId,
                });
              }}
              startAdornment={
                <ButtonAdornment position="start">
                  <SvgDownload />
                </ButtonAdornment>
              }
              style={{
                marginLeft: '8px',
              }}
            >
              Download report
            </ButtonAsync>
          </div>
        ) : null}
      </div>

      {predictionLoading && activePredictionChart !== 'time-based' ? (
        <ValidationSkeleton />
      ) : loading && activePredictionChart === 'time-based' ? (
        <ValidationSkeleton />
      ) : (
        <div style={{ display: 'flex' }}>
          {showPredictionFunnel ? (
            hasPredictionData ? (
              <PredictionFunnel
                totalPredictionData={totalPredictionData}
                predictionFunnelSteps={predictionFunnelSteps}
              />
            ) : null
          ) : null}
          {showPredictionDetails ? (
            hasPredictionData ? (
              <PredictionThresholdsChart
                selectedFunnelStepId={partialSelectedFunnelStep?.funnel_step_id}
                totalPredictionData={totalPredictionData}
                predictionFunnelSteps={predictionFunnelSteps}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            ) : (
              <NoDataFound
                icon={<SvgGauge />}
                title="There is no optimization within the selected time period"
                subtitle="Please select alternative dates from the date picker to view the optimization performance"
              />
            )
          ) : null}
          {showAchievedPredicted ? (
            !isTotalSelected ? (
              hasPredictionData ? (
                <PredictedVsAchievedChart data={selectedPredictionFunnelStep?.dailyPredictions} />
              ) : (
                <NoDataFound
                  icon={<SvgGauge />}
                  title="There is no optimization within the selected time period"
                  subtitle="Please select alternative dates from the date picker to view the optimization performance"
                />
              )
            ) : (
              <EmptyChartMessage>
                Select a funnel step from the funnel above to show achieved and predicted values.
              </EmptyChartMessage>
            )
          ) : null}
          {showPredictionTimeline ? (
            hasPredictionData ? (
              <PredictionTimelineChart
                data={
                  isTotalSelected
                    ? totalPredictionData?.dailyPredictionTotal
                    : selectedPredictionFunnelStep?.dailyPredictions
                }
              />
            ) : (
              <NoDataFound
                icon={<SvgGauge />}
                title="There is no optimization within the selected time period"
                subtitle="Please select alternative dates from the date picker to view the optimization performance"
              />
            )
          ) : null}
          <PortfolioFeatureSwitch
            bypass={isSupportUser}
            features={[PORTFOLIO_FEATURE_FLAGS.WHAT_IF_VALIDATION]}
            renderNew={() =>
              showTimeBasedValidation ? (
                <WhatIfValidation
                  loading={loading || predictionLoading}
                  funnelSteps={globalFunnelSteps}
                  validationPerformance={validationPerformance}
                  selectedFunnelStep={selectedFunnelStep}
                  portfolioName={portfolioMeta?.title}
                  showDetailsTable
                />
              ) : null
            }
            renderOld={() => null}
          />

          {download ? (
            <Suspense fallback={null}>
              <DetailedReportDownload
                data={validationReport}
                initialFunnelSteps={globalFunnelSteps as unknown as NexoyaFunnelStepPerformance[]}
                portfolioTitle={portfolioMeta?.title}
                // @ts-ignore
                duration={getFormattedDateSelector({ dateFrom, dateTo }, numberFormat)}
                onDone={() => setDownload(false)}
                onError={(err) => {
                  setDownload(false);
                  console.error(err);
                  toast.error('Something went wrong while downloading the report');
                }}
              />
            </Suspense>
          ) : null}
        </div>
      )}
    </>
  );
};
