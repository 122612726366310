import { Link } from 'react-router-dom';

import { get } from 'lodash';
import styled from 'styled-components';

import { useUserQuery } from '../../../graphql/user/queryUser';

import { track } from '../../../constants/datadog';
import { EVENT } from '../../../constants/events';
import { userInitials } from '../../../utils/user';

import { PATHS } from '../../../routes/paths';

import { sizes } from '../../../theme/device';
import { colorByKey } from '../../../theme/utils';

import auth from '../../../Auth/Auth';
import AvatarUser from '../../AvatarUser';
import ButtonBase from '../../ButtonBase';
import ListItemIcon from '../../ListItemIcon';
import { LaptopLUp } from '../../MediaQuery';
import Menu, { useMenu } from '../../Menu';
import MenuItem from '../../MenuItem';
import MenuList from '../../MenuList';
import Typography from '../../Typography';
import { ExternalLinkIcon, IntegrationIcon, PowerIcon } from '../../icons';
import SvgCog from '../../icons/Cog';

const ButtonBaseStyled = styled(ButtonBase)`
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 0.4px;

  color: ${colorByKey('blueyGrey')};

  margin-top: 16px;
  padding: 12px 8px !important;

  &:hover {
    color: ${colorByKey('charcoalGrey')};
    background: #f4f4f6;
  }
`;

const AvatarUserStyled = styled(AvatarUser)`
  margin-right: 8px;
  background: #e3e4e8;
  span {
    font-size: 12px;
  }

  @media (max-width: ${sizes.laptopL}px) {
    margin-right: 0;
  }
`;

const MenuStyled = styled(Menu)`
  color: #000000;
  display: inline-block;
  text-align: left;
  background: #fff;
  max-width: 100vw;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding-top: 8px;
`;

const UserInfoContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  font-size: 14px;
  p {
    color: #666;
    font-weight: 400;
    margin-top: 4px;
    line-height: 1.2;
  }
`;

const DividerStyled = styled.div`
  border-top: 1px solid #eaeaea;
  margin: 12px 0;
`;

const MenuItemStyled = styled(MenuItem)`
  display: flex;

  button {
    display: flow-root;
    text-align-last: start;
    margin: 0 !important;
    border-radius: 0;
    color: #666;
    background: #fff;
    padding: 8px 20px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: color.1s ease, background-color.1s ease;

    &:hover {
      background: #f5f5f5;
    }
  }
`;

const UserInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MenuItemWithIconStyled = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  color: #b0b1bd;
  font-weight: 400;
  font-size: 12px;
  max-width: 130px;
`;

function Account() {
  const { anchorEl, open, closeMenu, toggleMenu } = useMenu();
  const { data, loading } = useUserQuery({
    withTeams: true,
  });
  const user = get(data, 'user', {});

  function handleLogOut() {
    // TODO: Auth should be used through react hook.
    auth.logout();
  }

  if (loading) return null;

  return (
    <div>
      <ButtonBaseStyled ref={anchorEl} onClick={toggleMenu} data-cy="accountTopBarBtn">
        <AvatarUserStyled size={32} email={user.email} fallback={userInitials(user)} data-cy="userAvatarIcon" />
        <LaptopLUp>
          <UserInfoStyled>
            <Typography style={{ color: '#41424e', fontSize: 13 }}>
              {user.firstname} {user.lastname}
            </Typography>
            <StyledTypography>{user.email}</StyledTypography>
          </UserInfoStyled>
        </LaptopLUp>
      </ButtonBaseStyled>
      <MenuStyled
        container={anchorEl.current}
        anchorEl={anchorEl.current}
        open={open}
        onClose={closeMenu}
        placement="top"
      >
        <UserInfoContainerStyled>
          <p>
            {user.firstname} {user.lastname}
          </p>
          <p style={{ color: '#b0b1bd', fontWeight: 400 }}>{user.email}</p>
        </UserInfoContainerStyled>
        <MenuList
          style={{
            minWidth: 125,
          }}
        >
          <MenuItemStyled>
            <MenuItemWithIconStyled
              to={PATHS.APP.SETTINGS_INTEGRATIONS}
              onClick={() => {
                track(EVENT.ROUTE_SETTINGS_INTEGRATIONS);
                toggleMenu();
              }}
            >
              <span>Integrations</span>
              <ListItemIcon>
                <IntegrationIcon />
              </ListItemIcon>
            </MenuItemWithIconStyled>
          </MenuItemStyled>
          <MenuItemStyled>
            <MenuItemWithIconStyled
              to={PATHS.APP.SETTINGS}
              onClick={() => {
                track(EVENT.ROUTE_SETTINGS);
                toggleMenu();
              }}
            >
              <span>Settings</span>
              <ListItemIcon>
                <SvgCog />
              </ListItemIcon>
            </MenuItemWithIconStyled>
          </MenuItemStyled>
          <DividerStyled />
          <MenuItemStyled>
            <MenuItemWithIconStyled
              as="a"
              href="https://www.nexoya.com/help/"
              target="_blank"
              onClick={() => {
                toggleMenu();
              }}
            >
              <span>Nexoya help page</span>
              <ListItemIcon>
                <ExternalLinkIcon />
              </ListItemIcon>
            </MenuItemWithIconStyled>
          </MenuItemStyled>
          <DividerStyled />

          <MenuItemStyled data-cy="logOutBtn" onClick={handleLogOut}>
            <MenuItemWithIconStyled>
              <span>Log out</span>
              <ListItemIcon>
                <PowerIcon />
              </ListItemIcon>
            </MenuItemWithIconStyled>
          </MenuItemStyled>
        </MenuList>
      </MenuStyled>
    </div>
  );
}

export default Account;
