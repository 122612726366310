const SvgDollarInCircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Z"
      fill="#0EC76A"
      fillOpacity={0.11}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.587 13.768c0 1.485-.97 2.622-2.937 2.823v.683a.625.625 0 0 1-.62.615c-.377 0-.66-.28-.66-.615v-.67c-1.078-.093-2.399-.508-2.816-1.164a.806.806 0 0 1-.135-.428c0-.495.418-.883.89-.883.188 0 .363.054.525.16.461.317.985.53 1.536.63v-2.115c-1.388-.348-2.897-.856-2.897-2.676 0-1.352 1.078-2.503 2.897-2.69V6.73c0-.335.283-.616.66-.616.337 0 .62.281.62.616v.736c.795.093 1.94.401 2.33.977.081.12.135.267.135.414 0 .509-.485.87-.956.87a.926.926 0 0 1-.256-.04c-.182-.05-.375-.143-.572-.238-.227-.109-.458-.22-.681-.27v1.886c1.387.348 2.937.883 2.937 2.704Zm-4.219-3.038V9.098c-.606.08-.943.414-.943.87 0 .387.377.602.943.762Zm1.282 2.395c.58.187.997.415.997.856 0 .415-.336.803-.997.937v-1.793Z"
      fill="#0EC76A"
    />
  </svg>
);
export default SvgDollarInCircle;
