import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { v4 as uuidv4 } from 'uuid';

import auth from './Auth/Auth';
import { ENV_VARS_WRAPPER } from './configs/envVariables';

// this session id will be added to all graphql requests, this way we can track
// a full user session across all requests that a user makes while navigating our app
const sessionId = uuidv4();

const httpLink = new HttpLink({
  uri: `${window[ENV_VARS_WRAPPER]?.REACT_APP_API_BASE_URL || ''}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = auth.getAccessToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
      'x-nexoya-session-id': sessionId,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
  //In case user is unauthorized, clear local storage and redirect to log in.
  if (networkError && (networkError as ServerError).statusCode === 401) {
    auth.login();
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          portfolio: {
            merge: true,
          },
          portfolioV2: {
            merge: true,
          },
          team: {
            merge: true,
          },
          content: {
            merge: true,
          },
          portfolioV2Meta: {
            keyArgs: ['teamId', 'portfolioId'],
          },
        },
      },
      BudgetProposal: {
        keyFields: ['optimizationId'],
      },
    },
  }),
  link: from([authMiddleware, errorLink, httpLink]),
});

export default client;
