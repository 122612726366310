import * as Styles from 'components/Charts/styles/PortfolioPerformanceChart';

import CostPerLegend from './CostPerLegend';
import PerformanceLegend from './PerformanceLegend';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ComparisonPerformanceLegend } from './ComparisonPerformanceLegend';

type Props = {
  active: string;
  title: string;
};

function PortfolioChartLegend({ active, title }: Props) {
  const [dateComparisonActive] = useQueryParam('dateComparisonActive', BooleanParam);

  return (
    <Styles.LegendWrapper style={{ marginBottom: 24 }}>
      {dateComparisonActive ? (
        <ComparisonPerformanceLegend />
      ) : active === 'performance' ? (
        <PerformanceLegend />
      ) : (
        <CostPerLegend title={title} />
      )}
    </Styles.LegendWrapper>
  );
}

export default PortfolioChartLegend;
