const SvgMagixLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="2em"
    width="1em"
    viewBox="-52.728 -15.047 456.98 90.285"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <path d="m337.703 55.27-10.95-16.77-10.96 16.77h-13.82l17.13-25.47-16.39-24.47h13.82l10.22 16.05 10.22-16.05h13.82l-16.4 24.47 17.13 25.47zm-59.92-49.94h11.76v49.94h-11.76zM244.583 27v9.73h7.8v6.19c-1.18 1.07-5.33 2.5-8.42 2.5-9.19 0-15.52-5.92-15.52-15.12 0-10.49 7.4-15.12 15.86-15.12a19.61 19.61 0 0 1 10.46 3.74l6-8.85a28.91 28.91 0 0 0-18-5.6c-16.18 0-26.52 10.44-26.52 25.82 0 15.55 9.92 25.82 27.57 25.82 6.4 0 14.71-2.64 19.27-7.06V27zm-66.41 7.08h11.59l-5.77-14.12zm.51-28.75h10.52l21.39 49.94h-12.2l-4.26-10.49h-20.37l-4.26 10.49h-12.2zm-31.98 49.93h-11.69V22.95l-15.07 20.53h-.31l-15.06-20.53v32.31h-11.69V5.33h11l15.88 21.28 15.91-21.28h11zM5.613 47.64a5.62 5.62 0 0 1-5.16-7.83l10-23.38a5.62 5.62 0 0 1 10.31 4.43l-10 23.33a5.62 5.62 0 0 1-5.15 3.45zm16.01 12.55a5.62 5.62 0 0 1-5.16-7.83l20.92-48.81a5.62 5.62 0 1 1 10.33 4.42l-20.95 48.85a5.62 5.62 0 0 1-5.14 3.37zm24.03-6a5.62 5.62 0 0 1-5.16-7.83l15.77-36.81a5.62 5.62 0 1 1 10.33 4.42L50.813 50.8a5.62 5.62 0 0 1-5.16 3.39z" />
  </svg>
);
export default SvgMagixLogo;
