import { ComponentType, PureComponent, ReactElement } from 'react';

export const SvgWithProps = (ComposedComponent: ComponentType<any>): ComponentType<any> => {
  return class SvgProps extends PureComponent<{
    style?: Record<string, any>;
  }> {
    render() {
      let style = {
        height: '1em',
        width: '1em',
        display: 'block',
        fill: 'currentColor',
      };

      if (this.props.style) {
        style = { ...style, ...this.props.style };
      }

      return <ComposedComponent {...this.props} style={style} />;
    }
  };
};
type Props = {
  ariaHidden: boolean;
  focusable: boolean;
  role: string | 'presentation';
  style: any;
};
export const MonitorIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M15.2 15.9H4.8c-.5 0-.8.4-.8.8 0 .5.4.8.8.8h10.3c.5 0 .8-.4.8-.8.1-.4-.3-.8-.7-.8zM3.8 9.7v2.1c0 .3.3.6.6.6s.6-.3.6-.6V9.7c0-.3-.3-.6-.6-.6s-.6.3-.6.6zm2.1-.8v2.9c0 .3.3.6.6.6s.6-.3.6-.6V8.9c0-.3-.3-.6-.6-.6-.4 0-.6.2-.6.6zm2-.9v3.7c0 .3.3.6.6.6s.6-.3.6-.6V8c0-.3-.3-.6-.6-.6-.3.1-.5.3-.6.6z" />
      <path d="M18.3 3.1H1.7c-.5 0-.8.4-.8.8v10.3c0 .5.4.8.8.8h15.8s-.1 0-.1-.1l-1.1-1.1c-.7.4-1.4.6-2.2.6-1 0-2-.4-2.8-1H2.6V4.7h14.9V7c1.3 1.4 1.5 3.5.5 5.1l1.1 1.1V3.9c0-.4-.4-.8-.8-.8z" />
      <path d="M17.3 12.7c-.1-.1-.2-.1-.3-.1l-.2-.2c.6-.7.9-1.5.9-2.4 0-1-.4-1.9-1.1-2.5-.7-.7-1.6-1.1-2.5-1.1-1 0-1.9.4-2.5 1.1-.7.6-1.1 1.5-1.1 2.5s.4 1.9 1.1 2.5c.7.7 1.6 1.1 2.5 1.1.9 0 1.7-.3 2.4-.9l.2.2c0 .1 0 .2.1.3l1.2 1.2c.1.1.4.1.5 0 .1-.1.1-.4 0-.5l-1.2-1.2zm-1.2-.7c-.5.5-1.3.8-2 .8-.8 0-1.5-.3-2-.8s-.8-1.3-.8-2c0-.8.3-1.5.8-2s1.3-.8 2-.8c.8 0 1.5.3 2 .8s.8 1.3.8 2c.1.8-.2 1.5-.8 2z" />
    </svg>
  )
);
export const LineGraphIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.7 4.7c-.9 0-1.7.8-1.7 1.8 0 .2 0 .3.1.4l-3 3.1c-.1 0-.3-.1-.4-.1-.2 0-.3 0-.4.1L9.9 7.8c.1-.1.1-.3.1-.4 0-1-.8-1.8-1.7-1.8-.9 0-1.7.8-1.7 1.8 0 .2 0 .3.1.5l-3.9 4c-.1 0-.3-.1-.4-.1-.9 0-1.7.8-1.7 1.8s.8 1.8 1.7 1.8S4 14.5 4 13.5c0-.2 0-.3-.1-.4l3.9-4c.1 0 .3.1.4.1.2 0 .3 0 .4-.1l2.2 2.2c0 .1-.1.3-.1.5 0 1 .8 1.8 1.7 1.8s1.7-.8 1.7-1.8c0-.2 0-.3-.1-.5l3.1-3.1c.1 0 .3.1.4.1.9 0 1.7-.8 1.7-1.8.2-1-.5-1.8-1.5-1.8z" />
    </svg>
  )
);
export const DashboardIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M12.5 1.1h4.9c.8 0 1.5.7 1.5 1.5v3c0 .8-.7 1.5-1.5 1.5h-4.9C11.7 7 11 6.4 11 5.6v-3c0-.8.7-1.5 1.5-1.5zM2.6 1.1h4.9c.8 0 1.5.7 1.5 1.5v6.9c0 .8-.7 1.5-1.5 1.5H2.6c-.8 0-1.5-.7-1.5-1.5V2.6c0-.8.7-1.5 1.5-1.5zM2.6 13h4.9c.8 0 1.5.6 1.5 1.4v3c0 .8-.7 1.5-1.5 1.5H2.6c-.8 0-1.5-.7-1.5-1.5v-3c0-.8.7-1.4 1.5-1.4zM12.5 9h4.9c.8 0 1.5.7 1.5 1.5v6.9c0 .8-.7 1.5-1.5 1.5h-4.9c-.8 0-1.5-.7-1.5-1.5v-6.9c0-.8.7-1.5 1.5-1.5z" />
    </svg>
  )
);
export const MegaphoneIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.4 8.5l-7-7c-.4-.4-1.1-.4-1.5 0-.4.4-.4 1.1 0 1.5l7 7c.4.4 1.1.4 1.5-.1.4-.4.4-1 0-1.4zm-9-4.6l-.1.3c-.3 1.9-1.2 3.6-2.5 5L9.6 13c1.4-1.2 3.2-2.1 5-2.5l.3-.1-6.5-6.5zM5.1 10l-2.6 2.6c-.6.6-.6 1.6 0 2.2L4 16.4c.6.6 1.6.6 2.2 0l2.6-2.6L5.1 10zm.4 4.1c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l.7-.7c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-.7.7zm5.6 2.5l.7-.7c.6-.6.6-1.6 0-2.2l-.5-.5c-.3.2-.6.4-.8.6l.6.6c.2.2.2.5 0 .7l-.7.7-1.1-1-1.5 1.5 2.4 2.3c.4.4 1.1.4 1.5 0 .4-.4.4-1.1 0-1.5l-.6-.5zM13.2 1c-.3 0-.5.2-.5.5v1.1c0 .3.2.5.5.5s.5-.2.5-.5v-1c0-.3-.2-.6-.5-.6zm4.2 4.2h-1.1c-.3 0-.5.2-.5.5s.2.5.5.5h1.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zm-.7-3c-.2-.2-.5-.2-.7 0l-1.1 1.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0l1.1-1c.2-.2.2-.6 0-.8z" />
    </svg>
  )
);
export const NotificationIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M10 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 14c-.6 0-1.2-.5-1.2-1.2h2.3c.1.7-.5 1.2-1.1 1.2zm4-2.4c0 .4-.4.5-.8.5H6.8c-.4 0-.8 0-.8-.5v-.1c0-.3.2-.6.4-.7l.2-2.1c0-1.5 1.1-2.9 2.6-3.2v-.8c.1-.4.4-.7.8-.7s.7.3.7.7v.8c1.5.3 2.6 1.6 2.6 3.2l.2 2.1c.3.2.5.4.5.8z" />
    </svg>
  )
);
export const SettingsIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M18.6 9c0-.2-.3-.4-.6-.4-.8 0-1.5-.5-1.8-1.2-.3-.7-.1-1.6.5-2.1.2-.2.2-.5.1-.7-.4-.5-.9-1-1.4-1.4-.2-.2-.5-.1-.7.1-.5.6-1.4.8-2.2.5-.7-.3-1.2-1.1-1.2-1.9 0-.3-.2-.5-.4-.5-.6-.1-1.3-.1-1.9 0-.3 0-.4.2-.4.5 0 .8-.4 1.5-1.2 1.8-.7.3-1.6.1-2.1-.5-.2-.1-.5-.2-.7 0-.5.4-1 .9-1.4 1.4-.1.2-.1.5.1.6.6.5.8 1.4.5 2.2-.3.7-1 1.2-1.9 1.2-.3-.1-.5.1-.5.4-.1.7-.1 1.3 0 2 0 .2.3.4.6.4.8 0 1.5.4 1.8 1.2.3.7.1 1.6-.5 2.1-.2.2-.2.5-.1.7.4.5.9 1 1.4 1.4.2.2.5.1.7-.1.5-.6 1.4-.8 2.2-.5.7.3 1.2 1.1 1.2 1.9 0 .3.2.5.4.5.6.1 1.3.1 1.9 0 .3 0 .4-.2.4-.5 0-.8.4-1.5 1.2-1.8.7-.3 1.6-.1 2.1.5.2.2.5.2.7.1.5-.4 1-.9 1.4-1.4.2-.2.1-.5-.1-.7-.6-.5-.8-1.4-.5-2.2.3-.7 1-1.2 1.8-1.2h.1c.3 0 .5-.2.5-.4.1-.6.1-1.3 0-2zM10 12.9c-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9 1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9z" />
    </svg>
  )
);
export const AccountIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M19.1,10.4c0-5-4.1-9.1-9.1-9.1s-9.1,4.1-9.1,9.1c0,2.6,1.1,5,2.9,6.7l0,0l0.3,0.2c0,0,0,0,0.1,0
	c0.2,0.1,0.3,0.3,0.5,0.4c0.5,0.3,0.9,0.6,1.4,0.9l0,0c0.9,0.4,1.9,0.7,3,0.8l0.1,0c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0
	c0,0,0.1,0,0.1,0c0.2,0,0.5-0.1,0.7-0.1l0.1,0c0.7-0.1,1.5-0.4,2.2-0.7l0.1,0c0.4-0.2,0.9-0.4,1.3-0.7c0.1,0,0.1-0.1,0.2-0.1
	c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0,0.1-0.1l0.3-0.3l0,0C18,15.3,19.1,12.9,19.1,10.4z M1.6,10.4C1.6,5.7,5.4,2,10,2
	s8.4,3.8,8.4,8.4c0,2.5-1.1,4.7-2.8,6.3c-0.1-0.1-0.2-0.1-0.3-0.2l-2.8-1.4c-0.2-0.1-0.4-0.4-0.4-0.7v-1c0.1-0.1,0.1-0.2,0.2-0.3
	c0.4-0.5,0.7-1.1,0.9-1.7c0.4-0.2,0.7-0.6,0.7-1.1V9.2c0-0.3-0.1-0.6-0.3-0.8V6.9c0-0.2,0.1-1.1-0.6-1.9c-0.6-0.7-1.6-1-2.9-1
	S7.7,4.3,7.1,5C6.4,5.8,6.4,6.7,6.5,6.9v1.5C6.3,8.7,6.2,8.9,6.2,9.2v1.2c0,0.4,0.2,0.7,0.4,0.9c0.3,1,0.8,1.8,1,2.1v1
	c0,0.3-0.1,0.5-0.4,0.6l-2.6,1.4c-0.1,0-0.2,0.1-0.2,0.2C2.6,15,1.6,12.7,1.6,10.4z M14.9,17.2c-0.1,0.1-0.2,0.2-0.4,0.2
	c-0.2,0.1-0.4,0.3-0.6,0.4c0,0-0.1,0-0.1,0.1c-0.4,0.2-0.8,0.4-1.1,0.5l0,0c-0.2,0.1-0.4,0.1-0.6,0.2h0c-0.2,0.1-0.4,0.1-0.6,0.1
	l0,0c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.6,0l-0.1,0c-0.2,0-0.4,0-0.6-0.1l0,0c-0.4-0.1-0.9-0.2-1.3-0.3l0,0
	c-0.2-0.1-0.4-0.1-0.6-0.2l0,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.1-0.1-0.3-0.2-0.4-0.3l0,0l0,0l2.6-1.4
	c0.4-0.2,0.7-0.7,0.7-1.2v-1.2l-0.1-0.1c0,0-0.7-0.9-1-2l0-0.1l-0.1-0.1c-0.2-0.1-0.3-0.3-0.3-0.5V9.2c0-0.2,0.1-0.3,0.2-0.4
	l0.1-0.1V6.9l0,0c0,0-0.1-0.8,0.5-1.4C8.1,4.9,8.9,4.6,10,4.6c1.1,0,1.9,0.3,2.4,0.8C13,6,12.9,6.8,12.9,6.8l0,1.9L13,8.8
	c0.1,0.1,0.2,0.2,0.2,0.4v1.2c0,0.2-0.2,0.4-0.4,0.5L12.6,11l-0.1,0.2c-0.2,0.6-0.5,1.2-0.8,1.7c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0.1
	v1.2c0,0.5,0.3,1,0.8,1.2l2.8,1.4l0.1,0C15,17.1,15,17.1,14.9,17.2L14.9,17.2z"
      />
    </svg>
  )
);
export const DropIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M32 46a3.589 3.589 0 0 1-2.475-.971L8.026 24.663a3.194 3.194 0 0 1 0-4.691c1.367-1.295 3.584-1.295 4.952 0L32 37.992l19.023-18.021c1.367-1.295 3.584-1.295 4.951 0a3.194 3.194 0 0 1 0 4.691L34.475 45.029A3.592 3.592 0 0 1 32 46z" />
    </svg>
  )
);
export const PowerIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M10,0.9c-0.6,0-1.2,0.7-1.2,1.2v7.3c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.6,1.2-1.2V2.1C11.2,1.4,10.6,0.9,10,0.9
	z M5.9,3.9C5.8,4,5.7,4,5.6,4.1C2,6,0.9,9.9,1.9,13.1c1,3.2,4,6,8.1,6c4.1,0,7.1-2.6,8.1-5.8c1-3.2-0.1-7.1-3.8-9.2
	C13.8,3.8,13,4,12.7,4.5c-0.3,0.5-0.1,1.3,0.4,1.6c2.9,1.7,3.5,4.2,2.8,6.5c-0.7,2.3-2.8,4.2-5.9,4.2c-3.1,0-5.2-2-5.9-4.4
	C3.4,10.1,4,7.5,6.7,6.1c0.5-0.3,0.7-0.8,0.6-1.3C7.1,4.3,6.6,3.9,6.1,3.9C6,3.9,6,3.9,5.9,3.9L5.9,3.9z"
      />
    </svg>
  )
);
export const SwitchIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M12.7,5.8V3.6L7.3,7.2l5.4,3.6V8.5c1.2,0,1.9,0.1,2.4,0.1c3,0.4,3.8,2.2,3.8,2.2C18.9,6.3,12.7,5.8,12.7,5.8" />
      <path d="M7.3,11.5c-1.2,0-1.9-0.1-2.4-0.1c-3-0.4-3.8-2.2-3.8-2.2c0,4.6,6.2,5.1,6.2,5.1v2.2l5.4-3.6L7.3,9.1V11.5z" />
    </svg>
  )
);
export const CancelIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M17.7,15.4c0.6,0.6,0.6,1.6-0.1,2.2c-0.6,0.6-1.6,0.7-2.3,0L10,12.3l-5.3,5.3c-0.6,0.6-1.6,0.6-2.2-0.1
	C1.8,17,1.7,16,2.4,15.4L7.7,10L2.3,4.7C1.7,4,1.7,3,2.3,2.3C3,1.7,4,1.7,4.6,2.4L10,7.7l5.4-5.4c0.6-0.6,1.6-0.6,2.2,0
	C18.3,3,18.3,4,17.7,4.6L12.3,10L17.7,15.4z"
      />
    </svg>
  )
);
export const RemoveRowIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M16.6,4.6H7c-0.4,0-0.7,0.1-1,0.4l-4.5,4C1,9.5,1,10.4,1.5,11c0,0,0.1,0.1,0.1,0.1l4.5,4c0.3,0.2,0.6,0.4,1,0.4
	h9.6c1.2,0,2.2-1,2.2-2.2V6.8C18.9,5.6,17.9,4.6,16.6,4.6z M15.1,11.9c0.3,0.3,0.3,0.7,0,1c-0.3,0.3-0.7,0.3-1,0L12.2,11l-1.9,1.9
	c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1l1.9-1.9L9.3,8.1C9,7.8,9,7.4,9.3,7.1c0.3-0.3,0.7-0.3,1,0L12.2,9l1.9-1.9c0.3-0.3,0.7-0.3,1,0
	c0.3,0.3,0.3,0.7,0,1L13.2,10L15.1,11.9z"
      />
    </svg>
  )
);
export const DoneIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/s2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M17.6,5c0.1,0,0.2,0,0.4,0c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.3-0.4
		C19,4,19,3.8,19,3.6c0-0.3-0.2-0.7-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4c-0.1,0-0.2,0-0.4,0c-0.2,0.1-0.4,0.2-0.6,0.3
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.3,0.2,0.7,0.4,0.9C16.9,4.9,17.2,5.1,17.6,5z"
      />
      <path
        d="M14.1,5.2c-2.9,2.8-5.3,6.1-8,9.1c-1-1.2-2-2.4-2.9-3.6c-1.1-1.4-3,0.6-1.9,1.9c1.3,1.6,2.5,3.2,3.9,4.7
		c0.5,0.5,1.4,0.5,1.9,0c3-3.4,5.7-7,9-10.2C17.3,5.9,15.4,4,14.1,5.2z"
      />
    </svg>
  )
);
export const DropArrowIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M9.1,5.4l-7.7,7.5c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.5,0.5,1.3,0.5,1.8,0L10,8l6.8,6.6
	c0.5,0.5,1.3,0.5,1.8,0c0.5-0.5,0.5-1.2,0-1.7c0,0,0,0,0,0l-7.7-7.5C10.6,5.2,10.3,5,10,5C9.7,5,9.4,5.2,9.1,5.4z"
      />
    </svg>
  )
);
export const MoreDotsIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <g>
        <path d="M2.9,7.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2C5.1,8.8,4.1,7.8,2.9,7.8z" />
        <path d="M10,7.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C12.2,8.8,11.2,7.8,10,7.8z" />
        <path d="M17.1,7.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2C19.3,8.8,18.3,7.8,17.1,7.8z" />
      </g>
    </svg>
  )
);
export const ValueArrowIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M8.6,18L8.6,18l0-12L4.8,9.8C4.6,9.9,4.4,10,4.1,10c-0.3,0-0.5-0.1-0.7-0.3L2.8,9.2C2.6,9,2.5,8.7,2.5,8.5
	c0-0.3,0.1-0.5,0.3-0.7l6.5-6.5C9.5,1.1,9.7,1,10,1c0.3,0,0.5,0.1,0.7,0.3l6.5,6.5c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
	l-0.6,0.6c-0.4,0.4-1,0.4-1.4,0l-3.8-3.8v12c0,0.5-0.5,1-1,1H9.6C9,19,8.6,18.5,8.6,18L8.6,18z"
      />
    </svg>
  )
);
export const RemoveCircleIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M10,18.8c-4.8,0-8.8-3.9-8.8-8.8S5.2,1.2,10,1.2s8.8,3.9,8.8,8.8S14.8,18.8,10,18.8z M10,2.7
		C6,2.7,2.7,6,2.7,10S6,17.3,10,17.3s7.3-3.3,7.3-7.3S14,2.7,10,2.7z M12.9,10.7H7.1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h5.8
		c0.4,0,0.7,0.3,0.7,0.7S13.3,10.7,12.9,10.7z"
      />
    </svg>
  )
);
export const AddCircleIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M10,18.9c-4.9,0-8.9-4-8.9-8.9s4-8.9,8.9-8.9s8.9,4,8.9,8.9S14.9,18.9,10,18.9z M10,2.6
		c-4.1,0-7.4,3.3-7.4,7.4s3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4S14.1,2.6,10,2.6z M13.7,10.7H6.3c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7
		h7.4c0.4,0,0.7,0.3,0.7,0.7S14.1,10.7,13.7,10.7z M10,14.5c-0.4,0-0.7-0.3-0.7-0.7V6.3c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v7.4
		C10.7,14.1,10.4,14.5,10,14.5z"
      />
    </svg>
  )
);
export const FolderAddIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M16.5,16.3H1.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l0-11.6c0-0.3,0.2-0.5,0.5-0.5h3.1
			c0.1,0,0.3,0.1,0.4,0.2l1.1,1.3h10.2c0.3,0,0.5,0.2,0.5,0.5v1.7c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V6.1H6.1
			C5.9,6.1,5.8,6.1,5.7,6L4.6,4.7H2.2l0,10.7h14.3c0.3,0,0.5,0.2,0.5,0.5C17,16.1,16.8,16.3,16.5,16.3z"
      />
      <path
        d="M16.5,16.3C16.5,16.3,16.4,16.3,16.5,16.3C16.1,16.2,16,16,16,15.7l1.7-7.9H3.9l-1.7,8.1
			c-0.1,0.3-0.3,0.4-0.6,0.4c-0.3-0.1-0.4-0.3-0.4-0.6L3,7.2c0-0.2,0.2-0.4,0.5-0.4h14.8c0.1,0,0.3,0.1,0.4,0.2
			c0.1,0.1,0.1,0.3,0.1,0.4L17,15.9C16.9,16.1,16.7,16.3,16.5,16.3z"
      />
      <path
        d="M10,13.8c-0.3,0-0.5-0.2-0.5-0.5V9.8c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v3.6
				C10.5,13.6,10.3,13.8,10,13.8z"
      />
      <path
        d="M11.8,12H8.2c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h3.6c0.3,0,0.5,0.2,0.5,0.5
				C12.3,11.8,12.1,12,11.8,12z"
      />
    </svg>
  )
);
export const SearchIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M9,11.1L9,11.1c0.7,0.7,1.8,1.1,2.9,1.1c1.2,0,2.3-0.5,3-1.3c0.8-0.8,1.3-1.9,1.3-3.1c0-1.2-0.5-2.3-1.3-3
		c-0.7-0.8-1.9-1.3-3-1.3c-1.2,0-2.3,0.5-3.1,1.3C8,5.6,7.5,6.7,7.5,7.9c0,1.2,0.5,2.3,1.3,3.1C8.8,11,8.9,11.1,9,11.1z M8.4,13.4
		l-4.9,4.9c-0.4,0.4-1.1,0.4-1.6,0c-0.5-0.4-0.5-1.1,0-1.6L6.7,12c-0.9-1.1-1.4-2.5-1.4-4.1c0-1.8,0.7-3.4,1.9-4.7
		c1.2-1.2,2.8-1.9,4.7-1.9s3.5,0.7,4.7,1.9c1.2,1.2,1.9,2.8,1.9,4.7s-0.7,3.5-1.9,4.7c-1.2,1.2-2.8,1.9-4.7,1.9
		C10.6,14.5,9.4,14.1,8.4,13.4z"
      />
    </svg>
  )
);
export const MoreIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M10,19C5,19,1,15,1,10C1,5,5,0.9,10,0.9c5,0,9.1,4.1,9.1,9.1C19.1,15,15.1,19,10,19z M10,2.3
		c-4.2,0-7.7,3.4-7.7,7.7c0,4.2,3.4,7.7,7.7,7.7c4.2,0,7.7-3.4,7.7-7.7C17.7,5.7,14.3,2.3,10,2.3z"
      />
      <path
        d="M10,8.6c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C11.2,9.1,10.7,8.6,10,8.6z
		 M6,8.6c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C7.1,9.1,6.6,8.6,6,8.6z M14.1,8.6
		c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C15.2,9.1,14.7,8.6,14.1,8.6z"
      />
    </svg>
  )
);
export const FilterIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M7.5,10.6c0.1,0.2,0.2,0.4,0.2,0.6v5.6c0,0.3,0.3,0.6,0.5,0.4l3.9-1.6c0.1-0.1,0.2-0.3,0.2-0.5v-3.9
		c0-0.2,0.1-0.4,0.2-0.6l5.8-6.8c0.3-0.4,0-0.9-0.4-0.9H2.1c-0.5,0-0.7,0.6-0.4,0.9L7.5,10.6z"
      />
    </svg>
  )
);
export const DateIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M17.6,2.7h-1.7v2.4c0,0.8-0.6,1.4-1.4,1.4h-0.4c-0.8,0-1.4-0.6-1.4-1.4V2.7H7.5v2.4c0,0.8-0.6,1.4-1.4,1.4H5.7
	C5,6.5,4.3,5.9,4.3,5.1V2.7H2.4c-0.6,0-1,0.5-1,1.1v13.8c0,0.6,0.5,1.1,1,1.1h15.2c0.6,0,1-0.5,1-1.1V3.7
	C18.6,3.2,18.2,2.7,17.6,2.7z M17.2,16.4c0,0.4-0.3,0.8-0.8,0.8H3.6c-0.4,0-0.8-0.3-0.8-0.8V8.6c0-0.4,0.3-0.8,0.8-0.8h12.8
	c0.4,0,0.8,0.3,0.8,0.8V16.4z"
      />
      <path
        d="M5.7,5.9h0.4c0.4,0,0.8-0.4,0.8-0.8V2.2c0-0.4-0.3-0.8-0.8-0.8H5.7c-0.4,0-0.8,0.4-0.8,0.8v2.9
	C4.9,5.5,5.3,5.9,5.7,5.9z"
      />
      <path
        d="M14.1,5.9h0.4c0.4,0,0.8-0.4,0.8-0.8V2.2c0-0.4-0.3-0.8-0.8-0.8h-0.4c-0.4,0-0.8,0.4-0.8,0.8v2.9
	C13.3,5.5,13.7,5.9,14.1,5.9z"
      />
      <rect x="4.1" y="9.4" width="3" height="2.6" />
      <rect x="4.1" y="13.1" width="3" height="2.6" />
      <rect x="8.5" y="9.4" width="3" height="2.6" />
      <rect x="8.5" y="13.1" width="3" height="2.6" />
      <rect x="13" y="9.4" width="3" height="2.6" />
      <rect x="13" y="13.1" width="3" height="2.6" />
    </svg>
  )
);
export const BudgetIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path
        d="M1.4,10c0-4.7,3.8-8.6,8.6-8.6c0.5,0,1,0.4,1,1s-0.4,1-1,1c-3.7,0-6.6,3-6.6,6.6c0,0.5-0.4,1-1,1
	S1.4,10.5,1.4,10z M11,17.6c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1C10.6,18.6,11,18.1,11,17.6C11,17.6,11,17.6,11,17.6z
	 M13.8,4.4c0.5,0,1-0.4,1-1c0-0.3-0.2-0.7-0.5-0.8l0,0c-0.5-0.3-1.1-0.1-1.3,0.4c-0.3,0.5-0.1,1.1,0.4,1.3
	C13.5,4.3,13.6,4.4,13.8,4.4L13.8,4.4z M5.4,16.1c-0.3,0.5-0.1,1.1,0.4,1.3c0.5,0.3,1.1,0.1,1.3-0.4c0.3-0.5,0.1-1.1-0.4-1.3
	C6.2,15.5,5.6,15.6,5.4,16.1L5.4,16.1z M3.4,14.8c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1c0,0.2,0,0.3,0.1,0.5
	C2.8,14.6,3.1,14.8,3.4,14.8z M17.4,14.3L17.4,14.3c0.3-0.5,0.1-1.1-0.4-1.3c-0.5-0.3-1.1-0.1-1.3,0.4c-0.3,0.5-0.1,1.1,0.4,1.3
	c0,0,0,0,0,0C16.6,14.9,17.2,14.7,17.4,14.3C17.4,14.3,17.4,14.3,17.4,14.3z M18.6,10c0-0.5-0.4-1-1-1s-1,0.4-1,1c0,0.5,0.4,1,1,1
	C18.1,11,18.6,10.5,18.6,10z M15.7,6.7c0.2,0.3,0.5,0.5,0.8,0.5c0.5,0,1-0.4,1-1s-0.4-1-1-1c-0.5,0-1,0.4-1,1
	C15.6,6.4,15.7,6.5,15.7,6.7z M13.8,17.5c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.2,0-0.3,0-0.5,0.1c-0.5,0.3-0.6,0.9-0.4,1.3
	C13.1,17.4,13.5,17.6,13.8,17.5z M12.2,12.9c0.4-0.5,0.6-1.2,0.5-1.8c-0.1-0.7-0.6-1.3-1.2-1.6c-0.3-0.2-0.7-0.3-1.1-0.5l-0.1-0.1
	C10,8.8,9.8,8.7,9.6,8.5C9.5,8.4,9.4,8.3,9.4,8.1c0-0.2,0.1-0.3,0.3-0.4c0.1,0,0.2-0.1,0.4-0.1c0.5,0,1,0.1,1.4,0.3
	c0.5,0.2,0.6-0.1,0.6-0.3c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.2c0.1-0.2,0-0.5-0.2-0.5c0,0,0,0,0,0c-0.3-0.1-0.6-0.2-1-0.3
	c-0.3,0-0.3,0-0.3-0.4c0-0.7-0.1-0.8-0.7-0.8H9.7C9.4,5,9.3,5.1,9.3,5.5c0,0.1,0,0.2,0,0.3v0.1c0,0.3,0,0.3-0.3,0.4
	c-1,0.4-1.5,1-1.6,2c-0.1,0.8,0.3,1.5,1.2,2c0.3,0.2,0.6,0.3,1,0.4c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.5,0.3
	c0.2,0.1,0.2,0.3,0.2,0.5c0,0.2-0.2,0.4-0.4,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.4,0-0.9-0.2-1.3-0.4c-0.1-0.1-0.2-0.1-0.3-0.1
	c-0.2,0-0.3,0.2-0.3,0.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.4,0,0.5,0.3,0.7c0.4,0.2,0.8,0.3,1.2,0.4c0.1,0,0.1,0,0.2,0
	c0,0.1,0,0.1,0,0.2v0.5c0,0.2,0.1,0.4,0.4,0.5c0,0,0.1,0,0.1,0h0.3h0.4c0.2,0,0.4-0.2,0.4-0.4c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.3,0-0.4s0-0.2,0.2-0.2C11.4,13.6,11.8,13.3,12.2,12.9L12.2,12.9z"
      />
    </svg>
  )
);
export const SumIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <polygon
        points="16.7,15.1 16.7,18.8 3.3,18.8 3.3,17.7 9.1,10.1 3.3,1.9 3.3,1.2 16.7,1.2 16.7,5.2 14.5,2.5 7.2,2.5
	12.3,9 7.2,16 7.2,16.8 14.7,16.8 "
      />
    </svg>
  )
);
export const PencilIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M14.6 1.438l-2 2 3.9 3.9 2-2-3.9-3.9zm-2.6 2.6l-8.9 8.9 3.9 3.9 8.9-8.9-3.9-3.9zm-9.5 9.6l-1.1 5 5-1.1-3.9-3.9z" />
    </svg>
  )
);
export const TrashIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M12.2 1.8v1.6h4.4c.3 0 .5.2.5.5V5c0 .3-.2.5-.5.5H3.4c-.3 0-.5-.2-.5-.5V4c0-.3.2-.5.5-.5h4.4V1.8c0-.3.2-.5.5-.5h3.3c.3-.1.6.2.6.5zm-6.6 17h8.8c.9 0 1.6-.7 1.6-1.6V7.3H4v9.8c0 .9.7 1.7 1.6 1.7z" />
    </svg>
  )
);
export const ArrowCircleIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M6.3 8.3c-.3.3-.3.7 0 1 .3.3.7.3 1 0l2-2v7.1c0 .4.3.7.7.7s.7-.3.7-.7V7.3l2 2c.3.3.7.3 1 0 .1-.1.2-.3.2-.5s-.1-.4-.2-.5l-3.2-3.2c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2L6.3 8.3z" />
      <path d="M1.2 10c0 4.8 3.9 8.8 8.8 8.8s8.8-3.9 8.8-8.8-4-8.8-8.8-8.8-8.8 4-8.8 8.8zm16.2 0c0 4.1-3.3 7.4-7.4 7.4S2.6 14.1 2.6 10 5.9 2.6 10 2.6s7.4 3.3 7.4 7.4z" />
    </svg>
  )
);
export const ArrowIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M6.3 8.3c-.3.3-.3.7 0 1 .3.3.7.3 1 0l2-2v7.1c0 .4.3.7.7.7s.7-.3.7-.7V7.3l2 2c.3.3.7.3 1 0 .1-.1.2-.3.2-.5s-.1-.4-.2-.5l-3.2-3.2c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2L6.3 8.3z" />
    </svg>
  )
);
export const PrintIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.1 4.9V2c0-.2-.2-.5-.5-.5H3.4c-.2 0-.5.2-.5.5v2.9c-.9 0-1.6.7-1.6 1.5v6.7c0 .9.7 1.6 1.6 1.6h2.2v3.4c0 .2.2.5.5.5h9.2c.2 0 .5-.2.5-.5v-3.4h2c.9 0 1.6-.7 1.6-1.6V6.4c-.2-.8-.9-1.5-1.8-1.5zM3.8 2.4h12.4v2.5H3.8V2.4zm10.4 15.2H6v-5.3h8.3v5.3zm3.6-4.5c0 .4-.3.7-.7.7h-2v-1.4h.5c.2 0 .5-.2.5-.5 0-.2-.2-.5-.5-.5H4.3c-.2 0-.5.2-.5.5 0 .2.2.5.5.5h.8v1.4H2.9c-.4 0-.7-.3-.7-.7V6.4c0-.4.3-.7.7-.7h14.2c.4 0 .7.3.7.7v6.7zm-2.9-5.8c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2 1.2-.5 1.2-1.2-.5-1.2-1.2-1.2zm0 1.5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3.2 0 .3.1.3.3 0 .2-.1.3-.3.3zm-3.5-1.5c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2 1.2-.5 1.2-1.2-.6-1.2-1.2-1.2zm0 1.5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3.2 0 .3.1.3.3 0 .2-.2.3-.3.3z" />
    </svg>
  )
);
export const ExternalLinkIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M12.2 3.9h5l-1.1-1.1v5c0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8V6.1 3.4v-.6c0-.6-.5-1.1-1.1-1.1h-5c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8 0 .3.1.6.3.8.2.2.5.3.8.3z" />
      <path d="M9.7 11.9l.8-.8 2-2 2.4-2.4L17 4.6l1-1c.2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3-.3 0-.6.1-.8.3l-.8.8-2 2-2.4 2.4-2.1 2.1-1 1c-.2.2-.3.5-.3.8 0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3z" />
      <path d="M16.1 11.9V15.8c0 .1 0 .2-.1.3 0-.1.1-.2.1-.3 0 .1-.1.2-.2.3l.2-.2c-.1.1-.1.2-.2.2l.2-.2c-.1.1-.2.1-.3.2.1 0 .2-.1.3-.1-.1 0-.2.1-.3.1h.3H3.7 4c-.1 0-.2 0-.3-.1.1 0 .2.1.3.1-.1 0-.2-.1-.3-.2l.2.2c.1 0 0-.1-.1-.2.1.1.1.2.2.3-.1-.1-.1-.2-.2-.3 0 .1.1.2.1.3 0-.1-.1-.2-.1-.3v-.2-.9-2.9-3.5-3V4v-.2.3c0-.1 0-.2.1-.3 0 .1-.1.2-.1.3.1-.1.1-.2.2-.2-.1 0-.1.1-.2.2l.3-.3c-.1.1-.2.2-.3.2.1-.1.2-.1.3-.2 0 .1-.1.1-.2.2.1 0 .2-.1.3-.1h-.3 4.3c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.8-.3-.2-.6-.3-.9-.3H4.3c-.3 0-.7 0-1 .1-.1.1-.4.2-.5.3-.2.1-.3.2-.4.3l-.3.3c-.3.4-.4.9-.4 1.3V15.3c0 .4 0 .7.1 1.1 0 .1 0 .1.1.2 0 .2 0 .4.1.5.1.2.2.4.4.5.3.3.8.6 1.2.6.2 0 .4.1.6.1h11.9c.2 0 .5 0 .7-.1.1-.1.3-.1.4-.2.1-.1.3-.2.4-.3.2-.1.3-.3.4-.5l.3-.6c.1-.3.1-.6.1-.9v-1-2.3-.4c0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3-.3 0-.6.1-.8.3-.3.1-.4.4-.4.7z" />
    </svg>
  )
);
export const AdminLinkIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M18.2 12.5c0 .9-.8 1.7-1.7 1.7H3.7c-.9 0-1.7-.8-1.7-1.7V5.3c0-.9.8-1.7 1.7-1.7h12.8c.9 0 1.7.8 1.7 1.7v7.2zm-1.5 0V5.3c0-.2-.1-.4-.4-.4H3.7c-.2 0-.4.2-.4.4v7.2c0 .2.2.4.4.4h12.6c.2-.1.4-.2.4-.4zM19.3 15.5c0 .5-.4 1-1 1H1.7c-.5 0-1-.4-1-1v-.7h18.6v.7z" />
      <path d="M14.4 8.7h-.6c-.1.1-.1.2-.2.3l.1.6c-.1.1-.3.2-.5.3l-.4-.4h-.4l-.4.5c-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.1v-.6c-.1-.1-.2-.1-.3-.2l-.6.1c-.1-.1-.2-.3-.3-.5l.4-.4v-.4l-.5-.3c0-.1 0-.2.1-.3 0-.1.1-.2.1-.3h.6c.1 0 .1-.1.2-.2l-.1-.5c.1-.1.3-.2.5-.3l.4.4h.4l.3-.5c.1 0 .2 0 .3.1.1 0 .2.1.3.1v.6c.1.1.2.1.3.2l.6-.1c.1.1.2.3.3.5l-.4.3v.4l.5.3c0 .1 0 .2-.1.3v.2zm-1.5-.4c.3-.3.3-.8-.1-1-.3-.3-.8-.3-1 .1-.3.3-.3.8.1 1 .2.3.7.3 1-.1zM8 12.1l-.2-.5c-.1 0-.2-.1-.3-.1l-.5.2c-.1-.1-.3-.2-.4-.4l.2-.5c-.1-.1-.1-.2-.2-.3l-.6-.2V10v-.2l.5-.2c0-.1.1-.2.1-.3l-.3-.6c.1-.1.2-.3.4-.4l.5.2c.1-.1.2-.1.3-.2l.2-.6h.6l.2.5c.1 0 .2.1.3.1l.5-.3c.1.1.3.2.4.4l-.2.6c.1.1.1.2.2.3l.6.2v.6l-.5.2c0 .1-.1.2-.1.3l.3.5c-.1.1-.2.3-.4.4l-.5-.2c-.1.1-.2.1-.3.2l-.2.5h-.3c-.1.1-.2.1-.3.1zm-.1-1.5c.4.1.8 0 1-.4.1-.4 0-.8-.4-1-.4-.1-.8 0-1 .4s0 .8.4 1z" />
    </svg>
  )
);
export const QuestionIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M10 1.2c-4.9 0-8.8 4-8.8 8.8 0 4.9 4 8.8 8.8 8.8 4.9 0 8.8-4 8.8-8.8 0-4.9-3.9-8.8-8.8-8.8m0 16.2c-4.1 0-7.4-3.3-7.4-7.4S5.9 2.6 10 2.6c4.1 0 7.4 3.3 7.4 7.4s-3.3 7.4-7.4 7.4m-.1-4.6c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8 0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.8-.3-.1-.5-.3-.8-.3m2.5-7.3c-.4-.2-.8-.4-1.2-.5-.4-.1-.9-.2-1.3-.2s-.8.1-1.2.2c-.4.2-.7.4-1 .6-.3.3-.6.6-.7.9-.2.3-.3.6-.3 1 0 .3.1.5.3.6.2.1.4.2.6.2.2 0 .4 0 .5-.1l.3-.3c.1-.1.2-.3.2-.4.1-.2.1-.3.3-.4.1-.3.2-.4.4-.5.2-.1.4-.1.7-.1.4 0 .7.1 1 .3.2.2.4.5.4.9 0 .2-.1.4-.2.6-.1.2-.3.3-.4.5-.2.2-.4.3-.6.5-.3.1-.5.2-.6.4-.2.2-.3.4-.4.6-.2.2-.3.4-.3.7 0 .2.1.4.2.6.2.3.4.4.7.4.2 0 .4 0 .5-.1l.3-.3c.1-.1.1-.2.2-.4 0-.1.1-.3.2-.4l.3-.3c.2-.1.3-.2.5-.4s.4-.3.6-.4c.2-.2.4-.3.5-.5.2-.2.3-.4.4-.7.1-.2.2-.5.2-.8 0-.5-.1-.9-.3-1.3-.2-.4-.5-.7-.8-.9" />
    </svg>
  )
);
export const BellIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <g className="bell-rings">
        <path
          d="M15,5.8c0.7,0.4,1,1.3,0.6,2.1c-0.1,0.2,0,0.5,0.2,0.6c0.2,0.1,0.5,0,0.6-0.2c0.6-1.2,0.2-2.6-1-3.3
		c-0.2-0.1-0.5,0-0.6,0.2C14.7,5.4,14.8,5.6,15,5.8z"
        />
        <path
          d="M16,2.8c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.2,0,0.5,0.2,0.6l0,0c2.1,1.2,2.8,3.8,1.7,5.8c-0.1,0.2,0,0.5,0.2,0.6
		c0.2,0.1,0.5,0,0.6-0.2l0,0C19.4,7.3,18.5,4.1,16,2.8z"
        />
        <path
          d="M3.4,6.4C3.3,7,3.3,7.7,3.6,8.3C3.7,8.5,4,8.6,4.2,8.4c0.2-0.1,0.3-0.4,0.2-0.6l0,0C4,7.1,4.3,6.2,5,5.8
		c0.2-0.1,0.3-0.4,0.2-0.6C5.1,4.9,4.8,4.9,4.6,5C4,5.3,3.6,5.8,3.4,6.4z"
        />
        <path
          d="M2.3,10c0.2,0,0.4-0.2,0.4-0.4c0-0.1,0-0.1-0.1-0.2C1.6,7.3,2.3,4.7,4.4,3.5c0.2-0.1,0.3-0.4,0.2-0.6
		C4.5,2.7,4.2,2.6,4,2.8l0,0c-2.5,1.4-3.4,4.5-2.1,7C2,9.9,2.1,10,2.3,10z"
        />
      </g>
      <path
        d="M4.5,12.9c-0.5,0.5-0.7,1.4-0.3,2c0.3,0.6,0.9,1,1.6,1h2.7V17c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-1.1h2.7
	c1,0,1.8-0.8,1.8-1.8V14c0-0.4-0.2-0.8-0.4-1.1l-0.4-0.5c-0.4-0.4-0.6-1-0.6-1.5V8.5c0-0.1,0-0.2,0-0.3c-0.1-1.8-1.4-3.3-3.1-3.9V3
	c0-0.8-0.7-1.5-1.5-1.5S8.5,2.2,8.5,3v1.3c-1.7,0.5-3,2.1-3.1,3.9c0,0.1,0,0.2,0,0.3V11c0,0.6-0.2,1.2-0.7,1.6L4.5,12.9z M10.6,17
	c0,0.3-0.3,0.6-0.6,0.6S9.4,17.3,9.4,17v-1.1h1.2V17z M9.4,3c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v1.1c-0.2,0-0.3,0-0.5,0H9.9
	c-0.2,0-0.3,0-0.5,0L9.4,3z M5.1,13.5l0.2-0.2c0.6-0.6,0.9-1.4,0.9-2.3V8.5c0-0.1,0-0.2,0-0.2C6.4,6.5,8,5,9.9,5h0.3
	c1.9,0,3.5,1.4,3.6,3.2c0,0.1,0,0.2,0,0.2v2.4c0,0.8,0.3,1.5,0.8,2.1l0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6v0.1c0,0.5-0.4,0.9-0.8,0.9
	h-3.2h0h0H9h0h0H5.8c-0.3,0-0.7-0.2-0.8-0.5C4.8,14.2,4.9,13.8,5.1,13.5z"
      />
      <path
        d="M7.7,10C8,10,8.2,9.8,8.2,9.6v-1c0,0,0-0.1,0-0.1C8.2,7.6,9,7,9.9,7c0.2,0,0.4-0.2,0.4-0.4
	c0-0.2-0.2-0.4-0.4-0.4c-1.4,0-2.5,1-2.6,2.3c0,0.1,0,0.1,0,0.2v1C7.3,9.8,7.5,10,7.7,10z"
      />
    </svg>
  )
);
export const TeamIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M14.9 5.1c1 0 1.9-.9 1.9-2.1s-.9-2-1.9-2-1.9.9-1.9 2.1.8 2 1.9 2zm0-3.3c.6 0 1.1.6 1.1 1.3s-.5 1.3-1.1 1.3-1.1-.6-1.1-1.3.5-1.3 1.1-1.3zM14.9 12c-.2 0-.4.2-.4.4v6.2c0 .2.2.4.4.4s.4-.2.4-.4v-6.2c-.1-.2-.2-.4-.4-.4z" />
      <path d="M16.4 5.9h-2.2c-.2 0-.4.2-.4.4s.2.4.4.4h2.2c.9 0 1.9.4 1.9 1.5v3c0 .4-.3.7-.8.7V8.5c0-.2-.2-.4-.4-.4s-.4.2-.4.4v10.1c0 .2.2.4.4.4s.4-.2.4-.4v-6c.8 0 1.5-.7 1.5-1.5v-3c0-1.3-1.1-2.2-2.6-2.2zM5.1 5.1C6.1 5.1 7 4.2 7 3s-.8-2-1.9-2-1.9.9-1.9 2.1.9 2 1.9 2zm0-3.3c.6 0 1.1.6 1.1 1.3s-.5 1.3-1.1 1.3S4 3.8 4 3.1s.5-1.3 1.1-1.3zM5.1 12c-.2 0-.4.2-.4.4v6.2c0 .2.2.4.4.4s.4-.2.4-.4v-6.2c0-.2-.2-.4-.4-.4z" />
      <path d="M3.6 6.6h2.2c.2 0 .4-.2.4-.4s-.1-.3-.3-.3H3.6C2.1 5.9 1 6.8 1 8.1v3c0 .8.7 1.5 1.5 1.5v6c0 .2.2.4.4.4s.4-.2.4-.4V8.5c0-.2-.2-.4-.4-.4s-.4.2-.4.4v3.4c-.4 0-.8-.3-.8-.7v-3C1.8 7 2.8 6.6 3.6 6.6zM10 6.6c1.2 0 2.2-1.1 2.2-2.4s-1-2.4-2.2-2.4-2.2 1-2.2 2.4 1 2.4 2.2 2.4zm0-4.1c.8 0 1.5.8 1.5 1.7s-.7 1.7-1.5 1.7-1.5-.8-1.5-1.7.7-1.7 1.5-1.7zM10 13.1c-.2 0-.4.2-.4.4v5.2c0 .2.2.4.4.4s.4-.2.4-.4v-5.2c0-.3-.2-.4-.4-.4z" />
      <path d="M11.5 7.4h-3C7 7.4 5.9 8.6 5.9 10v3c0 .8.7 1.5 1.5 1.5v4.1c0 .2.2.4.4.4s.4-.2.4-.4V10c0-.2-.2-.4-.4-.4s-.4.2-.4.4v3.8c-.4 0-.8-.3-.8-.8v-3c0-1 .8-1.9 1.9-1.9h3c1 0 1.9.8 1.9 1.9v3c0 .4-.3.8-.8.8V10c0-.2-.2-.4-.4-.4s-.4.2-.4.4v8.6c0 .2.2.4.4.4s.4-.2.4-.4v-4.1c.8 0 1.5-.7 1.5-1.5v-3c0-1.4-1.2-2.6-2.6-2.6z" />
    </svg>
  )
);
export const NothingFoundIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.9 15.5c-1-.7-2.3-1.7-3.3-2.4-.3-.2-.5-.5-.8-.5-.2 0-.4.4-.6.6-.1.1-.1.2 0 .3 1.1 1.1 2.4 2.3 3.5 3.4.2.2.4.2.6 0 .3-.3.5-.6.8-.9 0-.1.1-.1.1-.2l-.3-.3zM9.5 5.9c-2.2 0-4 1.8-4 3.9 0 2.2 1.8 4 4 4s4-1.8 4-3.9c.1-2.2-1.7-4-4-4zm9.2 10.2l-.9 1.2c-.4.5-.9.5-1.4.1l-2.7-2.7c-.3-.2-.5-.5-.8-.7-.1-.1-.2-.2-.1-.3.1-.1-.4-.3-.5-.2-1 .7-2 1-3.2.9C6.8 14.1 4.9 12 5 9.7 5.1 7.6 6.5 6 8.5 5.5c2.5-.6 5.1 1.1 5.5 3.7.1.9 0 1.8-.4 2.6 0 .1.1.6.3.4.1-.1.1 0 .2.1 1.4.9 2.7 1.9 4 2.8.2.2.4.4.5.6v.4z" />
      <path d="M6.5 8.9c-.1 0-.2-.2-.2-.3.5-1.4 1.8-2.3 3.3-2.3.1 0 .2.1.2.2s-.1.2-.2.2c-1.3 0-2.5.8-2.9 2 0 .2-.1.2-.2.2zM12.9 10c-.1 0-.2-.1-.2-.2 0-.8-.3-1.5-.8-2-.1-.1-.1-.2 0-.3.1-.1.2-.1.3 0 .6.6.9 1.5.9 2.3 0 .1-.1.2-.2.2zM9.5 3.9c-.1 0-.2-.1-.2-.2V2.2c0-.2.1-.2.2-.2s.2 0 .2.2v1.5c0 .1-.1.2-.2.2zM9.5 18c-.1 0-.2-.1-.2-.2v-1.5c0-.1.1-.2.2-.2s.2.1.2.2v1.5c0 .2-.1.2-.2.2zM14.1 5.7c-.1 0-.1 0-.2-.1s-.1-.2 0-.3l1.1-1c.1-.1.2-.1.3 0 .1.1.1.2 0 .3l-1.1 1.1h-.1zM3.9 15.8c-.1 0-.1 0-.2-.1s-.1-.2 0-.3l1.1-1.1c.1-.1.2-.1.3 0 .1.1.1.2 0 .3L4 15.7l-.1.1zM17.4 10.2h-1.5c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h1.5c.1 0 .2.1.2.2.1.1 0 .2-.2.2zM3.1 10.2H1.6c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h1.5c.1 0 .2.1.2.2s-.1.2-.2.2zM5 5.7c-.1 0-.1 0-.2-.1l-1.1-1c-.1-.1-.1-.2 0-.3.1-.1.2-.1.3 0l1.1 1.1c.1.1.1.2 0 .3H5z" />
    </svg>
  )
);
export const SadFaceIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M10 1.138c-4.9 0-8.8 4-8.8 8.8 0 4.9 4 8.8 8.8 8.8s8.8-4 8.8-8.8c0-4.8-3.9-8.8-8.8-8.8zm0 1.2c1 0 2 .2 2.9.6-2.4.5-5 .9-7.6 1.1 1.3-1.1 2.9-1.7 4.7-1.7zm4.4 1.4c1 .7 1.8 1.6 2.3 2.6l-13.3-.3c.2-.3.4-.5.6-.8 3.5-.2 7.2-.8 10.4-1.5zm-11.5 3.4l7.3.2-7.8 1.6c.1-.7.3-1.2.5-1.8zm11.9.3l2.5.1c.3.8.4 1.6.4 2.5 0 4.2-3.4 7.7-7.7 7.7-4.2 0-7.6-3.4-7.7-7.6l3.1-.7c-.2.2-.4.6-.4 1 0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c0-.6-.4-1.1-.9-1.3l5.8-1.2c.6.9 2.8 1.4 3.2.6-.5.2-1.7-.2-2.7-.7l1.8-.4zm-1.2 1.6c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c.1-.7-.5-1.3-1.3-1.3zm-4.5 3.5c-.4 0-.7.2-.7.8.6-.4 2.1.2 3.1 1 .2-.9-1.4-1.9-2.4-1.8z" />
    </svg>
  )
);
export const NotFoundIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M135.9 12H14.1c-1.2 0-2.1.9-2.1 2.1v121.8c0 1.2.9 2.1 2.1 2.1h121.8c1.2 0 2.1-.9 2.1-2.1V14.1c0-1.2-.9-2.1-2.1-2.1zM47.7 133.8v-4.2c0-8.1 6.6-14.7 14.7-14.7h25.2c8.1 0 14.7 6.6 14.7 14.7v4.2H47.7zm-10.5-42H33v-21h4.2v21zm4.2-33.6h67.2v52.5H41.4V58.2zm31.5-14.7c0-1.2.9-2.1 2.1-2.1s2.1.9 2.1 2.1-.9 2.1-2.1 2.1-2.1-.9-2.1-2.1zm39.9 27.3h4.2v21h-4.2v-21zm21 63h-27.3v-4.2c0-5.9-2.8-11.2-7-14.7h11.2c1.2 0 2.1-.9 2.1-2.1V96h6.3c1.2 0 2.1-.9 2.1-2.1V68.7c0-1.2-.9-2.1-2.1-2.1h-6.3V56.1c0-1.2-.9-2.1-2.1-2.1H77.1v-4.6c2.4-.9 4.2-3.2 4.2-5.9 0-3.5-2.8-6.3-6.3-6.3s-6.3 2.8-6.3 6.3c0 2.7 1.8 5 4.2 5.9V54H39.3c-1.2 0-2.1.9-2.1 2.1v10.5h-6.3c-1.2 0-2.1.9-2.1 2.1v25.2c0 1.2.9 2.1 2.1 2.1h6.3v16.8c0 1.2.9 2.1 2.1 2.1h11.2c-4.3 3.5-7 8.8-7 14.7v4.2H16.2V33h117.6v100.8zm0-105H16.2V16.2h117.6v12.6z" />
      <path d="M20.4 20.4h4.2v4.2h-4.2zM28.8 20.4H33v4.2h-4.2zM37.2 20.4h4.2v4.2h-4.2zM66.6 20.4h63v4.2h-63zM65.1 87l3-3-5.9-5.8 5.9-5.9-3-3-5.9 5.9-5.8-5.9-3 3 5.9 5.9-5.9 5.8 3 3 5.8-5.9zM84.9 87l5.9-5.9 5.8 5.9 3-3-5.9-5.8 5.9-5.9-3-3-5.8 5.9-5.9-5.9-3 3 5.9 5.9-5.9 5.8zM87.6 106.5c1.2 0 2.1-.9 2.1-2.1 0-8.1-6.6-14.7-14.7-14.7s-14.7 6.6-14.7 14.7c0 1.2.9 2.1 2.1 2.1h25.2zM75 93.9c5.1 0 9.3 3.6 10.3 8.4H64.7c1-4.8 5.2-8.4 10.3-8.4z" />
    </svg>
  )
);
export const TeamMembersIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.3 10.3c0 .4-1.3 1.3-1.3 1.3s-1.2-.9-1.3-1.3c-.4 0-.8.2-1.1.5l.1.1c.6.6.9 1.4.9 2.2v.4h2.8c1 0 1.7-.1 1.7-1.1.1-1.1-.7-2-1.8-2.1z" />
      <path d="M16.1 10.6c1 0 1.7-1.4 1.7-2.4s-.8-1.7-1.7-1.7-1.7.8-1.7 1.7c-.1 1 .7 2.4 1.7 2.4zM6.2 10.9c.1 0 .1-.1 0 0-.2-.3-.6-.5-1-.5 0 .4-1.3 1.3-1.3 1.3s-1.2-.9-1.3-1.3c-1.1.1-1.9 1-1.9 2.1 0 1 .8 1.1 1.7 1.1H5.4v-.4c0-.9.3-1.7.8-2.3z" />
      <path d="M3.9 10.6c1 0 1.7-1.4 1.7-2.4s-.8-1.7-1.7-1.7-1.7.8-1.7 1.7c0 1 .8 2.4 1.7 2.4zM14 13.1c0-1.4-1.1-2.5-2.4-2.6 0 .5-1.6 1.7-1.6 1.7S8.4 11 8.4 10.5C7 10.6 6 11.7 6 13.1c0 1.3 1 1.4 2.2 1.4h3.5c1.4 0 2.3-.1 2.3-1.4z" />
      <path d="M9 10.4c.3.3.7.4 1 .4.4 0 .7-.2 1-.4.2-.1.3-.3.5-.5.4-.6.7-1.5.7-2.1 0-1.2-1-2.2-2.2-2.2-1.2 0-2.2 1-2.2 2.2 0 .7.3 1.5.7 2.1.2.1.3.3.5.5z" />
    </svg>
  )
);
export const WarningIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M18.7 16.1L11 2.8c-.5-.8-1.5-.8-2 0L1.3 16.1c-.4.7.1 1.7 1 1.7h15.4c.9 0 1.4-.9 1-1.7zM9.8 6.9c.5-.1.9.1 1.1.5.1.2.1.3.1.5 0 .5-.1 1-.1 1.5 0 .8-.1 1.5-.1 2.3v.7c0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7-.2-1.2-.3-2.4-.3-3.6 0-.3 0-.6-.1-1 0-.4.3-.8.8-.9zm.2 8.8c-.5 0-1-.4-1-1 0-.5.4-1 1-1s1 .4 1 1-.5 1-1 1z" />
    </svg>
  )
);
export const IntegrationIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M7.3 2.6c-.3-.4-.7-.7-1.3-.7-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h.3l2.3 3.8c.3-.3.6-.4 1-.5L7.2 4.1c.1-.1.1-.2.2-.4H12v-.4c0-.2 0-.5.1-.7H7.3zM6 3.9c-.3 0-.5-.2-.5-.5 0-.4.2-.6.5-.6s.5.2.5.5-.2.6-.5.6zm8-2c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h.3l2.3 3.8c.3-.3.6-.4 1-.5l-2.4-3.9c.1-.2.2-.5.2-.8 0-.8-.6-1.4-1.4-1.4zm0 2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5c0 .2-.2.5-.5.5zM6 18.1c.6 0 1.1-.3 1.3-.8h4.8c-.1-.2-.1-.4-.1-.7v-.4H7.4c0-.1-.1-.2-.1-.3L9.6 12c-.4-.1-.7-.3-1-.5l-2.3 3.7h-.4c-.8 0-1.4.6-1.4 1.4 0 .9.7 1.5 1.5 1.5zm0-2c.3 0 .5.2.5.5s-.3.5-.5.5-.5-.2-.5-.5c0-.2.2-.5.5-.5zm10.6-4.6l-2.3 3.8H14c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.3-.1-.5-.2-.8l2.4-3.9c-.4-.1-.7-.3-1-.5zM14 17.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5zm4-8.6c-.6 0-1 .3-1.3.8h-4.8c.1.1.1.4.1.6v.4h4.6c.2.6.7 1 1.4 1 .8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4zm0 1.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.3.5-.5.5zm-8-1.9c-.7 0-1.2.4-1.4 1H4v.4c0 .2 0 .5-.1.7h4.8c.3.5.7.8 1.3.8l2.4 3.9c.2-.3.5-.5.9-.6L11 11c.3-.3.4-.6.4-1s-.2-.8-.4-1l2.3-3.7c-.4-.1-.7-.3-.9-.6L10 8.6zm0 1.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5zm-8 .9l2.4 3.9c.2-.3.5-.5.9-.6L3 11c.3-.3.4-.6.4-1s-.2-.8-.4-1l2.3-3.7c-.3-.2-.6-.4-.9-.7L2 8.6C1.2 8.6.6 9.2.6 10c0 .8.6 1.4 1.4 1.4zm0-1.9c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5z" />
    </svg>
  )
);
export const InfoIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M10 7c.3 0 .6-.1.8-.3.2-.2.3-.4.3-.7 0-.3-.1-.6-.3-.7-.2-.2-.5-.3-.8-.3-.3 0-.6.1-.8.3-.2.1-.3.4-.3.7 0 .3.1.6.3.7.2.2.5.3.8.3zM10.9 14V7.9H8.2v1h.9V14h-.9v1h3.6v-1z" />
      <path d="M10 2.4c4.2 0 7.6 3.4 7.6 7.6s-3.4 7.6-7.6 7.6-7.6-3.4-7.6-7.6S5.8 2.4 10 2.4m0-.9c-4.7 0-8.5 3.8-8.5 8.5s3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5z" />
    </svg>
  )
);
export const CheckIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.7 4.3l.6.6c.3.3.3.8 0 1.1l-9.4 9.4c-.8.8-1 .8-1.8 0l-5.3-5.3c-.3-.3-.3-.8 0-1.1.2-.2.3-.4.5-.6.3-.3.8-.3 1.1 0l4.5 4.5 8.6-8.6c.3-.3.9-.3 1.2 0z" />
    </svg>
  )
);
export const ThumbIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M18.2 13.2c.4-.5.5-1 .5-1.5s-.2-.9-.5-1.3c.2-.6.3-1.6-.5-2.3-.6-.5-1.6-.8-3-.7-1 0-1.8.2-1.8.2-.2 0-.4.1-.6.1 0-.2 0-.8.5-2.2.5-1.6.5-2.8-.1-3.6-.6-.8-1.6-.9-1.9-.9-.3 0-.5.1-.7.3-.5.5-.4 1.4-.4 1.8-.5 1.4-1.9 4.6-3 5.5l-.1.1c-.3.4-.6.7-.7 1.1-.3-.1-.5-.2-.8-.2H2.8c-.9 0-1.6.7-1.6 1.6v6.1c0 .9.7 1.6 1.6 1.6h2.3c.3 0 .6-.1.9-.3l.9.1c.1 0 2.5.3 5 .3.4 0 .9.1 1.3.1.7 0 1.3-.1 1.7-.2 1.1-.2 1.9-.7 2.3-1.5.2-.4.3-.9.3-1.4.7-.7.9-1.4.9-1.9-.1-.4-.1-.6-.2-.9zM2.8 17.8c-.3 0-.5-.2-.5-.5v-6.1c0-.3.2-.5.5-.5h2.3c.3 0 .5.2.5.5v6.1c0 .3-.2.5-.6.5H2.8zm14.4-5c-.2.2-.2.4-.1.6 0 0 .2.3.2.6 0 .5-.2.9-.7 1.3-.2.1-.2.4-.2.6 0 0 .2.5-.1 1-.3.4-.8.8-1.7 1-.6.1-1.5.1-2.6 0h-.1c-2.4.1-4.8-.2-4.9-.2h-.4V11.3c0-.2 0-.3-.1-.5.2-.6.5-1 .8-1.4 1.7-1.3 3.3-5.9 3.4-6v-.3c-.1-.4 0-.9 0-1.1.2 0 .7.1 1.1.5.4.5.4 1.5 0 2.7-.6 1.9-.7 2.9-.2 3.4.2.2.5.3.8.1.2-.1.4-.1.7-.1 1.2-.3 3.2-.4 3.9.2.6.6.2 1.3.1 1.4-.1.2-.1.5.1.7 0 0 .4.4.4.9.1.3 0 .7-.4 1z" />
    </svg>
  )
);
export const GraphArrowUpIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M14.2 4.1v1.7h1.3l-4.7 4.7L8.3 8l-6.7 6.7 1.2 1.2 5.5-5.6 2.5 2.5L16.7 7v1.3h1.7V4.1z" />
    </svg>
  )
);
export const GraphArrowDownIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M14.2 15.9v-1.7h1.3l-4.7-4.7L8.3 12 1.6 5.3l1.2-1.2 5.5 5.6 2.5-2.5 5.9 5.8v-1.3h1.7v4.2z" />
    </svg>
  )
);
export const ThumbNewIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M18.4 8.1c-.5-.6-1.4-.8-2.1-.8-.6 0-1.5 0-2.6.1.3-1.1.7-2.9.9-3.8.2-1-.4-1.9-1.3-2.1-.9-.3-1.8.1-2.2.9l-.1.2C10.5 3.9 9.1 7.4 6.8 8c-.4-.4-.9-.6-1.5-.6H3.1c-1.1 0-2 .9-2 2v6.8c0 1.1.9 2 2 2h2.2c.6 0 1.2-.3 1.5-.7 1.1.2 5.5 1.1 8.2 1.1 1 0 3.1 0 3.3-2.3.1-.9.5-4.1.6-5.9.1-1.1 0-1.8-.5-2.3zm-.6 2.3c-.1 1.8-.5 5-.6 5.9-.1.9-.4 1.2-2.2 1.2-2.4 0-6.3-.7-7.7-1V9.4v-.3C10 8.2 11.4 4.6 12 3l.1-.2c.1-.3.5-.3.8-.2.2 0 .6.2.5.8-.2 1.1-.9 3.8-1 4.6 0 .2 0 .4.1.5.1.1.3.2.5.2 1.1-.1 2-.2 2.6-.2h.6c.7 0 1.1.2 1.3.4.3.2.4.7.3 1.5z" />
    </svg>
  )
);
export const MailLetterIcon = SvgWithProps(
  ({ ariaHidden, focusable, role, style }: Props): ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden}
      focusable={focusable}
      role={role}
      style={style}
    >
      <path d="M17.9 8.6c-.1-.1-.2-.1-.4 0l-.7.4V1.8c0-.3-.3-.6-.6-.6H3.5c-.3 0-.6.2-.7.5v7l-.3-.1c-.1-.1-.3-.1-.4 0-.4.5-.7 1.1-.6 1.8v5.7c0 1.2.7 2.6 2.6 2.6h11.8c1.3 0 2.6-.7 2.6-2.6v-5.7c.1-.6-.2-1.3-.6-1.8zM3.5 1.8H16.3v7.5l-6.2 4-6.7-4.2V1.8h.1zm-1.4 15c0-.2-.1-.4-.1-.6v-5.7c0-.5.2-1 .4-1.3l5.4 3.4-5.7 4.2zm13.8 1.4H4.1c-1 0-1.5-.4-1.8-.9l6-4.4 1.6 1h.2l1.5-1 6 4.4c-.4.6-1 .9-1.7.9zm2.1-7.8v5.7c0 .2 0 .4-.1.7l-5.7-4.2 5.4-3.4c.3.3.4.8.4 1.2z" />
      <path d="M9.1 8.8L7 6.6c-.2-.1-.2-.3 0-.5.1-.1.3-.1.4 0L9.3 8l4.1-4.2c.1-.1.3-.1.5 0 .1.2.1.4 0 .5L9.5 8.8c-.1.1-.3.1-.4 0z" />
    </svg>
  )
);
