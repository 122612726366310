type Props = {
  ariaHidden?: boolean;
  focusable?: boolean;
  role?: string;
  style?: Record<string, any>;
};

const SvgXlsx = (props: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 100 100"
    display="block"
    fill="currentColor"
    aria-hidden={true}
    focusable={false}
    role="presentation"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M17.66 66.562V4.093h45.285v20c0 1.082.871 1.957 1.953 1.957h17.266v40.512zm58.867 26.023l-1.7-3.402c-.698-1.309-1.144-2.286-1.675-3.371h-.05c-.391 1.086-.868 2.062-1.45 3.37l-1.562 3.403h-4.848l5.433-9.504-5.238-9.281h4.875l1.649 3.43a61.152 61.152 0 011.418 3.12h.054c.446-1.199.809-2.035 1.281-3.12l1.59-3.43h4.848l-5.293 9.168 5.574 9.617zm-20.34.277c-2.144 0-4.262-.558-5.32-1.144l.863-3.512c1.14.586 2.898 1.172 4.707 1.172 1.953 0 2.98-.809 2.98-2.035 0-1.168-.89-1.84-3.148-2.645-3.117-1.086-5.152-2.816-5.152-5.547 0-3.203 2.672-5.656 7.105-5.656 2.117 0 3.68.446 4.793.95l-.949 3.425c-.75-.36-2.09-.89-3.93-.89-1.836 0-2.73.836-2.73 1.812 0 1.195 1.058 1.727 3.484 2.645 3.317 1.226 4.875 2.953 4.875 5.601 0 3.149-2.422 5.824-7.578 5.824zm-7.106-.277h-11.73V73.8h4.266v15.215h7.465zm-18.914 0l-1.703-3.402c-.695-1.309-1.14-2.286-1.672-3.371h-.054c-.39 1.086-.863 2.062-1.45 3.37l-1.558 3.403h-4.848l5.434-9.504-5.238-9.281h4.875l1.644 3.43a58.104 58.104 0 011.422 3.12h.055c.449-1.199.808-2.035 1.28-3.12l1.59-3.43h4.848L29.5 82.968l5.575 9.617zm55.887-68.512a1.9 1.9 0 00-.464-1.266L66.367.85c-.004-.007-.008-.007-.016-.015a1.796 1.796 0 00-.387-.324c-.043-.028-.082-.055-.129-.079a1.935 1.935 0 00-.382-.16c-.04-.011-.07-.027-.106-.035a1.937 1.937 0 00-.45-.055H17.66a3.914 3.914 0 00-3.91 3.907v91.87a3.914 3.914 0 003.91 3.91h64.504a3.914 3.914 0 003.91-3.91V24.289c0-.07-.012-.145-.02-.215z" />
      <path d="M57.578 32.605h15.23v-6.356h-15.23zm15.23 7.328h-15.23v-6.352h15.23zm0 14.66h-15.23v-6.352h15.23zm-31.438 0H56.6v-6.352H41.37zm-16.207 0h15.23v-6.352h-15.23zm0-7.332h15.23V40.91h-15.23zm0-7.328h15.23v-6.352h-15.23zm0-7.328h15.23v-6.356h-15.23zm16.207 7.328H56.6v-6.352H41.37zm15.23-14.66H24.186V55.57h49.598V25.273z" />
    </g>
  </svg>
);

export default SvgXlsx;
